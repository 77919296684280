import { Button } from "primereact/button";

import { HealthCare } from "../../../../../iconComponents/HealthCare";
import { CompletionChecklist } from "../../../../../components/cards";

export const ManageServicesHeader = ({ onCreateServiceClick }) => {
  return (
    <div>
      <div className="flex grid-cols-12 flex-col-reverse gap-5 md:grid">
        <div className="col-span-8 flex justify-between rounded-lg bg-white p-5 font-inter xl:col-span-9">
          <div className="flex flex-col justify-between gap-2 p-4 xl:w-[60%]">
            <p className="text-2xl font-semibold text-[#3D3877]">
              The demand for your expertise is high.
            </p>
            <p className="pb-4 text-lg text-[#495057]">
              Seize the opportunity to create your services, attract new
              patients, and shape the future of healthcare in a meaningful way.
            </p>
            <div>
              <Button
                className="bg-[#594FC4] font-inter"
                onClick={onCreateServiceClick}
              >
                <i className="pi pi-plus pr-4"></i>Create Service
              </Button>
            </div>
          </div>
          <div className="hidden p-12 xl:inline-block">
            <HealthCare />
          </div>
        </div>
        <div className="col-span-4 flex xl:col-span-3">
          <CompletionChecklist view="render" />
        </div>
      </div>
    </div>
  );
};
