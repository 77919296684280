import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";

const ReusableCardList = ({
  tableData,
  actionTemplates,
  globalFilterValue,
  cardFilters,
  rightToolbarTemplate,
  setSelectedRemits,
  RemitsCheck,
  selectedRows,
}) => {
  // Filter data based on globalFilterValue and cardFilters
  const data =
    !globalFilterValue || globalFilterValue.length === 0
      ? tableData
      : cardFilters
          .flatMap((filter) =>
            tableData?.filter((row) =>
              row[filter]?.toString().includes(globalFilterValue),
            ),
          )
          .reduce((acc, current) => {
            const exists = acc?.find((row) => row.id === current.id);
            if (exists) return acc;
            return acc?.concat([current]);
          }, []);

  const renderActionTemplate = (row) => {
    return actionTemplates[0]?.template(row);
  };

  const handleCheckboxChange = (row) => {
    setSelectedRemits((prevSelectedRows) =>
      prevSelectedRows.includes(row.id)
        ? prevSelectedRows.filter((id) => id !== row.id)
        : [...prevSelectedRows, row.id],
    );
  };
  if (!data) return null;

  return (
    <>
      {RemitsCheck && (
        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
      )}
      <div className="flex flex-col gap-4 sm:hidden">
        {data.length > 0 ? (
          data.map((row, index) => (
            <div
              key={index}
              className="relative flex w-full flex-col gap-4 rounded-lg bg-light-text p-2 sm:gap-2"
            >
              {RemitsCheck && (
                <>
                  <Checkbox
                    inputId={`card-checkbox-${row.id}`}
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleCheckboxChange(row)}
                    className="absolute right-2 top-2"
                  />
                  <div className="flex flex-col gap-1">
                    <div>
                      <strong>Order Number:</strong> {row.orderNumber}
                    </div>
                    <div>
                      <strong>Order Total Charged:</strong> $
                      {row.orderTotalCharged}
                    </div>
                    <div>
                      <strong>Practice Charge Capacity:</strong> $
                      {row.practiceChargeCapacity}
                    </div>
                    <div>
                      <strong>Service Charged:</strong> ${row.serviceCharged}
                    </div>
                    <div>
                      <strong>Service Fees:</strong> {row.serviceFees}
                    </div>
                  </div>
                </>
              )}
              {!RemitsCheck && renderActionTemplate(row)}
            </div>
          ))
        ) : (
          <p className="rounded-lg bg-light-text p-2 text-center">
            No result found.
          </p>
        )}
      </div>
    </>
  );
};

export default ReusableCardList;
