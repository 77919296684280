export default function ReorderIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        width="40"
        height="40"
        rx="20"
        fill="#594FC4"
        fillOpacity="0.15"
      />
      <path
        d="M21 28.75C19.078 28.7474 17.2355 27.9827 15.8764 26.6236C14.5173 25.2645 13.7526 23.422 13.75 21.5C13.75 21.3011 13.829 21.1103 13.9697 20.9697C14.1103 20.829 14.3011 20.75 14.5 20.75C14.6989 20.75 14.8897 20.829 15.0303 20.9697C15.171 21.1103 15.25 21.3011 15.25 21.5C15.25 22.6372 15.5872 23.7489 16.219 24.6945C16.8509 25.6401 17.7489 26.3771 18.7996 26.8123C19.8502 27.2475 21.0064 27.3614 22.1218 27.1395C23.2372 26.9177 24.2617 26.37 25.0659 25.5659C25.87 24.7617 26.4177 23.7372 26.6395 22.6218C26.8614 21.5064 26.7475 20.3502 26.3123 19.2996C25.8771 18.2489 25.1401 17.3509 24.1945 16.719C23.2489 16.0872 22.1372 15.75 21 15.75H18.5C18.3011 15.75 18.1103 15.671 17.9697 15.5303C17.829 15.3897 17.75 15.1989 17.75 15C17.75 14.8011 17.829 14.6103 17.9697 14.4697C18.1103 14.329 18.3011 14.25 18.5 14.25H21C22.9228 14.25 24.7669 15.0138 26.1265 16.3735C27.4862 17.7331 28.25 19.5772 28.25 21.5C28.25 23.4228 27.4862 25.2669 26.1265 26.6265C24.7669 27.9862 22.9228 28.75 21 28.75Z"
        fill="#594FC4"
      />
      <path
        d="M20.9993 18.7499C20.9008 18.7504 20.8032 18.7312 20.7121 18.6934C20.6211 18.6556 20.5386 18.6 20.4693 18.5299L17.4693 15.5299C17.3289 15.3893 17.25 15.1987 17.25 14.9999C17.25 14.8012 17.3289 14.6105 17.4693 14.4699L20.4693 11.4699C20.538 11.3962 20.6208 11.3371 20.7128 11.2961C20.8048 11.2551 20.9041 11.2331 21.0048 11.2313C21.1055 11.2295 21.2055 11.2481 21.2989 11.2858C21.3923 11.3235 21.4772 11.3797 21.5484 11.4509C21.6196 11.5221 21.6757 11.6069 21.7135 11.7003C21.7512 11.7937 21.7697 11.8937 21.7679 11.9944C21.7662 12.0951 21.7441 12.1944 21.7031 12.2864C21.6621 12.3784 21.603 12.4612 21.5293 12.5299L19.0593 14.9999L21.5293 17.4699C21.6698 17.6105 21.7487 17.8012 21.7487 17.9999C21.7487 18.1987 21.6698 18.3893 21.5293 18.5299C21.4601 18.6 21.3775 18.6556 21.2865 18.6934C21.1955 18.7312 21.0979 18.7504 20.9993 18.7499Z"
        fill="#594FC4"
      />
    </svg>
  );
}
