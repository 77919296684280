import { useEffect, useLayoutEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

import { PrimeReactProvider } from "primereact/api";

import AppRoutes from "./AppRoutes";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

import "./index.css";
import useHandleResize from "./hooks/useHandleResize";
import useAxios from "./hooks/useAxios";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import useImpersonating from "./hooks/useImpersonating";
import { useLayoutToast } from "./store/store";
import { SessionExpiredModal } from "./components/dialogs";
import { GeneralMetaHeaders } from "./meta";

export default function App() {
  const { unPersonate } = useAxios();
  const { isSystemAdminImpersonating } = useImpersonating();
  const toast = useRef();
  const location = useLocation();
  const { isMobile } = useHandleResize();
  const { setLayoutToast } = useLayoutToast();

  useEffect(() => {
    toast.current && setLayoutToast(toast);
  }, []);

  useLayoutEffect(() => {
    document
      .getElementById("body-container")
      .scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  const productionEnv = process.env.REACT_APP_ENVIRONMENT === "prod";

  return (
    <PrimeReactProvider>
      {!productionEnv && <GeneralMetaHeaders />}
      <div
        id="body-container"
        className={`${isMobile ? "h-[100svh]" : "h-[100vh]"} overflow-auto`}
      >
        {isSystemAdminImpersonating && (
          <div className="pointer-events-none absolute top-0 z-[10003] h-full w-full border-8 border-[#F59E0B]">
            <Tag
              severity={"warning"}
              className="absolute left-1/2 -translate-x-1/2 rounded-t-none"
            >
              <div className="flex flex-row">
                <div className="font-inter text-xs">Impersonation mode</div>{" "}
                <div
                  className="pi pi-times pointer-events-auto ml-2 cursor-pointer text-xs"
                  onClick={unPersonate}
                ></div>
              </div>
            </Tag>
          </div>
        )}
        <Toast ref={toast} />
        <SessionExpiredModal />
        <AppRoutes></AppRoutes>
      </div>
    </PrimeReactProvider>
  );
}
