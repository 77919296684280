import { useRef } from "react";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

import useAxios from "../../../../../hooks/useAxios";

function DepositCard({ cardData, deleteCard, getDepositData, showToast }) {
  const menuRight = useRef(null);
  const { http } = useAxios();
  const toggleDefault = async () => {
    try {
      const res = await http.put(`/BankAccountInfos/${cardData.id}`, {
        ...cardData,
        isDefault: !cardData.isDefault,
        confirmBankAccountNumber: cardData.bankAccountNumber,
      });
      showToast("success", "Success", res.data.message);
      getDepositData();
    } catch (err) {
      showToast("error", "Error", err.response.data.message);
    }
  };
  const deleteDeposit = (id) => {
    deleteCard(id);
  };

  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Set As Default",
          icon: "pi pi-bookmark",
          disabled: cardData.isDefault,
          command: () => toggleDefault(),
        },
        !cardData.isDefault && {
          label: "Delete",
          icon: "pi pi-trash",
          command: () => deleteDeposit(cardData.id),
        },
      ].filter(Boolean),
    },
  ];

  return (
    <>
      <div className="flex w-full items-center justify-between rounded-lg bg-light-text p-5">
        <div>
          <div className="font-semibold">
            {cardData.bankAccountNumber}{" "}
            <span className="inline rounded-full bg-blue-100 px-3 text-sm text-blue-500 sm:hidden">
              {cardData.isDefault && "Default"}
            </span>
          </div>
          <div className="font-light text-slate-700">{cardData.bankName}</div>
        </div>
        <div className="hidden sm:flex">
          <div
            disabled={cardData.isDefault}
            onClick={() => !cardData.isDefault && toggleDefault()}
            className={`mr-10 flex cursor-pointer items-center rounded-full text-blue-500 ${
              cardData.isDefault ? "bg-blue-100 px-5 py-2" : ""
            }`}
          >
            {cardData.isDefault ? "Default" : "SET AS DEFAULT"}
          </div>

          {!cardData.isDefault && (
            <div
              onClick={() => deleteDeposit(cardData.id)}
              className="flex cursor-pointer items-center justify-center p-1 text-red-500"
            >
              <i className="pi pi-trash ml-1" />
            </div>
          )}
        </div>
        <Button
          icon="pi pi-ellipsis-v inline-flex sm:hidden"
          className="inline-flex border-0 bg-transparent text-slate-600 sm:hidden"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
        <Menu
          model={items}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
          className="block sm:hidden"
        />
      </div>
    </>
  );
}

export default DepositCard;
