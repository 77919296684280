import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { InputTextarea } from "primereact/inputtextarea";
import { useOverlayScrollListener } from "primereact/hooks";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../hooks/useAxios";
import useHandleResize from "../../../hooks/useHandleResize";
import ManageChargeForm from "./components/ManageChargeForm";
import { ReusableDataList } from "../../../components/Table";
import SelectUserImpersonate from "./components/SelectUserImpersonate";
import useDelayedInputChange from "../../../hooks/useDelayedInputChange";
import {
  useRefreshTokenStore,
  useSwitchOrdering,
  useTokenStore,
} from "../../../store/store";

function OurPractices() {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);
  const toast = useRef(null);
  const { http } = useAxios();
  const menuRight = useRef({});
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const [refetch, setRefetch] = useState();
  const [entityId, setEntityId] = useState();
  const [confirmData, setConfirmData] = useState();
  const [disableNote, setDisableNote] = useState();
  const [serviceFees, setServiceFees] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isImpersonateLoading, setIsImpersonateLoading] = useState(false);
  const { setIsOrderingMode } = useSwitchOrdering();
  const [editFeesVisible, setEditFeesVisible] = useState(false);
  const [disableNoteVisible, setDisableNoteVisible] = useState(false);
  const [showDialogChooseUser, setShowDialogChooseUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedEntityId, setSelectedEntityId] = useState();
  const { refreshToken: currentRefreshToken, setRefreshToken } =
    useRefreshTokenStore();
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );

  const { isMobile } = useHandleResize();

  const handleScroll = () => {
    setVisible(false);
  };

  const handleUserIdSubmit = (UserId) => {
    setSelectedUserId(UserId);
    EditPersonate(UserId);
  };
  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const ExternalOrdersTemplate = (rowData) => (
    <div>
      <h4 className="text-center font-inter text-xl font-bold">
        {rowData.fees}%
      </h4>
    </div>
  );

  const InternalOrdersTemplate = (rowData) => (
    <div>
      <h4 className="text-center font-inter text-xl font-bold">
        {rowData.internalPracticeFees}%
      </h4>
    </div>
  );

  const CapacityTemplate = (rowData) => (
    <div>
      <h4 className="text-center font-inter text-lg font-bold">
        ${rowData.serviceChargeCapacity}
      </h4>
    </div>
  );
  const statusTemplate = (rowData) => {
    return (
      <>
        {rowData.status === 3 ? (
          <div className="inline rounded-md bg-red-200 p-2 text-center text-sm text-red-700 sm:block sm:p-3 sm:text-base">
            Denied
          </div>
        ) : rowData.status === 2 ? (
          <div className="inline rounded-md bg-green-200 p-2 text-center text-sm text-green-700 sm:block sm:p-3 sm:text-base">
            Approved
          </div>
        ) : (
          <div className="inline rounded-md bg-[#FDBA74] p-2 text-center text-sm text-orange-700 sm:block sm:p-3 sm:text-base">
            Pending
          </div>
        )}
      </>
    );
  };
  const columns = [
    { name: "Entity Name", value: "name" },
    { name: "Owner Name ", value: "ownerName" },
    {
      name: "External Order Fees",
      template: ExternalOrdersTemplate,
    },
    {
      name: "Internal Order Fees",
      template: InternalOrdersTemplate,
    },
    { name: "Charge Capacity", template: CapacityTemplate },
    { name: "Status", value: "status", template: statusTemplate },
    {
      name: "Notes",
      template: (rowData) => {
        return rowData.isEnabled === true && rowData.isHidden === true ? (
          "This practice still has in progress orders"
        ) : (
          <div className="text-center font-bold">-</div>
        );
      },
    },
  ];

  const dialogDisableNote = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => {
          setDisableNoteVisible(false);
          setDisableNote("");
        }}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          setDisableNoteVisible(false);
          confirmDialog({
            icon: "pi pi-info-circle",
            message:
              confirmData.isHidden === false
                ? "Are you sure to disable this entity?"
                : "Are you sure to enable this entity?",
            accept: () => EditEnabled(confirmData),
          });
          setDisableNote("");
        }}
        disabled={!disableNote}
        autoFocus
      />
    </div>
  );

  const confirm = (rowData) => {
    setDisableNoteVisible(true);
    setEntityId(rowData.entityId);
    setConfirmData(rowData);
  };

  const confirmEnable = (rowData) => {
    confirmDialog({
      icon: "pi pi-info-circle",
      message:
        rowData.isHidden === false
          ? "Are you sure to disable this entity?"
          : "Are you sure to enable this entity?",
      accept: () => EditEnabled(rowData),
    });
  };

  let practicesForAdminUpdateDtos = [];
  const EditEnabled = async (rowData) => {
    if (rowData.isEnabled === true && rowData.isHidden === true) {
      practicesForAdminUpdateDtos.push({
        id: rowData.entityId,
        isEnabled: rowData.isEnabled,
        disableNote: disableNote,
      });
    } else {
      practicesForAdminUpdateDtos.push({
        id: rowData.entityId,
        isEnabled: !rowData.isEnabled,
        disableNote: disableNote,
      });
    }
    setIsLoading(true);
    try {
      const response = await http.put(`/Entities/UpdatePracticesForAdmin`, {
        practicesForAdminUpdateDtos,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      setRefetch((e) => !refetch);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const EditPersonate = async (userId) => {
    setIsImpersonateLoading(true);
    try {
      const response = await http.post(
        `/Entities/ImpersonatePracticeUser/${selectedEntityId}`,
        {
          userId: userId,
          currentRefreshToken,
        },
      );
      setToken(response.data.authResponse.token);
      setRefreshToken(response.data.authResponse.refreshToken);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      setIsOrderingMode(false);
      navigate(`/Dashboard/`, {
        replace: true,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000,
      });
    } finally {
      setIsImpersonateLoading(false);
    }
  };
  const practiceDetails = async (rowData, navigate) => {
    navigate(`/admin/ourPractices/${rowData.dueDiligenceId}`, {
      state: { rowData },
    });
  };
  const actionTemplate = (rowData) => {
    const handlePracticeClick = () => {
      practiceDetails(rowData, navigate);
    };
    return (
      <div>
        <Button
          onClick={() => {
            if (rowData.isHidden === false) {
              setEditFeesVisible(true);
              setServiceFees({
                fees: rowData.fees,
                internalPracticeFees: rowData.internalPracticeFees,
                serviceChargeCapacity: rowData.serviceChargeCapacity,
                internalServiceChargeCapacity:
                  rowData.internalServiceChargeCapacity,
              });
              setEntityId(rowData.entityId);
            }
          }}
          link
        >
          <i
            className={`custom-target-icon pi pi-pencil ${
              rowData.isHidden === true ? "text-gray-500" : "text-indigo-600"
            }`}
            data-pr-tooltip={`${
              rowData.isHidden === true ? "Fee Editing Disabled" : "Edit fee"
            }`}
            data-pr-position="top"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
        <Button
          onClick={() => {
            setSelectedEntityId(rowData.entityId);
            setShowDialogChooseUser(true);
          }}
          link
        >
          <i
            className="custom-target-icon pi pi-user text-sky-500"
            data-pr-tooltip="Impersonate"
            data-pr-position="top"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
        <Button
          onClick={() => {
            if (rowData.isHidden == false) {
              confirm(rowData);
              setDisableNoteVisible(true);
              setDisableNote("");
            } else {
              confirmEnable(rowData);
            }
          }}
          link
        >
          <i
            className={`custom-target-icon pi pi-power-off font-semibold ${
              rowData.isHidden === false ? "text-emerald-500" : "text-red-700"
            }`}
            data-pr-tooltip={`${
              rowData.isHidden === false ? "Disable" : "Enable"
            }`}
            data-pr-position="top"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>

        <Button onClick={handlePracticeClick} link>
          <i
            className="custom-target-icon pi pi-info-circle font-semibold text-gray-600"
            data-pr-tooltip="Practice Details"
            data-pr-position="top"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
      </div>
    );
  };

  const menuTemplate = (item) => {
    return (
      <div onClick={item.command} className="p-menuitem-content">
        <button className="align-items-center p-menuitem-link flex w-full">
          <span className={`${item.icon} ${item.styles}`} />
          <span className="mx-2">{item.label}</span>
        </button>
      </div>
    );
  };

  const getMenuItems = (rowData) => {
    const items = [
      {
        label: "Edit Fees",
        icon: "pi pi-pencil",
        styles: rowData.isHidden ? "text-gray-500" : "text-indigo-600",
        command: () => {
          if (!rowData.isHidden) {
            setEditFeesVisible(true);
            setServiceFees({
              fees: rowData.fees,
              internalPracticeFees: rowData.internalPracticeFees,
              serviceChargeCapacity: rowData.serviceChargeCapacity,
              internalServiceChargeCapacity:
                rowData.internalServiceChargeCapacity,
            });
            setEntityId(rowData.entityId);
          }
        },
        template: menuTemplate,
      },
      {
        label: "Impersonate",
        icon: "pi pi-user",
        styles: "text-sky-500",
        command: () => {
          setSelectedEntityId(rowData.entityId);
          setShowDialogChooseUser(true);
        },
        template: menuTemplate,
      },
      {
        label: "Enable/Disable",
        icon: "pi pi-power-off",
        styles: rowData.isHidden ? "text-red-700" : "text-emerald-500",
        command: () => {
          if (rowData.isHidden) {
            confirmEnable(rowData);
          } else {
            confirm(rowData);
            setDisableNoteVisible(true);
            setDisableNote("");
          }
        },
        template: menuTemplate,
      },
      {
        label: "Practice Details",
        icon: "pi pi-info-circle",
        styles: "",
        command: () => {
          practiceDetails(rowData, navigate);
        },
        template: menuTemplate,
      },
    ];

    return items;
  };

  const cardActionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.entityId].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.entityId}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.entityId] = el)}
          id={`popup_menu_right_${rowData.entityId}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">{row.name}</p>
          <p className="font-semibold">
            <span className="text-slate-500">&nbsp;Fees:&nbsp;</span>
            <span>{row.fees}%</span>
          </p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            <i className="pi pi-user" />
            <span className="text-slate-500">&nbsp;Owner Name:&nbsp;</span>
            <span>{row.ownerName}</span>
          </p>
          <div>{cardActionTemplate(row)}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <Tooltip target=".tooltip-icon" />
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="rounded-t-lg">
        {isLoading ? (
          <div className="absolute z-10 flex h-full w-[84%] items-center justify-center overflow-hidden bg-white bg-opacity-60">
            <ProgressSpinner
              strokeWidth={4}
              style={{ width: "40px", height: "40px" }}
            />
          </div>
        ) : null}
        <Dialog
          blockScroll
          draggable={false}
          header="Manage Practice Charge"
          visible={editFeesVisible}
          style={{ minWidth: "30vw" }}
          onHide={() => setEditFeesVisible(false)}
        >
          <ManageChargeForm
            toast={toast}
            refetch={refetch}
            entityId={entityId}
            setRefetch={setRefetch}
            serviceFees={serviceFees}
            setEditFeesVisible={setEditFeesVisible}
          />
        </Dialog>
        <Dialog
          blockScroll
          draggable={false}
          header="Enter disable note"
          visible={disableNoteVisible}
          style={{ minWidth: "30vw" }}
          onHide={() => setDisableNoteVisible(false)}
          footer={dialogDisableNote}
        >
          <p>
            <InputTextarea
              value={disableNote}
              onChange={(e) => setDisableNote(e.target.value)}
              className="w-full"
            />
          </p>
        </Dialog>
        <SelectUserImpersonate
          showDialogChooseUser={showDialogChooseUser}
          setShowDialogChooseUser={setShowDialogChooseUser}
          onUserIdSubmit={handleUserIdSubmit}
          selectedEntityId={selectedEntityId}
          setIsImpersonateLoading={setIsImpersonateLoading}
          isImpersonateLoading={isImpersonateLoading}
        />
        <ReusableDataList
          title={"List of practices"}
          sendSearchData={handleInputChange}
          searchValue={searchValue}
          dataLink={`/Entities/GetAllPracticesForAdmin?Filters=name@=*${searchValue}`}
          columns={columns}
          actionTemplates={
            isMobile
              ? [{ template: cardTemplate }]
              : [{ template: actionTemplate, header: "Actions" }]
          }
          refetch={refetch}
        />
      </div>
    </>
  );
}

export default OurPractices;
