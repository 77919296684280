import { useRef, useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";

const HeightInput = ({ heightValue, onHeightChange, disabled = false }) => {
  const heightData = useRef({ ft: 0, in: 0 });
  const [feetValue, setFeetValue] = useState(null);
  const [inchesValue, setInchesValue] = useState(null);

  useEffect(() => {
    if (heightValue !== null && heightValue !== undefined) {
      const ft = Math.floor(heightValue);
      const inches = Math.round((heightValue - ft) * 12);
      heightData.current = { ft, in: inches };
      setFeetValue(ft);
      setInchesValue(inches);
    } else {
      heightData.current = { ft: 0, in: 0 };
      setFeetValue(null);
      setInchesValue(null);
    }
  }, [heightValue]);

  const handleFeetChange = (e) => {
    const feet = e.value || 0;
    heightData.current.ft = feet;
    setFeetValue(feet);
    onHeightChange(feet + heightData.current.in / 12);
  };

  const handleInchesChange = (e) => {
    const inches = e.value || 0;
    heightData.current.in = inches;
    setInchesValue(inches);
    onHeightChange(heightData.current.ft + inches / 12);
  };

  const handleFeetKeyDown = (e) => {
    const { value } = e.target;
    const isHighlighting = e.target.selectionStart !== e.target.selectionEnd;
    const isZero = value === "0";

    if (!isHighlighting && isZero && /^[0-9]$/.test(e.key)) {
      // Replace 0 with new input
      setFeetValue(+e.key);
      heightData.current.ft = +e.key;
      onHeightChange(+e.key + heightData.current.in / 12);
      e.preventDefault();
    }

    const newValue = Number(value + e.key);
    if (newValue > 9 && !isHighlighting) e.preventDefault();
  };

  const handleInchesKeyDown = (e) => {
    const { value } = e.target;
    const isHighlighting = e.target.selectionStart !== e.target.selectionEnd;
    const isZero = value === "0";

    if (!isHighlighting && isZero && /^[0-9]$/.test(e.key)) {
      // Replace 0 with new input
      setInchesValue(+e.key);
      heightData.current.in = +e.key;
      onHeightChange(heightData.current.ft + +e.key / 12);
      e.preventDefault();
    }

    const newValue = Number(value + e.key);
    if (newValue > 11 && !isHighlighting) e.preventDefault();

    setTimeout(() => {
      const target = e.target;
      target.setSelectionRange(target.value.length, target.value.length);
    }, 0);
  };

  return (
    <div className="flex gap-4">
      <div className="flex items-center gap-1">
        <InputNumber
          useGrouping={false}
          className="!w-12"
          inputClassName="!w-12"
          keyfilter={/^\d+$/}
          max={9}
          min={0}
          value={feetValue}
          onChange={handleFeetChange}
          onKeyDown={handleFeetKeyDown}
          disabled={disabled}
          placeholder="ft."
        />
        <span className="font-semibold text-gray-500"> (ft)</span>
      </div>

      <div className="flex items-center gap-1">
        <InputNumber
          useGrouping={false}
          className="!w-12"
          inputClassName="!w-12"
          keyfilter={/^\d+$/}
          max={11}
          min={0}
          value={inchesValue}
          onChange={handleInchesChange}
          onKeyDown={handleInchesKeyDown}
          disabled={disabled}
          placeholder="in."
        />
        <span className="font-semibold text-gray-500"> (in)</span>
      </div>
    </div>
  );
};

export default HeightInput;
