import { useRef, useState } from "react";

import { Link } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import useHandleResize from "../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../components/Table";
import { BillableItemHeader } from "./components/BillableItemHeader";
import { CreateBillableItem } from "../components/CreateBillableItem";
import useDelayedInputChange from "../../../../hooks/useDelayedInputChange";
import AssociatedServicesDialog from "./components/AssociatedServicesDialog";

export const BillableItem = () => {
  const toast = useRef(null);
  const { entityId } = useTokenData();
  const [visible, setVisible] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { http } = useAxios();
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );
  const { isMobile } = useHandleResize();

  const onCreateBillableItemClick = () => {
    setRowData(null);
    setVisible(true);
  };

  const handleEdit = (rowData) => {
    setRowData(rowData);
    setVisible(true);
  };
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [serviceNames, setServiceNames] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisibleDelete(false)}
        autoFocus
      />
    </div>
  );
  const toastFunction = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const acceptDelete = async (rowData) => {
    try {
      const res = await http.delete(`/BillableItems/${rowData.id}`);
      setRefetch(!refetch);
      toastFunction("success", "Success", "Item Deleted Successfully");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        const billableItemInServices =
          err.response.data.billableItemInServices || [];
        const serviceNames = billableItemInServices
          .map((service) => service.name)
          .join(", ");
        setServiceNames(serviceNames);
        setErrorMessage(err.response.data.message);
        setVisibleDelete(true);
      }
    }
  };

  const handleDelete = (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: () => acceptDelete(rowData),
    });
  };
  const showRelatedServices = (rowData) => {
    setRowData(rowData);
    setShowServices(true);
  };
  const modTemplate = (rowData, mod) => {
    return rowData[mod] ? (
      <p>{rowData[mod]}</p>
    ) : (
      <p className="border-[1px] border-gray-400"></p>
    );
  };

  const priceTemplate = (rowData) => {
    return (
      <span className="flex items-center gap-1">
        <i className="pi pi-dollar text-green-700" />
        {rowData.chargeAmount}
      </span>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex items-center gap-2">
        <Tooltip target=".table-row" className="max-w-sm" />

        <i
          onClick={() => handleEdit(rowData)}
          className="pi pi-pencil table-row cursor-pointer rounded-full border-2 p-2 text-purple-700"
          data-pr-tooltip="Edit"
          data-pr-position="top"
        />
        <i
          onClick={() => handleDelete(rowData)}
          className="pi pi-trash table-row cursor-pointer rounded-full border-2 p-2 text-red-700"
          data-pr-tooltip="Delete"
          data-pr-position="top"
        />
        <i
          onClick={() => showRelatedServices(rowData)}
          className="pi pi-list table-row cursor-pointer rounded-full border-2 p-2 text-gray-700"
          data-pr-tooltip="Show Services"
          data-pr-position="top"
        />
      </div>
    );
  };
  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <p
          className="table-row cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };
  const cardTemplate = (row) => {
    return (
      <>
        <p className="font-semibold">
          <span className="font-semibold text-slate-500">HCPCS:&nbsp;</span>
          {row.hcpcs}
        </p>
        <p className="font-semibold">
          <span className="text-slate-500">Description:&nbsp;</span>
          {row.description}
        </p>
        <div className="flex flex-wrap gap-2">
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 1:&nbsp;</span>
            {row.mod1}
          </p>
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 2:&nbsp;</span>
            {row.mod2}
          </p>
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 3:&nbsp;</span>
            {row.mod3}
          </p>
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 4:&nbsp;</span>
            {row.mod4}
          </p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            <span className="text-slate-500">Price per unit:&nbsp;</span>
            <i className="pi pi-dollar text-green-700" />
            {row.chargeAmount}
          </p>
          <div className="flex items-center gap-2">
            <i
              onClick={() => handleEdit(row)}
              className="pi pi-pencil cursor-pointer rounded-full border-2 p-2 text-purple-700"
            />
            <i
              onClick={() => handleDelete(row)}
              className="pi pi-trash cursor-pointer rounded-full border-2 p-2 text-red-700"
            />
            <i
              onClick={() => showRelatedServices(row)}
              className="pi pi-list table-row cursor-pointer rounded-full border-2 p-2 text-gray-700"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        blockScroll
        draggable={false}
        visible={visible}
        onHide={() => setVisible(false)}
        header={`${rowData ? "Edit" : "New"} Billable Item`}
        className="w-11/12 lg:w-[80vw]"
      >
        <CreateBillableItem
          refetch={refetch}
          setRefetch={setRefetch}
          data={rowData}
          setVisible={setVisible}
          toast={toast}
        />
      </Dialog>
      <Dialog
        blockScroll
        draggable={false}
        visible={showServices}
        onHide={() => setShowServices(false)}
        header={`${rowData?.hcpcs} is used in the following services:`}
        className="w-11/12 lg:w-[60vw]"
      >
        <AssociatedServicesDialog id={rowData?.id} />
      </Dialog>

      <BillableItemHeader
        onCreateBillableItemClick={onCreateBillableItemClick}
        sendSearchData={handleInputChange}
        searchValue={searchValue}
      />
      <div className="mt-4">
        <ReusableDataList
          headerAction={
            <Link to="/Service/billableItems/report">
              <Button icon="pi pi-book" label="Show Full Report" outlined />
            </Link>
          }
          searchTitle="Search HCPCS, Description"
          fetchMethod="put"
          payload={[]}
          dataLink={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
          sendSearchData={handleInputChange}
          searchValue={searchValue}
          columns={[
            { name: "HCPCS", value: "hcpcs" },
            {
              name: "Short Description",
              template: descriptionWithTooltipTemplate,
            },
          ]}
          actionTemplates={[
            isMobile
              ? { template: cardTemplate }
              : { template: (e) => modTemplate(e, "mod1"), header: "Mod 1" },
            { template: (e) => modTemplate(e, "mod2"), header: "Mod 2" },
            { template: (e) => modTemplate(e, "mod3"), header: "Mod 3" },
            { template: (e) => modTemplate(e, "mod4"), header: "Mod 4" },
            { template: priceTemplate, header: "Price" },
            { template: actionTemplate, header: "Actions" },
          ]}
          refetch={refetch}
          addNew={onCreateBillableItemClick}
        />
      </div>
      <div className="w-full rounded-lg"></div>

      <Dialog
        header="Oops! It looks like there's an issue "
        visible={visibleDelete}
        onHide={() => setVisibleDelete(false)}
        footer={footerContent}
        className="w-3/6"
      >
        <div className="flex gap-2">
          <i
            className="pi pi pi-info-circle text-red-300"
            style={{ fontSize: "2rem" }}
          ></i>
          <p>
            This billableItem is currently associated with the following
            services:
            {serviceNames && (
              <p className="pb-1 pt-1 font-semibold"> {serviceNames} </p>
            )}
            Please ensure all references to this billableItem are removed from
            your services before proceeding with deletion.
          </p>
        </div>
      </Dialog>
    </>
  );
};
