import { Skeleton } from "primereact/skeleton";

export const ProviderInfoCardSkeleton = ({ infoCount = 2 }) => {
  return (
    <div className="bg-white p-3">
      <div className="flex w-full justify-between">
        <div className="flex w-[200px] gap-4">
          <Skeleton size="50px" shape="circle" />
          <div className="flex w-[80%] flex-col gap-2">
            <Skeleton className="p-3" width="70%" />
            <Skeleton className="p-2" />
          </div>
        </div>
        <div className="mb-6 flex w-72 gap-4">
          <Skeleton className="rounded-full p-6" />
          <Skeleton className="rounded-full p-6" />
        </div>
      </div>
      {Array(infoCount)
        .fill(0)
        .map((n, i) => (
          <div key={i} className="mt-3 w-full">
            <div className="flex w-[80%] gap-4">
              <Skeleton className="p-3" width="30%" />
              <Skeleton className="p-3" />
            </div>
          </div>
        ))}
    </div>
  );
};
