export const InvitationStatusTemplate = ({ status }) => {
  const statusData = {
    1: { className: "bg-orange-100 text-orange-600", content: "Pending" },
    2: { className: "bg-green-200 text-green-700", content: "Approved" },
    4: { className: "bg-red-200 text-red-700", content: "Declined" },
  };

  const { content, className } = statusData[status];

  return content ? (
    <div
      className={`max-w-[200px] rounded-md p-2 text-center text-sm font-bold sm:p-3 sm:text-base ${className}`}
    >
      {content}
    </div>
  ) : null;
};
