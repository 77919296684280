import { useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../hooks/useAxios";
import EmailVerifyIcon from "../../../iconComponents/EmailVerifyIcon";

import {
  useLayoutToast,
  useRefreshTokenStore,
  useTokenStore,
} from "../../../store/store";

function ConfirmPhoneNumberDialog({
  setSentData,
  setDialogVisible,
  VerifyPhoneNumber,
}) {
  const { http } = useAxios();
  const timeoutRef = useRef(null);
  const { setToken } = useTokenStore();
  const { layoutToast } = useLayoutToast();
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setRefreshToken } = useRefreshTokenStore();
  const [showRetryMessage, setShowRetryMessage] = useState(false);

  const OTPDefaultValue = {
    OTP: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: { OTPDefaultValue },
  });

  const timeOut = () => {
    timeoutRef.current = setTimeout(() => {
      setShowRetryMessage(true);
    }, 10_000);
  };

  const resetTimeout = () => {
    clearTimeout(timeoutRef?.current);
    setShowRetryMessage(false);
    timeOut();
  };

  const sendOTP = async () => {
    setIsSending(true);
    try {
      const response = await http.post(
        "/Authentication/SendPhoneNumberConfirmationCode",
        {
          phoneNumber: VerifyPhoneNumber,
        },
      );
      setSentData((old) => ({ ...old, [VerifyPhoneNumber]: Date.now() }));
      setIsSent(true);
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      resetTimeout();
    } catch (error) {
      if (error.response.data.message === "phone number already verified") {
        setDialogVisible(true);
        layoutToast.current.show({
          severity: "error",
          summary: "error",
          detail: error.response.data.message,
          life: 4000,
        });
      } else if (error.response.data.message.includes("Retry After")) {
        setIsSent(true);
        layoutToast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: error.response.data.message,
          life: 4000,
        });
        resetTimeout();
      } else {
        layoutToast.current.show({
          severity: "error",
          summary: "error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleVerifyNumber = async (formData) => {
    try {
      setIsLoading(true);
      const response = await http.post("/Authentication/VerifyPhoneNumber", {
        phoneNumber: VerifyPhoneNumber,
        confirmationCode: formData.OTP,
      });
      setDialogVisible(false);
      setToken(response?.data?.token);
      setRefreshToken(response?.data?.refreshToken);
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: response?.data?.message || "Verification success",
        life: 4000,
      });
      reset(OTPDefaultValue);
    } catch (error) {
      layoutToast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Verification failed",
        life: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="mb-2 flex justify-center">
        <EmailVerifyIcon />
      </div>
      {!isSent ? (
        <div className="flex flex-col gap-6 text-center">
          <h5 className="mb-2 mt-7 text-lg font-bold capitalize">
            step 1: send OTP{" "}
          </h5>
          <p className="text-center font-inter leading-loose">
            Click the{" "}
            <strong
              onClick={sendOTP}
              className="border-b-2 border-light-purple text-light-purple hover:cursor-pointer"
            >
              Send OTP
            </strong>{" "}
            Button to send your Mobile Phone a Unique Confirmation Code.
          </p>
          <div className="flex flex-col items-center gap-1">
            <span>The code will be sent to </span>
            <strong className="border-b p-2">{VerifyPhoneNumber}</strong>
          </div>
          <div className="mt-5 text-center">
            <Button
              label="Send OTP"
              className="px-16"
              icon="pi pi-envelope"
              loading={isSending}
              onClick={sendOTP}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-3">
          <h5 className="mb-2 mt-7 capitalize">
            <span className="text-lg font-bold">step 2:</span> transcribe the
            OTP Code that you received
          </h5>
          <h4 className="text-center font-Poppins leading-loose text-normal-text">
            Verify your phone number to complete setting up your account{" "}
          </h4>
          <div className="flex flex-col items-center gap-1">
            <span>Enter the verification code sent to</span>
            <strong className="border-b p-2">{VerifyPhoneNumber}</strong>
          </div>
          <form
            onSubmit={handleSubmit(handleVerifyNumber)}
            className="mt-3 w-full"
          >
            <InputText
              name="OTP"
              placeholder="OTP Code"
              className="w-full pb-2 pt-2"
              maxLength={6}
              keyfilter={/^\d+$/}
              {...register("OTP", {
                required: "OTP code is required",
                pattern: {
                  value: /^\d{6}$/,
                  message: "OTP code must be 6 digits",
                },
              })}
              onChange={() => {
                resetTimeout();
              }}
            />

            {errors.OTP && (
              <p className="mt-2 text-red-500">{errors.OTP.message}</p>
            )}
            {showRetryMessage && (
              <Message
                className="mt-3"
                severity="warn"
                content={
                  <div className="text-center">
                    <p className="text-xs">
                      Didn't receive a unique code? Please make sure the number
                      listed is correct and you can try again by {""}
                      <strong
                        onClick={sendOTP}
                        className="border-b-2 border-light-purple text-light-purple hover:cursor-pointer"
                      >
                        Send OTP
                      </strong>
                      , and also please make sure your phone did not mark the
                      inbound message as spam.
                    </p>
                  </div>
                }
              />
            )}

            <div className="mt-5 flex justify-end gap-2">
              <Button
                label="Cancel"
                severity="danger"
                type="button"
                onClick={() => setDialogVisible(false)}
              />
              <Button
                label="Verify"
                loading={isLoading}
                severity="success"
                className=""
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ConfirmPhoneNumberDialog;
