import { Navigate } from "react-router-dom";
import EmailVerifySuccess from "../pages/setupPractice/EmailVerifySuccess";
import { useStoreDataCookies, useTokenStore } from "../store/store";

function EmailSuccessGuard() {
  const { token } = useTokenStore();
  const { data } = useStoreDataCookies();

  if (token || data) {
    return <EmailVerifySuccess />;
  } else {
    return <Navigate replace to="/login" />;
  }
  // return <Outlet />;
}

export default EmailSuccessGuard;
