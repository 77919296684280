import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../hooks/useAxios";

const ManageChargeForm = ({
  toast,
  refetch,
  entityId,
  setRefetch,
  serviceFees,
  setEditFeesVisible,
}) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange" });
  const { http } = useAxios();
  const [isLoading, setIsLoading] = useState();

  const onPracticeChargeSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const response = await http.put(
        `/Entities/UpdateFessAndMaxChargePracticeForAdmin`,
        {
          practicesForAdminUpdateDtos: [
            {
              ...formData,
              id: entityId,
            },
          ],
        },
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      reset();
      setEditFeesVisible(false);
      setRefetch(!refetch);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const feeRules = {
    required: "Please enter the fees.",
    min: {
      value: 0,
      message: "Fees must be a minimum of 0.",
    },
    max: {
      value: 50,
      message: "Fees cannot exceed 50.",
    },
  };

  const serviceChargeCapacityRules = {
    required: "Please enter the service charge capacity.",
    min: {
      value: 1,
      message: "Service charge capacity must be a minimum of 1",
    },
  };

  useEffect(() => {
    serviceFees && reset(serviceFees);
  }, [reset, serviceFees]);

  const getErrorMessage = (error) => {
    return (
      errors?.[error] && <p className="text-red-500">{errors[error].message}</p>
    );
  };

  return (
    <form
      className="flex flex-col gap-8"
      onSubmit={handleSubmit(onPracticeChargeSubmit)}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
          <div className="mb-4">
            <label className="mb-1 block">External Order Fees</label>
            <Controller
              control={control}
              name="fees"
              render={({ field }) => (
                <InputText
                  maxLength={2}
                  keyfilter={/^\d+$/}
                  {...field}
                  className="w-full"
                />
              )}
              rules={feeRules}
            />
            {getErrorMessage("fees")}
          </div>
          <div className="mb-4">
            <label className="mb-1 block">Internal Order Fees</label>
            <Controller
              control={control}
              name="internalPracticeFees"
              render={({ field }) => (
                <InputText
                  maxLength={2}
                  keyfilter={/^\d+$/}
                  {...field}
                  className="w-full"
                />
              )}
              rules={feeRules}
            />
            {getErrorMessage("internalPracticeFees")}
          </div>
          <div className="mb-4">
            <label className="mb-1 block">Service Charge Capacity</label>
            <Controller
              control={control}
              name="serviceChargeCapacity"
              render={({ field }) => (
                <InputText keyfilter={/^\d+$/} {...field} className="w-full" />
              )}
              rules={serviceChargeCapacityRules}
            />
            {getErrorMessage("serviceChargeCapacity")}
          </div>
        </div>
      </div>
      <div className="text-end">
        <Button
          label="No"
          icon="pi pi-times"
          type="button"
          onClick={() => {
            setEditFeesVisible(false);
            reset();
          }}
          disabled={isLoading}
          className="p-button-text"
        />
        <Button label="Yes" icon="pi pi-check" loading={isLoading} autoFocus />
      </div>
    </form>
  );
};

export default ManageChargeForm;
