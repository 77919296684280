import { Navigate } from "react-router-dom";
import AdminLogin from "../pages/admin/login/AdminLogin";
import useTokenData from "../hooks/useTokenData";

export const AdminLoginAuthGaurd = () => {
  const { userId } = useTokenData();
  if (userId) {
    return <Navigate to={"/admin/ourPractices"} replace />;
  }
  return <AdminLogin />;
};
