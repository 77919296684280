import MSLogo from "../../iconComponents/MSLogo";

function MSAuthButton({ label }) {
  return (
    <div className="flex h-[41px] cursor-pointer items-center gap-3 border border-[8c8c8c] bg-white px-3 text-[#5e5e5e]">
      <MSLogo />
      <span className="font-semibold">{label}</span>
    </div>
  );
}

export default MSAuthButton;
