import { Navigate, Outlet, useLocation } from "react-router-dom";

import Layout from "../layouts/Layout";
import useTokenData from "../hooks/useTokenData";
import { useExpireSystemAdmin, useRefreshTokenStore } from "../store/store";

function ProtectedRoute() {
  const location = useLocation();
  const { refreshToken } = useRefreshTokenStore();
  const { isExpireAdmin } = useExpireSystemAdmin();
  const { entityId, SystemAdministrator, UserJourneyStatus } = useTokenData();

  return (
    <>
      {(refreshToken && entityId && UserJourneyStatus === "2") ||
      SystemAdministrator === "SystemAdministrator" ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : refreshToken && UserJourneyStatus === "2" ? (
        <Navigate to="/chooseEntity/" replace />
      ) : SystemAdministrator === "SystemAdministrator" &&
        isExpireAdmin == true ? (
        <Navigate to={"/admin/expired"} replace />
      ) : (
        <Navigate
          to={location.pathname.includes("admin") ? "/admin/login" : "/login"}
          replace
        />
      )}
    </>
  );
}

export default ProtectedRoute;
