import { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../../hooks/useAxios";
import { Pagination } from "../../../../../components/Table";

const SelectSingleBillableTable = ({
  link,
  value,
  payload,
  refetch,
  selection,
  searchValue,
  emptyMessage,
  onUnitsChange,
  onSelectionChange,
  handleInputChange,
}) => {
  const { http } = useAxios();

  const [first, setFirst] = useState(0);
  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  const handlePageChange = (event) => {
    setFirst(event.first);
    if (first !== event.first) {
      fetchData(event.page);
    }
  };

  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const modTemplate = (rowData, mod) => {
    return rowData[mod] ? (
      <p>{rowData[mod]}</p>
    ) : (
      <p className="border-[1px] border-gray-400"></p>
    );
  };

  const priceTemplate = (rowData) => {
    return (
      <p>
        {rowData.chargeAmount} <span className="text-green-500">$</span>
      </p>
    );
  };

  const fetchData = async (currPage) => {
    setIsLoading(true);
    try {
      const url = link + `&Page=${currPage + 1}&PageSize=5`;
      const response = await http.put(url, payload);
      setTableData(response.data);
      setTotalPages(response.data[0]?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (link) {
      setFirst(0);
      fetchData(0);
    }
  }, [refetch]);

  const header = (
    <div className="flex items-center justify-end">
      <span className="p-input-icon-left min-w-[300px]">
        <i className="pi pi-search" />
        <InputText
          className="w-full"
          placeholder={"Search"}
          value={searchValue}
          onChange={handleInputChange}
        />
      </span>
    </div>
  );

  const unitsTemplate = (rowData) => {
    return (
      <div className="mt-4">
        {rowData?.id === selection?.id ? (
          <div>
            <InputText
              className={"w-16"}
              id="billable-units"
              value={selection.units}
              keyfilter={/^\d+$/}
              onChange={onUnitsChange}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const getSelection = () => {
    if (!selection) return;

    if (value) return selection;

    const { units, ...rest } = selection;
    return rest;
  };

  const columns = [
    { name: "HCPCS", value: "hcpcs" },
    {
      name: "Description",
      template: descriptionWithTooltipTemplate,
    },
    {
      name: "Mod 1",
      template: (e) => modTemplate(e, "mod1"),
      header: "Mod 1",
    },
    {
      name: "Mod 2",
      template: (e) => modTemplate(e, "mod2"),
      header: "Mod 2",
    },
    {
      name: "Mod 3",
      template: (e) => modTemplate(e, "mod3"),
      header: "Mod 3",
    },
    {
      name: "Mod 4",
      template: (e) => modTemplate(e, "mod4"),
      header: "Mod 4",
    },
    {
      name: "Price per unit",
      template: priceTemplate,
      header: "Price per unit",
    },
  ];

  if (link) {
    columns.push({
      name: "Units",
      template: (e) => unitsTemplate(e),
    });
  }

  return (
    <>
      <DataTable
        header={link ? header : undefined}
        loading={isLoading}
        pt={{
          loadingOverlay: {
            style: {
              backgroundColor: "#b9b5ff60",
            },
          },
        }}
        value={tableData ? tableData : value}
        className="rounded-lg border-2 border-gray-200"
        emptyMessage={
          emptyMessage ? (
            emptyMessage
          ) : (
            <p className="text-center">
              Currently, there are no billable items selected.
            </p>
          )
        }
        selection={getSelection()}
        selectionMode="single"
        onSelectionChange={onSelectionChange}
        paginator={value}
        rows={5}
      >
        {columns.map((column) => (
          <Column
            key={column.name}
            header={column.name}
            align="center"
            alignHeader="center"
            style={column.style ? column.style : null}
            body={(rowData) => {
              if (column.template && typeof column.template === "function") {
                return column.template(rowData);
              } else {
                return rowData[column.value];
              }
            }}
          />
        ))}
      </DataTable>
      {link && (
        <Pagination
          currentPage={first}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          template={{
            layout:
              "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
          }}
        />
      )}
    </>
  );
};

export default SelectSingleBillableTable;
