import { ConfirmDialog } from "primereact/confirmdialog";

import { NewProvidersRequests } from "./NewProvidersRequests";
import { ProvidersUpdatesRequests } from "./ProvidersUpdatesRequests";

export const VerificationWorklist = () => {
  return (
    <div>
      <ConfirmDialog />
      <NewProvidersRequests />
      <div className="mt-10">
        <ProvidersUpdatesRequests />
      </div>
    </div>
  );
};
