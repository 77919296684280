import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../../hooks/useAxios";

export default function LocationServicesDialog({ location }) {
  const { id, name } = location;
  const [services, setServices] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [refetchToggle, setRefetchToggle] = useState(false);

  const toast = useRef(null);
  const { http } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices(id);
  }, [refetchToggle]);

  const fetchServices = async (id) => {
    try {
      const response = await http.get(`/Locations/GetLocationServices/${id}`);
      setServices(response.data.locationInServices);
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditService = (id) => {
    navigate(`/Service/MyServices/EditService/${id}/${false}`);
  };

  const toggleServiceInLocationStatus = async (
    status,
    serviceId,
    locationId,
  ) => {
    try {
      const response = await http.put("/Locations/UpdateLocationInService", {
        serviceId: serviceId,
        locationId: locationId,
        isEnabled: !status,
      });
      setRefetchToggle((prev) => !prev);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Status Update Failed",
        life: 4000,
      });
    }
  };
  return (
    <div>
      <Toast ref={toast} />

      {isFetching ? (
        <div className="flex items-center justify-center">
          <ProgressSpinner />
        </div>
      ) : services.length > 0 ? (
        <div className="flex flex-col gap-3 p-3">
          <Tooltip target=".service-action" className="max-w-sm" />
          {services.map((service) => (
            <div
              key={service.id}
              className={`grid grid-cols-12 rounded border p-3 ${service.isEnabled ? "bg-white" : "bg-slate-50"}`}
            >
              <div className="col-span-10 flex flex-col">
                <h1 className="font-bold">{service.name}</h1>
                <p className="text-sm italic text-gray-600">
                  {service.description}
                </p>
              </div>
              <div className="col-span-2 flex items-center justify-center gap-2">
                <i
                  onClick={() => handleEditService(service.id)}
                  className="pi pi-pencil service-action cursor-pointer rounded-full border-2 border-purple-100 bg-white p-2 text-purple-700"
                  data-pr-tooltip="Edit Service"
                  data-pr-position="top"
                />
                <i
                  onClick={() =>
                    confirmDialog({
                      message: (
                        <div>
                          <p>
                            {service.isEnabled
                              ? "You are about to pause the service at this location. This will make the service unavailable for patients to select at this location. Are you sure you want to proceed?"
                              : "You are about to resume the service at this location. This will make the service available for patients to select at this location. Are you sure you want to proceed?"}
                          </p>
                        </div>
                      ),
                      header: `Update ${service.name} status at ${name}`,
                      icon: "pi pi-exclamation-triangle",
                      accept: () => {
                        toggleServiceInLocationStatus(
                          service.isEnabled,
                          service.id,
                          id,
                        );
                      },
                      acceptLabel: service.isEnabled
                        ? "Yes, Pause"
                        : "Yes, Resume",
                      rejectLabel: "Cancel",
                      reject: () => {},

                      className: "max-w-xl",
                    })
                  }
                  className={`${service.isEnabled ? "pi pi-pause border-red-100 text-red-700" : "pi pi-play border-green-100 text-green-700"} service-action cursor-pointer rounded-full border-2 bg-white p-2`}
                  data-pr-tooltip={`${service.isEnabled ? "Pause" : "Resume"} Service at this Location`}
                  data-pr-position="top"
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No services to show</div>
      )}
    </div>
  );
}
