import Wrong from "../../iconComponents/Wrong";
function NotFound() {
  return (
    <>
      <div className="flex h-screen items-center justify-center">
        <div className="flex w-11/12 justify-center rounded-3xl bg-light-text py-16 md:w-3/6">
          <div className="2xl:w-5/5 Xl:w-4/5 md:w-5/5 w-11/12 rounded-3xl border border-solid border-light-purple p-10 py-8 lg:w-4/5">
            <span className="m-auto mb-6 flex w-11/12 items-center justify-center rounded-full bg-gray-300 p-2">
              <Wrong />
            </span>
            <h4 className="font-poppins text-center font-bold capitalize text-red-600">
              {" "}
              Something went wrong!{" "}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
