import { Skeleton } from "primereact/skeleton";

export const ServicesCardSkeleton = () => {
  return (
    <div className="mb-3 bg-white p-5">
      <div className="align-center flex justify-between">
        <Skeleton className="mb-3 p-4" width="15%" />
        <Skeleton size="30px" shape="circle" />
      </div>
      <div>
        <Skeleton className="mb-2 p-3" width="100%" />
        <Skeleton className="mb-2 p-3" width="100%" />
        <Skeleton className="mb-3 p-3" width="15%" />
      </div>
      <div>
        <Skeleton className="mb-2 mr-2 inline-block p-3" width="10%" />
        <Skeleton className="mb-2 inline-block p-3" width="10%" />
      </div>
    </div>
  );
};
