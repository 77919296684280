import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { Message } from "primereact/message";
import { BreadCrumb } from "primereact/breadcrumb";
import { TabPanel, TabView } from "primereact/tabview";

import useTokenData from "../../../hooks/useTokenData";
import { ChangePassword } from "../../../components/forms";
import { DialogCreatePin } from "../../../components/dialogs";
import useImpersonating from "../../../hooks/useImpersonating";

function Security({ fromTap = true }) {
  const { VerifyProvider, IsExternalLogin } = useTokenData();
  const location = useLocation();
  const [headerActiveIndex, setHeaderActiveIndex] = useState(
    location.state?.activeIndex || 0,
  );
  const { isSystemAdminImpersonating } = useImpersonating();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state?.activeIndex !== undefined) {
      setHeaderActiveIndex(location.state.activeIndex);
    }
    if (IsExternalLogin === "True") {
      setHeaderActiveIndex(1);
    }
  }, [location.state?.activeIndex, IsExternalLogin]);

  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Set PIN",
      template: () => <Link to="#">Set Authentication PIN</Link>,
    },
  ];

  return (
    <>
      {isSystemAdminImpersonating && (
        <Message
          severity="warn"
          text="Notice | Updates to this page are disabled for impersonated users and can only be completed by the user themselves."
          className="my-4 justify-normal !border !border-orange-200 font-semibold"
        />
      )}

      <TabView
        activeIndex={headerActiveIndex}
        onTabChange={(e) => setHeaderActiveIndex(e.index)}
      >
        {IsExternalLogin === "False" && (
          <TabPanel header="Change Password">
            <ChangePassword />
          </TabPanel>
        )}
        {VerifyProvider !== "NULL" && (
          <TabPanel header="Provider Authentication PIN">
            {/* {HasPin === "True" ? (
              <DialogUpdatePin />
            ) : ( */}
            <BreadCrumb
              model={breadCrumbsItems}
              className="border-0 bg-transparent pb-7 font-semibold"
              pt={{
                separator: { className: "first:hidden" },
                menuitem: { className: "last:text-purple-700" },
              }}
            />
            <DialogCreatePin
              fromTap={fromTap}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </TabPanel>
        )}
      </TabView>
    </>
  );
}

export default Security;
