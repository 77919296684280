import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Chart } from "primereact/chart";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { useOverlayScrollListener } from "primereact/hooks";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../hooks/useAxios";
import { formatDate } from "../../../utils/helpers";
import useTokenData from "../../../hooks/useTokenData";
import OrderIcon from "../../../iconComponents/OrderIcon";
import ResultIcon from "../../../iconComponents/ResultIcon";
import useHandleResize from "../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../components/Table";
import ReorderIcon from "../../../iconComponents/ReorderIcon";
import RevenueIcon from "../../../iconComponents/RevenueIcon";
import { CompletionChecklist } from "../../../components/cards";
import { InboundOrders } from "../orders/components/InboundOrders";

export default function Dashboard() {
  const toast = useRef();
  const { http } = useAxios();
  const buttonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const newOrdersRef = useRef(null);
  const { entityId } = useTokenData();
  const componentMount = useRef(false);
  const { isMobile } = useHandleResize();

  const [cardData, setCardData] = useState(null);
  const [chartData, setChartData] = useState({});
  const [selectedDay, setSelectedDay] = useState(0);
  const [chartOptions, setChartOptions] = useState({});
  const [menuvisible, setMenuVisible] = useState(false);
  const [refetchTopOrdering, setRefetchTopOrdering] = useState(false);

  const isWelcome = location?.state?.isWelcome;
  // range of days
  const days = [
    {
      name: "All",
      value: 0,
    },
    {
      name: "Last Day",
      value: 1,
    },
    {
      name: "Last Week",
      value: 7,
    },
    {
      name: "Last 2 Weeks",
      value: 14,
    },
    {
      name: "Last 3 Weeks",
      value: 21,
    },
    {
      name: "Last Month",
      value: 30,
    },
    {
      name: "Last 2 Months",
      value: 60,
    },
    {
      name: "Last Year",
      value: 365,
    },
  ];

  const fetchBestSellingServicesData = async () => {
    try {
      const response = await http.get(
        selectedDay
          ? `/Entities/GetBestSellingServices/${entityId}?days=${Number(
              selectedDay,
            )}`
          : `/Entities/GetBestSellingServices/${entityId}`,
      );
      createChart(response.data);
    } catch (e) {
      console.error("Error in Fetching data chart", e);
    }
  };

  const createChart = (chartData) => {
    const dataChart = {
      labels: chartData.map((item) => item.serviceName),
      datasets: [
        {
          data: chartData.map((item) => item.ordersCount),
        },
      ],
    };
    const options = {
      cutout: "60%",
    };
    setChartData(dataChart);
    setChartOptions(options);
  };

  const getCardData = async (url) => {
    const response = await http.get(url);
    setCardData(response.data);
  };

  const handleScroll = () => {
    setMenuVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: menuvisible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  useEffect(() => {
    setCardData(null);
    const url = selectedDay
      ? `/Entities/GetDashboardCards/${entityId}?days=${selectedDay}`
      : `/Entities/GetDashboardCards/${entityId}`;
    getCardData(url);
    fetchBestSellingServicesData();
    componentMount.current && setRefetchTopOrdering(!refetchTopOrdering);
    componentMount.current = true;
  }, [entityId, selectedDay]);

  const cardBestOrderingProviderTemplate = (row) => {
    return (
      <>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Practice Name:&nbsp;
          </span>
          {row.orderPracticeName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordering Provider Name:&nbsp;
          </span>
          {row.orderProviderName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Orders Number:&nbsp;
          </span>
          {row.ordersCount}
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            &nbsp;Last Order Date:&nbsp;
          </span>
          {row.lastOrderDate && (
            <span>{formatDate(row.lastOrderDate)}&nbsp;</span>
          )}
        </p>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-col gap-5">
        <div className="flex grid-cols-12 flex-col-reverse gap-5 md:grid">
          <div className="col-span-8 flex flex-col gap-4 xl:col-span-9">
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-semibold">Overview</h1>
              <Dropdown
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.value)}
                options={days}
                optionLabel="name"
                className="h-10 w-52 items-center"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 mt-4 md:col-auto">
                <div
                  onClick={() =>
                    navigate("/rendering/Orders/", {
                      state: { activeIndex: 0 },
                    })
                  }
                  className="cursor-pointer rounded-md bg-white p-4"
                >
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-pink-600">
                      {cardData ? (
                        cardData.newOrdersCount
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <OrderIcon />
                  </div>
                  <p className="text-base font-medium">New Orders</p>
                </div>
                <div
                  onClick={() =>
                    navigate("/rendering/Orders/", {
                      state: { activeIndex: 2 },
                    })
                  }
                  className="mt-4 cursor-pointer rounded-md bg-white p-4"
                >
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-[#009B2B]">
                      {cardData ? (
                        cardData.resultedOrdersCount
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <ResultIcon />
                  </div>
                  <p className="text-base font-medium">Resulted Services</p>
                </div>
              </div>
              <div className="col-span-2 md:col-auto">
                <div
                  onClick={() =>
                    navigate("/rendering/Orders/", {
                      state: { activeIndex: 1 },
                    })
                  }
                  className="mt-4 cursor-pointer rounded-md bg-white p-4"
                >
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-[#594FC4]">
                      {cardData ? (
                        `${cardData.inProgressOrdersCount} ${cardData.maxOrdersQuantity ? "/ " + cardData.maxOrdersQuantity : ""}`
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <ReorderIcon />
                  </div>
                  <p className="text-base font-medium">In-progress Orders</p>
                </div>
                <div className="mt-4 cursor-pointer rounded-md bg-white p-4">
                  <div className="mb-3 flex justify-between">
                    <h1 className="text-3xl font-bold text-[#0EA5E9]">
                      {cardData ? (
                        <div className="flex items-center">
                          <i className="pi pi-dollar"></i>
                          <p className="mr-1">{cardData.revenue}</p>
                        </div>
                      ) : (
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </h1>
                    <RevenueIcon />
                  </div>
                  <p className="text-base font-medium">Revenue</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 flex xl:col-span-3">
            <CompletionChecklist view="render" isWelcome={isWelcome} />
          </div>
        </div>

        {/* Table with diagram */}
        <div className="flex grid-cols-12 flex-col gap-5 md:grid">
          <div className="col-span-8 xl:col-span-9">
            <ReusableDataList
              title={"Top Ordering Providers"}
              fromFront={true}
              dataLink={`/Entities/GetTopOrderingProviders/${entityId}?days=${Number(
                selectedDay,
              )}`}
              columns={[
                { name: "Practice Name", value: "orderPracticeName" },
                { name: "Ordering Provider Name", value: "orderProviderName" },
                { name: "Last Order Date", value: "lastOrderDate" },
                { name: "Orders Count", value: "ordersCount" },
              ]}
              refetch={refetchTopOrdering}
              searchTitle="search by practice name"
              actionTemplates={
                isMobile && [{ template: cardBestOrderingProviderTemplate }]
              }
            />
          </div>

          <div className="col-span-4 flex-col gap-3 rounded-lg border border-slate-100 bg-white p-4 xl:col-span-3">
            <h1 className="mb-2 font-semibold">Best Selling Services</h1>
            <div className="w-full">
              {chartData?.labels && chartData?.labels[0] ? (
                <Chart
                  type="doughnut"
                  data={chartData}
                  options={chartOptions}
                  className="flex justify-center align-middle md:max-h-[20rem]"
                />
              ) : (
                <p className="pt-[130px] text-center">
                  You haven't sold any services yet...
                </p>
              )}
            </div>
          </div>
        </div>
        <div ref={newOrdersRef}>
          <InboundOrders />
        </div>
      </div>
    </>
  );
}
