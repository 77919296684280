import { formatDate } from "../../../utils/helpers";

export const LastCertifiedTemplate = ({ lastCertifiedDate, isCertified }) => {
  const severity = getSeverity(isCertified);
  return (
    <>
      {severity === "success" ? (
        <span className="inline rounded-md bg-[#9EC8B9] p-2 text-center text-sm text-[#1B4242] sm:block sm:p-3 sm:text-base">
          {formatDate(lastCertifiedDate)}
        </span>
      ) : (
        <span className="inline rounded-md bg-red-100 p-2 text-center text-sm text-red-500 sm:block sm:p-3 sm:text-base">
          {formatDate(lastCertifiedDate)}
        </span>
      )}
    </>
  );
};

const getSeverity = (isCertified) => (isCertified ? "success" : "danger");
