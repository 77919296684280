import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Divider } from "primereact/divider";
import { ConfirmDialog } from "primereact/confirmdialog";

import { ManageServicesHeader } from "./components/ManageServicesHeader";
import { ManageQuestionsTemplate } from "./components/ManageQuestionsTemplate";

export const ManageCreatedServices = () => {
  const [refetch, setRefetch] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="font-inter">
      <ConfirmDialog />
      <ManageServicesHeader
        onCreateServiceClick={() =>
          navigate("/Service/MyServices/createNewService")
        }
      />

      <Divider />

      <div className="w-full">
        <div>
          <ManageQuestionsTemplate refetch={refetch} setRefetch={setRefetch} />
        </div>
      </div>
    </div>
  );
};
