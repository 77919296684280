import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { ProgressSpinner } from "primereact/progressspinner";

import { formatDate, getBase64Src } from "../../utils/helpers";

const ProviderInfoCard = ({
  isLoading = false,
  tag,
  providerId,
  onApproveClick,
  onDeclineClick,
  name,
  image,
  status,
  ownerPhoneNumber,
  ownerEmail,
  createdAt,
  taxId,
  homeAddress,
  entityName,
  entityAddress,
  identityImage,
  selfieImage,
  NPIs,
  stateLicences,
  taxonomies,
  registeredBy,
  agentAddress,
  contactInfo,
  entityId,
  clickable = true,
  imageProviderImageSrc,
}) => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={`flex flex-col bg-white p-2 font-inter sm:p-5 ${clickable && "hover:shadow:md cursor-pointer rounded-lg hover:bg-slate-100"} `}
      onClick={() => {
        if (clickable) {
          navigate(`/admin/worklists/provider/${providerId}`);
        }
      }}
    >
      {/* Identity Verification */}
      <Dialog
        blockScroll
        draggable={false}
        className="max-h-[70vh] max-w-[70vw]"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <img alt="img" src={getBase64Src(imagePreview)} />
      </Dialog>
      {/* Logo & Name & Action Buttons */}
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        {/* Logo & Name */}
        <div className={`flex items-center gap-4`}>
          {imageProviderImageSrc ? (
            <img
              alt="img"
              src={imageProviderImageSrc}
              className="h-[40px] w-[40px]"
            />
          ) : (
            <Avatar
              label={name[0]}
              shape="circle"
              className="bg-blue-500 text-white"
            />
          )}
          <div>
            <p className="mb-1 block font-Poppins text-base capitalize leading-loose text-gray-500">
              {name}
            </p>
            <p className="text-xs">{ownerEmail}</p>
            <p className="text-xs">{createdAt && formatDate(createdAt)}</p>
          </div>
        </div>

        {isLoading ? (
          <div>
            <ProgressSpinner
              strokeWidth={3}
              style={{ width: "40px", height: "40px" }}
              className="self-end sm:self-auto"
            />
          </div>
        ) : status === 1 ? (
          <div className="flex max-h-14 flex-col gap-2 sm:flex sm:flex-row">
            <div
              onClick={(e) => {
                e.stopPropagation();
                onApproveClick();
              }}
              className="flex cursor-pointer items-center justify-center rounded-full bg-light-green px-5 py-2 text-white"
            >
              <i className="pi pi-check mr-2"></i>
              Approve
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                onDeclineClick();
              }}
              className="flex cursor-pointer items-center justify-center rounded-full bg-gray-300 px-5 py-2 text-gray-700 sm:ml-2"
            >
              <i className="pi pi-ban mr-2"></i>
              Decline
            </div>
          </div>
        ) : status === 2 ? (
          <div className="inline w-40 rounded-md bg-green-200 text-center align-middle text-sm font-semibold text-green-700 sm:block sm:p-3 sm:py-5 sm:text-base">
            Approved
          </div>
        ) : status === 3 ? (
          <div className="inline w-40 rounded-md bg-red-200 p-2 text-center align-middle text-sm font-semibold text-red-700 sm:block sm:p-3 sm:py-5 sm:text-base">
            Denied
          </div>
        ) : null}
      </div>

      {/* Details */}
      {!clickable && (
        <div className="flex flex-col gap-6 px-4 py-5">
          <div className="flex flex-col gap-2">
            <h4 className="text-lg font-semibold">Practice Info</h4>
            <div className="flex flex-col gap-2 rounded-lg border p-3">
              {taxId && (
                <div className="flex">
                  <label className="font-Poppins text-base capitalize text-gray-600">
                    Tax ID :
                  </label>
                  <span className="pl-1">{taxId}</span>
                </div>
              )}
              {homeAddress && (
                <div className="flex">
                  <label className="font-Poppins text-base capitalize text-gray-600">
                    Home/Primary Address :
                  </label>
                  <span>&nbsp;{homeAddress}</span>
                </div>
              )}
              {entityName && (
                <div className="flex">
                  <label className="font-Poppins text-base capitalize text-gray-600">
                    {tag === "individual"
                      ? "Provider Name :"
                      : "Legal Entity Name :"}
                  </label>
                  <span>&nbsp;{entityName}</span>
                </div>
              )}
              {ownerPhoneNumber && (
                <div className="flex">
                  <label className="font-Poppins text-base capitalize text-gray-600">
                    Contact Number:
                  </label>
                  <span>&nbsp;{ownerPhoneNumber}</span>
                </div>
              )}
              {entityAddress && (
                <div className="flex">
                  <label className="font-Poppins text-base capitalize text-gray-600">
                    {tag === "individual"
                      ? "Home Address :"
                      : "Primary Address :"}
                  </label>
                  <span>&nbsp;{entityAddress}</span>
                </div>
              )}
              {NPIs &&
                NPIs.map((npi, index) => (
                  <div className="flex" key={index}>
                    <label className="font-Poppins text-base capitalize text-gray-600">
                      NPI ({index + 1}) :
                    </label>
                    <span>{npi.npiNumber}</span>
                  </div>
                ))}
              {stateLicences &&
                stateLicences.map((stateLicence, index) => (
                  <div className="flex gap-8" key={index}>
                    <div className="flex">
                      <p className="font-Poppins text-base capitalize text-gray-600">
                        State License :
                      </p>
                      <span className="pl-1"> {stateLicence.state}</span>
                    </div>
                    <div className="flex">
                      <p className="font-Poppins text-base capitalize text-gray-600">
                        License Number :
                      </p>
                      <span className="pl-1">
                        {" "}
                        {stateLicence.licenseNumber}
                      </span>
                    </div>
                  </div>
                ))}
              {taxonomies && (
                <div className="text-sm sm:text-base">
                  <span className="font-Poppins text-base capitalize text-gray-600">
                    Taxonomies:&nbsp;
                  </span>
                  {taxonomies.map((taxonomy, index) =>
                    index !== taxonomies.length - 1 ? (
                      <span key={index}>
                        {taxonomy.label}
                        {taxonomy.isPrimary ? " (is primary)" : ""}
                        {index !== taxonomies.length - 1 ? "," : ""}
                      </span>
                    ) : (
                      <span key={index}>
                        {taxonomy.label}
                        {taxonomy.isPrimary ? " (is primary)" : ""}
                        {index !== taxonomies.length - 1 ? "," : ""}
                      </span>
                    ),
                  )}
                </div>
              )}

              {entityId !== "00000000-0000-0000-0000-000000000000"
                ? contactInfo && (
                    <p className="font-Poppins text-base capitalize text-gray-600">
                      Support Contact Information :
                      <span className="font-normal">
                        {" "}
                        {contactInfo[0] || " N/A"}{" "}
                      </span>
                      <span className="pl-4 font-normal">
                        <i className="pi pi-phone pr-1 text-blue-500"></i>
                        {contactInfo[1] || " N/A"}
                      </span>
                      <span className="pl-4 font-normal">
                        <i className="pi pi-envelope pr-1 text-blue-500"></i>
                        {contactInfo[2] || " N/A"}
                      </span>
                    </p>
                  )
                : null}
              {agentAddress && (
                <div className="text-sm sm:text-base">
                  <p className="font-Poppins text-base capitalize text-gray-600">
                    Registered Agent Address:
                  </p>
                  <span>&nbsp;{agentAddress}</span>
                </div>
              )}
            </div>
          </div>

          {tag === "individual" && (selfieImage || identityImage) && (
            <div className="flex flex-col gap-2 rounded-lg border p-3">
              {identityImage && (
                <div className="flex items-center">
                  <label className="w-32 font-Poppins text-base capitalize text-gray-600">
                    Identity Photo:
                  </label>
                  <span
                    onClick={() => {
                      setImagePreview(identityImage);
                      setVisible(true);
                    }}
                    className="ml-2 flex w-48 cursor-pointer items-center gap-2 rounded-lg border-2 bg-gray-100 p-2 hover:bg-gray-100 hover:shadow-sm"
                  >
                    <i className="pi pi-image"></i>
                    <span className="font-semibold text-gray-600">
                      Identity Image
                    </span>
                  </span>
                </div>
              )}
              {selfieImage && (
                <div className="flex items-center">
                  <label className="w-32 font-Poppins text-base capitalize text-gray-600">
                    Selfie Photo:
                  </label>
                  <span
                    onClick={() => {
                      setImagePreview(selfieImage);
                      setVisible(true);
                    }}
                    className="ml-2 flex w-48 cursor-pointer items-center gap-2 rounded-lg border-2 bg-gray-50 p-2 hover:bg-gray-100 hover:shadow-sm"
                  >
                    <i className="pi pi-image"></i>
                    <span className="font-semibold text-gray-600">
                      Selfie Image
                    </span>
                  </span>
                </div>
              )}
            </div>
          )}
          {registeredBy && (
            <div className="flex flex-col gap-2">
              <h4 className="text-lg font-semibold">Registered By</h4>

              <div className="flex flex-col gap-2 rounded-lg border p-3">
                <div>
                  <span className="font-Poppins text-base capitalize text-gray-600">
                    User Name:{" "}
                  </span>
                  <span>{registeredBy[0]}</span>
                </div>
                <div>
                  <span className="font-Poppins text-base capitalize text-gray-600">
                    Phone Number:{" "}
                  </span>
                  <span>{registeredBy[1]}</span>
                </div>
                <div>
                  <span className="font-Poppins text-base capitalize text-gray-600">
                    E-mail:{" "}
                  </span>
                  <span>{registeredBy[2]}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProviderInfoCard;
