import { useEffect, useRef, useState } from "react";

import axios from "axios";
import Webcam from "react-webcam";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import * as signalR from "@microsoft/signalr";
import { Dropdown } from "primereact/dropdown";

import { useCapturedPhoto } from "../../../store/store";
import { CaptureLinkDialog } from "../../../components/dialogs";
import { CapturedPhotoPreview } from "../../../components/forms";
import { DocumentUpload } from "../../../components/formElements";
import {
  checkCameraAccess,
  compressImage,
  getBase64Src,
} from "../../../utils/helpers";

function CompleteIdentityVerificationForm({ linkId, identifierId, token }) {
  const [userId, setUserId] = useState();
  const [error, setError] = useState(null);
  const [backImg, setBackImg] = useState();
  const [frontImg, setFrontImg] = useState();
  const [sessionId, setSessionId] = useState(null);
  const [passportImg, setPassportImg] = useState();
  const [IncomingData, setIncomingData] = useState();
  const [showCamera, setShowCamera] = useState(false);
  const [captureLink, setCaptureLink] = useState(null);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [selectedIdType, setSelectedIdType] = useState(null);
  const [showCaptureLinkDialog, setShowCaptureLinkDialog] = useState(false);
  const [capturedViaMobilePhoto, setCapturedViaMobilePhoto] = useState(null);
  const [showCapturedPhotoPreview, setShowCapturedPhotoPreview] =
    useState(false);

  const toast = useRef(null);
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const { setCapturedPhoto: setCapturedPhotoFromSignalR } = useCapturedPhoto();

  const IdTypes = [
    { label: "Passport", value: "passport" },
    { label: "State Issued Driver's License", value: "driverLicense" },
  ];

  let hubConnection = null;

  // Check if the event key is a keyboard key you want to ignore
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        return;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const defaultValues = {
    identityImageFile: {
      FileDetails: null,
      FileType: 1,
      FilePropertyName: null,
    },
    SelfieImageFile: {
      FileDetails: null,
      FileType: 1,
      FilePropertyName: 4,
    },
  };
  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isSubmitting },
  } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  useEffect(() => {
    if (token) {
      getEntityData();
      let base64Url;
      let base64;
      let jsonPayload;
      let deCodedJWT;

      base64Url = token.split(".")[1];
      base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );
      deCodedJWT = JSON.parse(jsonPayload);
      const userId =
        deCodedJWT[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
        ];
      setUserId(userId);
      startConnection();
      addTransferChartDataListener(userId);
    }
    return () => {
      if (hubConnection) {
        hubConnection.off("NotifyProviderTracking");
        hubConnection.stop();
      }
    };
  }, [token]);

  const startConnection = () => {
    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/notify", {
        accessTokenFactory: () => {
          return token;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    hubConnection
      .start()
      .then(() => {})
      .catch((err) => {});
  };

  const addTransferChartDataListener = (userId) => {
    hubConnection.on("NotifyProviderTracking", (newData) => {
      if (userId === newData.notifyTo) {
        if (newData.action === "DisplayImage") {
          const imageDataUrl = newData.source;
          const binaryData = atob(imageDataUrl.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: "image/png" });
          const file = new File([blob], "mobileCapturedPhoto.png", {
            type: "image/png",
          });
          const url = URL.createObjectURL(blob);

          const capturedPhoto = {
            file,
            url,
            filePropertyName: newData.propertyName,
            driverLicenseType: newData.side,
          };
          setCapturedPhotoFromSignalR(capturedPhoto);
          if (capturedPhoto) {
            setShowCaptureLinkDialog(false);
            setShowCapturedPhotoPreview(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    capturedViaMobilePhoto && handleCapturedPhotoChange(capturedViaMobilePhoto);
  }, [capturedViaMobilePhoto]);

  const handleCapturedPhotoChange = (capturedViaMobilePhoto) => {
    if (capturedViaMobilePhoto?.filePropertyName === 2) {
      // passport
      handlePassportIdChange(capturedViaMobilePhoto.file);
    } else if (capturedViaMobilePhoto?.filePropertyName === 4) {
      // selfie
      setValue("SelfieImageFile.FileDetails", capturedViaMobilePhoto.file);
      setCapturedPhoto(capturedViaMobilePhoto.url);
    } else if (
      capturedViaMobilePhoto?.some((item) => item) &&
      capturedViaMobilePhoto?.some((item) => item?.filePropertyName === 1)
    ) {
      capturedViaMobilePhoto[0]?.file &&
        handleFrontImgChange(capturedViaMobilePhoto[0]?.file);

      capturedViaMobilePhoto[1]?.file &&
        handleBackImgChange(capturedViaMobilePhoto[1]?.file);
    }
  };

  const getEntityData = async () => {
    const res = await axios.get(
      `/Identifications/GetIdentityData/${identifierId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    setIncomingData(res.data);

    if (
      res.data.identityImageType !== null &&
      res.data.identityImageType === "Passport"
    ) {
      setSelectedIdType(2);
      setValue("idType", 2);
      clearErrors("idType");
      setValue("identityImageFile.FilePropertyName", 2);
    } else if (
      res.data.identityImageType !== null &&
      res.data.identityImageType === "DriverLicense"
    ) {
      setSelectedIdType(1);
      setValue("idType", 1);
      clearErrors("idType");
      setValue("identityImageFile.FilePropertyName", 1);
    }
    setValue("identityImageFile.FileDetails", res.data.identityImage);
    setValue("SelfieImageFile.FileDetails", res.data.selfieImage);
  };

  const handleCapture = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedPhoto(imageSrc);

      const blob = dataURItoBlob(imageSrc);
      const file = new File([blob], "selfie_image.png", { type: "image/png" });

      let finalFile = file;

      if (file.type.startsWith("image/")) {
        try {
          const compressedBlob = await compressImage(file, 1280, 0.8);
          finalFile = new File([compressedBlob], file.name, {
            type: "image/png",
          });
        } catch (error) {
          console.warn("Compression failed, using original file:", error);
        }
      }
      setValue("SelfieImageFile.FileDetails", finalFile);
      setShowCamera(false);
      clearErrors("SelfieImageFile.FileDetails");
    } catch (error) {
      console.error("Error capturing or processing image:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to capture or process the image.",
        life: 4000,
      });
    }
  };

  const openCamera = async () => {
    const errorMessage = await checkCameraAccess();
    if (!errorMessage) {
      setShowCamera(true);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Camera Warning",
        detail: errorMessage,
        life: 4000,
      });
    }
  };

  const handleDeleteSelfiePhoto = () => {
    setCapturedPhoto(null);
    setValue("SelfieImageFile.FileDetails", "");
  };

  const handleIdTypeChange = (e) => {
    setSelectedIdType(e.value);
    setValue("identityImageFile.FileDetails", "");
    setBackImg(undefined);
    setFrontImg(undefined);
    setPassportImg(undefined);
  };

  const handleFrontImgChange = (file) => {
    setFrontImg(file);
    if (backImg) {
      sendImagesSideBySide(file, backImg);
    }
    setValue("identityImageFile.FilePropertyName", 1);
    setValue("identityImageFile.FileType", 1);
    clearErrors("frontImg");
    clearErrors("identityImageFile.FileDetails");
  };

  const handleBackImgChange = (file) => {
    setBackImg(file);
    if (frontImg) {
      sendImagesSideBySide(frontImg, file);
    }
    setValue("identityImageFile.FilePropertyName", 1);
    setValue("identityImageFile.FileType", 1);
    clearErrors("backImg");
    clearErrors("identityImageFile.FileDetails");
  };

  const handlePassportIdChange = (file) => {
    setPassportImg(file);
    setValue("identityImageFile.FileDetails", file);
    setValue("identityImageFile.FilePropertyName", 2);
    setValue("identityImageFile.FileType", 1);
    clearErrors("identityImageFile.FileDetails");
  };

  const onPassportClear = () => setValue("identityImageFile.FileDetails", "");

  const onFrontIdImageClear = () => {
    setFrontImg(undefined);
    setValue("identityImageFile.FileDetails", "");
  };

  const onBackIdImageClear = () => {
    setBackImg(undefined);
    setValue("identityImageFile.FileDetails", "");
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const sendImagesSideBySide = (frontImg, backImg) => {
    const img1 = new Image();
    const img2 = new Image();

    img1.onload = () => {
      img2.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img1.width + img2.width;
        canvas.height = Math.max(img1.height, img2.height);
        const ctx = canvas.getContext("2d");

        ctx.drawImage(img1, 0, 0);
        ctx.drawImage(img2, img1.width, 0);

        const dataURL = canvas.toDataURL("image/png");
        const blob = dataURItoBlob(dataURL);
        const file = new File([blob], "combined_image.png", {
          type: "image/png",
        });

        setValue("identityImageFile.FileDetails", file);
      };

      img2.src = URL.createObjectURL(frontImg);
    };
    img1.src = URL.createObjectURL(backImg);
  };
  const generateCaptureLink = async (type) => {
    try {
      const response = await axios.post(
        "/Identifications/GenerateCaptureIdentityViaMobileLink",
        {
          identificationId: userId,
          identityTypes: type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setCaptureLink(response.data);
      const sessionId = new URL(response.data).searchParams.get("id");
      setSessionId(sessionId);
      setShowCaptureLinkDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData) => {
    const url = `/Identifications/CompleteProviderIdentityData/${linkId}`;
    const data = new FormData();
    if (
      !formData?.identityImageFile?.FileDetails &&
      !formData?.SelfieImageFile?.FileDetails
    ) {
      setError("Please provide at least one Identity Document");
      return;
    }

    data.append(
      "IdentityImageFile.FileDetails",
      formData.identityImageFile.FileDetails || null,
    );
    data.append(
      "IdentityImageFile.FileType",
      formData.identityImageFile.FileType,
    );
    data.append(
      "IdentityImageFile.FilePropertyName",
      formData.identityImageFile.FilePropertyName || 0,
    );
    data.append(
      "SelfieImageFile.FileDetails",
      formData.SelfieImageFile.FileDetails || null,
    );
    data.append("SelfieImageFile.FileType", formData.SelfieImageFile.FileType);
    data.append(
      "SelfieImageFile.FilePropertyName",
      formData.SelfieImageFile.FilePropertyName || 0,
    );

    try {
      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.current.show({
        severity: "success",
        summary: "success",
        detail: response.data.message,
        life: 4000,
      });
      setTimeout(() => {
        navigate("/verified-success", {
          replace: true,
        });
      }, 1500);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={showCaptureLinkDialog}
        onHide={() => {
          setShowCaptureLinkDialog(false);
        }}
        className="max-w-4xl"
        header="Complete Via Mobile"
      >
        <CaptureLinkDialog captureLink={captureLink} />
      </Dialog>
      <Dialog
        visible={showCapturedPhotoPreview}
        onHide={() => {
          setShowCapturedPhotoPreview(false);
          setCapturedViaMobilePhoto(null);
        }}
        className="max-w-4xl"
        header="Captured Photo Preview"
      >
        <CapturedPhotoPreview
          setCapturedViaMobilePhoto={setCapturedViaMobilePhoto}
          sessionId={sessionId}
          setShowCapturedPhotoPreview={setShowCapturedPhotoPreview}
        />
      </Dialog>

      <div className="flex items-center justify-center">
        <div className="w-11/12 rounded-3xl bg-light-text p-10 shadow shadow-slate-300 md:w-9/12 lg:w-9/12 xl:w-7/12 2xl:w-6/12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5 border-b-2 pb-10">
              <h5 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
                {" "}
                Verify your Identity{" "}
              </h5>
              {error && (
                <div className="w-full rounded bg-red-100 p-2 text-center text-red-600">
                  {error}
                </div>
              )}
              <span className="p-input-icon-left mt-4 w-full">
                <label className="block pb-2 font-Poppins text-base capitalize leading-loose text-gray-500">
                  Select ID Type
                </label>
                <Dropdown
                  value={selectedIdType}
                  onChange={handleIdTypeChange}
                  options={IdTypes}
                  optionLabel="label"
                  placeholder="Choose Type"
                  className="w-full"
                />
              </span>
              {selectedIdType === "passport" && (
                <div className="flex flex-col gap-2 py-2">
                  <label className="font-Poppins text-gray-500">
                    Upload ID
                  </label>
                  <div className="flex items-start gap-2">
                    <div className="flex flex-col gap-1 sm:w-1/2 md:w-1/3">
                      <div className="flex items-center">
                        <DocumentUpload
                          name="passportImg"
                          fileType="image"
                          maxFileSizeInMB={10}
                          previewImage={passportImg}
                          onUpload={handlePassportIdChange}
                          onClear={onPassportClear}
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        outlined
                        type="button"
                        label="Capture Via Mobile"
                        onClick={(e) => {
                          generateCaptureLink(2);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              )}

              {selectedIdType === "driverLicense" && (
                <div className="flex flex-col gap-2 p-2">
                  {frontImg !== undefined && backImg === undefined && (
                    <p className="mt-1 text-left text-red-500">
                      Upload back images of your driver license.
                    </p>
                  )}
                  {backImg !== undefined && frontImg === undefined && (
                    <p className="mt-1 text-left text-red-500">
                      Upload front images of your driver license.
                    </p>
                  )}
                  <div className="flex flex-col gap-4 sm:w-1/2 md:w-1/3">
                    <div className="flex flex-col gap-1">
                      <label className="font-Poppins text-gray-500">
                        Front side of ID card
                      </label>

                      <div className="flex items-center">
                        <DocumentUpload
                          name="fornImg"
                          fileType="image"
                          maxFileSizeInMB={10}
                          previewImage={frontImg}
                          onUpload={handleFrontImgChange}
                          onClear={onFrontIdImageClear}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <label className="font-Poppins text-gray-500">
                        Back side of ID card
                      </label>
                      <div className="flex w-full items-center">
                        <DocumentUpload
                          name="backImg"
                          fileType="image"
                          maxFileSizeInMB={10}
                          previewImage={backImg}
                          onUpload={handleBackImgChange}
                          onClear={onBackIdImageClear}
                        />
                      </div>
                    </div>
                    <Divider
                      layout="horizontal"
                      align="center"
                      style={{
                        margin: 0,
                      }}
                    >
                      or
                    </Divider>
                    <div>
                      <Button
                        outlined
                        type="button"
                        label="Capture Via Mobile"
                        className="w-full"
                        onClick={(e) => {
                          generateCaptureLink(1);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              )}
              {!backImg &&
                !frontImg &&
                !passportImg &&
                IncomingData?.identityImage && (
                  <div className="mt-4">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      {IncomingData?.identityImageType === "DriverLicense"
                        ? "Driver License"
                        : IncomingData?.identityImageType}
                    </label>
                    <img
                      src={getBase64Src(IncomingData?.identityImage)}
                      alt="Captured"
                      className="w-90 h-60"
                    />
                  </div>
                )}

              <div className="take-selfie mt-9">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                  Selfie Photo
                </label>
                <div>
                  {showCamera && (
                    <Webcam
                      audio={false}
                      screenshotFormat="image/png"
                      ref={webcamRef}
                      mirrored={true}
                      disabled={!webcamRef.current}
                    />
                  )}
                  {showCamera && (
                    <div className="flex gap-3 py-3">
                      <Button
                        onClick={handleCapture}
                        label="Take Selfie"
                        severity="info"
                        icon="pi pi-cloud-upload"
                        type="button"
                      />
                      <Button
                        label="Cancel"
                        outlined
                        type="button"
                        onClick={() => {
                          setShowCamera(false);
                        }}
                      />
                    </div>
                  )}
                  {capturedPhoto && (
                    <div>
                      <img
                        src={capturedPhoto}
                        alt="Captured"
                        className="w-90 h-60"
                      />
                      <Button
                        type="button"
                        onClick={handleDeleteSelfiePhoto}
                        className="mb-5 mt-5"
                        severity="danger"
                      >
                        Delete Photo
                      </Button>
                    </div>
                  )}
                  {!capturedPhoto &&
                    IncomingData?.selfieImage &&
                    !showCamera && (
                      <div>
                        <img
                          src={getBase64Src(IncomingData?.selfieImage)}
                          alt="Captured"
                          className="w-90 h-60"
                        />
                      </div>
                    )}

                  {!showCamera && !capturedPhoto && (
                    <div className="flex gap-2 p-2">
                      <Button
                        onClick={openCamera}
                        label="Open Camera"
                        type="button"
                        severity="info"
                        icon="pi pi-camera"
                        className=""
                      />
                      <Button
                        outlined
                        type="button"
                        label="Capture Via Mobile"
                        onClick={(e) => {
                          generateCaptureLink(4);
                        }}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                type="submit"
                loading={isSubmitting}
                className="mt-9 w-full rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
              >
                Verify
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CompleteIdentityVerificationForm;
