import React from 'react'

function UploadFileIcon() {
    return (
        <>
            <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <path d="M0 19V17.14C0 15.06 0.73 13.29 2.19 11.83C3.65 10.37 5.42 9.64 7.5 9.64C8.14 9.64 8.82 9.75 9.54 9.97C9.9 8.25 10.77 6.83 12.15 5.71C13.53 4.59 15.11 4.02 16.89 4C18.95 4 20.71 4.74 22.17 6.22C23.63 7.7 24.37 9.46 24.39 11.5C24.39 12.06 24.29 12.7 24.09 13.42C24.23 13.4 24.33 13.39 24.39 13.39C25.93 13.39 27.25 13.94 28.35 15.04C29.45 16.14 30 17.46 30 19V20.89C30 22.45 29.45 23.77 28.35 24.85C27.25 25.93 25.93 26.48 24.39 26.5H24.03C24.21 26 24.32 25.55 24.36 25.15C24.42 24.33 24.32 23.53 24.06 22.75C23.8 21.97 23.36 21.28 22.74 20.68L18.99 16.93C17.89 15.81 16.56 15.25 15 15.25C13.44 15.25 12.12 15.8 11.04 16.9L7.29 20.65C6.69 21.25 6.25 21.95 5.97 22.75C5.69 23.55 5.59 24.36 5.67 25.18C5.69 25.54 5.77 25.93 5.91 26.35C4.21 25.97 2.8 25.1 1.68 23.74C0.56 22.38 0 20.8 0 19ZM9.39 24.82C9.33 24.22 9.51 23.72 9.93 23.32L13.68 19.57C14.06 19.19 14.5 19 15 19C15.52 19 15.96 19.19 16.32 19.57L20.07 23.32C20.49 23.72 20.68 24.22 20.64 24.82C20.62 25 20.57 25.18 20.49 25.36C20.35 25.7 20.12 25.98 19.8 26.2C19.48 26.42 19.13 26.52 18.75 26.5H16.89V32.14C16.89 32.66 16.7 33.1 16.32 33.46C15.94 33.82 15.5 34 15 34C14.5 34 14.06 33.82 13.68 33.46C13.3 33.1 13.12 32.66 13.14 32.14V26.5H11.25C10.87 26.5 10.53 26.4 10.23 26.2C9.93 26 9.69 25.72 9.51 25.36C9.45 25.18 9.41 25 9.39 24.82Z" fill="#594FC4" />
            </svg>
        </>
    )
}

export default UploadFileIcon