import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { FileUpload } from "primereact/fileupload";

import { compressImage, truncateFileName } from "../../utils/helpers";

export default function DocumentUpload({
  name,
  fileType,
  chooseLabel,
  previewImage,
  maxFileSizeInMB,
  onUpload,
  onClear,
}) {
  const toast = useRef(null);
  const fileUploadRef = useRef();

  const [uploadedFile, setUploadedFile] = useState();
  const [previewImageUrl, setPreviewImageUrl] = useState();

  const maxFileSizeinBytes = maxFileSizeInMB * 1024 * 1024;

  const allowedExtensions = {
    image: ["image/jpeg", "image/png", "image/gif", "image/tiff"],
    any: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/dicom",
      "image/tiff",
      "image/gif",
      "text/plain",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/svg+xml",
      "application/msword",
    ],
  };

  useEffect(() => {
    previewImage &&
      setPreviewImageUrl(
        typeof previewImage !== "object"
          ? previewImage
          : URL.createObjectURL(previewImage),
      );
    fileUploadRef.current?.clear();
    setUploadedFile(null);
  }, [previewImage]);

  const formUploadHandler = async (e) => {
    const [file] = e.files;

    // Check if the file extension is supported
    if (!allowedExtensions[fileType].includes(file.type)) {
      const supportedExtensions = allowedExtensions[fileType]
        .map((ext) => `.${ext.split("/")[1]}`)
        .join(", ");

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `The file format is not supported. Please upload a file with one of the following extensions: ${supportedExtensions}`,
        life: 3000,
      });

      clearFileSelection();
      return;
    }

    // Check if the file size exceeds the maximum limit
    if (file.size > maxFileSizeinBytes) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `The file you're trying to upload exceeds the maximum size limit of ${maxFileSizeInMB}MB. Please compress or resize the file.`,
        life: 3000,
      });

      clearFileSelection();
      return;
    }

    let finalFile = file;

    if (file.type.startsWith("image/")) {
      try {
        const compressedBlob = await compressImage(file, 1280, 0.7);
        finalFile = new File([compressedBlob], file.name, {
          type: "image/jpeg",
        });
      } catch (error) {
        console.warn("Image compression failed, using original file:", error);
      }
    }

    setUploadedFile(finalFile);
    onUpload(finalFile);

    if (previewImageUrl) {
      setPreviewImageUrl(undefined);
    }
  };

  const clearFileSelection = () => {
    setUploadedFile(undefined);
    setPreviewImageUrl(undefined);
    fileUploadRef.current?.clear();
    onClear();
  };

  return (
    <div className="w-full">
      <Toast ref={toast} />

      <div>
        <FileUpload
          ref={fileUploadRef}
          mode="basic"
          name={name}
          accept={fileType === "image" ? "image/*" : ""}
          customUpload
          uploadHandler={formUploadHandler}
          auto
          disabled={!!uploadedFile}
          chooseLabel={
            uploadedFile
              ? truncateFileName(uploadedFile.name, 15)
              : previewImageUrl
                ? "Replace Image"
                : chooseLabel
                  ? chooseLabel
                  : `Choose ${fileType === "image" ? "Image" : "File"}`
          }
          chooseOptions={{
            className: "w-full",
            icon: uploadedFile ? (
              <i className="pi pi-check pr-2"></i>
            ) : (
              <i className="pi pi-upload pr-2"></i>
            ),
          }}
          className="w-full"
        />
      </div>

      {(uploadedFile || previewImageUrl) &&
        (uploadedFile?.type.includes("image") || previewImageUrl ? (
          <div className="relative my-2 flex h-32 w-full items-center gap-2 overflow-hidden rounded-lg drop-shadow-md">
            <Button
              type="button"
              raised
              rounded
              size="small"
              icon="pi pi-trash"
              severity="secondary"
              className="absolute right-1 top-1"
              onClick={() => {
                clearFileSelection();
              }}
            />

            <img
              src={
                previewImageUrl
                  ? previewImageUrl
                  : URL.createObjectURL(uploadedFile)
              }
              alt="ID Preview"
              className="h-full w-full object-cover"
            />
          </div>
        ) : (
          <div className="flex items-center gap-2 py-2">
            <span className={"mr-2"}>
              <Avatar icon="pi pi-file" size="large" />
            </span>
            <span>{truncateFileName(uploadedFile.name, 15)}</span>
            <i
              className="pi pi-times-circle cursor-pointer pr-2 text-red-500"
              onClick={() => {
                clearFileSelection();
              }}
            ></i>
          </div>
        ))}
    </div>
  );
}
