import { useEffect, useState } from "react";

const useDelayedInputChange = (
  refetch,
  setRefetch,
  selectedValue,
  delay = 700,
) => {
  const [searchValue, setSearchValue] = useState("");
  const [delayTimer, setDelayTimer] = useState(null);

  useEffect(() => {
    if (!searchValue) return;
    setSearchValue("");
    setRefetch(!refetch);
  }, [selectedValue]);

  const handleInputChange = (event) => {
    clearTimeout(delayTimer);
    setSearchValue(event.target.value);
    setDelayTimer(
      setTimeout(() => {
        setRefetch(!refetch);
      }, delay),
    );
  };
  return [searchValue, handleInputChange, setSearchValue];
};

export default useDelayedInputChange;
