import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { ReusableFilter } from "../../../components/Table";
import useHandleResize from "../../../hooks/useHandleResize";
import { blockSpecialCharsPattern } from "../../../utils/helpers";
import FavoritePracticeCard from "./components/FavoritePracticeCard";
import { FavoritePracticesCardSkeleton } from "../../../components/skeletons/FavoritePracticesCardSkeleton";

const REQUESTED_DATA_SIZE = 10;

function FavoritePractices() {
  const { entityId } = useTokenData();
  const { http } = useAxios();
  const [practices, setPractices] = useState();
  const [filteredPractices, setFilteredPractices] = useState();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("idle");
  const [states, setStates] = useState();
  const [selectedState, setSelectedState] = useState(null);
  const [favPracticeSelected, setFavPracticeSelected] = useState();
  const [practicesCount, setPracticesCount] = useState(null);
  const { isMobile } = useHandleResize();
  const toast = useRef(null);

  const toastEnableSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Pracitce added to your favorite list successfully",
      life: 3000,
    });
  };

  const toastDisableSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Pracitce removed from your favorite list successfully",
      life: 3000,
    });
  };

  const toastError = (error) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error.message || "Something went wrong. Please try again later",
      life: 2000,
    });
  };

  let refetching = false;

  // Handle Paginator
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(REQUESTED_DATA_SIZE);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    if (first !== event.first) {
      getAllPractices(undefined, event.page + 1, event.rows);
    }
  };

  const getFavPractices = async () => {
    if (!refetching) {
      setStatus("loading");
    }

    const res = await http.get(
      `/Entities/EntityFavouritePractices/${entityId}`,
    );
    if (res.data && res.data.length > 0) {
      setStatus("success");
      setPractices(res.data);
      setFilteredPractices(res.data);
    } else {
      setStatus("no-data");
    }
  };

  const getAllPractices = async (text, page, pageCount) => {
    if (!refetching) {
      setStatus("loading");
    }
    const res = await http.get(
      `/Entities/GetPractices/${entityId}?Filters=name@=*${
        text !== undefined ? text : searchText
      }&Page=${page || 1}&PageSize=${pageCount || REQUESTED_DATA_SIZE}&searchState=${
        selectedState?.name || ""
      }`,
    );
    if (res.data.practices && res.data.practices.length > 0) {
      setStatus("success");
      setPractices(res.data.practices);
      setFilteredPractices(res.data.practices);
      setPracticesCount(res.data.count);
    } else {
      setStatus("no-data");
    }
  };

  const getStates = async () => {
    const res = await http.get(`/States`);
    setStates(res.data);
  };

  useEffect(() => {
    getAllPractices();
    getStates();
  }, []);
  useEffect(() => {
    if (!searchText && !selectedState && !favPracticeSelected) {
      setFirst(0);
      getAllPractices();
    } else if (!searchText && !selectedState && favPracticeSelected) {
      setFilteredPractices(practices);
    }
  }, [searchText, selectedState]);

  function handleFavPracticesSearch() {
    let filtered;
    if (selectedState) {
      filtered = practices?.filter(
        (data) =>
          data.practicePrincipalAddress.state === selectedState.name &&
          (data.name
            ?.toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
            data.description
              ?.toLowerCase()
              .trim()
              .includes(searchText.toLowerCase().trim())),
      );
    } else {
      filtered = practices?.filter(
        (data) =>
          data.name
            ?.toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          data.description
            ?.toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()),
      );
    }
    setFilteredPractices(filtered);
    setStatus("success");
    if (filtered && filtered.length === 0) {
      setStatus("no-data");
    }
  }
  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Location</h3>
        <div className="card justify-content-center flex flex-1">
          <ListBox
            filter
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            options={states}
            optionLabel="name"
            className="w-full"
            listStyle={{ height: "280px" }}
            virtualScrollerOptions={{ itemSize: 38 }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="mb-4 hidden sm:flex">
        <span className="p-input-icon-left mr-8 w-[80%]">
          <i className="pi pi-search" />
          <InputText
            keyfilter={blockSpecialCharsPattern}
            onKeyDown={(e) => {
              if (e.key === "Enter" && favPracticeSelected) {
                setFirst(0);
                handleFavPracticesSearch();
              } else if (e.key === "Enter" && searchText !== "") {
                setFirst(0);
                refetching = true;
                getAllPractices();
              }
            }}
            placeholder="Search practice."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            className="w-full"
          />
        </span>

        <Dropdown
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          options={states}
          optionLabel="name"
          placeholder="Select State"
          showClear
          className="mr-8"
        />
        <Button
          onClick={() => {
            if (selectedState !== (undefined || null) || searchText !== "") {
              if (favPracticeSelected) {
                setFirst(0);
                handleFavPracticesSearch();
              } else {
                setFirst(0);
                refetching = true;
                getAllPractices();
              }
            }
          }}
          label="Search"
          className="flex justify-center"
        />
      </div>
      {isMobile && (
        <ReusableFilter
          searchTitle={"Search practice."}
          filterTemplate={filterTemplate}
          onSearch={() => {
            if (selectedState !== (undefined || null) || searchText !== "") {
              if (favPracticeSelected) {
                setFirst(0);
                handleFavPracticesSearch();
              } else {
                setFirst(0);
                refetching = true;
                getAllPractices();
              }
            }
          }}
        />
      )}
      <div className="mb-5 flex gap-2">
        <div
          onClick={() => {
            if (favPracticeSelected) {
              setFirst(0);
              setFavPracticeSelected(false);
              getAllPractices("");
              setSearchText("");
              setSelectedState(null);
            }
          }}
          className={`${
            !favPracticeSelected
              ? "bg-light-purple font-semibold text-white"
              : null
          } flex flex-1 cursor-pointer items-center justify-center p-2 px-4 sm:flex-initial`}
        >
          All Practices
        </div>
        <div
          onClick={() => {
            if (!favPracticeSelected) {
              setFirst(0);
              setFavPracticeSelected(true);
              getFavPractices();
              setSearchText("");
              setSelectedState(null);
            }
          }}
          className={`${
            favPracticeSelected
              ? "bg-light-purple font-semibold text-white"
              : null
          } flex flex-1 cursor-pointer items-center justify-center p-2 px-4 sm:flex-initial`}
        >
          Favorite Practices
        </div>
      </div>
      {status === "success"
        ? filteredPractices?.map((data) => {
            return (
              <div key={data.practiceId}>
                <FavoritePracticeCard
                  isFavoritePractice={
                    data.isFavouritePractice !== undefined
                      ? data?.isFavouritePractice
                      : true
                  }
                  practiceDesc={data?.description}
                  practiceName={data?.name}
                  practiceImage={data?.practiceImage}
                  practiceSince={data?.memberSince}
                  practiceServicesNum={data?.numberOfServices}
                  practiceLocation={data?.practicePrincipalAddress}
                  practiceId={data.practiceId}
                  refetchData={() => {
                    refetching = true;
                    favPracticeSelected
                      ? getFavPractices()
                      : getAllPractices("", first / REQUESTED_DATA_SIZE + 1);
                  }}
                  toastDisableSuccess={toastDisableSuccess}
                  toastEnableSuccess={toastEnableSuccess}
                  toastError={toastError}
                />
              </div>
            );
          })
        : status === "loading"
          ? Array(8)
              .fill(0)
              .map((n, i) => <FavoritePracticesCardSkeleton key={i} />)
          : status === "no-data" && (
              <p className="rounded bg-white p-5 text-gray-400">
                No results found
              </p>
            )}
      {!favPracticeSelected && practicesCount > 0 && (
        <Paginator
          className="rounded-t-none"
          first={first}
          rows={rows}
          totalRecords={practicesCount}
          onPageChange={onPageChange}
          template={
            isMobile
              ? { layout: "PrevPageLink CurrentPageReport NextPageLink" }
              : {
                  layout:
                    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                }
          }
        />
      )}
    </>
  );
}

export default FavoritePractices;
