import { useEffect, useRef } from "react";

import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputSwitch } from "primereact/inputswitch";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";

export default function PrivacyPreferences() {
  const toast = useRef(null);
  const { http } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useTokenData();

  const defaultValues = {
    receiveSmsMessages: false,
  };

  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Privacy Preferences",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/settings/privacy`}>
          Privacy Preferences
        </Link>
      ),
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getPrivacyPreferences(userId);
    } catch (error) {
      console.error(error);
    }
  };

  const getPrivacyPreferences = async (userId) => {
    try {
      const response = await http.get(`/Users/GetUserPrivacy/${userId}`);
      setValue("receiveSmsMessages", response.data.receiveSmsMessages);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData) => {
    try {
      await http.put(`/Users/EditUserPrivacy/${userId}`, formData);
      navigate(-1, { replace: true });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          error.response.data.message ||
          "Something went wrong. Please try again later",
        life: 4000,
      });
    }
  };

  const handleCancel = () => {
    navigate(-1, { replace: true });
  };

  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb
        model={breadCrumbsItems}
        className="border-0 bg-transparent pb-7 font-semibold"
        pt={{
          separator: { className: "first:hidden" },
          menuitem: { className: "last:text-purple-700" },
        }}
      />
      <div className="flex w-full flex-col gap-6 rounded-3xl bg-white p-8">
        <div className="flex flex-col gap-5">
          <h4 className="text-center text-4xl font-bold sm:text-left">
            Privacy Preferences
          </h4>
          <p className="w-full text-center text-gray-600 sm:w-4/5 sm:text-left">
            Manage your privacy and communication preferences. Control how we
            communicate with you, manage your personal data, and ensure your
            account security.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="flex max-w-5xl flex-col rounded-lg border border-slate-100 p-6">
              <div className="flex">
                <Controller
                  name="receiveSmsMessages"
                  control={control}
                  render={({ field }) => (
                    <div className="grid w-full grid-cols-6 gap-2">
                      <div className="col-span-4 flex flex-col gap-1 md:col-span-5">
                        <label
                          htmlFor="receiveSmsMessages"
                          className="text-lg font-semibold text-gray-800"
                        >
                          Receive critical SMS push notifications for important
                          updates and alerts.
                        </label>
                        <p className="text-sm text-gray-500">
                          You will receive SMS notifications for crucial updates
                          related to your account and services. You can opt
                          in/out at any time.
                        </p>
                      </div>
                      <div className="col-span-2 flex items-center justify-center md:col-span-1">
                        <InputSwitch
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="flex justify-end gap-3">
              <Button
                severity="danger"
                type="button"
                id="cancel"
                label="Cancel"
                disabled={isSubmitting}
                onClick={() => {
                  handleCancel();
                }}
              ></Button>
              <Button
                loading={isSubmitting}
                id="save-preferences"
                label="Save Preferences"
              ></Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
