import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import {
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useTokenStore,
} from "../../store/store";

function ChooseEntity() {
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const { refreshToken, setRefreshToken } = useRefreshTokenStore();
  const { userId } = useTokenData();
  const [isFetching, setIsFetching] = useState(true);
  const [view, setView] = useState("viewRender");
  const [hasUnCertifiedEntities, setHasUnCertifiedEntities] = useState(false);
  const [entityType, setEntityType] = useState();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const [userEntities, setUserEntities] = useState([]);
  const { http } = useAxios();
  const [loading, setLoading] = useState(false);
  const { control, setValue, watch } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (userId) {
      getEntity();
    }
  }, [userId]);

  const getEntity = async () => {
    try {
      const response = await http.get(`/Users/GetUserEntitiesById/${userId}`);
      const entitiesData = response.data?.entities;
      setHasUnCertifiedEntities(response.data?.unCertifiedExists);
      // Find the default entity
      const defaultEntity =
        entitiesData.find((entity) => entity.isDefaultPractice === true) ||
        entitiesData[0] ||
        {};
      setUserEntities(entitiesData);
      // Set default entity values
      if (Object.keys(defaultEntity).length !== 0) {
        setValue("entityId", defaultEntity.entityId);
        setEntityType(defaultEntity.entityType);
        setView(
          defaultEntity.lastDashboardView === 2 ? "viewOrder" : "viewRender",
        );
      }

      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinue = async () => {
    const entityId = watch("entityId");
    if (entityId) {
      const dashboardView = view === "viewRender" ? 1 : 2;
      setLoading(true);
      try {
        const response = await http.post(
          `/Authentication/GetToken/${entityId}/${dashboardView}`,
        );
        setToken(response.data.token);
        setRefreshToken(response.data.refreshToken);
        setEntityId(entityId);
        const { userJourney } = response.data;
        if (userJourney.actionStatus === 1 && userJourney.entityType === 1) {
          navigate(`/setUpGroupFinishStep`, { replace: true });
        } else if (
          userJourney.actionStatus === 1 &&
          userJourney.entityType === 2
        ) {
          navigate(`/setUpIndividualFinish`, { replace: true });
        } else {
          navigate(
            view === "viewRender" ? `/Dashboard/` : `/ordering/FindServices`,
            {
              replace: true,
            },
          );
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSetUpEntity = () => {
    navigate(`/verifySuccess?UI=${userId}`);
  };

  const handleSelectedEntity = (field, selectedOption) => {
    const selectedEntity = userEntities.find(
      (entity) => entity.entityId === selectedOption.value,
    );
    setValue("entityId", selectedOption.value);
    setEntityType(selectedEntity.entityType);
    setView(
      selectedEntity.lastDashboardView === 2 ? "viewOrder" : "viewRender",
    );
    try {
      http.put(
        `/Entities/SetDefaultPractice/${userId}/${selectedOption.value}`,
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {refreshToken ? (
        <div className="flex h-full items-center justify-center">
          <div className="flex w-11/12 flex-col items-center rounded-3xl border border-solid border-light-purple bg-white p-4 sm:p-10 sm:py-8 md:w-6/12 lg:w-4/12">
            <h4 className="mb-14 mt-4 text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
              Continue Log in to MedX
            </h4>
            {isFetching ? (
              <ProgressSpinner />
            ) : (
              <div className="flex h-3/4 w-full flex-col">
                <div className="flex w-full flex-col gap-1 py-3">
                  <p className="font-semibold">
                    Please select the practice you would like to log into
                  </p>

                  <div className="flex">
                    <div
                      className={`${hasUnCertifiedEntities ? "w-10/12" : "w-full"} flex gap-2`}
                    >
                      <Controller
                        name="entityId"
                        control={control}
                        rules={{ required: "Please select your entity" }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            placeholder="Choose entity"
                            focusInputRef={field.ref}
                            options={userEntities}
                            optionLabel="entityName"
                            optionValue="entityId"
                            onChange={(selectedOption) => {
                              handleSelectedEntity(field, selectedOption);
                            }}
                            className="md:w-14rem w-full"
                          />
                        )}
                      />
                    </div>
                    {hasUnCertifiedEntities && (
                      <div className="flex w-2/12 cursor-pointer items-center justify-center">
                        <Tooltip target=".info-icon" />
                        <span
                          className="info-icon max-w-lg"
                          data-pr-position="top"
                          data-pr-tooltip={`Do you not see a practice that you previously were linked to?
                             It is possible that your account requires re-certification by the practice for continued access.
                                Please contact the system administrator at the practice to get your account re-certified and enabled`}
                        >
                          <i className="pi pi-question-circle text-lg" />
                        </span>
                      </div>
                    )}
                  </div>
                  {!userEntities.length &&
                    !isFetching &&
                    (hasUnCertifiedEntities ? (
                      <>
                        <span className="font-semibold text-red-500">
                          Unfortunately your account has been disabled with all
                          previously linked practices.
                        </span>
                        <span className="text-red-500">
                          Please contact the system administrator at the
                          practice to get your account re-certified and enabled.
                        </span>
                      </>
                    ) : (
                      <span className="text-red-500">
                        It seems there's no entity available or your setup
                        request is still pending.
                      </span>
                    ))}
                </div>
                <p className="mb-2 font-semibold">
                  Pick your preferred default login view
                </p>

                <div className="mb-12 flex w-full gap-5">
                  <div className="align-items-center flex">
                    <RadioButton
                      inputId="viewRender"
                      name="view"
                      value="viewRender"
                      onChange={(e) => setView(e.value)}
                      checked={view === "viewRender"}
                      className="pt-1"
                    />
                    <label htmlFor="viewRender" className="ml-2">
                      Rendering Provider View
                    </label>
                  </div>
                  <div className="align-items-center flex">
                    <RadioButton
                      inputId="viewOrder"
                      name="view"
                      value="viewOrder"
                      onChange={(e) => setView(e.value)}
                      checked={view === "viewOrder"}
                      className="pt-1"
                    />
                    <label htmlFor="viewOrder" className="ml-2">
                      Ordering Provider View
                    </label>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={handleContinue}
                    disabled={!entityType}
                    loading={loading}
                    className="mt-5 w-full justify-center gap-3 rounded-full bg-light-purple py-2.5 font-semibold capitalize sm:mt-auto"
                  >
                    Continue
                  </Button>
                </div>
                <div className="mt-4 text-end">
                  <Button
                    onClick={handleSetUpEntity}
                    link
                    className="capitalize"
                  >
                    setup a new entity?
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Navigate to={"/login"} />
      )}
    </>
  );
}

export default ChooseEntity;
