import { Skeleton } from "primereact/skeleton";
function ConfirmOrderByProviderSkeleton() {
  return (
    <div className="flex h-screen items-center justify-center p-4 sm:p-6 md:p-8 lg:p-10">
      <div className="flex w-full max-w-4xl justify-center rounded-3xl bg-light-text py-8 sm:py-12 md:py-16">
        <div className="w-full max-w-3xl rounded-3xl border border-solid border-light-purple p-6 sm:p-8 md:p-10 lg:p-12">
          <div className="mb-5 flex justify-center">
            <span className="flex items-center justify-center rounded-full bg-gray-300 p-2">
              <Skeleton shape="circle" size="4rem" />
            </span>
          </div>
          <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
            <Skeleton width="60%" height="2rem" />
          </h4>

          <div className="mt-3">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="my-2">
                <Skeleton width="100%" height="1.5rem" />
              </div>
            ))}
            <h3 className="text-normal mt-4 font-Poppins capitalize leading-loose text-light-purple">
              <Skeleton width="30%" height="2rem" />
            </h3>
            {[...Array(3)].map((_, index) => (
              <div key={index} className="my-2">
                <Skeleton width="100%" height="1.5rem" />
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-center gap-5">
            <Skeleton width="100px" height="40px" className="rounded-full" />
            <Skeleton width="100px" height="40px" className="rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmOrderByProviderSkeleton;
