import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { confirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";

import useAxios from "../../../../../hooks/useAxios";
import useTokenData from "../../../../../hooks/useTokenData";
import useHandleResize from "../../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../../components/Table";
import SelectSingleBillableTable from "./SelectSingleBillableTable";
import AttachServiceFiles from "../../components/AttachServiceFiles";
import ServicesIcon from "../../../../../iconComponents/ServicesIcon";
import LocationForm from "../../../locations/locationForm/LocationForm";
import { CreateBillableItem } from "../../components/CreateBillableItem";
import { formatDate, trimValidation } from "../../../../../utils/helpers";
import useDelayedInputChange from "../../../../../hooks/useDelayedInputChange";

import "driver.js/dist/driver.css";

function ServiceInfoTemplate({
  driverObj,
  billables,
  location,
  onFormDataChange,
  editValues = false,
  setAvailableLocations,
  availableLocations,
  setErrorMessage,
  errorsForm,
  serviceInfoFormData,
  setGeneralEditInfo,
  setFormQuestions,
  setAllQuestions,
  allRules,
  setAllRules,
  selectedCptCodes,
  setSelectedCptCodes,
  onUpdateErrors,
  setErrorsFields,
  errorsFields,
  locationRefetch,
  setLocationRefetch,
  formInteracted,
  isCopy = false,
}) {
  const toast = useRef(null);
  const [currentGeneralInfo, setCurrentGeneralInfo] = useState();
  const { http } = useAxios();
  const { serviceId } = useParams();
  const { entityId } = useTokenData();
  const [selectedService, setSelectedService] = useState();
  const [serviceDocumentsIds, setServiceDocumentsIds] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);
  const [billableItemVisible, setBillableItemVisible] = useState(false);
  const [notSavedBillableItems, setNotSavedBillableItems] = useState([]);
  const [searchValue, handleInputChange, setSearchValue] =
    useDelayedInputChange(refetch, setRefetch);
  const [hcpcsSearchValue, setHcpcsSearchValue] = useState(undefined);
  const [locations, setLocations] = useState([]);
  const [tableVisible, setTableVisible] = useState(false);
  const { isMobile } = useHandleResize();
  const [editableBillableItem, setEditableBillableItem] = useState({});
  const [billableToReplaceDialogVisible, setBillableToReplaceDialogVisible] =
    useState(false);
  const [billableToReplace, setBillableToReplace] = useState();
  const [replace, setReplace] = useState(false);
  const locationRef = useRef();
  const [noResults, setNoResults] = useState(false);

  const defaultValues = {
    serviceName: "",
    serviceDescription: "",
    serviceType: 1,
    internalOnly: false,
    location: "",
    cptCode: selectedCptCodes,
    sendCheckInPass: true,
  };

  const { setValue, control, reset, watch } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const formData = [
    watch("serviceName"),
    watch("serviceDescription"),
    watch("serviceType"),
    watch("internalOnly"),
    watch("location"),
    selectedCptCodes,
    serviceDocumentsIds,
    watch("sendCheckInPass"),
  ];

  const getAllBillableItemsInRules = () => {
    const swapAction = allRules.filter((rule, i) => rule.action === "swapCPT");
    const swappedBillableItems = swapAction.length
      ? swapAction.map((item) => item.billableItems[1])
      : [];

    return allRules
      .filter((rule, i) => rule.action === "addCPT")
      .map((rule) => rule.billableItems)
      .flat()
      .concat(swappedBillableItems)
      .map((b) => b.id);
  };

  useEffect(() => {
    onFormDataChange(formData);
  }, [
    watch("serviceName"),
    watch("serviceDescription"),
    watch("serviceType"),
    watch("internalOnly"),
    watch("location"),
    selectedCptCodes,
    serviceDocumentsIds,
    watch("sendCheckInPass"),
  ]);

  const handleInputErrorChange = (field, e) => {
    field.onChange(e.target.value);
    const name = field.name;
    let errorMessage = null;
    if (name === "serviceName" && !e.target.value.trim()) {
      errorMessage = "Please enter a name for the service.";
    } else if (name === "serviceDescription") {
      if (!e.target.value.trim()) {
        errorMessage = "Please enter a description for the service.";
      } else if (e.target.value.length > 999) {
        errorMessage = "The description must be less than 1000 characters.";
      }
    } else if (name === "location" && e.target.value.length === 0) {
      errorMessage = "Please select at least one location.";
    }

    setErrorsFields((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage ? { message: errorMessage } : null,
    }));
  };
  const handleBillableItemsChange = () => {
    const errorMessage =
      selectedCptCodes?.length === 0
        ? "Please select at least one billable item."
        : null;
    setErrorsFields((prevErrors) => ({
      ...prevErrors,
      billables: { message: errorMessage },
    }));
  };
  const dataTableRef = useRef(null);
  useEffect(() => {
    if (formInteracted) {
      handleBillableItemsChange();
    }
  }, [selectedCptCodes]);
  useEffect(() => {
    setNotSavedBillableItems(selectedCptCodes);
  }, [selectedCptCodes]);

  const getData = async () => {
    const servicesData = await http.get(
      `/Services/GetServiceWizard/${serviceId}`,
    );
    const data = JSON.parse(servicesData.data.serviceJson);

    setValue("serviceType", servicesData.data.serviceType);
    setValue("internalOnly", servicesData.data.internalOnly);
    setValue("sendCheckInPass", servicesData.data.sendCheckInPass);
    setCurrentGeneralInfo(data.generalInfo);
    console.log(location);
    const filteredLoc = location?.filter((loc) =>
      currentGeneralInfo?.locations?.includes(loc.id),
    );

    setAvailableLocations(filteredLoc);
  };

  useEffect(() => {
    if (!locations[0]) {
      setLocations(location);
    }
  }, [location]);

  useEffect(() => {
    if (!locationVisible) return;
    const getLocations = async () => {
      try {
        const res = await http.get(
          `/Locations/GetLocationsByEntityId/${entityId}${serviceId ? "?serviceId=" : ""}${serviceId && !isCopy ? serviceId : ""}`,
        );
        setLocations(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    getLocations();
    setLocationVisible(false);
  }, [locationRefetch]);

  useEffect(() => {
    if (editValues) {
      const formattedBillables = editValues.billables.map((billable) => {
        return billable.id;
      });
      setSelectedCptCodes(editValues.billables);
      setNotSavedBillableItems(editValues.billables);
      setServiceDocumentsIds(editValues?.serviceDocumentsIds || []);
      reset({
        serviceName: `${editValues.name} ${isCopy ? " - Copy" : ""}`,
        serviceDescription: editValues.description,
        serviceType: editValues.serviceType,
        location: editValues.locations,
        cptCode: formattedBillables,
        sendCheckInPass: editValues.sendCheckInPass,
      });
    }
    serviceId && getData();
  }, [editValues]);

  const editUnitsTemplate = (rowData, options) => {
    const isEditing = editableBillableItem?.index === options.rowIndex;
    return (
      <span className="p-input-icon-right w-24">
        <InputText
          keyfilter={/^\d+$/}
          className="w-full"
          onBlur={() => {
            if (editableBillableItem?.value <= 0) {
              setEditableBillableItem({});
              return;
            }

            const clonedCPTCodes = structuredClone(selectedCptCodes);
            const updatedItem = {
              ...clonedCPTCodes[options.rowIndex],
              units: editableBillableItem?.value,
              error: "",
            };
            clonedCPTCodes[options.rowIndex] = updatedItem;
            setSelectedCptCodes(clonedCPTCodes);
            setEditableBillableItem({});
          }}
          value={isEditing ? editableBillableItem?.value : rowData.units}
          disabled={!isEditing}
          onChange={(e) => {
            setEditableBillableItem((prev) => ({
              ...prev,
              value: e.target.value,
            }));
          }}
        />
        {isEditing ? (
          <i
            className="pi pi-check cursor-pointer"
            style={{ color: "green" }}
            onClick={() => {
              if (editableBillableItem?.value <= 0) {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Minimum units must be at least 1",
                  life: 4000,
                });
                return;
              }

              const clonedCPTCodes = structuredClone(selectedCptCodes);
              const updatedItem = {
                ...clonedCPTCodes[options.rowIndex],
                units: editableBillableItem?.value,
                error: "",
              };
              clonedCPTCodes[options.rowIndex] = updatedItem;
              setSelectedCptCodes(clonedCPTCodes);
              setEditableBillableItem({});
            }}
          />
        ) : (
          <i
            className="pi pi-pencil cursor-pointer"
            style={{ color: "blue" }}
            onClick={(e) => {
              setEditableBillableItem({
                index: options.rowIndex,
                value: selectedCptCodes[options.rowIndex].units,
              });
            }}
          />
        )}
      </span>
    );
  };

  const isBillableItemUsed = (rules, id) => {
    return rules.some(
      (rule) =>
        rule?.defaultUnitsChange?.some((b) => b.id === id) ||
        (rule?.action === "removeCPT" &&
          rule?.billableItems?.some((b) => b.id === id)),
    );
  };

  const showBillableItemInUseToast = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: (
        <p>
          A rule is applying an action on this billable item,
          <strong> Please review your rules.</strong>
        </p>
      ),
      life: 4000,
    });
  };

  const deleteTemplate = (rowData) => {
    const billables = [...selectedCptCodes];
    const index = billables.findIndex((obj) => obj.id == rowData.id);

    return (
      <div key={rowData.id}>
        {index !== -1 ? (
          <div className="flex items-center gap-2">
            <i
              className="description pi pi-arrow-right-arrow-left cursor-pointer text-blue-500"
              data-pr-position="top"
              data-pr-tooltip="Replace"
              onClick={() => {
                setBillableToReplace(rowData);
                setBillableToReplaceDialogVisible(true);
              }}
            />
            <i
              className="description pi pi-trash cursor-pointer text-red-400"
              data-pr-position="top"
              data-pr-tooltip="Delete"
              onClick={(e) => {
                confirmDialog({
                  message: "Are you sure you want to remove billable item?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => {
                    if (isBillableItemUsed(allRules, rowData.id)) {
                      showBillableItemInUseToast();
                      return;
                    }

                    setEditableBillableItem({});
                    const billables = [...selectedCptCodes];
                    billables.splice(index, 1);
                    setSelectedCptCodes(billables);
                    setNotSavedBillableItems(billables);
                  },
                  reject: () => {},
                });
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const unitsTemplate = (rowData) => {
    const index = notSavedBillableItems.findIndex(
      (obj) => obj.id === rowData.id,
    );

    const inputValue = index !== -1 ? notSavedBillableItems[index].units : "";

    const handleInputChange = (e) => {
      const newValue = e.target.value.trim(); // Trim leading and trailing spaces
      const cloneSelected = [...notSavedBillableItems];

      if (newValue === "" || parseFloat(newValue) === 0) {
        // If empty or 0 value, set error message or handle as needed
        // For now, let's set an error message in the state
        const updatedItem = {
          ...cloneSelected[index],
          units: Number(newValue),
          error: "Value is required and cannot be 0",
        };
        cloneSelected[index] = updatedItem;
      } else {
        const updatedItem = {
          ...cloneSelected[index],
          units: Number(newValue),
          error: "", // Clear error message
        };
        cloneSelected[index] = updatedItem;
      }

      setNotSavedBillableItems(cloneSelected);
    };

    return (
      <div className="inline-flex" key={rowData.id}>
        {index !== -1 ? (
          <>
            <div className="">
              <InputText
                className={`${notSavedBillableItems[index].error ? "p-invalid" : ""} w-16`}
                keyfilter={/^\d+$/}
                value={inputValue}
                tooltipOptions={{ position: "top" }}
                tooltip={notSavedBillableItems[index].error}
                onChange={handleInputChange}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const getSelectedTemplateJSON = async (serviceId) => {
    const res = await http.get(`/Services/GetServiceWizard/${serviceId}`);
    const data = JSON.parse(res.data.serviceJson);
    setGeneralEditInfo(data.generalInfo);
    setFormQuestions(data.questions);
    setAllQuestions(data.questions);
    setAllRules(data.services);
  };

  const modTemplate = (rowData) => {
    const mods = [
      rowData.mod1,
      rowData.mod2,
      rowData.mod3,
      rowData.mod4,
    ].filter((mod) => mod);

    return mods.length ? (
      <span className="text-sm"> {mods.join(" | ")} </span>
    ) : (
      <span className="text-md"> - - -</span>
    );
  };

  const attributeType = {
    1: "None",
    2: "NDC",
    3: "Supply Id",
    4: "Provider Type",
  };

  const attTemplate = (rowData) => {
    if (rowData.attributeType) {
      return (
        <span>
          {attributeType[rowData.attributeType]} / {rowData.attributeTypeValue}
        </span>
      );
    }
  };

  const priceTemplate = (rowData) => {
    return (
      <p>
        <span className="text-green-500">$</span> {rowData.chargeAmount}
      </p>
    );
  };
  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const cardBillableItemsTemplate = (row) => {
    return (
      <div className="flex flex-col gap-2 rounded-lg border p-4 text-sm">
        <div className="flex gap-2">
          <Checkbox
            inputId={row.hcpcs}
            name={row.hcpcs}
            value={row}
            onChange={(e) => {
              const cloneSelected = [...selectedCptCodes];
              const index = selectedCptCodes.findIndex(
                (obj) => obj.id === e.value.id,
              );
              // If the object exists, remove it; otherwise, add it to the array
              if (index !== -1) {
                // Remove the object from the array using splice
                cloneSelected.splice(index, 1);
              } else {
                // Add the object to the selectedCptCodes
                cloneSelected.push({
                  ...e.value,
                  units: e.value.defaultUnits,
                });
              }
              // Update selectedCptCodes state
              setSelectedCptCodes(cloneSelected);
              setNotSavedBillableItems(cloneSelected);
              setErrorsFields((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (cloneSelected.length === 0) {
                  updatedErrors.billables = {
                    message: "Please select at least one billable item",
                  };
                } else {
                  delete updatedErrors.billables;
                }
                return updatedErrors;
              });
            }}
            checked={!!selectedCptCodes.find((cpt) => cpt.id === row.id)}
          />
          <label htmlFor={row.hcpcs}>
            <p>
              <span className="text-slate-500">Code:&nbsp;</span>
              <span className="text-black">{row.hcpcs}</span>
            </p>
          </label>
        </div>
        <p className="font-semibold">
          <span className="text-slate-500">Description:&nbsp;</span>
          {row.description}
        </p>
        <div className="flex flex-wrap gap-2">
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 1:&nbsp;</span>
            {row.mod1}
          </p>
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 2:&nbsp;</span>
            {row.mod2}
          </p>
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 3:&nbsp;</span>
            {row.mod3}
          </p>
          <p className="basis-5/12 font-semibold">
            <span className="text-slate-500">Modifier 4:&nbsp;</span>
            {row.mod4}
          </p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            <span className="text-slate-500">Price per unit:&nbsp;</span>
            <i className="pi pi-dollar text-green-700" />
            {row.chargeAmount}
          </p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p className="flex items-center gap-2 font-semibold">
            <span className="text-slate-500">Units:&nbsp;</span>
            {unitsTemplate(row)}
          </p>
        </div>
      </div>
    );
  };

  const replaceBillable = (items, idToReplace, newItem) => {
    return items.map((item) =>
      item.id === idToReplace ? { ...item, ...newItem } : item,
    );
  };

  const replaceAllBillableItems = (idToReplace, newItem) => {
    const replacedDefaultCPT = replaceBillable(
      selectedCptCodes,
      idToReplace,
      newItem,
    );

    const replacedRulesCPT = allRules.map((rule) => {
      let updatedRule = { ...rule };

      if (updatedRule.billableItems.length) {
        updatedRule.billableItems = replaceBillable(
          updatedRule.billableItems,
          idToReplace,
          newItem,
        );
      }
      if (updatedRule.defaultUnitsChange) {
        updatedRule.defaultUnitsChange = replaceBillable(
          updatedRule.defaultUnitsChange,
          idToReplace,
          newItem,
        );
      }

      return updatedRule;
    });

    setAllRules(replacedRulesCPT);
    setSelectedCptCodes(replacedDefaultCPT);
  };

  const showConfirmReplace = (newBillable, acceptFunc) => {
    //show mods
    const getNonEmptyMods = (billable) => {
      return [
        billable.mod1,
        billable.mod2,
        billable.mod3,
        billable.mod4,
      ].filter((mod) => mod);
    };

    const oldMods = getNonEmptyMods(billableToReplace);
    const newMods = getNonEmptyMods(newBillable);

    const renderMods = (mods) => {
      return mods.length ? (
        <span className="text-sm italic"> - {mods.join(" ")} -</span>
      ) : null;
    };
    confirmDialog({
      message: (
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-lg font-semibold">
              This action will remove the current billable item:
            </p>
            <strong>{billableToReplace.hcpcs}</strong> {renderMods(oldMods)}{" "}
            <span className="text-sm italic"></span>
            <span className="text-sm italic">
              {billableToReplace.description}
            </span>
          </div>
          <div>
            <p className="text-lg font-semibold">
              And replace it with the new one:
            </p>
            <strong>{newBillable.hcpcs}</strong> {renderMods(newMods)}{" "}
            <span className="text-sm italic"></span>
            <span className="text-sm italic">{newBillable.description}</span>
          </div>
          <p className="text-lg font-semibold">
            Are you sure you want to proceed?
          </p>
        </div>
      ),
      header: `Replace ${billableToReplace.hcpcs} with ${newBillable.hcpcs}`,
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, Replace",
      rejectLabel: "Cancel",
      className: "w-1/2",
      accept: async () => {
        if (acceptFunc) {
          await acceptFunc();
        } else {
          replaceAllBillableItems(billableToReplace.id, newBillable);
          setSearchValue("");
          setBillableToReplaceDialogVisible(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Item Replaced Successfully",
            life: 4000,
          });
        }
      },
      reject: () => {},
    });
  };

  const handleBeforeSubmit = (submit, formData) => {
    showConfirmReplace(formData, () => submit(formData));
  };

  const serviceTypeOptions = [
    { name: "Scheduled Appointment Service", code: 1 },
    { name: "Walk-In Service", code: 2 },
  ];

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        visible={billableToReplaceDialogVisible}
        onHide={() => {
          setSearchValue("");
          setBillableToReplaceDialogVisible(false);
        }}
        header="Choose a billable item to replace"
        className="w-11/12 lg:w-[80vw]"
      >
        <div className="flex flex-col">
          <SelectSingleBillableTable
            link={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
            refetch={refetch}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
            emptyMessage={
              searchValue ? (
                <p className="text-center">
                  No results found in your practice's configured billable items.
                  Alternatively, you can{" "}
                  <span
                    className="cursor-pointer font-semibold text-light-purple underline"
                    onClick={() => {
                      setNoResults(true);
                      setReplace(true);
                      setSearchValue("");
                      setHcpcsSearchValue("");
                      setBillableItemVisible(true);
                      setBillableToReplaceDialogVisible(false);
                    }}
                  >
                    Search in National HCPCS Codes{" "}
                  </span>
                  and import a new item into your practice's billable items.
                </p>
              ) : (
                <p className="text-center">
                  You haven't created any billable items yet. You can create a
                  new item by clicking on 'Create New Billable Item'.
                </p>
              )
            }
            payload={getAllBillableItemsInRules().concat(
              selectedCptCodes.map((item) => item.id),
            )}
            selection={{}}
            onSelectionChange={(e) => {
              showConfirmReplace(e.value);
            }}
          />
          <Divider
            layout="horizontal"
            align="center"
            className="w-10/12 self-center"
          >
            <b>OR</b>
          </Divider>
          <div className="flex justify-center">
            <Button
              id="serviceBillables"
              label="Create new billable item"
              type="button"
              outlined
              onClick={() => {
                setReplace(true);
                setSearchValue("");
                setHcpcsSearchValue("");
                setBillableItemVisible(true);
                setBillableToReplaceDialogVisible(false);
              }}
            />
          </div>
        </div>
      </Dialog>
      <Tooltip target=".custom-target-icon" className="max-w-sm" />
      <Toast ref={toast} />
      <div className="mb-2 rounded-2xl bg-white p-7">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="w-full md:w-6/12">
            <h2 className="text-xl font-semibold capitalize text-[#3D3877]">
              {editValues && !isCopy ? "Edit" : "Create new"} service
            </h2>
          </div>
        </div>

        <Dialog
          blockScroll
          draggable={false}
          visible={billableItemVisible}
          onHide={() => {
            setReplace(false);
            setBillableItemVisible(false);
            setHcpcsSearchValue("");
            setNoResults(false);
          }}
          header="New Billable Item"
          className="w-11/12 lg:w-[80vw]"
        >
          <CreateBillableItem
            collapse={!noResults}
            data={false}
            hcpcsSearchValue={hcpcsSearchValue}
            toast={toast}
            setVisible={setBillableItemVisible}
            addToService={true}
            selectedCptCodes={selectedCptCodes}
            handleBeforeSubmit={replace ? handleBeforeSubmit : undefined}
            setSelectedCptCodes={(allBillables) => {
              if (replace) {
                setReplace(false);
                replaceAllBillableItems(
                  billableToReplace.id,
                  allBillables[allBillables.length - 1],
                );
              } else {
                setSelectedCptCodes(allBillables);
              }
            }}
          />
        </Dialog>
        <Dialog
          blockScroll
          draggable={false}
          visible={locationVisible}
          style={{ width: "70vw" }}
          onHide={() => setLocationVisible(false)}
        >
          <div className="mt-4">
            <LocationForm
              setLocationVisible={setLocationVisible}
              fromService
              locationRefetch={locationRefetch}
              setLocationRefetch={setLocationRefetch}
            />
          </div>
        </Dialog>
        <div>
          <div className="flex flex-col gap-4 py-4">
            {/* name */}
            <div>
              <label
                htmlFor="serviceName"
                className="font-medium capitalize leading-loose text-gray-500"
              >
                service name
                <span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <div>
                <span className="p-input-icon-left w-full">
                  <ServicesIcon />
                  <Controller
                    name="serviceName"
                    control={control}
                    rules={{
                      required: "Enter Service Name",
                      validate: (value) =>
                        trimValidation(value, "Service Name"),
                    }}
                    render={({ field }) => (
                      <InputText
                        value={field.value}
                        maxLength={1000}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          handleInputErrorChange(field, e);
                        }}
                        placeholder="Enter service name"
                        className="w-full"
                        id="serviceName"
                      />
                    )}
                  />
                </span>
                {errorsFields && errorsFields.serviceName?.message && (
                  <p className="text-red-500">
                    {errorsFields.serviceName.message}
                  </p>
                )}
              </div>
            </div>
            {/* description */}
            <div>
              <label
                htmlFor="serviceDescription"
                className="font-medium capitalize leading-loose text-gray-500"
              >
                service description
                <span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <span className="w-full">
                <Controller
                  name="serviceDescription"
                  control={control}
                  rules={{
                    required: "Service description is required",
                  }}
                  render={({ field }) => (
                    <InputTextarea
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleInputErrorChange(field, e);
                      }}
                      placeholder="Enter a description for your service"
                      rows={5}
                      cols={30}
                      className="w-full"
                      id="serviceDescription"
                    />
                  )}
                />
                {errorsFields && errorsFields.serviceDescription?.message && (
                  <p className="text-red-500">
                    {errorsFields.serviceDescription.message}
                  </p>
                )}
              </span>
            </div>
            {/* servie Type  */}

            <div>
              <label
                htmlFor="serviceType"
                className="font-medium capitalize leading-loose text-gray-500"
              >
                service Type
                <span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <div>
                <div className="flex items-center gap-5">
                  <span className="grow">
                    <Controller
                      name="serviceType"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          name={field.name}
                          value={field.value}
                          placeholder="Service Type"
                          focusInputRef={field.ref}
                          options={serviceTypeOptions}
                          optionLabel="name"
                          optionValue="code"
                          onChange={(e) => {
                            field.onChange(e.value);
                            handleInputErrorChange(field, e);
                          }}
                          className="md:w-14rem w-full"
                        />
                      )}
                    />
                  </span>
                  <span className="flex gap-1">
                    <Controller
                      name="internalOnly"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          inputId="internalOnly"
                          name={field.name}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.checked);
                          }}
                        />
                      )}
                    />
                    <label
                      htmlFor="internalOnly"
                      className="cursor-pointer font-semibold text-gray-800"
                    >
                      Internal Only
                      <span
                        className="custom-target-icon"
                        data-pr-position="top"
                        data-pr-tooltip={`Enabling this feature will make this service hidden from external ordering providers. By enabling this feature you are publishing a service that can ONLY be ordered by users associated with your practice. This is customarily done in order to publish services that you provide to your patients, where you wish to appropriately collect for them using the MedX platform, but do not wish to allow any staff or providers external to your practice from ordering such services. The default is to have this disabled, especially if you are planning on having this service to be visible and purchasable by other providers.`}
                      >
                        <i className="pi pi-info-circle px-2 text-sm" />
                      </span>
                    </label>
                  </span>
                </div>
                {errorsFields && errorsFields.serviceType?.message && (
                  <p className="text-red-500">
                    {errorsFields.serviceType.message}
                  </p>
                )}
              </div>
            </div>

            {/* check-in pass  */}
            <div className="mt-4 flex w-full">
              <div className="w-full rounded-lg border border-slate-200 bg-white p-4 shadow-sm">
                <div className="flex items-center gap-3">
                  <Controller
                    name="sendCheckInPass"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        inputId="sendCheckInPass"
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.checked);
                        }}
                      />
                    )}
                  />
                  <label
                    htmlFor="sendCheckInPass"
                    className="cursor-pointer font-semibold text-gray-800"
                  >
                    Check-In Pass
                  </label>
                </div>
                <p className="py-2 text-xs italic text-gray-400">
                  Send a Check-In pass with order details and barcode to
                  patient. If the service type is{" "}
                  <span className="text-xs font-semibold text-gray-500">
                    "Scheduled Appointment Service"
                  </span>{" "}
                  then the patient will receive the check-in slip after you
                  schedule the service. If the service type is{" "}
                  <span className="text-xs font-semibold text-gray-500">
                    {" "}
                    "Walk-In Service"
                  </span>{" "}
                  then the patient will receive the appointment check-in slip
                  instantly after completing payment and your acceptance of the
                  order.
                </p>
              </div>
            </div>

            {/* LOCATIONS */}
            <div className="flex flex-col">
              <label
                htmlFor="location"
                className="font-medium capitalize leading-loose text-gray-500"
              >
                rendered at
                <span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <div className="flex flex-col gap-3 rounded-lg border border-slate-200 p-3">
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-gray-500">
                    Select one or more locations where this service can be
                    performed.
                  </p>
                  <p className="text-xs text-gray-400">
                    * Please note that locations flagged as disabled will not be
                    visible to order providers.
                  </p>
                </div>
                <div id="serviceLocation">
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: "please select your location" }}
                    render={({ field }) => (
                      <MultiSelect
                        name="location"
                        filter
                        filterTemplate={(options) => {
                          return (
                            <span className="ml-2 font-inter text-sm">
                              Select All
                            </span>
                          );
                        }}
                        panelHeaderTemplate={(options) => (
                          <div
                            className={`{${options.className} flex-start flex w-full items-center gap-4 bg-[#f8f9fa] pl-5`}
                          >
                            <div>
                              {options.checkboxElement}
                              {options.filterElement}
                            </div>
                            <Button
                              label="Create new location"
                              id="newLocation"
                              icon="pi pi-map-marker"
                              type="button"
                              link
                              onClick={() => {
                                if (driverObj ? driverObj.isActive() : false) {
                                  driverObj.moveNext();
                                } else {
                                  setLocationVisible(true);
                                }
                              }}
                            />
                          </div>
                        )}
                        className="md:w-14rem w-full"
                        ref={field.ref}
                        value={field.value}
                        selectedItemTemplate={(value) => {
                          const filteredLoc = location?.find(
                            (loc) => value === loc.id,
                          );
                          return (
                            filteredLoc && (
                              <span
                                className={`mr-1 rounded-full border border-gray-200 bg-gray-50 px-3 py-1 font-semibold capitalize ${filteredLoc.isEnabled ? "text-gray-600" : "text-gray-400"}`}
                              >
                                {filteredLoc.name}
                              </span>
                            )
                          );
                        }}
                        placeholder="Choose Locations"
                        options={locations}
                        optionLabel="name"
                        optionValue="id"
                        onShow={() => {
                          if (driverObj ? driverObj.isActive() : false) {
                            driverObj.moveNext();
                            locationRef.current.hide();
                          }
                        }}
                        onChange={(e) => {
                          field.onChange(e.value);
                          const filteredLoc = locations?.filter((loc) =>
                            e.value.includes(loc.id),
                          );
                          setAvailableLocations(filteredLoc);
                          handleInputErrorChange(field, e);
                        }}
                        itemTemplate={(option) => (
                          <div>
                            <h4>
                              <span
                                className={`font-medium capitalize ${option.isEnabled ? "text-gray-600" : "text-gray-400"}`}
                              >
                                {option.name}{" "}
                              </span>
                              {!option.isEnabled && (
                                <span className="text-sm italic text-gray-400">
                                  (disabled)
                                </span>
                              )}
                            </h4>
                            <p
                              className={`font-normal capitalize ${option.isEnabled ? "text-gray-600" : "text-gray-400"}`}
                            >{`${option.address.lineOne}, ${
                              option.address.lineTwo
                                ? option.address.lineTwo + ","
                                : ""
                            } ${option.address.city}, ${option.address.state} ${
                              option.address.zipCode
                            }`}</p>
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              {errorsFields && errorsFields.location?.message && (
                <div className="text-red-500">
                  {errorsFields.location.message}
                </div>
              )}
            </div>

            {/* Billable Items */}
            <div>
              <span className="flex items-baseline gap-2">
                <span className="font-medium capitalize leading-loose text-gray-500">
                  default Billable Items
                  <span className="ml-1 font-bold text-red-500">*</span>
                </span>
                <i
                  className="custom-target-icon pi pi-info-circle cursor-pointer text-sm text-gray-400"
                  data-pr-tooltip="These are the base charges that will be automatically added to
                    the cart when this service is ordered. You can further
                    customize charges based on order answers."
                  data-pr-position="right"
                ></i>
              </span>
              <div className="flex flex-col gap-3 rounded-lg border border-slate-200 p-3">
                <div className="flex flex-col gap-3 sm:flex-row">
                  <Button
                    label="Attach billable items"
                    onClick={() => {
                      setTableVisible(true);
                      if (driverObj ? driverObj.isActive() : false) {
                        driverObj.moveNext();
                      }
                    }}
                    icon="pi pi-plus"
                    id="serviceDefaultBillables"
                  />
                </div>

                <div className={isMobile ? "max-h-[360px] overflow-auto" : ""}>
                  <DataTable
                    ref={dataTableRef}
                    className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
                    scrollHeight="360px"
                    scrollable
                    emptyMessage={
                      <p className="text-center">
                        Currently, there are no billable items selected.
                      </p>
                    }
                    value={selectedCptCodes}
                  >
                    {[
                      { name: "HCPCS", value: "hcpcs" },
                      {
                        name: "Description",
                        template: descriptionWithTooltipTemplate,
                      },
                      {
                        name: "Mods",
                        template: (e) => modTemplate(e),
                        header: "Mods",
                      },
                      {
                        name: "Attributes",
                        template: (e) => attTemplate(e),
                        header: "Attributes",
                      },
                      {
                        name: "Price per unit",
                        template: priceTemplate,
                        header: "Price per unit",
                      },
                      {
                        name: "Units",
                        template: editUnitsTemplate,
                        header: "Units",
                      },
                      {
                        name: "",
                        template: (e) => deleteTemplate(e),
                        header: "Remove Billable items",
                      },
                    ].map((column) => (
                      <Column
                        key={column.name}
                        header={column.name}
                        align="center"
                        alignHeader="center"
                        style={column.style ? column.style : null}
                        body={(rowData, options) => {
                          if (column.name.toLowerCase().includes("date")) {
                            return (
                              rowData[column.value] &&
                              formatDate(rowData[column.value])
                            );
                          } else if (
                            column.template &&
                            typeof column.template === "function"
                          ) {
                            return column.template(rowData, options);
                          } else {
                            return rowData[column.value];
                          }
                        }}
                      />
                    ))}
                  </DataTable>
                  {/* )} */}
                </div>
              </div>
              {errorsFields &&
                errorsFields.billables &&
                errorsFields.billables.message && (
                  <p className="text-red-500">
                    {errorsFields.billables.message}
                  </p>
                )}
            </div>
            <div>
              <AttachServiceFiles
                serviceDocumentsIds={serviceDocumentsIds}
                setServiceDocumentsIds={setServiceDocumentsIds}
              />
            </div>
          </div>

          <div>
            <Dialog
              blockScroll
              draggable={false}
              className="w-11/12 lg:w-[70vw]"
              header="Attach Billable Items"
              visible={tableVisible}
              onHide={() => {
                setNotSavedBillableItems(selectedCptCodes);
                setTableVisible(false);
                setSearchValue("");
              }}
            >
              <div className="flex flex-col p-4">
                <div className="flex flex-col gap-2">
                  <div className="overflow-hidden rounded-xl border border-gray-300">
                    <ReusableDataList
                      scrollable={true}
                      scrollHeight="264px"
                      emptyMessage={
                        searchValue ? (
                          <p className="text-center">
                            No results found in your practice's configured
                            billable items. Alternatively, you can{" "}
                            <span
                              className="cursor-pointer font-semibold text-light-purple underline"
                              onClick={() => {
                                setNoResults(true);
                                setHcpcsSearchValue(searchValue);
                                setBillableItemVisible(true);
                                setTableVisible(false);
                                setSearchValue("");
                              }}
                            >
                              Search in National HCPCS Codes{" "}
                            </span>
                            and import a new item into your practice's billable
                            items.
                          </p>
                        ) : (
                          <p className="text-center">
                            You haven't created any billable items yet. You can
                            create a new item by clicking on 'Create New
                            Billable Item'.
                          </p>
                        )
                      }
                      selectedRows={notSavedBillableItems}
                      onSelectionChange={(rowData) => {
                        const cloneSelected = [...notSavedBillableItems];
                        const index = cloneSelected.findIndex(
                          (obj) => obj.id === rowData.data.id,
                        );
                        if (
                          index !== -1 &&
                          isBillableItemUsed(allRules, rowData.data.id)
                        ) {
                          showBillableItemInUseToast();
                          return true;
                        }
                        setNotSavedBillableItems(() => {
                          // If the object exists, remove it; otherwise, add it to the array
                          if (index !== -1) {
                            // Remove the object from the array using splice
                            cloneSelected.splice(index, 1);
                          } else {
                            // Add the object to the selectedCptCodes
                            cloneSelected.push({
                              ...rowData.data,
                              units: rowData.data.defaultUnits,
                              error: "",
                            });
                          }
                          return cloneSelected;
                        });
                      }}
                      searchTitle="Search HCPCS, Description"
                      fetchMethod="put"
                      payload={getAllBillableItemsInRules()}
                      dataLink={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
                      sendSearchData={handleInputChange}
                      searchValue={searchValue}
                      columns={[
                        { name: "HCPCS", value: "hcpcs" },
                        { name: "Description", value: "description" },
                      ]}
                      actionTemplates={
                        isMobile
                          ? [{ template: cardBillableItemsTemplate }]
                          : [
                              {
                                name: "Mods",
                                template: (e) => modTemplate(e),
                                header: "Mods",
                              },
                              {
                                name: "Attributes",
                                template: (e) => attTemplate(e),
                                header: "Attributes",
                              },
                              {
                                template: priceTemplate,
                                header: "Price per unit",
                              },
                              {
                                template: (e) => unitsTemplate(e),
                                header: "Units",
                              },
                            ]
                      }
                      selectionRowFunction={true}
                      refetch={refetch}
                    />
                  </div>
                </div>
                <Divider
                  layout="horizontal"
                  align="center"
                  className="w-10/12 self-center"
                >
                  <b>OR</b>
                </Divider>
                <div className="flex justify-center">
                  <Button
                    id="serviceBillables"
                    label="Create new billable item"
                    type="button"
                    outlined
                    onClick={() => {
                      if (driverObj ? driverObj.isActive() : false) {
                        driverObj.moveNext();
                      } else {
                        setBillableItemVisible(true);
                        setHcpcsSearchValue("");
                        setTableVisible(false);
                        setSearchValue("");
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex justify-end p-4">
                <Button
                  label="Save"
                  onClick={() => {
                    const hasErrors = notSavedBillableItems.some(
                      (item) => item?.error && item.error !== "",
                    );
                    if (!hasErrors) {
                      setSelectedCptCodes(notSavedBillableItems);
                      setTableVisible(false);
                      setSearchValue("");
                    } else {
                      return;
                    }
                  }}
                />
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceInfoTemplate;
