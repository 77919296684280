import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";

const SwappedBillableTable = ({ value }) => {
  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const modTemplate = (rowData, mod) => {
    return rowData[mod] ? (
      <p>{rowData[mod]}</p>
    ) : (
      <p className="border-[1px] border-gray-400"></p>
    );
  };

  const priceTemplate = (rowData) => {
    return (
      <p>
        {rowData.chargeAmount} <span className="text-green-500">$</span>
      </p>
    );
  };
  return (
    <DataTable
      value={value ? [value] : []}
      className="rounded-lg border-2 border-gray-200"
      emptyMessage={
        <p className="text-center">
          Currently, there are no billable items selected.
        </p>
      }
    >
      {[
        { name: "HCPCS", value: "hcpcs" },
        {
          name: "Description",
          template: descriptionWithTooltipTemplate,
        },
        {
          name: "Mod 1",
          template: (e) => modTemplate(e, "mod1"),
          header: "Mod 1",
        },
        {
          name: "Mod 2",
          template: (e) => modTemplate(e, "mod2"),
          header: "Mod 2",
        },
        {
          name: "Mod 3",
          template: (e) => modTemplate(e, "mod3"),
          header: "Mod 3",
        },
        {
          name: "Mod 4",
          template: (e) => modTemplate(e, "mod4"),
          header: "Mod 4",
        },
        {
          name: "Price per unit",
          template: priceTemplate,
          header: "Price per unit",
        },
        { name: "Units", value: "units" },
      ].map((column) => (
        <Column
          key={column.name}
          header={column.name}
          align="center"
          alignHeader="center"
          style={column.style ? column.style : null}
          body={(rowData) => {
            if (column.template && typeof column.template === "function") {
              return column.template(rowData);
            } else {
              return rowData[column.value];
            }
          }}
        />
      ))}
    </DataTable>
  );
};

export default SwappedBillableTable;
