import { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../../hooks/useAxios";
import useTokenData from "../../../../../hooks/useTokenData";

const BillableItemsReport = () => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const [billableItems, setBillableItems] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const GetAllBillableItems = async () => {
    setIsLoading(true);
    try {
      const response = await http.put(
        `/BillableItems/GetAllBillableItems/${entityId}`,
        [],
      );
      setBillableItems(response.data);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    GetAllBillableItems();
  }, []); // eslint-disable-line

  const descriptionWithTooltipTemplate = (rowData, options) => {
    return (
      <div>
        {options.rowIndex === 0 && (
          <Tooltip className="max-w-xs" target=".table-row" />
        )}
        <p
          className="table-row cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const priceTemplate = (rowData) => {
    return (
      <p>
        <span className="text-green-500">$</span>
        {rowData.chargeAmount}
      </p>
    );
  };

  const modTemplate = (rowData, mod) => {
    return rowData[mod] ? <p>{rowData[mod]}</p> : <p>________</p>;
  };

  const columns = [
    { field: "hcpcs", header: "HCPCS", sortable: true },
    {
      field: "shortDescription",
      body: descriptionWithTooltipTemplate,
      header: "Description",
    },
    { field: "mod1", body: (e) => modTemplate(e, "mod1"), header: "Mod 1" },
    { field: "mod2", body: (e) => modTemplate(e, "mod2"), header: "Mod 2" },
    { field: "mod3", body: (e) => modTemplate(e, "mod3"), header: "Mod 3" },
    { field: "mod4", body: (e) => modTemplate(e, "mod4"), header: "Mod 4" },
    {
      field: "chargeAmount",
      body: priceTemplate,
      header: "Price",
      sortable: true,
    },
  ];

  const exportPdf = () => {
    setIsDownloading(true);
    import("jspdf")
      .then((jsPDF) => {
        import("jspdf-autotable").then(() => {
          const doc = new jsPDF.default(0, 0);

          doc.autoTable({ html: ".billable-items-table" });
          doc.save("billable-items-report.pdf");
        });
      })
      .finally(() => setIsDownloading(false));
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className="flex flex-col gap-2">
      <div className="py-2">
        <p>Billable Items Report</p>
        <span className="w-[20rem] text-sm font-normal italic text-gray-500">
          Export will include sorted and filtered data only.
        </span>
      </div>
      <div className="flex w-full flex-col items-start justify-between gap-2 sm:flex-row sm:items-center">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Filter by HCPCS, Description"
          className="sm:w-[20rem]"
        />
        <Button
          tooltip="Export will include sorted and filtered data only."
          tooltipOptions={{ position: "left", showDelay: 700 }}
          icon="pi pi-file-pdf"
          label="Export"
          loading={isDownloading}
          onClick={exportPdf}
        />
      </div>
    </div>
  );

  return (
    <div>
      <DataTable
        filters={filters}
        globalFilterFields={columns
          .map((col) => col.field)
          .concat(["description"])}
        removableSort
        header={header}
        loading={isLoading}
        value={billableItems}
        tableClassName="billable-items-table min-w-[50rem]"
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            body={column.body}
            header={column.header}
            sortable={column.sortable}
            sortField={column.field}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default BillableItemsReport;
