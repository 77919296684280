import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";

function NotifsForm({ notifs, onSubmit, onCancel, cancelLabel }) {
  const showSMSCol = notifs?.notifications.some((notif) => notif.hasSMS);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: notifs,
  });
  const handleCancel = () => {
    reset(notifs);
    onCancel && onCancel();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex max-w-4xl flex-col gap-2 rounded-xl border bg-white p-4">
          <div className="grid grid-cols-12 rounded-md bg-slate-50 px-8 py-3 text-lg font-semibold shadow">
            <div className="col-span-8">Notification</div>
            <div className="col-span-4 grid grid-cols-5 justify-center">
              <div
                className={`${showSMSCol ? "col-span-2" : "col-span-5"} text-center`}
              >
                Emails
              </div>
              {showSMSCol && (
                <div className="col-span-2 col-start-4 text-center align-top">
                  SMS
                  {notifs.isOptedOut && (
                    <span className="text-gray-400">*</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {notifs?.notifications.map((notif, index) => {
              const isEmailDisabled =
                notif.isHighPriority &&
                (!notif.hasSMS ||
                  !watch(`notifications.${index}.values.isSMSEnabled`));
              const isSMSDisabled =
                notifs.isOptedOut ||
                (notif.isHighPriority &&
                  !watch(`notifications.${index}.values.isEmailEnabled`));
              return (
                <div
                  className="grid grid-cols-12 rounded-md bg-white px-8 py-3 shadow-sm hover:bg-slate-50"
                  key={notif.key}
                >
                  {notifs.isOptedOut && (
                    <Tooltip target=".notifs-tooltip" className="max-w-sm" />
                  )}
                  <div className="col-span-8 flex flex-col">
                    <div className="font-semibold">{notif.title}</div>
                    <div className="text-sm italic text-gray-400">
                      {notif.description}
                    </div>
                  </div>

                  <div className="col-span-4 grid grid-cols-5 text-center">
                    <div
                      className={`${showSMSCol ? "col-span-2" : "col-span-5"} flex items-center justify-center`}
                    >
                      <Controller
                        control={control}
                        name={`notifications.${index}.values.isEmailEnabled`}
                        render={({ field }) => (
                          <span aria-label="check-email">
                            <Checkbox
                              inputId={field.name}
                              checked={field.value}
                              inputRef={field.ref}
                              onChange={(e) => {
                                field.onChange(e.checked);
                              }}
                              disabled={isEmailDisabled}
                            />
                          </span>
                        )}
                      />
                    </div>
                    {notif.hasSMS && (
                      <div className="col-span-2 col-start-4 flex items-center justify-center">
                        <Controller
                          control={control}
                          name={`notifications.${index}.values.isSMSEnabled`}
                          render={({ field }) => (
                            <span
                              className="notifs-tooltip"
                              aria-label="check-sms"
                              data-pr-position="top"
                              data-pr-tooltip="Available to edit when the associated phone number is verified and opt-in setting is enabled."
                            >
                              <Checkbox
                                inputId={field.name}
                                checked={field.value}
                                inputRef={field.ref}
                                onChange={(e) => {
                                  field.onChange(e.checked);
                                }}
                                disabled={isSMSDisabled}
                              />
                            </span>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {notifs.isOptedOut && showSMSCol && (
            <div className="mt-1 border-t-2 px-5 py-2 text-xs text-gray-400">
              * Available to edit when the associated phone number is verified
              and opt-in setting is enabled.
            </div>
          )}
          <div className="flex justify-end gap-4 p-4">
            <Button
              disabled={isSubmitting}
              type="button"
              severity="danger"
              onClick={handleCancel}
              id="cancel-notifs"
              label={cancelLabel ? cancelLabel : "Cancel"}
            />
            <Button
              label="Save Changes"
              id="save-notifs"
              loading={isSubmitting}
            ></Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NotifsForm;
