const notifications = {
  1: {
    title: "Practice Verified",
    description: "Practice has been verified successfully.",
  },
  2: {
    title: "Practice Declined",
    description: "Practice verification has been declined.",
  },
  3: {
    title: "Practice Enabled",
    description: "Practice has been enabled.",
  },
  4: {
    title: "Practice Disabled",
    description: "Practice has been disabled.",
  },
  5: {
    title: "Order Cancellation",
    description: "An order has been canceled.",
  },
  6: {
    title: "Join Request Approval",
    description: "A join request has been approved.",
  },
  7: {
    title: "Join Request Denial",
    description: "A join request has been denied.",
  },
  8: {
    title: "Inbound Orders",
    description: "New inbound orders have been received.",
  },
  9: {
    title: "Rescheduled Orders",
    description: "An order has been rescheduled.",
  },
  10: {
    title: "New Orders Assigned",
    description: "You have been assigned new orders.",
  },
  11: {
    title: "Pending Orders Reminder",
    description: "You have pending orders that need attention.",
  },
  12: {
    title: "Setup New Practice",
    description: "A new practice setup is required.",
  },
  13: {
    title: "Re-Validate Requests",
    description: "There are requests that need re-validation.",
  },
  14: {
    title: "Inactive Practices",
    description: "Some practices are inactive and need attention.",
  },
  15: {
    title: "Finalized Orders",
    description: "Orders have been finalized.",
  },
  16: {
    title: "Provider Verified",
    description: "Provider has been verified successfully.",
  },
  17: {
    title: "Provider Declined",
    description: "Provider verification has been declined.",
  },
};

export function mapUserNotifsWithDetails(userNotifs) {
  return userNotifs.map((notif) => {
    const details = notifications[notif.key];

    return {
      ...notif,
      title: details?.title,
      description: details?.description,
    };
  });
}
