import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { RadioButton } from "primereact/radiobutton";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { useCurrentEntity } from "../../../store/store";
import PinStatusPanal from "./components/PinStatusPanal";
import { ReusableFilter } from "../../../components/Table";
import useHandleResize from "../../../hooks/useHandleResize";
import useImpersonating from "../../../hooks/useImpersonating";
import { blockSpecialCharsPattern } from "../../../utils/helpers";
import AroundTheWorld from "../../../iconComponents/AroundTheWorld";
import { CompletionChecklist, ServicesCard } from "../../../components/cards";
import { ServicesCardSkeleton } from "../../../components/skeletons/ServicesCardSkeleton";
import ProviderNotificationPanel from "../../../components/banners/ProviderNotificationPanel";
import { ServiceLocationsSkeleton } from "../../../components/skeletons/ServiceLocationsSkeleton";

const REQUESTED_DATA_SIZE = 10;

function FindServices() {
  const { http } = useAxios();
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [cities, setCities] = useState("");
  const [services, setServices] = useState();
  const { currentEntity } = useCurrentEntity();
  const isWelcome = location?.state?.isWelcome;
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchService, setSearchService] = useState("");
  const [servicePanel, setServicePanel] = useState("all");
  const [selectedState, setSelectedState] = useState(null);
  const [servicesCount, setServicesCount] = useState(null);
  const { isSystemAdminImpersonating } = useImpersonating();
  const { entityId, IsEntityApproved, HasPin, VerifyProvider } = useTokenData();
  const statesSort = [
    {
      name: "price (Low to High)",
      servicesCode: "minFees",
      savedServicesCode: "Services.MinFees",
    },
    {
      name: "price (High to Low)",
      servicesCode: "-minFees",
      savedServicesCode: "-Services.MaxFees",
    },
  ];
  const [currentServiceLocations, setCurrentServiceLocations] = useState([]);
  const [serviceLocationsStatus, setServiceLocationsStatus] = useState("idle");

  const [sort, setSort] = useState("");
  let newSort = { name: "", servicesCode: "", savedServicesCode: "" };

  // Handle Paginator
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(REQUESTED_DATA_SIZE);
  const { isMobile, isTablet } = useHandleResize();

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    if (first !== event.first) {
      getServices(servicePanel, undefined, event.page + 1, event.rows);
    }
  };

  const getCurrentServiceLocations = async (serviceId) => {
    setServiceLocationsStatus("loading");
    const response = await http.get(
      `/Services/GetServiceLocations/${serviceId}`,
    );
    if (response.status === 200) {
      setServiceLocationsStatus("success");
      if (response.data.length === 0) {
        setServiceLocationsStatus("no-data");
      }
      setCurrentServiceLocations(response.data);
    } else {
      setServiceLocationsStatus("error");
    }
  };

  const getCities = async () => {
    const response = await http.get(`/States`);
    setCities(response.data);
  };

  const getServices = async (
    serviceTab,
    searchText,
    page,
    pageCount,
    state,
  ) => {
    setIsLoading(true);

    if (serviceTab !== servicePanel) setServicePanel(serviceTab);

    const isSavedServices = serviceTab === "saved";
    const isAllServices = serviceTab === "all";

    const pathname = isSavedServices
      ? `GetEntitySavedServices/${entityId}?`
      : isAllServices
        ? "FindServices?"
        : `FindServices?myServicesOnly=true&`;

    const response = await http.get(
      `/Services/${pathname}Filters=${
        searchText !== undefined ? searchText : searchService
      }&searchState=${state !== undefined ? "" : selectedState?.name || ""}&Sorts=${
        newSort.servicesCode
      }&Page=${page || 1}&PageSize=${pageCount || REQUESTED_DATA_SIZE}`,
    );

    const services =
      serviceTab === "saved"
        ? response.data.savedServices
        : response.data.services;

    setServices(services);
    setServicesCount(response.data.count);
    setIsLoading(false);
  };

  const searchServices = async (page, pageCount) => {
    setServiceLocationsStatus("idle");
    getServices(servicePanel, undefined, page, pageCount);
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    setSearchService("");
    setSelectedState(null);

    getServices(servicePanel, "", undefined, undefined, "");
  }, [entityId]);

  useEffect(() => {
    if (refetch) {
      getServices(servicePanel, searchService, first / REQUESTED_DATA_SIZE + 1);
      setRefetch(false);
    }
  }, [refetch]);

  const filterTemplate = () => (
    <div className="mb-20 flex flex-col gap-4 overflow-y-auto">
      <div>
        <h3 className="text-md mb-3 font-bold">Sort By</h3>
        <div className="flex flex-col gap-2">
          {statesSort &&
            statesSort.map((s) => {
              return (
                <div key={s.name} className="flex items-center">
                  <RadioButton
                    inputId={s.name}
                    name="name"
                    value={s}
                    onChange={(e) => {
                      if (e.target.value) {
                        newSort = e.value;
                      }
                      setSort(newSort);
                      searchServices();
                    }}
                    checked={sort.name === s.name}
                  />
                  <label htmlFor={s.name} className="ml-2 capitalize">
                    {s.name}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex-1">
        <h3 className="text-md mb-3 font-bold">Location</h3>
        <div className="card justify-content-center flex flex-1">
          <ListBox
            filter
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            options={cities}
            optionLabel="name"
            className="w-full"
            listStyle={{ height: "280px" }}
            virtualScrollerOptions={{ itemSize: 38 }}
          />
        </div>
      </div>
      <Button
        onClick={() => setSelectedState(null)}
        label="Clear"
        outlined
        className="flex justify-center p-6"
      />
    </div>
  );

  const handleDisabledAction = (action) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail:
        IsEntityApproved === "InComplete"
          ? `${action} is disabled until you complete your practice data and be approved`
          : `${action} is disabled until your practice is approved`,
      life: 3000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      {!isSystemAdminImpersonating &&
        HasPin === "False" &&
        (VerifyProvider === "Approved" || VerifyProvider === "Pending") && (
          <PinStatusPanal />
        )}

      {(isMobile || isTablet) && (
        <div>
          <CompletionChecklist view="order" isWelcome={isWelcome} />
        </div>
      )}
      {currentEntity?.providerExist === false && <ProviderNotificationPanel />}
      <div className="flex gap-2">
        {isMobile || (
          <div className="hidden w-fit py-5 sm:flex">
            <Dropdown
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value);
              }}
              options={cities}
              optionLabel="name"
              showClear={selectedState ?? false}
              placeholder="Select State"
              className="w-44"
            />
          </div>
        )}
        <div className="flex-1">
          <ReusableFilter
            searchTitle={
              "Search by Doing business as name, Services, HCPCs and Group practice name."
            }
            filterTemplate={filterTemplate}
            searchValue={searchService}
            keyFilter={blockSpecialCharsPattern}
            sendSearchData={(e) => {
              setSearchService(e.target.value);
              e.target.value === "" && getServices(servicePanel, "");
            }}
            onSearch={[() => setFirst(0), searchServices]}
          />
        </div>
      </div>

      <div className="flex gap-0 lg:gap-8">
        <div className="w-full">
          <div className="mb-5 flex justify-between">
            <div className="flex flex-1 sm:flex-auto">
              <div
                onClick={() => {
                  if (servicePanel === "all") return;
                  setSearchService("");
                  getServices("all", "", 1, REQUESTED_DATA_SIZE, true);
                }}
                className={`flex basis-1/2 cursor-pointer items-center justify-center p-2 pl-4 pr-4 sm:basis-auto ${
                  servicePanel === "all"
                    ? "bg-light-purple font-semibold text-white"
                    : ""
                } `}
              >
                All Services
              </div>
              <div
                onClick={() => {
                  if (servicePanel === "our") return;
                  setSearchService("");
                  getServices("our", "", 1, REQUESTED_DATA_SIZE, true);
                }}
                className={`ml-6 flex basis-1/2 cursor-pointer items-center justify-center p-2 pl-4 pr-4 sm:basis-auto ${
                  servicePanel === "our"
                    ? "bg-light-purple font-semibold text-white"
                    : ""
                } `}
              >
                Our Services
              </div>
              <div
                onClick={() => {
                  if (servicePanel === "saved") return;
                  setSearchService("");
                  getServices("saved", "", 1, REQUESTED_DATA_SIZE, true);
                }}
                className={`${
                  servicePanel === "saved"
                    ? "bg-light-purple font-semibold text-white"
                    : null
                } ml-6 flex basis-1/2 cursor-pointer items-center justify-center p-2 pl-4 pr-4 sm:basis-auto`}
              >
                Saved Services
              </div>
            </div>

            <div className="hidden sm:block">
              <Dropdown
                value={sort}
                options={statesSort}
                onChange={(e) => {
                  if (e.target.value) {
                    newSort = e.target.value;
                  }
                  setSort(newSort);
                  searchServices();
                }}
                optionLabel="name"
                placeholder="Sort By"
                className="min-w-[200px]"
              />
            </div>
          </div>
          <div className="flex w-[100%] flex-col">
            {!isLoading
              ? services?.map((service) => {
                  const serviceData =
                    servicePanel === "saved" ? service.serviceData : service;
                  return (
                    <div key={serviceData.id}>
                      <ServicesCard
                        onCardClick={() =>
                          getCurrentServiceLocations(serviceData.id)
                        }
                        serviceName={serviceData?.name}
                        serviceDescriprtion={serviceData?.description}
                        serviceType={serviceData?.serviceType}
                        internalOnly={serviceData?.internalOnly}
                        entityName={serviceData?.entityName}
                        minCost={serviceData?.minFees}
                        maxCost={serviceData?.maxFees}
                        savedService={serviceData?.isSavedService}
                        serviceId={serviceData?.id}
                        serviceEntityId={serviceData?.entityId}
                        setRefetch={setRefetch}
                        imageSrc={serviceData.entityImage}
                        showOrderButton={true}
                        disableOrderButton={
                          IsEntityApproved === "Pending" ||
                          IsEntityApproved === "Denied" ||
                          IsEntityApproved === "InComplete"
                        }
                        orderButtonClick={() => {
                          if (
                            IsEntityApproved === "Pending" ||
                            IsEntityApproved === "Denied" ||
                            IsEntityApproved === "InComplete"
                          ) {
                            handleDisabledAction("Ordering");
                            return;
                          } else {
                            navigate(
                              `/ordering/RequestService/${serviceData.id}`,
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })
              : Array(8)
                  .fill(0)
                  .map((n, i) => <ServicesCardSkeleton key={i} />)}
            {services && services.length === 0 && (
              <p className="mb-2 mt-1 rounded border-b-2 bg-white p-2 text-gray-400">
                No results found
              </p>
            )}
          </div>
          {servicesCount > 0 && (
            <Paginator
              className="rounded-t-none"
              first={first}
              rows={rows}
              totalRecords={servicesCount}
              onPageChange={onPageChange}
              template={
                isMobile
                  ? { layout: "PrevPageLink CurrentPageReport NextPageLink" }
                  : {
                      layout:
                        "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                    }
              }
            />
          )}
        </div>
        <div className="flex flex-col gap-4">
          {!isMobile && !isTablet && (
            <div className="w-[22.5rem]">
              <CompletionChecklist view="order" isWelcome={isWelcome} />
            </div>
          )}
          <div className="sticky top-0 hidden w-[22.5rem] rounded-md bg-white p-5 font-inter lg:block">
            <p className="mb-4 text-xl font-extrabold text-[#3D3877]">
              Service Locations
            </p>
            <div className="max-h-[16rem] overflow-y-auto">
              {serviceLocationsStatus === "success" ? (
                currentServiceLocations.map((location, i) => (
                  <div key={i} className="flex w-[100%] items-center gap-x-2">
                    <i className="pi pi-map-marker text-[#594FC4]" />
                    <p className="mt-2 p-2 text-justify font-inter text-sm font-normal capitalize text-[#434343]">{`${location.address.lineOne}. ${location.address.city}, ${location.address.state} ${location.address.zipCode}`}</p>
                  </div>
                ))
              ) : serviceLocationsStatus === "loading" ? (
                <ServiceLocationsSkeleton />
              ) : serviceLocationsStatus === "no-data" ? (
                <p className="rounded bg-white p-5 text-gray-400">
                  No results found
                </p>
              ) : (
                <div>
                  <p className="mb-4 text-justify font-semibold text-gray-400">
                    By clicking on a service card, the locations where the
                    service is available will be displayed here.
                  </p>
                  <div className="flex justify-center">
                    <AroundTheWorld />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindServices;
