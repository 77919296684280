import { useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

import useAxios from "../../../../hooks/useAxios";
import useTwilioStatus from "../../../../hooks/useTwilioStatus";

function EnvironmentSettings() {
  const toast = useRef(null);
  const { http } = useAxios();
  const navigate = useNavigate();
  const { twilioStatus } = useTwilioStatus();

  const defaultValues = {
    status: "false",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    setValue("status", twilioStatus.toString());
  }, [twilioStatus]);

  const onSubmit = async (formData) => {
    try {
      const response = await http.post(`/Users/ChangeTwilioStatus`, formData);
      //   navigate(-1, { replace: true });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Twilio Status updated successfully",
        life: 2000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 4000,
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/ourPractices", { replace: true });
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div className="flex flex-col gap-4">
            <h4 className="text-center text-4xl font-bold sm:text-left">
              Environment Settings
            </h4>
            <p className="w-full text-center text-gray-600 sm:w-4/5 sm:text-left">
              <span className="font-semibold">Enable</span> or{" "}
              <span className="font-semibold">Disable</span> SMS communication
              for the environment, setting this option to{" "}
              <span className="font-semibold italic">Disabled</span> will stop
              dispatching any messages via Twilio feed & will hide the options
              and the buttons in the user interface that have anything to do
              with sending an SMS.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <div className="max-w-5xl cursor-pointer rounded-lg border border-slate-100 hover:bg-slate-50">
                    <div className="grid w-full grid-cols-6 gap-2">
                      <div className="col-span-6 flex flex-col gap-1 p-6 md:col-span-5 lg:col-span-4">
                        <label
                          htmlFor="status"
                          className="cursor-pointer text-lg font-semibold text-gray-800"
                        >
                          Dispatch SMS communication for the environment
                        </label>
                        <p className="text-sm text-gray-500"></p>
                      </div>
                      <div className="col-span-6 flex items-center justify-center md:col-span-1 lg:col-span-2">
                        <InputSwitch
                          inputId={field.name}
                          checked={field.value === "true" ? true : false}
                          inputRef={field.ref}
                          onChange={(e) => {
                            field.onChange(e.value === true ? "true" : "false");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              />
              <div className="flex justify-end gap-3">
                <Button
                  severity="danger"
                  type="button"
                  id="cancel"
                  label="Cancel"
                  className="md:px-auto px-4"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleCancel();
                  }}
                ></Button>
                <Button
                  loading={isSubmitting}
                  id="save-preferences"
                  label="Save Preferences"
                ></Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EnvironmentSettings;
