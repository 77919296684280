function SuccessIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 340 259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4233 245.708C24.4233 245.708 -7.01874 223.581 7.43726 195.771C21.8963 167.961 50.8103 125.263 36.3413 103.492C21.8963 81.7272 20.4233 11.9945 103.556 1.30501C169.862 -7.21515 190.015 27.858 200.292 51.795C213.847 83.3708 201.733 141.015 265.129 148.62C328.541 156.221 364.761 187.624 319.341 238.805C299.492 261.172 24.4233 245.708 24.4233 245.708Z"
        fill="#C9D6F2"
      />
      <path
        d="M137.953 228.306C91.162 238.547 53.054 224.326 66.058 200.129C79.055 175.932 71.09 172.881 61.063 165.269C51.037 157.66 28.511 136.466 50.455 113.187C72.426 89.9209 55.737 83.1704 48.878 69.4783C28.842 29.5356 110.101 13.1363 88.113 49.8971C71.607 77.4867 72.477 98.6143 92.989 112.651C113.518 126.692 117.613 134.096 116.706 151.448C115.796 168.8 108.71 205.68 129.628 204.144C150.53 202.609 161.95 223.048 137.952 228.305L137.953 228.306Z"
        fill="#EBF2FF"
      />
      <path
        d="M52.9592 64.0329C50.7862 59.8985 50.2762 39.6252 69.9152 40.6758C81.8672 41.3168 75.6612 57.6651 72.9502 70.2445C70.2472 82.8249 72.0942 102.575 77.0892 110.71C86.3162 125.713 84.3442 132.027 72.9202 144.899C72.9202 144.899 66.9882 154.459 77.9312 164.717C88.8712 174.976 84.6442 203.227 97.3542 207.805C110.064 212.38 117.244 222.141 108.534 223.048C99.8442 223.964 63.7972 227.471 73.2852 203.701C87.1152 169.095 60.1532 158.837 53.4362 151.157C47.2772 144.119 42.0092 128.39 59.8722 108.666C73.6912 93.3983 59.6222 76.7686 52.9592 64.0329Z"
        fill="#CBDBF4"
      />
      <path
        d="M101.578 244.108L100.861 247.28C100.181 247.107 83.8848 243.003 66.3228 231.718C42.9448 216.685 28.7358 196.049 25.2578 171.997L28.2858 171.475C36.3698 227.329 100.926 243.942 101.579 244.108H101.578Z"
        fill="#7585F8"
      />
      <path
        d="M27.4589 175.207C27.4589 175.207 10.4789 166.185 17.5139 145.404C17.5139 145.404 32.5819 157.665 27.4589 175.207Z"
        fill="#7585F8"
      />
      <path
        d="M32.896 192.196C32.896 192.196 9.74698 199.943 0.333984 173.769C0.333984 173.769 12.681 169.228 32.896 192.196Z"
        fill="#7585F8"
      />
      <path
        d="M32.1079 192.3C32.1079 192.3 53.6019 180.292 42.8889 154.709C42.8889 154.709 31.1539 160.799 32.1079 192.3Z"
        fill="#7585F8"
      />
      <path
        d="M44.9427 211.676C44.9427 211.676 22.6267 221.898 10.7227 196.913C10.7227 196.913 22.5657 191.047 44.9427 211.676Z"
        fill="#7585F8"
      />
      <path
        d="M44.1584 211.855C44.1584 211.855 64.3894 197.587 51.2304 173.312C51.2304 173.312 40.1344 180.656 44.1584 211.855Z"
        fill="#7585F8"
      />
      <path
        d="M64.1548 228.6C64.1548 228.6 43.7848 242.689 28.0098 220.27C28.0098 220.27 38.7368 212.34 64.1548 228.6Z"
        fill="#7585F8"
      />
      <path
        d="M63.4004 228.919C63.4004 228.919 81.0434 211.204 64.1514 189.704C64.1514 189.704 54.3954 198.942 63.4004 228.919Z"
        fill="#7585F8"
      />
      <path
        d="M83.9026 239.218C83.9026 239.218 67.6946 258.414 46.8906 241.16C46.8906 241.16 55.3266 230.57 83.9026 239.218Z"
        fill="#7585F8"
      />
      <path
        d="M83.2731 239.733C83.2731 239.733 95.9961 217.809 74.3501 201.76C74.3501 201.76 67.1901 213.339 83.2731 239.733Z"
        fill="#7585F8"
      />
      <path
        d="M222.199 244.18C267.007 256.428 304.608 244.546 293.218 220.463C281.825 196.388 289.689 193.832 299.779 186.956C309.873 180.083 332.723 160.68 312.624 137.053C292.511 113.421 309 107.717 316.319 94.7984C337.687 57.1092 259.816 37.1915 279.29 73.8881C293.912 101.428 292.027 121.836 271.453 134.413C250.89 146.996 246.565 153.964 246.579 170.811C246.596 187.661 251.638 223.724 231.457 221.203C211.287 218.674 199.22 237.896 222.199 244.18Z"
        fill="#7585F8"
      />
      <path
        d="M312.631 89.322C314.946 85.421 316.441 65.8187 297.37 65.8567C285.77 65.8838 290.975 82.0257 292.971 94.3447C294.964 106.66 292.203 125.693 286.961 133.325C277.283 147.393 278.884 153.604 289.309 166.635C289.309 166.635 294.571 176.196 283.462 185.584C272.367 194.974 275.064 222.545 262.533 226.347C249.993 230.142 242.555 239.242 250.94 240.554C259.321 241.869 294.063 247.061 286.033 223.568C274.359 189.369 300.972 180.772 307.862 173.668C314.176 167.158 320.05 152.188 303.734 132.2C291.106 116.729 305.561 101.323 312.63 89.322H312.631Z"
        fill="#F6F5F7"
      />

      <path
        d="M215.674 243.39L214.994 239.231C280.937 228.437 309.462 176.718 309.75 176.182L313.455 178.188C313.164 178.714 283.725 232.257 215.674 243.39Z"
        fill="#EBF2FF"
      />
      <path
        d="M309.477 179.721C309.477 179.721 318.089 157.417 329.32 164.538C334.094 167.558 331.516 178.382 309.477 179.721Z"
        fill="#EBF2FF"
      />
      <path
        d="M304.479 187.014C304.479 187.014 326.429 174.308 328.72 187.15C329.684 192.631 320.064 199.805 304.479 187.014Z"
        fill="#EBF2FF"
      />
      <path
        d="M304.522 189.382C304.522 189.382 317.959 168.852 304.126 166.873C298.231 166.015 290.594 175.023 304.522 189.382Z"
        fill="#EBF2FF"
      />
      <path
        d="M289.9 204.263C289.9 204.263 304.366 184.234 290.699 181.624C284.879 180.506 276.832 189.185 289.9 204.263Z"
        fill="#EBF2FF"
      />
      <path
        d="M277.086 215.604C277.086 215.604 291.447 195.554 277.776 192.972C271.949 191.857 263.93 200.574 277.086 215.604Z"
        fill="#EBF2FF"
      />
      <path
        d="M259.172 226.763C259.172 226.763 276.9 209.052 264.22 204.185C258.809 202.115 249.402 209.51 259.172 226.763Z"
        fill="#EBF2FF"
      />
      <path
        d="M243.658 233.843C243.658 233.843 260.882 215.702 247.994 211.204C242.529 209.306 233.307 216.922 243.658 233.843Z"
        fill="#EBF2FF"
      />
      <path
        d="M222.414 239.947C222.414 239.947 243.877 226.405 233.245 218.623C228.708 215.301 217.849 220.476 222.414 239.947Z"
        fill="#EBF2FF"
      />
      <path
        d="M293.748 200.448C293.748 200.448 315.736 187.735 318.003 200.597C318.971 206.063 309.371 213.221 293.748 200.448Z"
        fill="#EBF2FF"
      />
      <path
        d="M280.633 212.296C280.633 212.296 302.618 199.61 304.884 212.458C305.862 217.922 296.242 225.089 280.633 212.296Z"
        fill="#EBF2FF"
      />
      <path
        d="M266.229 221.938C266.229 221.938 290.203 213.936 289.188 227.248C288.778 232.915 277.873 238.036 266.229 221.938Z"
        fill="#EBF2FF"
      />
      <path
        d="M249.199 230.973C249.199 230.973 273.684 225.269 271.173 238.56C270.101 244.217 258.832 248.3 249.199 230.973Z"
        fill="#EBF2FF"
      />
      <path
        d="M231.15 237.31C231.15 237.31 255.862 234.857 251.351 247.911C249.399 253.455 237.796 256.044 231.15 237.31Z"
        fill="#EBF2FF"
      />
      <path
        d="M318.237 249.5C318.237 254.746 250.758 259 167.51 259C84.2622 259 16.7832 254.746 16.7832 249.5C16.7832 244.254 84.2692 240.004 167.51 240.004C250.751 240.004 318.237 244.253 318.237 249.5Z"
        fill="#ACBFE2"
      />
      <path
        d="M281.52 142.573C281.52 201 234.228 248.362 175.892 248.362C117.556 248.362 70.2637 201 70.2637 142.573C70.2637 84.1459 117.553 36.7778 175.892 36.7778C234.231 36.7778 281.52 84.1429 281.52 142.573Z"
        fill="#665BD9"
      />
      <path
        d="M185.315 181.893C188.381 186.895 185.288 194.391 178.392 198.632C171.506 202.868 163.426 202.247 160.36 197.243L125.094 139.717C122.025 134.711 125.118 127.221 132.017 122.982C138.903 118.742 146.983 119.366 150.049 124.368L185.315 181.894V181.893Z"
        fill="#F6F5F7"
      />
      <path
        d="M184.029 194.449C179.799 201.346 170.782 203.511 163.896 199.275C156.997 195.038 154.844 186.007 159.074 179.1L207.675 99.7691C211.905 92.8654 220.922 90.7031 227.818 94.9396C234.707 99.1761 236.87 108.204 232.64 115.108L184.029 194.448V194.449Z"
        fill="#F6F5F7"
      />
      <path
        d="M98.3684 127.909C99.8334 127.231 104.645 126.855 107.156 130.661C110.543 135.755 111.71 148.162 119.263 151.9C126.84 155.627 131.56 152.445 134.998 150.714C138.432 148.999 139.001 146.685 141.129 145.905C143.234 145.129 144.056 147.688 139.204 151.775C136.862 153.727 126.562 164.773 116.736 162.013C103.841 158.374 89.7034 131.871 98.3694 127.91L98.3684 127.909Z"
        fill="#E28C71"
      />
      <path
        d="M109.587 207.988C107.472 220.924 105.766 248.362 111.434 248.145C117.119 247.925 120.157 245.834 116.824 245.034C113.471 244.231 113.353 243.852 113.386 242.825C113.646 236.352 118.638 224.033 120.522 214.604C122.715 203.665 111.453 196.68 109.586 207.989L109.587 207.988Z"
        fill="#F27851"
      />
      <path
        d="M108.854 245.545C108.854 245.545 113.94 247.389 116.826 245.033C116.826 245.033 122.447 245.505 119.036 247.22C115.646 248.941 108.97 250.605 108.854 245.545Z"
        fill="#6E86CC"
      />
      <path
        d="M88.7795 154.798C88.7795 154.798 118.018 175.36 122.228 185.872C126.004 195.331 117.237 233.427 117.237 233.427C117.237 233.427 111.075 235.887 105.417 232.413L106.158 202.786L81.4805 180.181L88.7795 154.798Z"
        fill="#0E056E"
      />
      <path
        d="M69.9177 209.595C59.1167 218.525 38.4517 239.425 43.1857 243.252C47.9157 247.065 51.8447 247.528 49.7057 244.55C47.5807 241.534 47.7397 241.123 48.4977 240.337C53.2617 235.311 65.9207 228.893 74.0517 222.637C83.5157 215.385 79.3537 201.801 69.9167 209.596L69.9177 209.595Z"
        fill="#F27851"
      />
      <path
        d="M42.922 239.34C42.922 239.34 45.731 244.403 49.706 244.549C49.706 244.549 53.895 248.873 49.957 247.856C46.018 246.839 39.484 243.491 42.922 239.339V239.34Z"
        fill="#6E86CC"
      />
      <path
        d="M84.4487 154.652C84.4487 154.652 99.6217 189.267 95.6187 200.695C92.0287 210.984 58.2077 235.487 58.2077 235.487C58.2077 235.487 51.5077 233.121 49.3867 226.333L70.8027 202.989C70.8027 202.989 62.2417 176.005 66.4447 166C66.5937 165.665 65.8597 168.383 65.8597 168.383L84.4497 154.651L84.4487 154.652Z"
        fill="#0E056E"
      />
      <path
        d="M102.697 169.97C108.301 162.565 111.468 151.087 111.688 142.177C111.996 129.82 101.093 126.632 94.1666 125.977C87.2366 125.309 75.9106 123.963 76.3746 139.363C76.6826 150.11 72.7876 155.722 67.3466 164.809C67.2006 165.049 67.0526 165.245 66.9066 165.473C66.8826 165.469 66.8726 165.442 66.8726 165.442C66.8796 165.459 66.8826 165.469 66.9066 165.473C59.2356 174.907 96.3316 178.368 102.697 169.97Z"
        fill="#FCDCDA"
      />
      <path
        d="M67.3468 164.809C67.2008 165.049 67.0527 165.245 66.9067 165.473C66.8827 165.469 66.8728 165.442 66.8728 165.442C66.8798 165.459 66.8827 165.469 66.9067 165.473C60.9677 172.769 81.8088 176.477 94.4978 173.921C96.4668 170.969 86.9318 170.183 83.4018 167.315C75.2638 160.707 104.283 160.144 99.2847 157.715C93.8877 155.112 85.8547 156.149 89.3907 148.236C92.9137 140.309 89.8817 130.84 86.5347 127.634C83.0457 124.283 77.2057 128.21 76.5697 135.503C76.4107 136.666 76.3158 137.927 76.3738 139.363C76.6818 150.11 72.7878 155.723 67.3468 164.809Z"
        fill="#EFBEBD"
      />
      <path
        d="M95.1509 116.919C95.1509 116.919 96.6799 122.778 92.3629 124.94C87.9369 127.142 101.516 137.977 101.729 134.764C102.007 130.62 100.372 127.827 103.224 123.54L95.1509 116.919Z"
        fill="#E67052"
      />
      <path
        d="M105.598 108.317C108.45 112.211 109.496 127.211 105.293 129.695C103.452 130.759 97.25 128.008 94.313 120.948C93.091 118.057 91.914 112.824 95.423 108.445C97.013 106.452 102.282 103.776 105.598 108.317Z"
        fill="#EE9D7F"
      />
      <path
        d="M93.2489 108.239C89.5439 112.902 91.1649 117.406 91.4289 118.234C92.3939 121.256 94.8469 124.073 95.5949 123.198C96.4849 122.155 93.1149 121.063 94.6609 118.07C96.2139 115.071 94.2379 114.013 95.2669 111.333C96.2679 108.662 98.2409 109.577 104.484 109.775C107.042 109.856 107.685 108.984 107.594 107.921C104.427 103.373 97.1469 103.332 93.2489 108.239Z"
        fill="#062440"
      />
      <path
        d="M108.101 111.564C109.623 107.945 115.048 108.812 113.319 106.254C112.162 104.539 112.148 105.83 99.226 103.19C93.193 101.96 92.005 104.539 90.855 107.945C89.789 111.09 90.121 118.817 94.252 123.369C98.37 127.91 94.123 120.342 95.703 116.92C97.283 113.5 98.326 114.948 100.599 115.513C102.873 116.072 105.648 117.393 108.101 111.564Z"
        fill="#353A66"
      />
      <path
        d="M65.5234 175.06L76.4694 190.779L164.573 129.174L175.892 109.568L153.609 113.458L65.5234 175.06Z"
        fill="#494C7F"
      />
      <path
        d="M159.693 122.174L71.5859 183.77L76.4666 190.773L164.574 129.177L159.693 122.174Z"
        fill="#373C66"
      />
      <path
        d="M175.892 109.568L172.829 114.878L169.875 110.625L175.892 109.568Z"
        fill="#1F1440"
      />
      <path
        d="M83.9959 126.689C85.6369 126.821 88.4969 129.302 88.8649 133.911C89.3829 140.079 85.8939 151.174 90.7569 158.19C95.6399 165.219 101.382 164.717 105.26 164.881C109.162 165.05 110.793 163.284 113.036 163.624C115.29 163.966 114.761 166.646 108.458 167.88C105.443 168.466 90.9059 173.18 83.5569 165.894C73.9269 156.35 74.3939 125.927 83.9969 126.69L83.9959 126.689Z"
        fill="#E28C71"
      />
      {/* </g> */}
      <defs>
        <clipPath id="clip0_5316_26225">
          <rect
            width="339.333"
            height="259"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SuccessIcon;
