import { TabPanel, TabView } from "primereact/tabview";

import { Remits } from "./components/Remits";
import { Claims } from "./components/Claims";

export const Transactions = () => {
  return (
    <div className="rounded-t-lg">
      <TabView>
        <TabPanel header="Claims">
          <Claims />
        </TabPanel>
        <TabPanel header="Remits">
          <Remits />
        </TabPanel>
      </TabView>
    </div>
  );
};
