import { Skeleton } from "primereact/skeleton";

export const ServiceLocationsSkeleton = () => {
  return (
    <div>
      {Array(5)
        .fill(0)
        .map((n, i) => (
          <div key={i} className="flex w-[100%] items-center gap-x-2">
            <i className="pi pi-map-marker" />
            <Skeleton className="mt-2 p-3" />
          </div>
        ))}
    </div>
  );
};
