import useAxios from "./useAxios";
import { downloadFileFromResponse } from "../utils/helpers";

const useDocument = () => {
  const { http } = useAxios();

  const handleDownloadDocument = async (documentData) => {
    try {
      const response = await http.get(
        `/EntityDocuments/DownloadDocumentById/${documentData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    handleDownloadDocument,
  };
};

export default useDocument;
