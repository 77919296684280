import { TabPanel, TabView } from "primereact/tabview";

import OrderingOpen from "./components/OrderingOpen";
import OrderingPending from "./components/OrderingPending";
import OrderingFinalized from "./components/OrderingFinalized";

function OrderingOrders() {
  return (
    <>
      <div className="overflow-y-auto rounded-t-lg">
        <TabView panelContainerClassName="sm:p-auto !p-1">
          <TabPanel header="Open">
            <OrderingOpen />
          </TabPanel>
          <TabPanel
            header="Pending"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <OrderingPending />
          </TabPanel>
          <TabPanel
            header="Finalized"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <OrderingFinalized />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default OrderingOrders;
