import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useAxios from "../../hooks/useAxios";
import { useShowSessionExpiredDialog } from "../../store/store";
import { useEffect, useRef } from "react";

export default function SessionExpiredModal() {
  const navigate = useNavigate();
  const { logOut, unPersonate } = useAxios();
  const { sessionExpiredData, setSessionExpiredData } =
    useShowSessionExpiredDialog();

  const isInvoked = useRef();

  const dialogContent = {
    sessionExpired: {
      icon: "pi pi-history",
      header: "Your Session has Expired",
      content: "Please login again",
      buttonLabel: "Back to login",
      action: logOut,
    },
    accessDenied: {
      icon: "pi pi-lock",
      header: "Access Denied",
      content:
        "You do not have the required privileges to access this resource",
      buttonLabel: "Back to dashboard",
      action: () => navigate("/Dashboard/"),
    },
    endImpersonate: {
      icon: "pi pi-times-circle",
      header: "Impersonation Session Ended",
      content:
        "The current impersonated user account has become inactive. You have been returned to the admin dashboard",
      buttonLabel: "Dismiss",
      action: () => {},
      invoke: unPersonate,
    },
  };

  const currentDialogContent = dialogContent[sessionExpiredData?.action];
  useEffect(() => {
    if (currentDialogContent?.invoke && !isInvoked.current) {
      currentDialogContent?.invoke();
      isInvoked.current = true;
    }
  }, [currentDialogContent]);

  return (
    <Dialog
      draggable={false}
      visible={sessionExpiredData?.visible}
      className="w-11/12 max-w-2xl"
      onHide={() => {
        setSessionExpiredData(null);
        isInvoked.current = false;
      }}
      pt={{
        header: {
          style: {
            display: "none",
          },
        },
        content: {
          style: {
            background: "#e2e8f0",
            padding: "4rem 0",
          },
        },
      }}
      closable={false}
    >
      <div className="flex w-full flex-col items-center justify-center gap-12 text-gray-500">
        <div className="flex flex-col items-center gap-4">
          <i
            className={`${currentDialogContent?.icon} rounded-full text-5xl`}
          ></i>
          <p className="text-center text-4xl text-gray-700">
            {currentDialogContent?.header}
          </p>
          <p className="px-6 text-center text-xl font-medium">
            {currentDialogContent?.content}
          </p>
        </div>
        <Button
          onClick={() => {
            setSessionExpiredData(null);
            isInvoked.current = false;
            currentDialogContent?.action();
          }}
          rounded
          className="px-8"
          label={currentDialogContent?.buttonLabel}
        />
      </div>
    </Dialog>
  );
}
