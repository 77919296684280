import { useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { TaxonomyTree } from "../../../components/formElements";
import useRefreshTokenHook from "../../../hooks/useRefreshTokenHook";
import GroupPracticeIcon from "../../../iconComponents/GroupPracticeIcon";
import { useRefreshTokenStore, useTokenStore } from "../../../store/store";

function GroupPracticeFinishStep() {
  const [noNPI, setNoNPI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { IdentifierId, impersonatedBy } = useTokenData();
  const { http } = useAxios();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  // NPI expand form
  const [NPIList, setNPIList] = useState([{ npiNumber: "" }]);
  const AddNPI = () => {
    setNPIList([...NPIList, { npiNumber: "" }]);
  };
  const { refresh } = useRefreshTokenHook();
  //get tree taxonomy from api
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);
  const [selectedChooseList, setSelectedChooseList] = useState([]);
  const { entityId } = useTokenData();

  const deleteNPI = (index) => {
    setNPIList((oldNpi) => {
      const newNpi = [...oldNpi];
      newNpi.splice(index, 1);

      setValue("npIs", newNpi);
      return newNpi;
    });
  };

  const defaultValues = {
    identifierId: IdentifierId,
    npIs: NPIList,
    assignTaxonomies: selectedChooseList,
    taxID: "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: defaultValues });

  const onSubmit = async (formData) => {
    if (
      (formData?.npIs?.length === 1 && !formData.npIs[0].npiNumber) ||
      noNPI
    ) {
      formData.npIs = null;
    } else {
      for (let i = formData.npIs.length - 1; i >= 0; i--) {
        if (!formData.npIs[i].npiNumber) {
          formData.npIs.splice(i, 1);
        }
      }

      if (formData.npIs.length === 0) {
        formData.npIs = null;
      }
    }

    const modifiedOptionsList = selectedChooseList.map((option) => {
      const { label, ...rest } = option;
      return rest;
    });

    formData.assignTaxonomies = modifiedOptionsList;
    setIsLoading(true);
    const currentRefreshToken = JSON.parse(localStorage.getItem("refreshToken"))
      .state.refreshToken;

    try {
      await http.post("/Identifications/AssignDueDiligence", formData);
      const refreshTokenResponse = await refresh.post(
        `/Authentication/RefreshToken/${entityId}`,
        {
          refreshToken: currentRefreshToken,
          impersonatedBy,
        },
      );
      setToken(refreshTokenResponse.data.token);
      setRefreshToken(refreshTokenResponse.data.refreshToken);
      reset();
      navigate(`/welcome`, {
        replace: true,
        state: { fromSetup: true },
      });
    } catch (error) {
      if (error.message === "Please select at least one taxonomy as primary") {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select at least one taxonomy as primary",
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex items-center justify-center">
        <div className="mt-10 w-full rounded-3xl border bg-light-text p-10 sm:w-full md:w-3/4">
          <div className="grid grid-cols-2 items-center md:grid-cols-6">
            <div className="col-span-1 md:col-span-2">
              <div className="flex h-24 w-24 items-center justify-center overflow-hidden rounded-full border-2 border-dashed border-gray-300 bg-slate-100">
                <GroupPracticeIcon />
              </div>
            </div>
            <div className="col-span-1 md:col-span-4">
              <h4 className="font-inter text-lg font-extrabold capitalize text-dark-purple">
                Set up my Group practice
              </h4>
            </div>
          </div>
          <div className="mt-9">
            <p>
              Please populate TAX-ID, all the NPIs associated with your practice
              below, along with the taxonomies.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-container">
              <div className="mt-3">
                <label
                  htmlFor="taxIDInput"
                  className="font-Poppins text-base capitalize leading-loose text-gray-500"
                >
                  group Tax ID <span className="text-red-500">*</span>
                </label>
                <span className="p-input-icon-right w-full">
                  <InputText
                    id="taxIDInput"
                    placeholder="Enter nine digit tax id number"
                    className="w-full pb-2 pt-2"
                    maxLength={17}
                    {...register("taxID", {
                      required: "Tax ID is required",
                      pattern: {
                        value: /^(?:\d-?){9}$/,
                        message: "Tax ID must be exactly 9 digits",
                      },
                    })}
                  />
                </span>
                {errors.taxID && (
                  <p className="text-red-500">{errors.taxID.message}</p>
                )}
              </div>

              {NPIList.map((NPIs, index) => (
                <div key={index} className="mt-4">
                  <label
                    htmlFor={`npIs[${index}].npiNumber`}
                    className="font-Poppins text-base capitalize leading-loose text-gray-500"
                  >
                    group NPI
                  </label>
                  <div className="grid grid-cols-6">
                    <div className="col-span-5">
                      <span className="p-input-icon-right w-full">
                        {index !== 0 ? (
                          <i
                            className="pi pi-times cursor-pointer"
                            style={{ color: "red" }}
                            key={`${index}BTN`}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteNPI(index);
                            }}
                          />
                        ) : null}
                        <InputText
                          key={`${index}input`}
                          id={`npIs[${index}].npiNumber`}
                          name={`npIs[${index}].npiNumber`}
                          disabled={noNPI}
                          placeholder="0000000000"
                          maxLength={10}
                          keyfilter={/^\d+$/}
                          className="w-full"
                          {...register(`npIs[${index}].npiNumber`, {
                            pattern: {
                              value: /^\d{10}$/,
                              message: "NPI must be exactly 10 digits",
                            },
                          })}
                          value={NPIs.npiNumber}
                          onChange={(e) => {
                            setNPIList((oldNpi) => {
                              const newNpi = [...oldNpi];
                              newNpi[index].npiNumber = e.target.value;
                              return newNpi;
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </span>
                    </div>
                    <div className="col-span-1 mt-2 text-center"></div>
                    {errors.npIs &&
                      errors.npIs[index] &&
                      errors.npIs[index].npiNumber && (
                        <p className="col-span-6 mt-1 text-red-500">
                          {errors.npIs[index].npiNumber.message}
                        </p>
                      )}
                  </div>
                </div>
              ))}
              {!noNPI && (
                <div>
                  <Button
                    link
                    className="pl-1 text-base font-semibold capitalize"
                    onClick={(e) => {
                      e.preventDefault();
                      AddNPI();
                    }}
                    icon="pi pi-plus"
                    label="Add NPI"
                  />
                </div>
              )}
              <div className="card justify-content-center mt-4 flex items-center gap-2 border-b-2 pb-6">
                <Checkbox
                  onChange={(e) => {
                    const newNpi = [{ npiNumber: "" }];
                    setNPIList(newNpi);
                    setValue("npIs", newNpi);
                    setNoNPI(e.checked);
                  }}
                  checked={noNPI}
                  inputId="noNPI"
                  name="noNPI"
                  className="mt-1"
                />
                <label
                  htmlFor="noNPI"
                  className="font-medium text-light-purple"
                >
                  My practice does not have a group National Provider Identifier
                  (NPI). Please note, that this is abnormal, as most group
                  practices have registered for and have been assigned a Group
                  Practice NPI.
                </label>
              </div>
              <label className="mt-7 block font-Poppins text-base capitalize leading-loose text-gray-500">
                group taxonomy <span className="text-red-500">*</span>
              </label>
              <span className="text-sm">
                Click the arrow icon to select a taxonomy.
              </span>
              <TaxonomyTree
                selectedChooseList={selectedChooseList}
                selectedOptionsList={selectedOptionsList}
                setSelectedChooseList={setSelectedChooseList}
                setSelectedOptionsList={setSelectedOptionsList}
              />
              {!selectedChooseList[0] && (
                <p className="mt-1 text-left text-red-500">
                  Select taxonomy & at least one is primary
                </p>
              )}
              <div className="mt-5 text-left">
                {isLoading ? (
                  <div className="text-center">
                    <ProgressSpinner
                      strokeWidth={3}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-row-reverse gap-8">
                      <Button
                        disabled={!selectedChooseList[0] || !NPIList[0]}
                        type="submit"
                        className="mt-9 justify-end rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default GroupPracticeFinishStep;
