import { NavLink } from "react-router-dom";

const SettingsSection = ({ settings, isOrderingMode, title }) => (
  <>
    <h5 className="mb-3 mt-6 text-xl font-semibold">{title}</h5>
    <div className="flex w-full cursor-pointer flex-col gap-1 overflow-hidden rounded-lg bg-white p-1">
      {settings.map((setting) => (
        <NavLink
          key={setting.id}
          to={isOrderingMode ? setting.ordering : setting.rendering}
        >
          <div className="flex w-full items-center justify-between rounded-md bg-white p-5 shadow-sm hover:bg-slate-50">
            <div>
              <div className="mb-2 w-full font-semibold">
                <span className={`${setting.icon} mr-1.5`} />
                {setting.title}
              </div>
              <div className="text-gray-600">{setting.description}</div>
            </div>
            <i className="pi pi-angle-right" />
          </div>
        </NavLink>
      ))}
    </div>
  </>
);

export default SettingsSection;
