export default function ResultIcon() {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        width='40'
        height='40'
        rx='20'
        fill='#009B2B'
        fillOpacity='0.15'
      />
      <path
        d='M18.0009 26.2501C17.9002 26.2467 17.8012 26.2228 17.7101 26.1798C17.619 26.1368 17.5376 26.0757 17.4709 26.0001L12.4709 21.0001C12.3756 20.9383 12.2955 20.8556 12.2368 20.7584C12.1781 20.6611 12.1422 20.5517 12.132 20.4386C12.1217 20.3254 12.1373 20.2114 12.1775 20.1052C12.2177 19.9989 12.2816 19.9032 12.3642 19.8252C12.4469 19.7473 12.5462 19.6891 12.6546 19.6551C12.763 19.6211 12.8777 19.6122 12.9901 19.6291C13.1024 19.6459 13.2095 19.6881 13.3032 19.7523C13.3969 19.8166 13.4747 19.9013 13.5309 20.0001L18.0009 24.4401L28.4709 14.0001C28.6118 13.9087 28.7794 13.8673 28.9467 13.8825C29.114 13.8977 29.2714 13.9686 29.3936 14.0839C29.5158 14.1991 29.5957 14.3521 29.6206 14.5182C29.6455 14.6844 29.6139 14.8541 29.5309 15.0001L18.5309 26.0001C18.4642 26.0757 18.3828 26.1368 18.2917 26.1798C18.2006 26.2228 18.1016 26.2467 18.0009 26.2501Z'
        fill='#009B2B'
        stroke='#009B2B'
      />
    </svg>
  );
}
