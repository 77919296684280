import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import useTokenData from "../../hooks/useTokenData";

function ProviderNotificationPanel() {
  const navigate = useNavigate();
  const { userRoles, entityType, isOwner } = useTokenData();

  const notifTemplate = (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-2">
        <div className="flex items-center justify-center">
          <i className="pi pi-exclamation-triangle text-2xl"></i>
        </div>
        <div className="m-2 flex w-full items-center">
          <span className="w-full font-bold md:w-1/6">
            Missing Verified Practice Ordering Provider
          </span>

          <span className="hidden w-5/6 border-l px-2 md:inline">
            This practice is currently unable to create and submit orders to
            other providers until an ordering provider with order signing
            authority (M.D./D.O./A.P.N/etc) is added to the practice. If you are
            a provider with ordering authority{" "}
            <span
              onClick={() =>
                entityType === "2" && isOwner === "True"
                  ? navigate("/ordering/settings/individualSetting")
                  : navigate("/ordering/settings/verifyProvider")
              }
              className="ml-2 cursor-pointer font-bold underline"
            >
              Click Here
            </span>{" "}
            to verify your account as an authorized ordering provider,
            {userRoles === "Admin" ? (
              <>
                {" "}
                otherwise please invite an ordering provider under{" "}
                <span
                  onClick={() => navigate("/ordering/staff")}
                  className="ml-2 cursor-pointer font-bold underline"
                >
                  Staff Section
                </span>{" "}
                that can authorize orders for your practice. Your practice may
                have more than one ordering provider, but must have at least one
                to start placing orders on the MedX Marketplace.
              </>
            ) : null}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="card mb-4 w-full">
      <Message
        style={{
          borderLeft: `6px #ddad67 solid`,
        }}
        className="flex w-full justify-start p-4 shadow-sm"
        severity="warn"
        content={notifTemplate}
      />
    </div>
  );
}

export default ProviderNotificationPanel;
