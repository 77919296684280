import { useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../hooks/useAxios";
import { trimValidation } from "../../../../utils/helpers";
import useTwilioStatus from "../../../../hooks/useTwilioStatus";
import { EmbeddedStripeDialog } from "../../../../components/dialogs";

function PaymentOptions({
  patientInfo,
  setActiveIndex,
  orderId,
  setIsLoading,
  isLoading,
  paymentLink: link,
  setPaymentLink: setLink,
  setIsPaymentComplete,
}) {
  const toast = useRef(null);
  const [selectedPayOption, setSelectedPayOption] = useState(null);
  const { http } = useAxios();
  const [isPayLaterDialog, setIsPayLaterDialog] = useState(false);
  const [isSendToPatient, setIsSendToPatient] = useState(false);
  const [dialogAppear, setDialogAppear] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const { twilioStatus } = useTwilioStatus();

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onSubmit", defaultValues });

  const handleOptionChange = (e) => {
    setSelectedPayOption(e.value);
  };

  const handleCompletePayment = () => {
    setIsPaymentComplete(true);
    setDialogAppear(false);
    setActiveIndex((prev) => prev + 1);
  };

  const getPaymentLink = async (orderId) => {
    try {
      const response = await http.get(`/Orders/GetOrderPaymentLink/${orderId}`);
      setPaymentLink(response.data.paymentLink);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Something went wrong.",
        life: 3000,
      });
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  let chooseValue = 0;
  if (selectedPayOption === "email") {
    chooseValue = 1;
  } else if (selectedPayOption === "sms") {
    chooseValue = 2;
  } else if (selectedPayOption === "Both") {
    chooseValue = 3;
  }

  const sendEmail = async (formData) => {
    try {
      setIsLoading(true);
      await http.post(`/Payments/SendPaymentLink/${orderId}`, {
        emailData: {
          email: formData.email,
          userName: formData.name,
          isPatient: true,
        },
        messageOptions: chooseValue,
      });
      setPopupMessage(
        "The payment link has been sent via email. The recipient can complete the payment using the link provided. Thank you for using our service!",
      );
      setShowPopup(true);
      setIsPayLaterDialog(false);
      setIsSendToPatient(false);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 3000,
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const sendSMS = async (formData) => {
    try {
      setIsLoading(true);
      await http.post(
        `/Payments/SendPaymentLink/${orderId}`,

        {
          smsData: {
            toPhoneNumber: formData.phone,
          },
          messageOptions: chooseValue,
        },
      );
      setPopupMessage(
        "The payment link has been sent via SMS. The recipient can complete the payment using the link provided. Thank you for using our service!",
      );
      setShowPopup(true);
      setIsPayLaterDialog(false);
      setIsSendToPatient(false);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 3000,
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const sendBoth = async (formData) => {
    try {
      setIsLoading(true);
      await http.post(
        `/Payments/SendPaymentLink/${orderId}`,

        {
          emailData: {
            email: formData.email,
            userName: formData.name,
          },
          smsData: {
            toPhoneNumber: formData.phone,
          },
          messageOptions: chooseValue,
        },
      );
      setPopupMessage(
        "The payment link has been sent via both email and SMS. The recipient can complete the payment using either link provided. Thank you for using our service!",
      );
      setShowPopup(true);
      setIsPayLaterDialog(false);
      setIsSendToPatient(false);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 3000,
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const sendLinkOptions = twilioStatus
    ? [
        {
          label: isSendToPatient ? "Patient Email" : "Email",
          value: "email",
        },
        { label: isSendToPatient ? "Patient SMS" : "SMS", value: "sms" },
        {
          label: isSendToPatient ? "Patient Email and SMS" : "Email and SMS",
          value: "Both",
        },
      ]
    : [
        {
          label: isSendToPatient ? "Patient Email" : "Email",
          value: "email",
        },
      ];

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="mt-5">
        <div className="mb-5 font-inter">
          <h4 className="text-title font-extrabold capitalize text-dark-purple">
            payment options
          </h4>
          <p className="mt-1 w-[80%]">
            After successfully creating your order, you have the option to
            proceed with payment now using the provided payment link or choose
            to pay later within the 'Orders' open tab.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 rounded-lg p-4 md:grid-cols-2">
          <div
            id="pay-now-patient-card"
            onClick={async () => {
              await getPaymentLink(orderId);
              setDialogAppear(true);
            }}
            className="col-span-1 flex cursor-pointer rounded-lg border border-slate-500 bg-slate-50 px-8 py-6 text-gray-700 hover:bg-slate-100 hover:text-dark-purple hover:shadow-md hover:shadow-slate-100"
          >
            <i className="pi pi-credit-card text-4xl"></i>
            <div className="px-4">
              <h4 className="text-lg font-semibold">
                Pay Now With Patient's Card
              </h4>
              <p className="text-sm italic text-gray-500">
                (Debit, Credit, Flex, or HSA)
              </p>
            </div>
          </div>
          <div
            id="pay-now-your-card"
            onClick={async () => {
              await getPaymentLink(orderId);
              setDialogAppear(true);
            }}
            className="col-span-1 flex cursor-pointer rounded-lg border border-slate-500 bg-slate-50 px-8 py-6 text-gray-700 hover:bg-slate-100 hover:text-dark-purple hover:shadow-md hover:shadow-slate-100"
          >
            <i className="pi pi-wallet text-4xl"></i>
            <div className="px-4">
              <h4 className="text-lg font-semibold">
                Pay Now With My Debit or Credit Card
              </h4>
              <p className="text-sm italic text-gray-500">
                (Patient Paid You With Cash or Check)
              </p>
            </div>
          </div>
          <div
            id="send-link-patient"
            onClick={() => {
              setIsPayLaterDialog(true);
              setIsSendToPatient(true);
              reset({
                name: `${patientInfo.Fname} ${patientInfo.Lname}`,
                phone: patientInfo.phone,
                email: patientInfo.email,
              });
            }}
            className="col-span-1 flex cursor-pointer rounded-lg border border-slate-500 bg-slate-50 px-8 py-6 text-gray-700 hover:bg-slate-100 hover:text-dark-purple hover:shadow-md hover:shadow-slate-100"
          >
            <i className="pi pi-envelope text-4xl"></i>
            <div className="px-4">
              <h4 className="text-lg font-semibold">
                Send Payment Link to Patient
              </h4>
            </div>
          </div>
          <div
            id="send-link-payor"
            onClick={() => {
              setIsPayLaterDialog(true);
              setIsSendToPatient(false);
              reset({ name: "", phone: "", email: "" });
            }}
            className="col-span-1 flex cursor-pointer rounded-lg border border-slate-500 bg-slate-50 px-8 py-6 text-gray-700 hover:bg-slate-100 hover:text-dark-purple hover:shadow-md hover:shadow-slate-100"
          >
            <i className="pi pi-envelope text-4xl"></i>
            <div className="px-4">
              <h4 className="text-lg font-semibold">
                Send Payment Link to Another Payor or Guarantor
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        visible={dialogAppear}
        className="max-h-[75%] min-h-[50%] w-[85%] sm:w-[70%]"
        onHide={() => {
          setDialogAppear(false);
        }}
        header="Complete Payment"
      >
        <EmbeddedStripeDialog
          paymentLinkId={
            paymentLink && new URL(paymentLink).searchParams.get("Id")
          }
          handleComplete={handleCompletePayment}
        />
      </Dialog>
      <Dialog
        visible={showPopup}
        draggable={false}
        closable={false}
        header="Payment Link Sent Successfully"
        modal
        className="w-[30vw] min-w-max"
      >
        <div className="flex flex-col gap-5">
          <span className="max-w-xs text-center text-sm text-gray-600 sm:max-w-sm">
            {popupMessage}
          </span>
          <div className="flex justify-end">
            <Button
              onClick={() => {
                setShowPopup(false);
                setActiveIndex((prev) => prev + 1);
              }}
              label="Proceed to Summary"
            ></Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={
          isSendToPatient
            ? "Send payment link to the patient via"
            : "Send payment link to another payor or guarantor"
        }
        headerClassName="capitalize"
        draggable={false}
        visible={isPayLaterDialog}
        onHide={() => {
          setIsPayLaterDialog(false);
          setIsSendToPatient(false);
        }}
        className="w-2/5"
      >
        <Dropdown
          value={selectedPayOption}
          options={sendLinkOptions}
          onChange={handleOptionChange}
          placeholder="Select an option"
          optionLabel="label"
          style={{ width: "100%", marginBottom: "1rem" }}
        />
        {selectedPayOption === "email" && (
          <form onSubmit={handleSubmit(sendEmail)}>
            <div>
              <label>
                Name <span className="text-red-600">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-user" />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      placeholder="Name"
                      className="w-full pb-2 pt-2"
                    />
                  )}
                  rules={{
                    required: "Name is required",
                    validate: (value) => trimValidation(value, "Name"),
                  }}
                />
              </span>
              {errors.name && (
                <p className="text-red-500">{errors.name.message}</p>
              )}
            </div>

            <div className="mt-4">
              <label>
                Email <span className="text-red-600">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-envelope" />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      placeholder="Email"
                      className="w-full pb-2 pt-2"
                    />
                  )}
                  rules={{
                    required: "Email address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  }}
                />
              </span>
              {errors.email && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
            </div>
            <Button
              disabled={isLoading}
              icon={isLoading ? "pi pi-spin pi-spinner" : undefined}
              rounded
              className="mt-4 w-full"
              label={isLoading ? "Sending..." : "Send Email"}
            />
          </form>
        )}
        {selectedPayOption === "sms" && (
          <form onSubmit={handleSubmit(sendSMS)}>
            <div>
              <label>
                Phone Number <span className="text-red-600">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-phone" />
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone is required",
                    pattern: {
                      value: /^\(\d{3}\) \d{3}-\d{4}$/,
                      message: "Invalid phone format",
                    },
                  }}
                  render={({ field }) => (
                    <InputMask
                      id={field.name}
                      value={field.value}
                      autoClear={false}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      mask="(999) 999-9999"
                      placeholder="(999) 999-9999"
                    />
                  )}
                />
              </span>
              {errors.phone && (
                <p className="text-red-500">{errors.phone.message}</p>
              )}
            </div>
            <Button
              disabled={isLoading}
              icon={isLoading ? "pi pi-spin pi-spinner" : undefined}
              rounded
              className="mt-4 w-full"
              label={isLoading ? "Sending..." : "Send SMS Message"}
            />
          </form>
        )}
        {selectedPayOption === "Both" && (
          <form onSubmit={handleSubmit(sendBoth)}>
            <div>
              <label>
                Name <span className="text-red-600">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-user" />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      placeholder="Name"
                      className="w-full pb-2 pt-2"
                    />
                  )}
                  rules={{
                    required: "Name is required",
                    validate: (value) => trimValidation(value, "Name"),
                  }}
                />
              </span>
              {errors.name && (
                <p className="text-red-500">{errors.name.message}</p>
              )}
            </div>
            <div className="mt-4">
              <label>
                Email <span className="text-red-600">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-envelope" />
                <Controller
                  name="email"
                  control={control}
                  defaultValue={patientInfo.email}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      placeholder="Email"
                      className="w-full pb-2 pt-2"
                    />
                  )}
                  rules={{
                    required: "Email address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  }}
                />
              </span>
              {errors.email && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
            </div>

            <div className="mt-4">
              <label>
                Phone Number <span className="text-red-600">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-phone" />
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone is required",
                    pattern: {
                      value: /^\(\d{3}\) \d{3}-\d{4}$/,
                      message: "Invalid phone format",
                    },
                  }}
                  render={({ field }) => (
                    <InputMask
                      id={field.name}
                      value={field.value}
                      autoClear={false}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      mask="(999) 999-9999"
                      placeholder="(999) 999-9999"
                    />
                  )}
                />
              </span>
              {errors.phone && (
                <p className="text-red-500">{errors.phone.message}</p>
              )}
            </div>
            <Button
              disabled={isLoading}
              icon={isLoading ? "pi pi-spin pi-spinner" : undefined}
              rounded
              className="mt-4 w-full"
              label={isLoading ? "Sending..." : "Send SMS And Email"}
            />
          </form>
        )}
      </Dialog>
    </div>
  );
}

export default PaymentOptions;
