import { useEffect, useState } from "react";

import useAxios from "./useAxios";

const useTwilioStatus = () => {
  const { http } = useAxios();
  const [twilioStatus, setTwilioStatus] = useState(false);

  const checkTwilioStatus = async () => {
    try {
      const response = await http.get(`/Users/CheckTwilioStatus/`);
      setTwilioStatus(response.data.status === "true" ? true : false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkTwilioStatus();
  }, []); // eslint-disable-line

  return { twilioStatus };
};

export default useTwilioStatus;
