import useTokenData from "../hooks/useTokenData";
import { Navigate, Outlet } from "react-router-dom";

export const PersonateAuthGaurd = () => {
  const { SystemAdministrator } = useTokenData();
  if (SystemAdministrator === "SystemAdministrator") {
    return <Navigate to={"/admin/ourPractices"} replace />;
  }
  return <Outlet />;
};
