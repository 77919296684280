import { useEffect, useState } from "react";

const CountdownTimer = ({ durationInMilliseconds, onTimeEnd }) => {
  const [remainingTime, setRemainingTime] = useState(durationInMilliseconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 1000) {
        setRemainingTime((prevTime) => prevTime - 1000);
      } else {
        onTimeEnd();
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const formatTime = (milliseconds) => {
    const date = new Date(milliseconds);
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  return (
    <p>
      Retry in <strong>{formatTime(remainingTime)}</strong>
    </p>
  );
};

export default CountdownTimer;
