import { Skeleton } from "primereact/skeleton";

export const FavoritePracticesCardSkeleton = () => {
  return (
    <div className="mb-2 grid grid-rows-2 bg-white p-5">
      <div className="mb-2 flex w-[100%] flex-row items-center justify-center">
        <div className="w-[80px]">
          <Skeleton className="m-auto" size="60px" shape="circle" />
        </div>
        <div className="flex-1">
          <Skeleton className="mb-2 p-3" width="30%" />
          <Skeleton className="mb-2 p-3" width="50%" />
        </div>
      </div>
      <div>
        <Skeleton className="mb-2 p-3" width="80%" />
        <Skeleton className="mb-2 p-3" width="50%" />
      </div>
    </div>
  );
};
