import { Skeleton } from "primereact/skeleton";

export const NoteSkeleton = () => {
  return (
    <div className="mt-2 grid grid-cols-[1.25fr_3fr] gap-2 rounded-md border border-gray-400 p-5">
      <Skeleton className="p-3" />
      <Skeleton className="p-3" />
    </div>
  );
};
