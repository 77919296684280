import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";

import { SelectableCard } from "../../components/cards";

export default function WelcomeProvider() {
  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      view: "verify",
    },
  });

  const onSubmit = async (formData) => {
    const view = formData.view;
    if (view === "verify") {
      navigate("/ordering/settings/verifyProvider");
    } else {
      navigate("/Dashboard");
    }
  };
  return (
    <div className="flex h-full items-center justify-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex max-w-xl flex-col gap-5 rounded-lg border border-dark-purple bg-white p-10 text-center">
          <h1 className="font-philosopher text-2xl font-extrabold text-dark-purple">
            Welcome!.
          </h1>
          <p className="max-w-md self-center text-gray-600">
            Choose your preferred option for your situation. <br /> Please
            select one of the following options
          </p>
          <div className="grid grid-cols-2 gap-5">
            <Controller
              name="view"
              control={control}
              render={({ field }) => (
                <>
                  <SelectableCard
                    field={field}
                    label="verify yourself as a provider"
                    description={`Select this option if you're ready to verify yourself 
                      as a provider now and complete the steps of the provider
                      verification.`}
                    selectedValue={watch("view")}
                    value="verify"
                  />
                  <SelectableCard
                    field={field}
                    label="go to the dashboard"
                    description={`you'll have the option to verify yourself as a provider at
                      a later time.`}
                    selectedValue={watch("view")}
                    value="login"
                  />
                </>
              )}
            />
          </div>
          <div className="flex justify-end">
            <Button label="Save and Proceed" loading={isSubmitting} />
          </div>
        </div>
      </form>
    </div>
  );
}
