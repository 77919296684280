import { RadioButton } from "primereact/radiobutton";

const SelectableCard = ({
  field,
  label,
  value,
  onSelect,
  disabled,
  description,
  selectedValue,
}) => {
  return (
    <div
      onClick={() => {
        field.onChange(value);
        onSelect && onSelect(value);
      }}
      className={`${disabled ? "p-disabled" : ""} col-span-1 cursor-pointer rounded-lg border p-4 hover:bg-slate-100 hover:shadow-md ${selectedValue === value ? "border-dark-purple bg-slate-50 shadow-md" : "border-slate-300"}`}
    >
      <div className="flex gap-3">
        <RadioButton
          {...field}
          inputRef={field.ref}
          value={value}
          checked={field.value === value}
          onChange={(e) => {
            field.onChange(e.value);
          }}
        />
        <span className="font-semibold text-gray-800">{label}</span>
      </div>
      <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
        {description}
      </p>
    </div>
  );
};

export default SelectableCard;
