function EmailVerifyIcon({ width = "110", height = "105" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.5664 119.632L0.38421 97.343L19.2409 26.9688L67.9009 11.7052L102.423 49.2574L83.5664 119.632Z"
        fill="#594FC4"
      />
      <path
        opacity="0.2"
        d="M83.5664 119.632L0.38421 97.343L19.2409 26.9688L67.9009 11.7052L102.423 49.2574L83.5664 119.632Z"
        fill="black"
      />
      <path
        d="M99.9585 39.6807L26.1582 19.906L5.80705 95.8575L79.6073 115.632L99.9585 39.6807Z"
        fill="#EBEBEB"
      />
      <path
        d="M30.0123 34.3102L90.1348 50.42L90.7859 47.9899L30.6634 31.8801L30.0123 34.3102Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.3677 40.4448L88.4902 56.5546L89.1414 54.1245L29.0189 38.0147L28.3677 40.4448Z"
        fill="#DBDBDB"
      />
      <path
        d="M26.7232 46.5882L86.8457 62.6979L87.4969 60.2678L27.3744 44.158L26.7232 46.5882Z"
        fill="#DBDBDB"
      />
      <path
        d="M25.0767 52.7282L85.1992 68.838L85.8504 66.4079L25.7279 50.2981L25.0767 52.7282Z"
        fill="#DBDBDB"
      />
      <path
        d="M23.4322 58.8683L83.5547 74.9781L84.2058 72.548L24.0834 56.4382L23.4322 58.8683Z"
        fill="#DBDBDB"
      />
      <path
        d="M21.7857 65.0117L81.9082 81.1214L82.5594 78.6913L22.4369 62.5815L21.7857 65.0117Z"
        fill="#DBDBDB"
      />
      <path
        d="M20.1412 71.1462L80.2637 87.256L80.9148 84.8259L20.7923 68.7161L20.1412 71.1462Z"
        fill="#DBDBDB"
      />
      <path
        d="M18.4967 77.2896L78.6191 93.3994L79.2703 90.9692L19.1478 74.8594L18.4967 77.2896Z"
        fill="#DBDBDB"
      />
      <path
        d="M16.8502 83.4308L76.9727 99.5405L77.6238 97.1104L17.5013 81.0006L16.8502 83.4308Z"
        fill="#DBDBDB"
      />
      <path
        d="M15.2056 89.5719L75.3281 105.682L75.9793 103.252L15.8568 87.1418L15.2056 89.5719Z"
        fill="#DBDBDB"
      />
      <path
        d="M97.7037 66.8725L83.5669 119.632L0.384739 97.3429L14.5215 44.5839C17.551 47.5662 20.4941 50.632 23.478 53.6519C26.4619 56.6717 29.3912 59.7623 32.3139 62.8512C35.5557 66.2628 38.802 69.7111 42.0526 73.1962L42.1983 73.157C42.1585 73.1463 42.1159 73.1989 42.0756 73.2166L49.3377 81.0122C52.7201 79.9686 56.1278 78.9104 59.5181 77.8903C59.4849 77.8814 59.4744 77.8145 59.4416 77.7773C59.472 77.8153 59.5094 77.847 59.5518 77.8709C64.0838 76.4853 68.6215 75.1227 73.1649 73.7829C77.2391 72.5831 81.3174 71.4034 85.3998 70.2438C89.5271 69.1293 93.5889 67.9404 97.7037 66.8725Z"
        fill="#594FC4"
      />
      <g opacity="0.2">
        <path
          d="M97.7037 66.8725C93.7106 68.336 89.6791 69.7038 85.6773 71.0938C81.6755 72.4838 77.6264 73.8113 73.5839 75.1406C69.3687 76.5303 65.1527 77.8962 60.9361 79.2381L60.552 79.37L49.3324 82.9179L48.7778 83.0753L48.3895 82.6653L40.4621 73.9798L40.1804 73.6766C37.1919 70.3993 34.2205 67.1029 31.266 63.7873C28.4389 60.6339 25.6255 57.4558 22.8259 54.2528C20.0624 51.0216 17.2478 47.8479 14.5215 44.5839C17.551 47.5662 20.4941 50.632 23.478 53.6519C26.4619 56.6717 29.3912 59.7623 32.3139 62.8512C35.5557 66.2628 38.802 69.7111 42.0526 73.1962L49.3377 81.0122C52.7201 79.9686 56.1278 78.9104 59.5181 77.8903C64.0501 76.5048 68.5878 75.1421 73.1312 73.8024C77.2054 72.6026 81.2837 71.4229 85.3661 70.2632C89.5271 69.1293 93.5889 67.9404 97.7037 66.8725Z"
          fill="black"
        />
        <path
          d="M42.1923 73.1543C42.1525 73.1436 42.1099 73.1962 42.0696 73.2139L40.4428 73.9736C37.8864 75.1744 35.2966 76.288 32.6877 77.3396C29.4981 78.6199 26.262 79.8877 23.0291 81.0639C19.7963 82.2401 16.5378 83.3524 13.2404 84.3974C9.94303 85.4425 6.64306 86.4441 3.27599 87.2712C6.39151 85.7362 9.56749 84.374 12.7558 83.072C15.9441 81.7701 19.1948 80.5275 22.4126 79.3544C25.6304 78.1813 28.9208 77.0562 32.2115 76.0094C34.8334 75.1748 37.4898 74.3707 40.1611 73.6703C40.7825 73.5024 41.4021 73.3411 42.0333 73.1899L42.1923 73.1543Z"
          fill="black"
        />
        <path
          d="M86.08 109.458C83.5776 107.058 81.2006 104.536 78.8875 101.987C76.5743 99.4389 74.3072 96.8246 72.0637 94.2024C69.8201 91.5802 67.6678 88.8828 65.5612 86.1478C63.841 83.9363 62.1549 81.6769 60.553 79.3689C60.2011 78.8761 59.8625 78.3869 59.5257 77.891C59.4925 77.8821 59.4819 77.8152 59.4492 77.778C59.4796 77.816 59.517 77.8478 59.5594 77.8716L60.937 79.237C62.8985 81.1859 64.7854 83.2001 66.652 85.2374C68.9785 87.7894 71.2256 90.3983 73.4523 93.0302C75.6791 95.6622 77.8481 98.3499 79.9565 101.078C82.0649 103.807 84.1494 106.571 86.08 109.458Z"
          fill="black"
        />
      </g>
      <path
        d="M91.0202 72.7534C93.0726 67.6374 90.5892 61.8262 85.4732 59.7738C80.3573 57.7213 74.5461 60.2048 72.4936 65.3207C70.4412 70.4367 72.9247 76.2479 78.0406 78.3003C83.1566 80.3528 88.9677 77.8693 91.0202 72.7534Z"
        fill="#0077AA"
      />
      <path
        d="M84.4766 64.0703L81.7012 74.4282C81.666 74.5561 81.6059 74.6759 81.5244 74.7806C81.4429 74.8853 81.3416 74.973 81.2262 75.0386C81.1108 75.1041 80.9837 75.1463 80.852 75.1628C80.7203 75.1792 80.5867 75.1695 80.4588 75.1343C80.3308 75.1019 80.2106 75.0441 80.1055 74.9643C80.0003 74.8845 79.9123 74.7844 79.8467 74.6698C79.7811 74.5553 79.7392 74.4287 79.7236 74.2976C79.708 74.1666 79.7189 74.0337 79.7558 73.9069L82.0686 65.2753L81.2563 65.4917C81.0843 65.544 80.901 65.5467 80.7275 65.4995C80.5587 65.4525 80.4052 65.3617 80.2826 65.2364C80.16 65.1111 80.0726 64.9557 80.0292 64.7858C79.9886 64.66 79.9739 64.5272 79.9861 64.3956C79.9983 64.264 80.0371 64.1362 80.1002 64.02C80.1633 63.9038 80.2493 63.8016 80.353 63.7197C80.4568 63.6377 80.5761 63.5777 80.7038 63.5433L83.2117 62.8418C83.2117 62.8418 83.2581 62.8542 83.2781 62.8596C83.298 62.8649 83.3401 62.8406 83.3786 62.8296C83.4172 62.8186 83.4517 62.8491 83.4849 62.858C83.5105 62.8583 83.5356 62.865 83.5579 62.8776C83.5997 62.8786 83.6407 62.8895 83.6774 62.9096L83.87 62.9612C83.891 62.9768 83.9171 62.9838 83.943 62.9808C83.9806 62.9784 84.0179 62.9884 84.0492 63.0093C84.0492 63.0093 84.1024 63.0235 84.1156 63.0271C84.1289 63.0306 84.1744 63.0997 84.2076 63.1086C84.225 63.1331 84.2453 63.1553 84.2682 63.1747L84.3204 63.2456C84.3602 63.2563 84.3593 63.313 84.3738 63.3382C84.3906 63.3648 84.402 63.3945 84.4074 63.4255L84.4626 63.5114C84.4626 63.5114 84.452 63.5513 84.4484 63.5646C84.4606 63.5971 84.4635 63.6324 84.4567 63.6664C84.4478 63.6996 84.4389 63.7328 84.4734 63.7634C84.5079 63.794 84.4556 63.8298 84.4503 63.8497C84.4229 63.8857 84.4107 63.931 84.4165 63.9759L84.4766 64.0703Z"
        fill="white"
      />
    </svg>
  );
}

export default EmailVerifyIcon;
