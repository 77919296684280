import { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import { useDefaultEntity } from "../../../../store/store";

export const OrderSettings = () => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const { defaultEntity, setDefaultEntity } = useDefaultEntity();
  const toast = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: { maxOrdersQuantity: 0 },
  });
  const navigate = useNavigate();
  const location = useLocation();

  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Order Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/settings/OrderSettings`}>
          Order Settings
        </Link>
      ),
    },
  ];

  const handleCancel = () => {
    navigate(-1, { replace: true });
  };

  useEffect(() => {
    defaultEntity &&
      setValue("maxOrdersQuantity", defaultEntity?.maxOrdersQuantity);
  }, []);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const response = await http.put(
        `/Entities/SetOrdersSettings/${entityId}`,
        formData,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Saved Successfully",
        life: 4000,
      });
      const res = await http.get(`/Entities/${entityId}`);
      setDefaultEntity(res.data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 4000,
      });
    }
    setIsLoading(false);
    navigate(-1);
  };

  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb
        model={breadCrumbsItems}
        className="border-0 bg-transparent pb-7 font-semibold"
        pt={{
          separator: { className: "first:hidden" },
          menuitem: { className: "last:text-purple-700" },
        }}
      />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div className="mb-5">
            <h4 className="mb-4 text-center text-4xl font-bold sm:text-left">
              Orders Settings
            </h4>
            <p className="mb-3 text-center text-gray-700 sm:text-left">
              Update your orders settings
            </p>
          </div>
          <Tooltip target=".tool-tip" className="max-w-[324px]" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-container mt-3 w-full">
              <div className="border-b-2 pb-11">
                <label
                  htmlFor="ssn"
                  className="font-Poppins text-base capitalize leading-loose text-gray-500"
                >
                  Max Number Of Orders <span className="text-red-500">*</span>
                </label>
                <span className="p-input-icon-right flex w-full items-center gap-2">
                  <InputText
                    keyfilter="int"
                    id="maxOrders"
                    className="w-60 pb-2 pt-2"
                    {...register("maxOrdersQuantity", {
                      required: "Max Orders Number Required",
                      min: {
                        value: 0,
                        message: "Number of orders can't be less than 0.",
                      },
                    })}
                  />
                  <div>
                    <i
                      className="tool-tip pi pi-exclamation-circle cursor-pointer text-2xl text-black"
                      data-pr-tooltip="Max number of orders your facility can take, if your orders exceed this number, your services will be hidden."
                      data-pr-position="right"
                    ></i>
                  </div>
                </span>
                {errors.maxOrdersQuantity && (
                  <p className="text-red-500">
                    {errors.maxOrdersQuantity.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-center gap-5 sm:justify-end">
              <div className="">
                <Button
                  onClick={handleCancel}
                  type="button"
                  className="mt-9 justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple"
                >
                  Cancel
                </Button>
              </div>
              <div className="flex items-end justify-end">
                {isLoading ? (
                  <div className="text-center">
                    <ProgressSpinner
                      strokeWidth={3}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="mt-9 justify-center rounded-full bg-light-purple py-2.5 font-inter font-semibold capitalize text-light-text"
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
