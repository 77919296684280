import { TabPanel, TabView } from "primereact/tabview";

import RemitsPending from "./components/RemitsPending";
import { VerificationWorklist } from "./components/VerificationWorklist";

export const WorkLists = () => {
  return (
    <div className="rounded-t-lg">
      <TabView>
        <TabPanel header="Verification Worklist">
          <VerificationWorklist />
        </TabPanel>
        <TabPanel header="R.P. Remits Worklist">
          <RemitsPending />
        </TabPanel>
      </TabView>
    </div>
  );
};
