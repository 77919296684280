function NurseIcon() {
  return (
    <svg
      width="118"
      height="111"
      viewBox="0 0 118 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.8891 54.9012L84.5056 59.0231C78.6236 60.5992 75.1331 66.6451 76.7092 72.5269L85.2858 104.535L99.689 100.676L100.195 102.563L120.588 97.098C121.63 96.8188 122.249 95.7479 121.969 94.7059L113.393 62.6976C111.817 56.8158 105.771 53.3251 99.8891 54.9012Z"
        fill="#3D3877"
      />
      <path
        d="M101.113 73.321C98.9106 65.2505 91.7905 59.6785 83.6364 59.2978C78.286 61.2111 75.2118 66.9415 76.7085 72.527L85.7906 106.422L108.363 100.374L101.113 73.321Z"
        fill="#594FC4"
      />
      <path
        d="M92.1966 56.9622C98.7321 55.211 102.611 48.4934 100.859 41.9579C99.1082 35.4224 92.3905 31.544 85.855 33.2952C79.3195 35.0463 75.4411 41.764 77.1923 48.2995C78.9434 54.835 85.6611 58.7134 92.1966 56.9622Z"
        fill="#FFDFCF"
      />
      <path
        d="M91.7915 33.1934C93.1213 34.6148 94.132 36.3731 94.671 38.3848C96.4222 44.9202 92.5437 51.6379 86.0082 53.3891C83.9968 53.9281 81.9685 53.932 80.0726 53.4911C83.0635 56.6881 87.6735 58.1747 92.1975 56.9625C98.733 55.2113 102.611 48.4936 100.86 41.9581C99.648 37.434 96.0556 34.1851 91.7915 33.1934Z"
        fill="#FFCEBF"
      />
      <path
        d="M47.6614 114.617L62.0614 110.758L53.4848 78.7501C51.9087 72.8682 45.8628 69.3777 39.981 70.9537L24.5974 75.0758C18.7156 76.6518 15.2249 82.6978 16.8009 88.5795L25.3776 120.588C25.6568 121.63 26.7277 122.248 27.7696 121.969L48.1669 116.504L47.6614 114.617Z"
        fill="#3D3877"
      />
      <path
        d="M47.6605 114.617L62.0605 110.758L53.4839 78.7501C51.9872 73.1645 46.4597 69.7387 40.8695 70.7572C33.998 75.164 30.6178 83.5495 32.7462 91.6398L39.9937 118.693L48.1661 116.503L47.6606 114.617L47.6605 114.617Z"
        fill="#594FC4"
      />
      <path
        d="M32.2884 73.0147C38.8239 71.2635 42.7023 64.5459 40.9511 58.0104C39.2 51.4749 32.4823 47.5965 25.9468 49.3476C19.4113 51.0988 15.5329 57.8165 17.2841 64.352C19.0352 70.8874 25.7529 74.7659 32.2884 73.0147Z"
        fill="#FFDFCF"
      />
      <path
        d="M20.1644 69.5434C22.0603 69.9843 24.0884 69.9804 26.1 69.4413C32.6355 67.6902 36.514 60.9725 34.7628 54.437C34.2238 52.4256 33.2131 50.6671 31.8833 49.2456C36.1474 50.2373 39.7398 53.4864 40.952 58.0104C42.7032 64.5459 38.8248 71.2635 32.2893 73.0147C27.7653 74.2269 23.1553 72.7403 20.1644 69.5434Z"
        fill="#FFCEBF"
      />
      <path
        d="M94.8394 75.033C93.2371 69.1613 87.6528 65.2798 81.586 65.8323L75.1437 66.4191L66.7617 77.2686C66.2242 77.9644 65.2881 78.2152 64.4746 77.8814L51.7908 72.6765L45.918 75.3895C40.3877 77.9444 37.4923 84.098 39.0406 89.9842L45.7749 115.122C46.0541 116.164 47.125 116.783 48.167 116.503L100.195 102.563C101.237 102.283 101.855 101.212 101.576 100.171L94.8394 75.033Z"
        fill="#E6F7FE"
      />
      <path
        d="M94.8396 75.0329C93.2803 69.3185 87.9492 65.4908 82.0723 65.7991C84.8493 67.4102 86.9932 70.0716 87.8898 73.3572L87.8911 73.3568L96.0167 103.682L100.195 102.562C101.237 102.283 101.856 101.212 101.577 100.17L94.8409 75.0326L94.8396 75.0329Z"
        fill="#D3EFFD"
      />
      <path
        d="M69.641 65.2927L68.5222 61.1173L54.8745 64.7742L55.9929 68.9497C56.2389 69.8677 55.7883 70.8294 54.9253 71.2281L51.7904 72.6763L64.4742 77.8812C65.2876 78.215 66.2237 77.9642 66.7613 77.2683L75.1434 66.4189L71.7045 66.7321C70.7581 66.8185 69.8869 66.2107 69.641 65.2927Z"
        fill="#FFDFCF"
      />
      <path
        d="M70.5984 66.5036C70.14 66.2552 69.7841 65.8273 69.6409 65.2928L68.5221 61.1174L54.8745 64.7743L55.9929 68.9498C56.1361 69.4842 56.0418 70.0328 55.7688 70.4772C57.862 70.677 60.7515 70.6984 62.2961 70.2846L64.8494 69.6004C66.394 69.1868 68.8856 67.7232 70.5984 66.5036Z"
        fill="#FFCEBF"
      />
      <path
        d="M109.202 71.3232C108.502 70.9187 107.606 71.1589 107.201 71.8594L106.313 73.3977C106.119 73.7342 106.067 74.1339 106.167 74.5092L112.78 99.1903L115.61 98.432L109.154 74.3373L109.739 73.3245C110.143 72.6237 109.903 71.7279 109.202 71.3232Z"
        fill="#3D3877"
      />
      <path
        d="M26.7432 93.4181C26.0425 93.0136 25.1466 93.2537 24.7423 93.9543C24.3378 94.6549 24.5778 95.5508 25.2784 95.9552L26.2912 96.5399L32.7474 120.635L35.5773 119.877L28.9641 95.1957C28.8635 94.8204 28.618 94.5004 28.2815 94.3063L26.7432 93.4181Z"
        fill="#3D3877"
      />
      <path
        d="M89.0341 95.8299C88.2525 96.0393 87.7889 96.8425 87.9983 97.6239L90.0499 105.281L92.8797 104.522L90.8281 96.8657C90.6187 96.084 89.8155 95.6205 89.0341 95.8299Z"
        fill="#B2E5FB"
      />
      <path
        d="M54.4638 105.093C53.6823 105.303 53.2186 106.106 53.428 106.887L55.4796 114.544L58.3094 113.786L56.2578 106.129C56.0484 105.347 55.2452 104.884 54.4638 105.093Z"
        fill="#B2E5FB"
      />
      <path
        d="M53.2328 83.1185L49.9295 70.7901C49.5584 69.4052 50.3831 67.9766 51.7681 67.6055L55.3728 66.6397L54.6145 63.8098L51.0098 64.7757C48.0645 65.5649 46.3104 68.6031 47.0996 71.5484L50.4029 83.8766C46.4698 85.657 44.2798 90.0812 45.433 94.3848L46.6429 98.9003C47.2581 101.196 49.6262 102.563 51.922 101.948L53.2962 101.58C54.0776 101.371 54.5414 100.567 54.332 99.7859C54.1226 99.0045 53.3194 98.5407 52.5379 98.7501L51.1638 99.1183C50.4284 99.3154 49.6698 98.8775 49.4728 98.1421L48.2629 93.6265C47.3987 90.4013 49.3194 87.0744 52.5447 86.2101C55.77 85.3459 59.097 87.2669 59.9611 90.492L61.1711 95.0075C61.3681 95.7429 60.9302 96.5014 60.1948 96.6985L58.8204 97.0667C58.039 97.2761 57.5752 98.0794 57.7846 98.8608C57.994 99.6422 58.7973 100.106 59.5787 99.8966L60.953 99.5283C63.2488 98.9132 64.6161 96.545 64.0009 94.2493L62.791 89.7337C61.6378 85.4301 57.5292 82.6936 53.2328 83.1185Z"
        fill="#9FACBA"
      />
      <path
        d="M85.3055 87.9915L82.4756 88.7498L75.8055 63.8565C75.4344 62.4715 74.0056 61.6468 72.6209 62.0178L69.02 62.9827L68.2617 60.1528L71.8626 59.188C74.8079 58.3988 77.8461 60.1529 78.6353 63.0982L85.3055 87.9915Z"
        fill="#9FACBA"
      />
      <path
        d="M85.0276 92.613C86.7624 92.1481 87.7919 90.365 87.3271 88.6302C86.8622 86.8954 85.0791 85.8659 83.3443 86.3307C81.6095 86.7955 80.58 88.5787 81.0448 90.3135C81.5096 92.0483 83.2928 93.0778 85.0276 92.613Z"
        fill="#0077AA"
      />
      <path
        d="M52.5049 28.6469C56.842 27.4848 59.4158 23.0268 58.2537 18.6897C57.0916 14.3525 52.6335 11.7787 48.2964 12.9408C43.9593 14.103 41.3855 18.561 42.5476 22.8981C43.7097 27.2352 48.1677 29.8091 52.5049 28.6469Z"
        fill="#9B5E5E"
      />
      <path
        d="M41.9095 43.0562L45.1519 55.157C46.0035 58.3353 48.0948 61.0389 50.9569 62.6619L53.7001 64.2174C56.3216 65.7038 59.4223 66.0999 62.3333 65.3199C65.2443 64.5399 67.7316 62.6465 69.2587 60.0485L70.8565 57.3298C72.5237 54.493 72.983 51.106 72.1314 47.9279L68.889 35.827C66.8927 28.3768 59.2349 23.9555 51.7847 25.9518C44.3346 27.9481 39.9132 35.6059 41.9095 43.0562Z"
        fill="#FFDFCF"
      />
      <path
        d="M51.7835 25.9518C50.9449 26.1765 50.1451 26.4735 49.388 26.8333C55.9752 26.3058 62.2055 30.5312 63.9771 37.1428L67.2195 49.2437C68.0711 52.422 67.6118 55.809 65.9446 58.6456L64.3467 61.3643C63.2568 63.219 61.6755 64.7115 59.8022 65.6994C60.6506 65.6668 61.4992 65.5431 62.3321 65.3199C65.2431 64.5399 67.7304 62.6465 69.2575 60.0485L70.8553 57.3298C72.5225 54.493 72.9818 51.106 72.1302 47.9279L68.8878 35.827C66.8917 28.3767 59.2338 23.9555 51.7835 25.9518Z"
        fill="#FFCEBF"
      />
      <path
        d="M50.4013 20.7938C40.2397 23.5166 34.2092 33.9616 36.932 44.1231L38.3718 49.4966L43.2813 48.1811L43.9741 50.7669C49.0973 49.3941 52.7411 43.9317 54.3674 41.0092C54.9038 40.0454 56.129 39.7171 57.0752 40.2837C59.945 42.0016 65.8318 44.9101 70.9549 43.5374L70.2621 40.9516L75.1715 39.6361L73.7317 34.2626C71.0089 24.101 60.5639 18.0707 50.4025 20.7935"
        fill="#B98080"
      />
      <path
        d="M60.1267 20.755C65.1465 31.3527 61.3208 38.6816 59.373 41.5351C62.5038 43.0762 66.9612 44.6077 70.9549 43.5376L70.2621 40.9518L75.1715 39.6364L73.7317 34.2628C71.8941 27.4043 66.5385 22.4287 60.1267 20.755Z"
        fill="#AE6C6C"
      />
      <path
        d="M43.7801 55.5245C41.3145 56.1852 38.779 54.6861 38.1821 52.1868C37.607 49.778 39.1265 47.3606 41.5187 46.7196L41.8894 46.6203C42.3918 46.4856 42.9084 46.7838 43.0431 47.2863L45.152 55.1569L43.7801 55.5245Z"
        fill="#FFB09E"
      />
      <path
        d="M73.5039 47.5602C75.9695 46.8996 77.4158 44.3335 76.6831 41.8707C75.9767 39.497 73.4521 38.1632 71.0599 38.8042L70.6892 38.9035C70.1868 39.0381 69.8884 39.5546 70.0231 40.0572L72.132 47.9278L73.5039 47.5602Z"
        fill="#FFB09E"
      />
      <path
        d="M53.5605 102.562C52.7791 102.771 51.9759 102.307 51.7665 101.526L51.2405 99.5628C51.0311 98.7813 51.4948 97.9781 52.2763 97.7687C53.0577 97.5593 53.8609 98.0231 54.0703 98.8045L54.5963 100.768C54.8057 101.549 54.342 102.352 53.5605 102.562Z"
        fill="#0077AA"
      />
      <path
        d="M59.8438 100.878C59.0623 101.088 58.2591 100.624 58.0497 99.8426L57.5237 97.8794C57.3143 97.098 57.778 96.2947 58.5595 96.0853C59.3409 95.876 60.1441 96.3397 60.3535 97.1211L60.8796 99.0843C61.0889 99.8657 60.6252 100.669 59.8438 100.878Z"
        fill="#0077AA"
      />
    </svg>
  );
}

export default NurseIcon;
