import { useEffect, useRef, useState } from "react";

import { unstable_usePrompt, useNavigate, useParams } from "react-router-dom";

import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import { pdf } from "@react-pdf/renderer";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useAxios from "../../../hooks/useAxios";
import ServiceCart from "./components/ServiceCart";
import { formatDate } from "../../../utils/helpers";
import OrderDetails from "./components/OrderDetails";
import OrderSummary from "./components/OrderSummary";
import useTokenData from "../../../hooks/useTokenData";
import InitiateOrder from "./components/InitiateOrder";
import PaymentOptions from "./components/PaymentOptions";
import { useBlockNavigation } from "../../../store/store";
import { PatientInfoPDF } from "./components/PatientInfoPDF";
import Stethoscope from "../../../iconComponents/Stethoscope";
import { getVisibleQuestions } from "../../../utils/serviceUtils";
import { PatientInfo, RequestService } from "../../../components/forms";
import StepsSkeleton from "../../../components/skeletons/StepsSkeleton";
import { DialogCreatePin, DialogEnterPin } from "../../../components/dialogs";
import InitiateOrderSkeleton from "../../../components/skeletons/InitiateOrderSkeleton";
export const OrderService = () => {
  const toast = useRef(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState(null);
  const [hasUnCertifiedProviders, setHasUnCertifiedProviders] = useState(false);
  const onSubmitCallback = (formData) => {
    setPatientInfo(formData);
  };
  let patientFullName =
    patientInfo?.Fname && patientInfo?.Lname
      ? `${patientInfo.Fname} ${patientInfo.Lname}`
      : null;
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepsOptions, setStepsOptions] = useState([]);
  const submitRef = useRef();
  const [questions, setQuestions] = useState([]);
  const [services, setServices] = useState({});
  const [generalInfo, setGeneralInfo] = useState({});
  const [initiateOrderData, setInitiateOrderData] = useState(null);
  const [skipAnswerQuestions, setSkipAnswerQuestions] = useState(false);
  const [orderData, setOrderData] = useState();
  const [serviceDetails, setServiceDetails] = useState();
  const [cptBucket, setCptBucket] = useState();
  const [locationsBucket, setLocationsBucket] = useState();
  const [selectedLoc, setSelectedLoc] = useState();
  const [cost, setCost] = useState();
  const { serviceId } = useParams();
  const [orderId, setOrderId] = useState();
  const { http } = useAxios();
  const [progressIndex, setProgressIndex] = useState(0);
  const [orderDetailsFormData, setOrderDetailsFormData] = useState(null);
  const [orderDetailsFiles, setOrderDetailsFiles] = useState([]);
  const [requestServiceData, setRequestServiceData] = useState(null);
  const [submittedResponses, setSubmittedResponses] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);
  const [selectedChooseList, setSelectedChooseList] = useState([]);
  const [orderDocs, setOrderDocs] = useState([]);
  const [paymentReceipt, setPaymentReceipt] = useState();
  const [paymentLink, setPaymentLink] = useState();
  const [paymentLinkId, setPaymentLinkId] = useState();
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [serviceFees, setServiceFees] = useState(0);
  const [serviceChargeCapacity, setServiceChargeCapacity] = useState(0);
  const [showDialogCreatePin, setShowDialogCreatePin] = useState(false);
  const [showDialogEnterPin, setShowDialogEnterPin] = useState(false);
  const [isLoadingDialogPin, setIsLoadingDialogPin] = useState(false);
  const [isLoadingDialogPinEnter, setIsLoadingDialogPinEnter] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [serviceUnavailableDialogVisible, setServiceUnavailableDialogVisible] =
    useState(false);
  const handlePinSubmit = async (pin) => {
    setIsLoadingDialogPinEnter(true);
    await createOrder(pin);
  };

  useEffect(() => {
    serviceUnavailableDialogVisible && setBlockNavigation(false);
  }, [serviceUnavailableDialogVisible]);

  const navigate = useNavigate();

  const { entityId, firstName, lastName, userId, HasPin } = useTokenData();
  const itemRenderer = (item, itemIndex, activeIndex, options) => {
    return (
      <span
        className={`z-10 flex h-full w-[100px] cursor-pointer flex-col items-center justify-start gap-2 before:mt-[-1.5rem] lg:before:mt-0`}
        onClick={() => setActiveIndex(itemIndex)}
      >
        <span
          className={`flex h-10 w-10 items-center justify-center rounded-full border ${activeIndex === itemIndex ? "bg-violet-100" : "bg-white"} `}
        >
          {typeof item.icon === "string" ? (
            <i className={`${item.icon} text text-xl`} />
          ) : (
            item.icon
          )}
        </span>
        <div
          className={`${options.labelClassName} hidden items-start whitespace-normal text-center lg:flex`}
        >
          {item.label}
        </div>
      </span>
    );
  };

  const getPaymentLink = async (orderId) => {
    try {
      const response = await http.post("/Payments/CreatePaymentLink", {
        successUrl: "https://localhost:44413/PaymentSuccess",
        orderId: orderId,
        productWithPrice: {
          name: orderData.generalInfo.name,
          description: orderData.generalInfo.description,
          amount: cost + (serviceFees / 100) * cost,
        },
      });

      return response.data;
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
      return null;
    }
  };
  const getData = async () => {
    const servicesData = await http.get(
      `/Services/GetServiceWizard/${serviceId}`,
    );
    const data = JSON.parse(servicesData.data.serviceJson);
    setStepsOptions([
      {
        label: "Initiate Order",
        disabled: false,
        icon: <Stethoscope />,
        template: (item, options) =>
          itemRenderer(item, 0, activeIndex, options),
      },
      {
        label: "Patient Information",
        disabled: true,
        icon: "pi pi-info",
        template: (item, options) =>
          itemRenderer(item, 1, activeIndex, options),
      },
      {
        label: "Order Details",
        disabled: true,
        icon: "pi pi-list",
        template: (item, options) =>
          itemRenderer(item, 2, activeIndex, options),
      },
      {
        label: "Answer Questions",
        disabled: true,
        icon: "pi pi-question",
        template: (item, options) =>
          itemRenderer(item, 3, activeIndex, options),
      },
      {
        label: "Cart",
        disabled: true,
        icon: "pi pi-shopping-cart",
        template: (item, options) =>
          itemRenderer(item, 4, activeIndex, options),
      },
      {
        label: "Payment Options",
        disabled: true,
        icon: "pi pi-credit-card",
        template: (item, options) =>
          itemRenderer(item, 5, activeIndex, options),
      },
      {
        label: "Summary",
        disabled: false,
        icon: "pi pi-book",
        template: (item, options) =>
          itemRenderer(item, 6, activeIndex, options),
        visible: false,
      },
    ]);
    setOrderData(data);
    setQuestions(data.questions);
    setServices(data.services);
    setGeneralInfo(data.generalInfo);
    setServiceFees(servicesData.data.fees);
    setServiceChargeCapacity(servicesData.data.serviceChargeCapacity);
    setServiceDetails(servicesData.data);
  };

  const convertToBlob = (pdfDocument) => {
    return pdf(pdfDocument).toBlob();
  };

  const uniqueQuestions = [
    ...new Set(submittedResponses?.map((item) => item.question.label)),
  ];
  let orderCounter;
  const generateUniqueOrderNumber = () => {
    const timestamp = Date.now();
    const newOrderCounter = Math.floor(10000 + Math.random() * 90000);
    const uniqueOrderNumber = (timestamp * 1000 + newOrderCounter) % 100000;
    orderCounter = uniqueOrderNumber;
    return uniqueOrderNumber;
  };

  const createPDF = async (data, billableItems) => {
    generateUniqueOrderNumber();
    const PDF = (
      <PatientInfoPDF
        Fname={data.Fname}
        Lname={data.Lname}
        phone={data.phone}
        email={data.email}
        birthDate={formatDate(data.birth)}
        AddressLineOne={data.AddressLineOne}
        AddressLineTwo={data.AddressLineTwo}
        City={data.City}
        State={data.State}
        ZipCode={data.ZipCode}
        gender={data.gender}
        orderData={orderData}
        diagnosis={orderDetailsFormData.selectedNodes}
        notes={orderDetailsFormData.notes}
        questionsData={submittedResponses}
        uniqueQuestions={uniqueQuestions}
        orderCounter={orderCounter}
        orderedByFname={firstName}
        orderedByLname={lastName}
        selectedProvider={initiateOrderData.orderingProvider.fullName}
        npi={initiateOrderData.orderingProvider.npi}
        billableItems={billableItems}
        guid={initiateOrderData.orderingProvider.entityId}
      />
    );
    const result = await convertToBlob(PDF);

    return {
      fileDetails: result,
      fileType: 4,
      filePropertyName: 6,
      fileName: `${orderCounter}_order_summary.pdf`,
    };
  };

  const createOrder = async (pinCode) => {
    setIsLoading(true);
    const combinedUnits = {};
    cptBucket.forEach((cpt) => {
      if (!combinedUnits[cpt.id]) {
        combinedUnits[cpt.id] = structuredClone(cpt);
      } else {
        combinedUnits[cpt.id].units += cpt.units;
        combinedUnits[cpt.id].count += cpt.count;
      }
    });

    const combinedArray = Object.values(combinedUnits);

    const patientPDFFileData = await createPDF(patientInfo, combinedArray);

    const data = new FormData();
    data.append("PinCode", pinCode);
    data.append("Charge", cost);
    data.append(
      "OrderSummary.FileDetails",
      patientPDFFileData.fileDetails,
      patientPDFFileData.fileName,
    );
    data.append("OrderSummary.FileType", patientPDFFileData.fileType);
    data.append(
      "OrderSummary.FilePropertyName",
      patientPDFFileData.filePropertyName,
    );
    data.append("OrderedEntityId", entityId);
    data.append("PatientName", patientFullName);
    data.append("patientEmail", patientInfo.email);
    data.append("PatientDOB", patientInfo.birth);
    data.append("patientPhoneNumber", patientInfo.phone);
    data.append("OrderType", initiateOrderData.orderType);
    data.append("AssignedProviderId", initiateOrderData.orderingProvider.id);
    if (initiateOrderData.ExistingOrderDate) {
      const selectedDate = new Date(initiateOrderData.ExistingOrderDate);

      if (!isNaN(selectedDate)) {
        const formattedDate = selectedDate.toLocaleDateString();
        data.append("ExistingOrderDate", formattedDate);
      }
    }
    const orderProviderTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    data.append("OrderProviderTimeZone", orderProviderTimeZone);
    data.append(
      "ExistingOrderReferenceIdentifier",
      initiateOrderData.ExistingOrderReferenceIdentifier,
    );

    data.append("OrderTrackingNumber", orderCounter);
    combinedArray.forEach((billableItem, index) => {
      data.append(`BillableItems[${index}].id`, billableItem.id);
      data.append(
        `BillableItems[${index}].label`,
        billableItem.shortDescription,
      );
      data.append(
        `BillableItems[${index}].chargeAmount`,
        billableItem.chargeAmount,
      );
      data.append(
        `BillableItems[${index}].units`,
        isNaN(billableItem.units) ? 1 : billableItem.units,
      );
    });

    orderDetailsFiles.forEach((file, index) => {
      data.append(`AttachmentFiles[${index}].FileDetails`, file.fileDetails);
      data.append(
        `AttachmentFiles[${index}].FilePropertyName`,
        file.filePropertyName,
      );
      data.append(`AttachmentFiles[${index}].FileType`, file.fileType);
    });
    locationsBucket.forEach((location, index) => {
      data.append(`RenderedLocations[${index}].locationId`, location);
      data.append(
        `RenderedLocations[${index}].isPreferredByPatient`,
        location === selectedLoc,
      );
    });

    orderDocs.forEach((doc, index) => {
      data.append(`OrderDocumentsIds[${index}]`, doc);
    });

    try {
      const res = await http.post(`/Orders/CreateOrder/${serviceId}`, data);
      setOrderId(res.data);
      const response = await getPaymentLink(res.data);
      setPaymentLink(response.paymentLink);
      setPaymentLinkId(response.id);
      setActiveIndex(activeIndex + 1);
      setErrorMessage && setErrorMessage("");
      setShowDialogEnterPin(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Order created successfully",
        life: 4000,
      });
    } catch (error) {
      console.error("Error creating order:", error);
      if (!showDialogEnterPin) {
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: error.response.data.message || error.response.data.title,
          life: 4000,
        });
      } else {
        const errMsg = error.response.data.message;
        setErrorMessage(errMsg);
      }
    } finally {
      setIsLoading(false);
      setIsLoadingDialogPinEnter(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const getProviders = async () => {
      const response = await http.get(
        `/Entities/GetEntityProviders/${entityId}`,
      );
      const providersData = response.data?.providers;
      setProviders(providersData);
      setHasUnCertifiedProviders(response.data?.unCertifiedExists);
    };
    getProviders();
  }, []);

  useEffect(() => {
    setProgressIndex((prevProgressIndex) =>
      Math.max(prevProgressIndex, activeIndex),
    );

    setStepsOptions((prevSteps) =>
      prevSteps.map((step, index) => {
        if (activeIndex >= 5) {
          // Show Summary step but keep it disabled
          if (index === 5) {
            return {
              ...step,
              disabled: !!isPaymentComplete,
              visible: true,
              template: (item, options) =>
                itemRenderer(item, index, activeIndex, options),
            };
          }
          if (index === 6) {
            return {
              ...step,
              disabled: false,
              visible: true,
              template: (item, options) =>
                itemRenderer(item, index, activeIndex, options),
            };
          }
          // Disable all previous steps if the user is on the Payment Options step
          return {
            ...step,
            disabled: index < activeIndex,
            template: (item, options) =>
              itemRenderer(item, index, activeIndex, options),
          };
        }
        return {
          ...step,
          disabled: index > activeIndex && index > progressIndex,
          template: (item, options) =>
            itemRenderer(item, index, activeIndex, options),
        };
      }),
    );
    document
      .getElementById("protected-routes")
      .scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [activeIndex, progressIndex]);

  const { blockNavigation, setBlockNavigation } = useBlockNavigation();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Wanna leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setBlockNavigation(true);
    };
  }, [setBlockNavigation]);
  unstable_usePrompt({
    message:
      "Are you sure you want to leave? If you exit now, your current order progress will be lost.",
    when: ({ currentLocation, nextLocation }) =>
      blockNavigation &&
      currentLocation.pathname !== nextLocation.pathname &&
      activeIndex !== 6,
  });

  const defaultValues = {
    orderType:
      serviceDetails?.entityId === entityId && providers?.length === 0 ? 2 : 1,
    orderingProvider: initiateOrderData?.orderingProvider
      ? initiateOrderData?.orderingProvider
      : providers && providers?.length === 1
        ? providers[0]
        : null,
    ExistingOrderDate: "",
    ExistingOrderReferenceIdentifier: "",
    attestation: undefined,
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={serviceUnavailableDialogVisible}
        modal
        closable={false}
        header="Oops! It looks like there's an issue with your order."
        footer={
          <Button
            label="Ok"
            icon="pi pi-check"
            onClick={() => {
              navigate("/ordering/FindServices");
              setServiceUnavailableDialogVisible(false);
            }}
            autoFocus
          />
        }
        style={{ width: "50rem" }}
      >
        <div className="flex items-center gap-2">
          <i className="pi pi-info-circle mr-2 text-3xl text-red-400"></i>
          <p className="text-lg font-semibold">
            We're sorry, but it seems that your order doesn't meet the necessary
            requirements at this time.
          </p>
        </div>
      </Dialog>
      <Dialog
        visible={showDialogCreatePin}
        modal
        draggable={false}
        style={{ width: "40rem" }}
        headerStyle={{ paddingBottom: "0px" }}
        onHide={() => {
          if (!showDialogCreatePin) return;
          setShowDialogCreatePin(false);
        }}
      >
        <DialogCreatePin
          isLoading={isLoadingDialogPin}
          setIsLoading={setIsLoadingDialogPin}
          showDialogCreatePin={showDialogCreatePin}
          setShowDialogCreatePin={setShowDialogCreatePin}
        />
      </Dialog>
      <DialogEnterPin
        showDialogEnterPin={showDialogEnterPin}
        setShowDialogEnterPin={setShowDialogEnterPin}
        onPinSubmit={handlePinSubmit}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isLoading={isLoadingDialogPinEnter}
      />

      <div className="flex items-center justify-center font-inter">
        <div className="flex w-full flex-col justify-center rounded-3xl border bg-light-text py-5 md:w-full lg:w-11/12">
          {activeIndex <= 4 && (
            <div className="px-8 py-2">
              <span
                className={`flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border hover:bg-slate-50`}
                onClick={() => {
                  setActiveIndex((currentIndex) =>
                    currentIndex > 0 ? currentIndex - 1 : currentIndex,
                  );
                }}
              >
                <i className="pi pi-arrow-left"></i>
              </span>
            </div>
          )}
          <p className="px-8 pt-4 font-inter text-title font-extrabold capitalize text-dark-purple shadow-md shadow-slate-50">
            {orderData?.generalInfo?.name}
          </p>
          <div className="w-full p-4 md:px-12 md:py-8">
            <div>
              {stepsOptions?.length > 0 ? (
                <Steps
                  pt={{
                    menu: {
                      className: "flex flex-col md:gap-auto gap-5  md:flex-row",
                    },
                  }}
                  model={stepsOptions}
                  activeIndex={activeIndex}
                  onSelect={(e) => {
                    setActiveIndex(e.index);
                  }}
                  readOnly={false}
                  className="mb-12"
                />
              ) : (
                <StepsSkeleton />
              )}
              {activeIndex === 0 ? (
                // Initiate Order
                providers && serviceDetails ? (
                  <InitiateOrder
                    isInternalOrder={serviceDetails?.entityId === entityId}
                    submitRef={submitRef}
                    providers={providers}
                    initiateOrderData={initiateOrderData}
                    setInitiateOrderData={setInitiateOrderData}
                    hasUnCertifiedProviders={hasUnCertifiedProviders}
                    setActiveIndex={setActiveIndex}
                    progressIndex={progressIndex}
                    defaultValues={defaultValues}
                  />
                ) : (
                  <InitiateOrderSkeleton />
                )
              ) : activeIndex === 1 ? (
                // Patient Information
                <PatientInfo
                  submitRef={submitRef}
                  setActiveIndex={setActiveIndex}
                  orderData={orderData}
                  onSubmitCallback={onSubmitCallback}
                  isLoading={isLoading}
                  patientInfoData={patientInfo}
                  setIsLoading={setIsLoading}
                />
              ) : activeIndex === 2 ? (
                // Order Details
                <OrderDetails
                  questions={questions}
                  submitRef={submitRef}
                  patientInfo={patientInfo}
                  selectedNodes={selectedNodes}
                  setActiveIndex={setActiveIndex}
                  formData={orderDetailsFormData}
                  uploadedFiles={orderDetailsFiles}
                  setSelectedNodes={setSelectedNodes}
                  setFormData={setOrderDetailsFormData}
                  setUploadedFiles={setOrderDetailsFiles}
                  selectedChooseList={selectedChooseList}
                  selectedOptionsList={selectedOptionsList}
                  setSelectedChooseList={setSelectedChooseList}
                  setSelectedOptionsList={setSelectedOptionsList}
                />
              ) : activeIndex === 3 ? (
                // Answer Questions
                <RequestService
                  setCost={setCost}
                  services={services}
                  submitRef={submitRef}
                  serviceId={serviceId}
                  questions={questions}
                  setCptBucket={setCptBucket}
                  patientInfo={patientInfo}
                  generalInfo={generalInfo}
                  setOrderDocs={setOrderDocs}
                  setActiveIndex={setActiveIndex}
                  submittedResponses={submittedResponses}
                  setLocationsBucket={setLocationsBucket}
                  requestServiceData={requestServiceData}
                  skipAnswerQuestions={skipAnswerQuestions}
                  setSubmittedResponses={setSubmittedResponses}
                  setRequestServiceData={setRequestServiceData}
                  setSkipAnswerQuestions={setSkipAnswerQuestions}
                  setServiceUnavailableDialogVisible={
                    setServiceUnavailableDialogVisible
                  }
                />
              ) : activeIndex === 4 ? (
                // Cart
                <ServiceCart
                  generalInfo={generalInfo}
                  cost={cost}
                  maxFeesEntity={serviceChargeCapacity}
                  cptBucket={cptBucket}
                  selectedLoc={selectedLoc}
                  setSelectedLoc={setSelectedLoc}
                  locationsBucket={locationsBucket}
                  serviceFess={serviceFees}
                  serviceId={serviceId}
                  serviceType={serviceDetails.serviceType}
                  setLocationsBucket={setLocationsBucket}
                  setServiceUnavailableDialogVisible={
                    setServiceUnavailableDialogVisible
                  }
                />
              ) : activeIndex === 5 ? (
                // Payment Options
                <PaymentOptions
                  patientInfo={patientInfo}
                  setActiveIndex={setActiveIndex}
                  submitRef={submitRef}
                  orderId={orderId}
                  cost={cost}
                  orderData={orderData}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  serviceFess={serviceFees}
                  setPaymentReceipt={setPaymentReceipt}
                  paymentLink={paymentLink}
                  setPaymentLink={setPaymentLink}
                  paymentLinkId={paymentLinkId}
                  setPaymentLinkId={setPaymentLinkId}
                  setIsPaymentComplete={setIsPaymentComplete}
                />
              ) : activeIndex === 6 ? (
                // Summary
                <OrderSummary
                  orderId={orderId}
                  orderDocs={orderDocs}
                  paymentLink={paymentLink}
                  paymentLinkId={paymentLinkId}
                  paymentReceipt={paymentReceipt}
                  isPaymentComplete={isPaymentComplete}
                  showCheckInPass={
                    serviceDetails?.sendCheckInPass &&
                    serviceDetails?.serviceType === 2 &&
                    serviceDetails?.entityId === entityId &&
                    initiateOrderData?.orderingProvider?.id === userId &&
                    isPaymentComplete
                  }
                />
              ) : null}
            </div>

            <div className="mt-8 text-end">
              {activeIndex !== 5 && (
                <Button
                  loading={isLoading}
                  label={
                    activeIndex === 6
                      ? "Explore More Services"
                      : activeIndex === 4
                        ? "Confirm & Proceed To Payment"
                        : "Next"
                  }
                  disabled={
                    providers?.length === 0 &&
                    serviceDetails?.entityId !== entityId
                  }
                  onClick={() => {
                    if (activeIndex > 6) return;

                    if (activeIndex === 6) {
                      navigate("/ordering/FindServices");
                      return;
                    }

                    if (activeIndex < 4) {
                      if (activeIndex === 1) {
                        setSubmittedResponses([]);
                      }
                      if (activeIndex === 2) {
                        const visibleQuestions = getVisibleQuestions({
                          questions,
                          patientInfo,
                        });
                        setSkipAnswerQuestions(visibleQuestions.length <= 0);
                      }
                      submitRef.current.requestSubmit();
                      return;
                    }
                    if (activeIndex === 4) {
                      if (initiateOrderData.orderingProvider.id === userId) {
                        if (HasPin === "False") {
                          setShowDialogCreatePin(true);
                        } else {
                          setShowDialogEnterPin(true);
                        }
                      } else {
                        createOrder();
                      }
                      return;
                    }
                    if (activeIndex === 5) {
                      submitRef.current.requestSubmit();
                      return;
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
