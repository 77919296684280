import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { ProgressBar } from "primereact/progressbar";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Accordion, AccordionTab } from "primereact/accordion";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { showPinConfirmation } from "../../utils/pinUtils";
import { DialogCreatePin } from "../../components/dialogs";
import {
  useCurrentEntity,
  useRefreshTokenStore,
  useTokenStore,
} from "../../store/store";

export default function Welcome() {
  const { http } = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const fromSetup = location?.state?.fromSetup;
  const { currentEntity } = useCurrentEntity();
  const {
    userRoles = {},
    entityId = {},
    entityType,
    VerifyProvider,
    HasPin,
    individualPracticeOwner,
  } = useTokenData();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();

  const [checklistItems, setChecklistItems] = useState([]);
  const [practiceCompletion, setPracticeCompletion] = useState(0);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [showDialogCreatePin, setShowDialogCreatePin] = useState(false);
  const [isLoadingDialogPin, setIsLoadingDialogPin] = useState(false);
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      view: "render",
    },
  });
  // show confirm message set pin if create individual and change order view which no pin
  let path = "";
  const state = {
    isWelcome: true,
    fromSetup: fromSetup,
  };
  const handlePinNavigate = (path) => {
    if (path) {
      navigate(path, { state });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="create"
        icon="pi pi-check"
        type="submit"
        form="createPinForm"
        isLoading={isLoadingDialogPin}
        autoFocus
        className="mt-9 w-full justify-center rounded-lg bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
      />
    </div>
  );

  const handleAccept = () => setShowDialogCreatePin(true);
  const handleReject = () => handlePinNavigate("/ordering/FindServices");

  const onPinCreationComplete = () => {
    setShowDialogCreatePin(false);
    handlePinNavigate("/ordering/FindServices");
  };

  const onSubmit = async (formData) => {
    const view = formData.view === "render" ? 1 : 2;
    try {
      const response = await http.post(
        `/Authentication/GetToken/${entityId}/${view}`,
      );

      getPracticeChecklist(entityId, formData.view);
      setToken(response.data.token);
      setRefreshToken(response.data.refreshToken);

      if (view === 1) {
        path = "/setUpDeposit";
      } else if (view === 2) {
        if (individualPracticeOwner === "True") {
          HasPin === "False"
            ? showPinConfirmation(handleAccept, handleReject)
            : (path = "/ordering/FindServices");
        } else {
          path = "/ordering/FindServices";
        }
      }

      if (path) {
        handlePinNavigate(path);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPracticeChecklist = async (entityId, view) => {
    try {
      const response = await http.get(
        `Entities/GetPracticeCheckList/${entityId}`,
      );
      const {
        verifiedProvider,
        practiceApproval,
        individualDataCompleted,
        providerDataCompleted,
        ...rest
      } = response.data;

      const checklistData =
        view === "order"
          ? {
              practiceApproval,
              verifiedProvider,
            }
          : {
              ...rest,
              practiceApproval,
            };

      if (entityType == 2 && userRoles === "Admin") {
        checklistData.individualDataCompleted = individualDataCompleted;
      }

      const trueCount = Object.values(checklistData).filter(
        (value) => value === true,
      ).length;
      const totalCount = Object.keys(checklistData).length;
      const practiceCompletion = Math.round((trueCount / totalCount) * 100);

      setPracticeCompletion(practiceCompletion);

      const renderingChecklistItems = [
        {
          label: "Locations",
          completed: checklistData.location,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>
                Please, make sure all your practice locations are correctly
                listed and up to date.
              </p>
              <NavLink to="/rendering/mylocations/">
                <Button label="Manage Locations"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Billable Items",
          completed: checklistData.billableItems,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>Add & update billable items for your services</p>
              <NavLink to="/Service/billableItems">
                <Button label="Manage Billable Items"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Create Your First Service",
          completed: checklistData.service,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>
                Ready to begin? Let's create your first service and showcase
                what you have to offer!
              </p>
              <NavLink to="/Service/MyServices/createNewService">
                <Button label="Create Service"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Deposit Method",
          completed: checklistData.depositMethod,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>Set up your deposit method to receive payments</p>
              <NavLink to="/rendering/settings/DepositSettings/">
                <Button label="Set Up Deposit Method"></Button>
              </NavLink>
            </div>
          ),
        },
        {
          label: "Practice Approval",
          completed: checklistData.practiceApproval,
          body: (
            <div>
              {checklistData.practiceApproval ? (
                <p>
                  Congratulations! Your practice has been approved. You're now
                  ready to start publishing your services.
                </p>
              ) : (
                <p>
                  Your practice is currently under review. Once approved, you'll
                  be able to publish your services. Thanks for your patience!
                </p>
              )}
            </div>
          ),
        },
        {
          label: "Publish Your First Service",
          completed: checklistData.isServicePublished,
          body: (
            <div>
              {checklistData.isServicePublished ? (
                <div className="flex flex-col gap-3">
                  <p>
                    Congratulations! Your first service has been successfully
                    published! Keep adding more services to expand your
                    practice's offerings.
                  </p>
                  <NavLink to="/service/MyServices" state={{ activeIndex: 1 }}>
                    <Button
                      onClick={(e) => {
                        if (location.pathname === "/service/MyServices") {
                          setShowCompletionDialog(false);
                        }
                      }}
                      label="Add More Services"
                    ></Button>
                  </NavLink>
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <p>
                    Ready to showcase your services to the world? Click below to
                    publish your first service and start reaching your patients.
                  </p>
                  <NavLink to="/service/MyServices" state={{ activeIndex: 1 }}>
                    <Button
                      onClick={(e) => {
                        if (location.pathname === "/service/MyServices") {
                          setShowCompletionDialog(false);
                        }
                      }}
                      label="Publish Service"
                    ></Button>
                  </NavLink>
                </div>
              )}
            </div>
          ),
        },
      ];

      const orderingChecklistItems = [
        {
          label: "Provider Verfication",
          completed: checklistData.verifiedProvider,
          body: (
            <div className="flex flex-col items-start gap-4">
              <p>
                To place an order, your practice must have at least one verified
                provider.
              </p>
              {VerifyProvider === "NULL" && (
                <NavLink to="/ordering/settings/verifyProvider">
                  <Button
                    outlined
                    label="Verify Yourself As A Provider"
                  ></Button>
                </NavLink>
              )}
            </div>
          ),
        },
        {
          label: "Practice Approval",
          completed: checklistData.practiceApproval,
          body: (
            <div>
              {checklistData.practiceApproval ? (
                <p>
                  Congratulations! Your practice has been approved. You're all
                  set to start placing orders!
                </p>
              ) : (
                <p>
                  Your practice is currently under review. Once approved, you'll
                  be able to order services. Thanks for your patience!
                </p>
              )}
            </div>
          ),
        },
      ];

      if (entityType == 2 && userRoles === "Admin") {
        const completeIdentityTemplate = {
          label: "Complete Your Identity",
          completed: checklistData.individualDataCompleted,
          body: (
            <div className="flex flex-col items-start justify-start gap-4">
              {checklistData.individualDataCompleted ? (
                <p>Congratulations! You completed your basic practice setup.</p>
              ) : (
                <div className="flex flex-col gap-4">
                  <p>
                    Looks like you haven't filled in the identity and selfie
                    data for your practice yet. Please fill them to ensure your
                    practice can undergo admin review.
                  </p>
                  <NavLink
                    to={
                      view === "render"
                        ? "/rendering/settings/individualSetting"
                        : "/ordering/settings/individualSetting"
                    }
                  >
                    <Button outlined label="Complete identity" />
                  </NavLink>
                </div>
              )}
            </div>
          ),
        };
        orderingChecklistItems.unshift(completeIdentityTemplate);
        renderingChecklistItems.unshift(completeIdentityTemplate);
      }

      setChecklistItems(
        view === "order" ? orderingChecklistItems : renderingChecklistItems,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const checklistHeaderTemplate = (title, completed) => {
    return (
      <div className="flex items-center">
        {completed ? (
          <>
            <i className="pi pi-check-square mr-2 text-lg font-bold text-light-purple"></i>
            <span className="text-gray-500">{title}</span>
          </>
        ) : (
          <>
            <i className="pi pi-stop mr-2 text-lg font-bold"></i>
            <span className="text-gray-700">{title}</span>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <Dialog
        header="Complete your profile"
        blockScroll
        draggable={false}
        visible={showCompletionDialog}
        showHeader={false}
        contentStyle={{ padding: "0", borderRadius: "1rem" }}
        className="h-[40rem] w-11/12 max-w-5xl lg:w-[60vw]"
      >
        <div className="grid h-full grid-cols-12">
          <div className="hidden h-full flex-col items-center justify-center gap-4 bg-slate-50 py-20 md:col-span-5 md:flex">
            <div className="h-36 w-36 overflow-hidden rounded-full border border-slate-200 shadow-md">
              {currentEntity?.imageUrl ? (
                <img
                  src={currentEntity?.imageUrl}
                  alt="entityImage"
                  className="h-full w-full object-cover"
                ></img>
              ) : (
                <Avatar
                  label={currentEntity?.name && currentEntity?.name[0]}
                  shape="circle"
                  className="h-full w-full bg-blue-500 text-4xl text-white"
                />
              )}
            </div>
            <h2 className="text-lg font-semibold">{currentEntity?.name}</h2>
            <div className="text-center">
              <p className="text-lg font-semibold">You're Almost Done!</p>
              <div className="flex items-center gap-3">
                <ProgressBar
                  showValue={false}
                  value={practiceCompletion}
                  className="h-2 w-full"
                ></ProgressBar>
                <span className="text-sm font-bold text-light-purple">
                  {practiceCompletion}%
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-12 flex flex-col md:col-span-7">
            <div className="flex flex-col gap-4 border-b border-slate-200 p-8 pb-0">
              <div className="flex items-center justify-between">
                <h2 className="text-center font-philosopher text-2xl font-semibold text-dark-purple">
                  Almost There!
                </h2>
              </div>
              <p>
                <strong>Your profile is almost ready!</strong>
                <br /> Take a moment to review these steps to ensure your
                profile is fully prepared.
              </p>
              <div className="h-96 overflow-y-auto p-4">
                <Accordion>
                  {checklistItems?.map((item) => {
                    return (
                      <AccordionTab
                        key={item.label}
                        headerTemplate={checklistHeaderTemplate(
                          item.label,
                          item.completed,
                        )}
                      >
                        {item.body}
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              </div>
            </div>
            <div className="flex flex-grow items-center justify-end px-6">
              <Button
                onClick={() => {
                  setShowCompletionDialog(false);
                  watch("view") === "render"
                    ? navigate(`/Dashboard/`, {
                        replace: true,
                      })
                    : navigate("/ordering/FindServices", { replace: true });
                }}
                label="Continue"
                severity="Primary"
                rounded
              />
            </div>
          </div>
        </div>
      </Dialog>
      <ConfirmDialog draggable={false} />
      <Dialog
        visible={showDialogCreatePin}
        modal
        draggable={false}
        style={{ width: "40rem" }}
        headerStyle={{ paddingBottom: "0px" }}
        onHide={() => {
          if (!showDialogCreatePin) return;
          setShowDialogCreatePin(false);
        }}
      >
        <DialogCreatePin
          isLoading={isLoadingDialogPin}
          setIsLoading={setIsLoadingDialogPin}
          showDialogCreatePin={showDialogCreatePin}
          setShowDialogCreatePin={setShowDialogCreatePin}
          onComplete={onPinCreationComplete}
        />
      </Dialog>

      <div className="flex h-full items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex max-w-xl flex-col gap-5 rounded-lg border border-dark-purple bg-white p-10 text-center">
            <h1 className="font-philosopher text-2xl font-extrabold text-dark-purple">
              Welcome! Let's Set Up Your Default View.
            </h1>
            <p className="max-w-md self-center text-gray-600">
              Choose your preferred default view for your account. <br /> Please
              select one of the following options
            </p>
            <div className="grid grid-cols-2 gap-5">
              <Controller
                name="view"
                control={control}
                render={({ field }) => (
                  <>
                    <div
                      onClick={() => {
                        setValue("view", "render");
                      }}
                      className={`col-span-1 cursor-pointer rounded-lg border p-4 hover:bg-slate-100 hover:shadow-md ${watch("view") === "render" ? "border-dark-purple bg-slate-50 shadow-md" : "border-slate-300"}`}
                    >
                      <div className="flex gap-3">
                        <RadioButton
                          inputId="render"
                          {...field}
                          inputRef={field.ref}
                          value={"render"}
                          checked={field.value === "render"}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                        <label
                          htmlFor="render"
                          className="font-semibold text-gray-800"
                        >
                          Render Services
                        </label>
                      </div>
                      <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
                        Select this option if you primarily render services and
                        want to focus on managing your practice offerings.
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setValue("view", "order");
                      }}
                      className={`col-span-1 cursor-pointer rounded-lg border p-4 hover:bg-slate-100 hover:shadow-md ${watch("view") === "order" ? "border-dark-purple bg-slate-50 shadow-md" : "border-slate-300"}`}
                    >
                      <div className="flex gap-3">
                        <RadioButton
                          inputId="order"
                          {...field}
                          inputRef={field.ref}
                          value={"order"}
                          checked={field.value === "order"}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                        <label
                          htmlFor="order"
                          className="font-semibold text-gray-800"
                        >
                          Order Services
                        </label>
                      </div>
                      <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
                        Choose this option if you mainly order services and want
                        to focus on browsing and placing orders.
                      </p>
                    </div>
                  </>
                )}
              />
            </div>
            <div className="flex justify-end">
              <Button label="Save and Proceed" loading={isSubmitting} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
