import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";

function CompleteVerificationSuccess() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex w-3/6 justify-center rounded-3xl bg-light-text py-16">
        <div className="2xl:w-5/5 Xl:w-4/5 md:w-5/5 rounded-3xl border border-solid border-light-purple p-10 py-8 sm:w-4/5 lg:w-4/5">
          <span className="m-auto mb-5 flex w-max items-center justify-center rounded-full bg-gray-300 p-2">
            <MedicalHistoryIcon />
          </span>
          <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
            Verification has already been completed
          </h4>

          <p className="mt-3 text-center font-bold capitalize text-gray-600">
            Thank you for choosing MedX
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompleteVerificationSuccess;
