import { useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { useOverlayScrollListener } from "primereact/hooks";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import useHandleResize from "../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../components/Table";
import useDelayedInputChange from "../../../../hooks/useDelayedInputChange";
import {
  useDefaultEntity,
  useStoreRenderCounter,
} from "../../../../store/store";
import {
  formatDate,
  RENDERING_DROPDOWN_OPTIONS,
} from "../../../../utils/helpers";
import { PatientInfoTemplate } from "../../../../components/templates/columnBodyTemplates";

export const InboundOrders = () => {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const { entityId } = useTokenData();
  const { setCountRender } = useStoreRenderCounter();
  const { http } = useAxios();
  const toast = useRef(null);
  const { defaultEntity } = useDefaultEntity();
  const menuRight = useRef({});
  const [refetch, setRefetch] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
    selectedValue,
  );
  const { isMobile } = useHandleResize();
  const [rejectReason, setRejectReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rejectDialogVisible, setRejectDialogVisible] = useState(false);
  const [confirmData, setConfirmData] = useState();

  const handleScroll = () => {
    setVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  // reject method
  const handleRejectOrder = async (rowData) => {
    const data = new FormData();
    data.append("Status", 7);
    data.append("CancelationResone", rejectReason);
    try {
      await http.put(`/Orders/UpdateOrder/${rowData.id}`, data);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: `Order Rejected Successfully`,
        life: 3000,
      });
      const res = await http.get(`/Orders/GetHoldedOrdersCount/${entityId}`);
      setCountRender(res.data);
      setRefetch((e) => !refetch);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
    setRejectReason("");
    setRejectDialogVisible(false);
  };
  const dialogRejectOrder = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => {
          setRejectDialogVisible(false);
          setRejectReason("");
        }}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          const message = (
            <>
              <p className="mb-4 font-inter">
                Your max number of orders is set to{" "}
                <span className="font-semibold">
                  {defaultEntity?.maxOrdersQuantity || "unlimited"}
                </span>{" "}
                if you want to change it,{" "}
                <Link
                  className="ml-1 text-blue-600"
                  to="/rendering/settings/OrderSettings"
                >
                  Click Here
                </Link>
              </p>
              <p>Are you sure you want to reject this order?</p>
            </>
          );
          setRejectDialogVisible(false);
          confirmDialog({
            icon: "pi pi-info-circle",
            message: message,
            accept: () => handleRejectOrder(confirmData),
          });
          setRejectReason("");
        }}
        disabled={!rejectReason.trim()}
        autoFocus
      />
    </div>
  );

  const confirm = (rowData) => {
    setRejectDialogVisible(true);
    setConfirmData(rowData);
  };

  // accept method
  const handleAcceptOrder = (rowData, status) => {
    const message = (
      <p>By clicking on 'Confirm' button, this order will be accepted</p>
    );
    confirmDialog({
      message: message,
      header: "Accept This Order?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Confirm",
      rejectLabel: "Cancel",
      accept: async () => {
        const data = new FormData();
        data.append("Status", status);
        try {
          await http.put(`/Orders/UpdateOrder/${rowData.id}`, data);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Accepted Successfully",
            life: 3000,
          });
          const res = await http.get(
            `/Orders/GetHoldedOrdersCount/${entityId}`,
          );
          setCountRender(res.data);
          setRefetch((e) => !refetch);
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              error.message ||
              error.message ||
              "Something went wrong. Please try again later",
            life: 3000,
          });
        }
      },
    });
  };
  // order info navigate
  const orderInfo = async (rowData) => {
    navigate(`/rendering/Orders/${rowData.id}`, {
      state: {
        orderNumber: rowData.orderTrackingNumber,
        serviceName: rowData.serviceName,
        inbound: true,
      },
    });
  };

  const getMenuItems = (rowData) => {
    const items = [];
    items.push({
      label: "Accept",
      icon: "pi pi-check",
      command: () => handleAcceptOrder(rowData, 8),
    });

    items.push({
      label: "Reject",
      icon: "pi pi-times",
      command: () => {
        confirm(rowData, 7);
        setRejectDialogVisible(true);
        setRejectReason("");
      },
    });

    items.push({
      label: "Order Info",
      icon: "pi pi-info-circle",
      command: () => orderInfo(rowData),
    });
    return items;
  };
  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const dropdownValues = {
    options: RENDERING_DROPDOWN_OPTIONS,
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };

  const chargeTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold"> ${rowData.charge} </h4>
    </>
  );
  const serviceTypeTemplate = (rowData) => {
    return (
      <>
        {rowData.serviceType === 1 ? (
          <p className="inline rounded-md bg-orange-100 p-2 text-center text-sm text-orange-600 sm:block sm:p-3 sm:text-base">
            Scheduled Appointment Service
          </p>
        ) : (
          <p
            className="inline rounded-md p-2 text-center text-sm sm:block sm:p-3 sm:text-base"
            style={{ backgroundColor: "#A0D9D9", color: "#2F4F4F" }}
          >
            Walk-In Service
          </p>
        )}
      </>
    );
  };

  const cardTemplate = (rowData) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{rowData.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(rowData.orderedDate)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="font-semibold">{rowData.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{rowData.charge}</span>
          </p>
        </div>
        <p className="font-semibold"> {serviceTypeTemplate(rowData)}</p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordered By:&nbsp;
          </span>
          {rowData.orderedBy}
        </p>
        <div className="flex justify-between gap-2">
          <p className="font-semibold">
            <i className="pi pi-user" />
            <span className="font-semibold text-slate-500">
              &nbsp;Patient Info:&nbsp;
            </span>
            <PatientInfoTemplate rowData={rowData} />
          </p>
          {actionTemplate(rowData)}
        </div>
      </>
    );
  };
  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Search By</h3>
        <div className="flex flex-col gap-2">
          {dropdownValues.options.map((option) => {
            return (
              <div key={option.name} className="align-items-center flex">
                <RadioButton
                  inputId={option.name}
                  name="name"
                  value={option.value}
                  onChange={(e) => dropdownValues?.setSelectedValue(e.value)}
                  checked={dropdownValues.selectedValue === option.value}
                />
                <label htmlFor={option.name} className="ml-2 capitalize">
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        blockScroll
        draggable={false}
        header="Please provide a reason for your decision."
        visible={rejectDialogVisible}
        style={{ minWidth: "50vw" }}
        onHide={() => setRejectDialogVisible(false)}
        footer={dialogRejectOrder}
      >
        <p>
          <InputTextarea
            value={rejectReason}
            onChange={(e) => {
              setRejectReason(e.target.value);
              setErrorMessage("");
            }}
            className="w-full"
          />
        </p>
        {errorMessage && <span className="text-red-500">{errorMessage}</span>}
      </Dialog>
      <ReusableDataList
        dropdownValues={dropdownValues}
        title={"List of orders"}
        sendSearchData={handleInputChange}
        searchValue={searchValue}
        dataLink={`/Orders/GetOrders/${entityId}?Filters=${
          searchValue ? selectedValue : ""
        }${searchValue}%2Cstatus==5`}
        columns={[
          {
            name: "Order Number",
            value: "orderTrackingNumber",
            style: { width: "13%" },
          },
          {
            name: "Service Name",
            value: "serviceName",
            style: { width: "15%" },
          },
          {
            name: "Service Type",
            value: "serviceType",
            template: serviceTypeTemplate,
            style: { width: "15%" },
          },
          {
            name: "Charge",
            value: "charge",
            template: chargeTemplate,
            style: { width: "15%" },
          },
          { name: "Ordered By", value: "orderedBy", style: { width: "15%" } },
          { name: "Date", value: "orderedDate", style: { width: "15%" } },
          {
            name: "Patient Info",
            value: "patientName",
            template: (rowData) => <PatientInfoTemplate rowData={rowData} />,
            style: { width: "15%" },
          },
        ]}
        actionTemplates={
          isMobile
            ? [{ template: cardTemplate }]
            : [{ template: actionTemplate, header: "Actions" }]
        }
        filterTemplate={filterTemplate}
        refetch={refetch}
      ></ReusableDataList>
    </>
  );
};
