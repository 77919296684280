import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { NotifsForm } from "../../../components/forms";
import {
  ReusableCardList,
  ReusableDataList,
  ReusableFilter,
} from "../../../components/Table";
import { mapUserNotifsWithDetails } from "../../../utils/notifsUtils";
import NotificationsSkeleton from "../../../components/skeletons/NotificationsSkeleton";
import {
  EmailOwnerTemplate,
  LastCertifiedTemplate,
} from "../../../components/templates/columnBodyTemplates";
import useDelayedInputChange from "../../../hooks/useDelayedInputChange";
import { FilterMatchMode } from "primereact/api";
import useHandleResize from "../../../hooks/useHandleResize";

function PracticeNotifs() {
  const { entityId } = useTokenData();
  const toast = useRef(null);
  const [showNotifsDialog, setShowNotifsDialog] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [practiceNotifs, setPracticeNotifs] = useState(null);
  const { http } = useAxios();
  const [refetch, setRefetch] = useState();
  const [data, setData] = useState();
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );

  const columns = [
    { value: "name", name: "Name", style: { width: "20%" } },
    { value: "role", name: "Role" },
  ];

  useEffect(() => {
    currentMember && getPracticeSpecificNotifs(currentMember);
  }, [currentMember]);

  const getPracticeSpecificNotifs = async (currentMember) => {
    try {
      const res = await http.get(
        `/EntityUser/GetUserPracticeNotifications/${currentMember.entityUserId}`,
      );
      const notifications = mapUserNotifsWithDetails(res.data.notifications);
      const isOptedOut = res.data.isOptedOut;
      setPracticeNotifs({ notifications, isOptedOut });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await http.get(`/Entities/GetEntityStaff/${entityId}`);

      const mappedUsers = data.map((user) => {
        return {
          key: user.applicationUserId,
          name: user.name,
          role: user.role,
        };
      });

      setData(mappedUsers);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onPracticeNotifsSubmit = async (formData) => {
    try {
      const res = await http.put(
        `/EntityUser/UpdateUserPracticeNotifications/${currentMember.entityUserId}`,
        formData,
      );
      setShowNotifsDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          error?.response?.data?.message || "An unexpected error occurred",
        life: 3000,
      });
    } finally {
      setCurrentMember(null);
    }
  };

  const onCancel = () => {
    setShowNotifsDialog(false);
    setPracticeNotifs(null);
    setCurrentMember(null);
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <div className="grid grid-cols-2 items-center">
          <div>
            {rowData.name} {rowData.role}
          </div>
          <div className="grid justify-end">
            <Button
              icon="pi pi-cog"
              text
              onClick={() => {
                setPracticeNotifs(null);
                setCurrentMember(rowData);
                console.log(rowData);

                setShowNotifsDialog(true);
              }}
            />
          </div>
        </div>
      </>
    );
  };
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    console.log(value);

    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const { isMobile } = useHandleResize();
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={showNotifsDialog}
        onHide={() => {
          setShowNotifsDialog(false);
          setCurrentMember(null);
        }}
        blockScroll
        dismissableMask={false}
        draggable={false}
        className="w-11/12 max-w-4xl"
        header={`Manage ${currentMember?.name ? currentMember?.name : "Member"}'s Notifications`}
      >
        <>
          {practiceNotifs?.notifications ? (
            <NotifsForm
              notifs={practiceNotifs}
              onSubmit={onPracticeNotifsSubmit}
              onCancel={onCancel}
            />
          ) : (
            <NotificationsSkeleton />
          )}
        </>
      </Dialog>
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <ReusableDataList
            title={"Manage Practice Members Notifications"}
            fromFront={true}
            tableData={data}
            dataLink={`/Entities/GetEntityStaff/${entityId}`}
            columns={columns}
            searchTitle="search by name"
            cardFilters={["name"]}
            searchValue={searchValue}
            sendSearchData={handleInputChange}
            onGlobalFilterChange={onGlobalFilterChange}
            globalFilterValue={globalFilterValue}
            actionTemplates={
              isMobile
                ? [{ template: actionTemplate }]
                : [
                    {
                      template: EmailOwnerTemplate,
                      header: "Email",
                      style: { width: "20%" },
                    },
                    {
                      template: LastCertifiedTemplate,
                      header: "Last Certified Date",
                      style: { width: "20%" },
                    },
                    {
                      template: actionTemplate,
                      header: "Actions",
                      style: { width: "20%" },
                    },
                  ]
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PracticeNotifs;
