import { Helmet } from "react-helmet";

const GeneralMetaHeaders = ({ children }) => {
  return (
    <Helmet>
      <meta name="robots" content="noindex, nofollow, noarchive, nosnippet" />
      <meta
        name="googlebot"
        content="noindex, nofollow, noarchive, nosnippet"
      />
      {children}
    </Helmet>
  );
};

export default GeneralMetaHeaders;
