import { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { GeneralMetaHeaders } from "../../../meta";
import { trimValidation } from "../../../utils/helpers";
import { GENERAL_REQUEST_HEADERS } from "../../../constants/constants";
import {
  useAuthStore,
  useRefreshTokenStore,
  useTokenStore,
} from "../../../store/store";

function AdminLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    email: "",
    password: "",
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);
  const { setUserLoginId } = useAuthStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setToken } = useTokenStore();
  const [errorMessage, setErrorMessage] = useState(null);
  // handle password eye
  const [passwordEye, setPasswordEye] = useState(false);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        "/Authentication/SystemAdminLogin",
        formData,
        {
          headers: GENERAL_REQUEST_HEADERS,
        },
      );

      const data = response.data;

      if (data.isAuthenticated) {
        setToken(data.token);
        setRefreshToken(data.refreshToken);
        setUser(data);
        setUserLoginId(data.userId);
        setErrorMessage(null);
        navigate(`/admin/ourPractices`, {
          replace: true,
        });
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <GeneralMetaHeaders />
      <div className="flex items-center justify-center">
        <div className="Xl:w-1/3 mt-10 w-4/5 rounded-3xl border border-solid border-light-purple bg-light-text px-5 py-5 md:w-6/12 lg:w-1/3 2xl:w-1/3">
          <h4 className="text-center font-philosopher text-mobileTitle font-extrabold capitalize text-dark-purple sm:text-title">
            Admin Access - MedX
          </h4>
          <div className="mt-8">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label>
                  Email<span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-user" />
                  <InputText
                    placeholder="Email Address"
                    className="w-full pb-2 pt-2"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                  />
                </span>
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>
              <div className="mt-4">
                <label>
                  Password<span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <span className="p-input-icon-left p-input-icon-right w-full">
                  <i className="pi pi-lock" />
                  <InputText
                    placeholder="Password (8 or more characters)"
                    className="w-full pb-2 pt-2"
                    name="password"
                    type={!passwordEye ? "password" : "text"}
                    {...register("password", {
                      required: "Password is required",
                      validate: (value) => trimValidation(value, "Password"),
                    })}
                  />
                  {!passwordEye ? (
                    <i
                      className="pi pi-eye-slash"
                      onClick={handlePasswordClick}
                    />
                  ) : (
                    <i className="pi pi-eye" onClick={handlePasswordClick} />
                  )}
                </span>
                {errors.password && (
                  <p className="mt-1 text-red-500">{errors.password.message}</p>
                )}

                {errorMessage && (
                  <Message
                    severity="error"
                    text={errorMessage + ". Please try again!"}
                    className="mt-9 w-full font-bold"
                  />
                )}
              </div>

              <div className="mt-8 flex flex-col justify-between sm:flex-row">
                <div>
                  <Controller
                    name="checked"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.checked)}
                          className="pb-5"
                        />
                      </>
                    )}
                  />
                  <span> Keep me logged in </span>
                </div>
                <div
                  onClick={() => navigate("/admin/forgetPassword")}
                  className="text-right"
                >
                  <span className="cursor-pointer text-light-purple">
                    Forgot password?
                  </span>
                </div>
              </div>

              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                >
                  {" "}
                  login{" "}
                </Button>
              )}
            </form>
          </div>
          <div className="mt-16 grid grid-cols-1 grid-rows-2 items-center justify-center sm:flex sm:justify-between">
            <span className="mr-3 text-center text-sm text-gray-600 md:text-left">
              © <span id="currentYear">{new Date().getFullYear()}</span>{" "}
              MedX™. All Rights Reserved.
            </span>
            <a href="/legal" target="_blank" className="text-center">
              <span className="text-sm text-gray-600 hover:underline">
                Legal Notices and Terms
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
