import { useEffect, useRef, useState } from "react";

import axios from "axios";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { OTPInput } from "../../components/formElements";
import EmailVerifyIcon from "../../iconComponents/EmailVerifyIcon";
import CountdownTimer from "../../components/helpers/CountdownTimer";

function VerifyEmail() {
  const toast = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastResendTime, setLastResendTime] = useState(0);
  const [twilioStatus, setTwilioStatus] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isConfirmPhoneLoading, setIsConfirmPhoneLoading] = useState(false);
  const [isConfirmEmailLoading, setIsConfirmEmailLoading] = useState(false);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);

  const email = location.state?.email;
  const phoneNumber = location.state?.phoneNumber;
  const redirectUrl = "/verifySuccess";
  const cookieData = Cookies.get("userData");
  const getToken = () => {
    const tokenRegex = /JwToken:'([^']+)/;
    const tokenMatch = cookieData.match(tokenRegex);
    return tokenMatch ? tokenMatch[1] : null;
  };

  useEffect(() => {
    isEmailVerified && checkTwilioStatus();
  }, [isEmailVerified]);

  const checkTwilioStatus = async () => {
    try {
      const response = await axios.get(`/Users/CheckTwilioStatus/`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setTwilioStatus(response.data.status === "true" ? true : false);
      if (response.data.status === "false") {
        navigate(redirectUrl, { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const skipPhoneVerification = () => {
    confirmDialog({
      message:
        "Are you sure you want to proceed without validating your mobile phone number?",
      header: "Mobile Phone Validation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Proceed",
      rejectLabel: "Cancel",
      accept: () => {
        navigate(redirectUrl, { replace: true });
      },
      reject: () => {},
      style: { width: "500px" },
      draggable: false,
    });
  };

  const handleResendEmail = async () => {
    setIsLoading(true);
    try {
      await axios.post("/Authentication/ReSendConfirmationEmail", { email });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Resend Email Successfully",
        life: 4000,
      });
    } catch (error) {
      if (error.response.data.message === "this email already confirmed") {
        setIsEmailVerified(true);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: error.response.data.message,
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message || "Email address is required",
          life: 4000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onEmailOTPComplete = async (otpCode) => {
    setIsConfirmEmailLoading(true);
    try {
      if (otpCode) {
        const res = await axios.get(
          `/Authentication/ConfirmEmail?email=${String(email)}&token=${String(
            otpCode,
          )}`,
        );
        setIsEmailVerified(true);
        if (res.data.includes("/login")) {
          toast.current.show({
            severity: "success",
            summary: "Successfully",
            detail:
              "Your email verified successfully , please try log in again.",
            life: 4000,
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else if (!phoneNumber) {
          navigate(redirectUrl, { replace: true });
          return;
        }
      }
    } catch (error) {
      if (error.response.data === "this email already confirmed") {
        setIsEmailVerified(true);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: error.response.data,
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data,
          life: 4000,
        });
      }
      setReset(true);
    }
    setIsConfirmEmailLoading(false);
  };

  const sendPhoneOTP = async () => {
    setIsSending(true);
    try {
      const response = await axios.post(
        "/Authentication/SendPhoneNumberConfirmationCode",
        {
          phoneNumber: phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      );
      setCanResend(false);
      const currentDate = Date.now();
      setLastResendTime(currentDate);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 4000,
      });
    } finally {
      setIsSending(false);
    }
  };

  const onPhoneOTPComplete = async (otpCode) => {
    setIsConfirmPhoneLoading(true);
    try {
      await axios.post(
        "/Authentication/VerifyPhoneNumber",
        {
          phoneNumber: phoneNumber,
          confirmationCode: otpCode,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      );
      navigate(redirectUrl, { replace: true });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
      setReset(true);
    }
    setIsConfirmPhoneLoading(false);
  };

  const onResendTimeEnd = () => {
    setCanResend(true);
  };

  const getRemainingTime = () => {
    return 120_000 - (Date.now() - lastResendTime);
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <div className="flex h-screen items-center justify-center">
        <div className="w-11/12 rounded-3xl bg-light-text p-10 text-center shadow shadow-slate-300 md:w-9/12 lg:w-9/12 xl:w-7/12 2xl:w-6/12">
          {email ? (
            <>
              {!isEmailVerified && !twilioStatus ? (
                <div className="flex items-end justify-center gap-1 pb-10">
                  <EmailVerifyIcon width="50" height="50" />
                  <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
                    Verify your contact information
                  </h4>
                </div>
              ) : null}
              <div className="flex flex-col">
                {/* Email Verification */}
                {!isEmailVerified && (
                  <div className="flex flex-col gap-4 rounded-lg border border-slate-200 p-5">
                    <p className="font-inter text-xl font-bold capitalize text-dark-purple">
                      Email Verification
                    </p>
                    <div className="flex justify-center">
                      <p className="text-center font-Poppins text-p leading-loose text-normal-text">
                        We’ve sent an email to
                        <span className="text-dark-purple"> {email} </span>{" "}
                        Please check your inbox and click the link in the email
                        to complete verification.
                      </p>
                    </div>
                    <p className="text-center font-Poppins text-p leading-loose text-normal-text">
                      The link in the email will expire in 24 hours.
                    </p>

                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-1 md:flex">
                        <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
                          <strong className="mr-3">OTP</strong>
                          <OTPInput
                            length={6}
                            onComplete={onEmailOTPComplete}
                            reset={reset}
                            setReset={setReset}
                          />
                          <Button
                            loading={isLoading || isConfirmEmailLoading}
                            onClick={handleResendEmail}
                            link
                            disabled={isConfirmEmailLoading}
                            label={
                              isConfirmEmailLoading ? "Verifying..." : "Resend"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Phone Verification */}
                {isEmailVerified && twilioStatus && !showPhoneVerification && (
                  <div className="flex flex-col items-center gap-5 rounded-lg border border-slate-200 p-5">
                    <Message
                      className="w-full"
                      severity="success"
                      text="Email verified successfully"
                    />
                    <p className="max-w-[80%] text-center font-Poppins leading-loose text-normal-text">
                      Your email has been verified. To ensure you receive
                      important notifications, we recommend verifying your phone
                      number as well.
                    </p>
                    <div className="flex justify-end gap-2 self-end">
                      <Button
                        label="Skip"
                        onClick={skipPhoneVerification}
                        link
                      />
                      <Button
                        label="Verify phone number"
                        onClick={() => {
                          setShowPhoneVerification(true);
                          sendPhoneOTP();
                        }}
                      />
                    </div>
                  </div>
                )}

                {showPhoneVerification && twilioStatus && (
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-4 rounded-lg border border-slate-200 p-5">
                      <p className="font-inter text-xl font-bold capitalize text-dark-purple">
                        Phone Verification
                      </p>
                      <div className="flex justify-center">
                        <p className="text-center font-Poppins text-p leading-loose text-normal-text">
                          We’ve sent an SMS to
                          <span className="text-dark-purple">
                            {" "}
                            {phoneNumber}{" "}
                          </span>{" "}
                          Please check the OTP code in your messages.
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
                        <strong>OTP</strong>
                        <OTPInput
                          length={6}
                          onComplete={onPhoneOTPComplete}
                          reset={reset}
                          setReset={setReset}
                        />
                        <Button
                          disabled={!canResend || isConfirmPhoneLoading}
                          label={
                            isConfirmPhoneLoading ? (
                              "Verifying..."
                            ) : canResend ? (
                              "Resend"
                            ) : (
                              <CountdownTimer
                                durationInMilliseconds={getRemainingTime()}
                                onTimeEnd={onResendTimeEnd}
                              />
                            )
                          }
                          link
                          severity="info"
                          loading={isSending || isConfirmPhoneLoading}
                          onClick={sendPhoneOTP}
                        />
                      </div>
                      <div className="mt-4 text-end">
                        <Button
                          label="Verify later"
                          onClick={skipPhoneVerification}
                          severity="info"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : !email && !isEmailVerified ? (
            <div className="flex flex-col items-center gap-4">
              <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
                Oops! You Found a Shortcut!
              </h4>
              <p className="text-center text-gray-700">
                It looks like you're trying to access this page directly.
                Complete a registration first to see the details here.
              </p>
              <Button
                className="capitalize"
                onClick={() => {
                  navigate("/registration", { replace: true });
                }}
                label="Sign Up"
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
