import { useEffect, useRef, useState } from "react";

import { addYears } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { trimValidation } from "../../../utils/helpers";
import useTwilioStatus from "../../../hooks/useTwilioStatus";
import useImpersonating from "../../../hooks/useImpersonating";
import { DocumentUpload } from "../../../components/formElements";
import useRefreshTokenHook from "../../../hooks/useRefreshTokenHook";
import FormSkeleton from "../../../components/skeletons/FormSkeleton";
import ConfirmPhoneNumberDialog from "../components/ConfirmPhoneNumberDialog";
import {
  useRefreshTokenStore,
  useRegisterFormStore,
  useTokenStore,
} from "../../../store/store";

const genderOptions = [
  { name: "Male", value: 1 },
  { name: "Female", value: 2 },
];

function ProfileSettings() {
  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings/`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Edit Profile",
      template: () => (
        <Link
          to={`/${location.pathname.split("/")[1]}/settings/ProfileSettings`}
        >
          Edit Profile
        </Link>
      ),
    },
  ];

  //default value of registration form
  const defaultValues = {
    firstName: null || "",
    middleName: null || "",
    lastName: null || "",
    suffix: null || "",
    email: null || "",
    phoneNumber: null || "",
    dateOfBirth: null || "",
    gender: null || "",
    userImageFile: {
      FileDetails: null || "",
      FileType: 1,
      FilePropertyName: 3,
    },
  };

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const msg = useRef();
  const toast = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { http, logOut } = useAxios();
  const { setToken } = useTokenStore();
  const [visible, setVisible] = useState();
  const { refresh } = useRefreshTokenHook();
  const { twilioStatus } = useTwilioStatus();
  const [sentData, setSentData] = useState();
  const VerifyPhoneNumber = watch("phoneNumber");
  const [isLoading, setIsLoading] = useState(false);
  const [savedEmail, setSavedEmail] = useState(null);
  const { setRefreshToken } = useRefreshTokenStore();

  const [selectedImage, setSelectedImage] = useState(null);
  const { isSystemAdminImpersonating } = useImpersonating();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isEntityDataLoading, setIsEntityDataLoading] = useState(true);
  const setFormData = useRegisterFormStore((state) => state.setFormData);
  const {
    userId,
    entityId,
    IsExternalLogin,
    impersonatedBy,
    IsPhoneVerified,
    phoneNumber: tokenPhoneNumber,
  } = useTokenData();

  const getEntityData = async () => {
    setIsEntityDataLoading(true);
    try {
      const res = await http.get(`/Users/GetUserById/${userId}`);
      setSavedEmail(res.data.email);
      setSelectedImage(res.data.userImage);
      reset({
        ...res.data,
        dateOfBirth: new Date(res.data.dateOfBirth),
        userImageFile: {
          FileDetails: null,
          FileType: 1,
          FilePropertyName: 3,
        },
      });
    } catch (error) {
      console.error(error);
    }
    setIsEntityDataLoading(false);
  };

  useEffect(() => {
    getEntityData();
  }, []);

  const validateName = (value) => {
    const regex = /^[A-Za-z]+$/;
    if (value.includes(" ")) {
      return "Name should not contain spaces";
    }
    if (!value.match(regex)) {
      return "Name should only contain letters";
    }
    return true;
  };
  const onSubmit = async (formData) => {
    if (
      VerifyPhoneNumber !== tokenPhoneNumber &&
      IsPhoneVerified === "True" &&
      twilioStatus
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please verify your phone number.",
        life: 4000,
      });
      return;
    }

    setIsLoading(true);
    // method request register
    const data = new FormData();
    data.append("FirstName", formData.firstName || "");
    data.append("MiddleName", formData.middleName || "");
    data.append("LastName", formData.lastName || "");
    data.append("Suffix", formData.suffix || "");
    data.append("Email", formData.email || "");
    data.append("PhoneNumber", formData.phoneNumber || "");
    data.append(
      "DateOfBirth",
      formData.dateOfBirth.toLocaleDateString("en-CA").split("T")[0] || "",
    );
    data.append("Gender", formData.gender || "");
    data.append(
      "UserImageFile.FileDetails",
      formData.userImageFile.FileDetails || "",
    );
    data.append(
      "UserImageFile.FileType",
      formData.userImageFile.FileType || "",
    );
    data.append(
      "UserImageFile.FilePropertyName",
      formData.userImageFile.FilePropertyName || "",
    );

    try {
      const response = await http.put(`/Users/EditUser/${userId}`, data);
      setFormData(response.data);
      const currentRefreshToken = JSON.parse(
        localStorage.getItem("refreshToken"),
      ).state.refreshToken;
      const refreshTokenResponse = await refresh.post(
        `/Authentication/RefreshToken/${entityId}`,
        {
          refreshToken: currentRefreshToken,
          impersonatedBy,
        },
      );
      setToken(refreshTokenResponse.data.token);
      setRefreshToken(refreshTokenResponse.data.refreshToken);

      if (formData.email.trim() === savedEmail.trim()) {
        navigate(-1, { replace: true });
      } else {
        if (!isSystemAdminImpersonating) {
          setTimeout(() => {
            logOut();
          }, 2000);
        } else {
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb
        model={breadCrumbsItems}
        className="border-0 bg-transparent pb-7 font-semibold"
        pt={{
          separator: { className: "first:hidden" },
          menuitem: { className: "last:text-purple-700" },
        }}
      />
      <Dialog
        visible={dialogVisible}
        className="max-w-md"
        headerClassName="!p-0"
        pt={{ closeButton: { style: { right: "12px", top: "10px" } } }}
        onHide={() => setDialogVisible(false)}
      >
        <ConfirmPhoneNumberDialog
          sentData={sentData}
          setSentData={setSentData}
          dialogVisible={dialogVisible}
          setDialogVisible={setDialogVisible}
          VerifyPhoneNumber={VerifyPhoneNumber}
        />
      </Dialog>
      <Dialog
        blockScroll
        draggable={false}
        header="Schedule Date"
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        className="min-w-[30rem]"
      />

      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <Messages ref={msg} />
          <div className="mb-8">
            <h4 className="mb-4 text-center text-xl font-bold capitalize sm:text-left sm:text-4xl">
              Edit profile
            </h4>
            <p className="mb-3 text-center text-gray-700 sm:text-left">
              Update your profile information presented.
            </p>
          </div>
          {isEntityDataLoading ? (
            <FormSkeleton />
          ) : (
            <div className="form-container mb-3 w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container mb-3">
                  <div className="mb-8">
                    <div className="flex flex-col items-center border-b-2 pb-9 sm:items-start">
                      <p className="mb-2 text-lg font-semibold text-gray-500">
                        Profile Photo
                      </p>
                      <div className="mt-2 flex items-center sm:w-1/2 md:w-1/3">
                        <DocumentUpload
                          name="profilePhoto"
                          fileType="image"
                          maxFileSizeInMB={10}
                          previewImage={selectedImage}
                          onUpload={(file) => {
                            setValue("userImageFile.FileDetails", file);
                            setValue("userImageFile.FileType", 1);
                            setValue("userImageFile.FilePropertyName", 3);
                          }}
                          onClear={() => {
                            setValue("userImageFile.FileDetails", "");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <label className="mb-2 block capitalize text-gray-500">
                    first name
                    <span className="ml-1 font-bold text-red-500">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <InputText
                        name={field.name}
                        placeholder="First Name"
                        className="w-full pb-2 pt-2"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                    rules={{
                      required: "First name is required",
                      validate: validateName,
                    }}
                  />
                  {errors.firstName && (
                    <p className="mt-1 text-red-500">
                      {errors.firstName.message}
                    </p>
                  )}
                  <div className="mt-4 grid gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5">
                    <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                      <label className="mb-2 block capitalize text-gray-500">
                        Middle name
                      </label>
                      <span className=" ">
                        <InputText
                          placeholder="Middle Name"
                          name="middleName"
                          {...register("middleName", {
                            pattern: {
                              value: /^[A-Za-z]+$/,
                              message: "Only letters are allowed, no spaces",
                            },
                          })}
                          className="w-full pb-2 pt-2"
                        />
                        {errors.middleName && (
                          <p className="mt-1 text-red-500">
                            {errors.middleName.message}
                          </p>
                        )}
                      </span>
                    </div>
                    <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                      <label className="mb-2 block capitalize text-gray-500">
                        Last name
                        <span className="ml-1 font-bold text-red-500">*</span>
                      </label>
                      <span className="">
                        <Controller
                          control={control}
                          name="lastName"
                          render={({ field }) => (
                            <InputText
                              placeholder="Last Name"
                              name={field.name}
                              className="w-full pb-2 pt-2"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            />
                          )}
                          rules={{
                            required: "Last name is required",
                            validate: validateName,
                          }}
                        />
                      </span>
                      {errors.lastName && (
                        <p className="mt-1 text-red-500">
                          {errors.lastName.message}
                        </p>
                      )}
                    </div>
                    <div className="lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                      <label className="mb-2 block capitalize text-gray-500">
                        suffix
                      </label>
                      <span>
                        <InputText
                          placeholder="Suffix"
                          name="suffix"
                          {...register("suffix", {
                            pattern: {
                              value: /^[A-Za-z.]+$/,
                              message: "Only letters are allowed, no spaces",
                            },
                          })}
                          className="w-full pb-2 pt-2"
                        />
                        {errors.suffix && (
                          <p className="mt-1 text-red-500">
                            {errors.suffix.message}
                          </p>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pt-4">
                    <label className="mb-2 block text-gray-500">
                      Date of Birth
                      <span className="ml-1 font-bold text-red-500">*</span>
                    </label>
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      rules={{
                        required: "Date of Birth is required",
                        validate: {
                          validDate: (value) => {
                            const currentDate = new Date();
                            const minDate = addYears(currentDate, -18);
                            return (
                              new Date(value) <= minDate ||
                              "You must be at least 18 years old."
                            );
                          },
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <Calendar
                            value={new Date(field.value)}
                            showIcon
                            showOnFocus={false}
                            pt={{
                              dropdownButton: {
                                root: {
                                  style: {
                                    backgroundColor: "#4F46E5",
                                  },
                                },
                              },
                            }}
                            onChange={(e) => {
                              e.value && field.onChange(e.value);
                            }}
                            mask="99/99/9999"
                            dateFormat="mm/dd/yy"
                            placeholder="MM/DD/YYYY"
                            className="w-full"
                          />
                        </>
                      )}
                    />
                    {errors.dateOfBirth && (
                      <p className="mt-1 text-red-500">
                        {errors.dateOfBirth.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-4">
                    <label className="mb-2 block capitalize text-gray-500">
                      gender
                      <span className="ml-1 font-bold text-red-500">*</span>
                    </label>
                    <Controller
                      name="gender"
                      control={control}
                      rules={{
                        required: "Gender is required",
                      }}
                      render={({ field }) => (
                        <Dropdown
                          id="gender"
                          {...field}
                          options={genderOptions}
                          value={field.value}
                          optionLabel="name"
                          placeholder="Select a gender"
                          className="md:w-14rem w-full"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                    {errors.gender && (
                      <p className="mt-1 text-red-500">
                        {errors.gender.message}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="mb-2 block capitalize text-gray-500">
                      Mobile Phone Number
                      <span className="ml-1 font-bold text-red-500">*</span>
                    </label>
                    <span className="border-1 w-full">
                      <div className="border-1 grid grid-cols-12 items-center justify-center rounded-md border border-solid p-1">
                        <Controller
                          control={control}
                          name="phoneNumber"
                          render={({ field }) => (
                            <InputMask
                              {...field}
                              id="phoneInput"
                              mask="999-999-9999"
                              disabled={
                                !twilioStatus || isSystemAdminImpersonating
                              }
                              placeholder="Mobile Phone Number"
                              onClick={(e) => {
                                if (e.target.value === "___-___-____") {
                                  e.target.setSelectionRange(0, 0);
                                }
                              }}
                              className="custom-button-verify col-span-12 h-full border-none pb-2 pt-2 outline-none sm:col-span-6"
                            />
                          )}
                          rules={{
                            required: "Phone number is required",
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/,
                              message:
                                "The phone number that has been entered is incorrect",
                            },
                          }}
                        />
                        <div className="col-span-12 flex justify-center sm:col-span-6 sm:justify-end">
                          {VerifyPhoneNumber?.match(/^\d{3}-\d{3}-\d{4}$/) &&
                          twilioStatus &&
                          !isSystemAdminImpersonating ? (
                            VerifyPhoneNumber !== tokenPhoneNumber ||
                            IsPhoneVerified === "False" ? (
                              <Button
                                icon="pi pi-verified"
                                label="Verify Your Number"
                                severity="info"
                                type="button"
                                link
                                onClick={() => {
                                  setDialogVisible(true);
                                }}
                                className="animate-fadeIn opacity-0 transition-opacity duration-500 ease-in"
                              />
                            ) : (
                              <div className="h-full px-3">
                                <i className="pi pi-check-circle pr-1 text-green-600"></i>
                                <span className="font-semibold capitalize text-green-600">
                                  your phone number is verified
                                </span>
                              </div>
                            )
                          ) : null}
                        </div>
                      </div>
                    </span>
                    {errors.phoneNumber && (
                      <p className="mt-1 text-red-500">
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="mb-2 block capitalize text-gray-500">
                      Email Address
                      <span className="ml-1 font-bold text-red-500">*</span>
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email address is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                        validate: (value) =>
                          trimValidation(value, "Email Address"),
                      }}
                      render={({ field }) => (
                        <InputText
                          placeholder="Email Address"
                          {...field}
                          className="w-full pb-2 pt-2"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          disabled={
                            isSystemAdminImpersonating ||
                            IsExternalLogin === "True"
                          }
                        />
                      )}
                    />
                    {errors.email && (
                      <p className="mt-1 text-red-500">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
                  <div className="w-full md:w-auto">
                    <Button
                      onClick={handleCancel}
                      type="button"
                      className="mt-2 w-full justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple md:mt-9 md:w-auto"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="w-full items-end justify-end md:w-auto">
                    {isLoading ? (
                      <div className="text-center">
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
                      >
                        Save Changes
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileSettings;
