function UploadFile() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" clipPath="url(#clip0_3048_22633)">
        <path
          d="M48.5864 15.7276L37.4984 5.75115C34.3428 2.90915 32.7664 1.48675 30.826 0.744751L30.8008 8.39995C30.8008 14.9996 30.8008 18.3008 32.8504 20.3504C34.9 22.4 38.2012 22.4 44.8008 22.4H54.8248C53.8112 20.4288 51.9912 18.7936 48.5864 15.7276Z"
          fill="#9CA3AF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4 55.9994H33.6C44.1588 55.9994 49.4396 55.9994 52.7184 52.7178C56 49.439 56 44.1582 56 33.5994V32.3758C56 29.9314 56 28.0946 55.8796 26.5994H44.534C41.4624 26.5994 38.7464 26.5994 36.5596 26.3054C34.188 25.9862 31.8164 25.2554 29.8816 23.3206C27.944 21.383 27.2132 19.0114 26.894 16.6398C26.6 14.453 26.6 11.7398 26.6 8.66539L26.628 0.73019C26.628 0.49779 26.6476 0.27099 26.684 0.0469896C25.5388 -0.000610466 24.1808 -0.000610352 22.484 -0.000610352C11.8692 -0.000610352 6.5604 -0.000610685 3.2816 3.28099C-3.33786e-07 6.55979 0 11.8406 0 22.3994V33.5994C0 44.1582 -3.33786e-07 49.439 3.2816 52.7178C6.5604 55.9994 11.8412 55.9994 22.4 55.9994ZM19.6364 30.6678C19.2472 30.3028 18.7336 30.0997 18.2 30.0997C17.6664 30.0997 17.1528 30.3028 16.7636 30.6678L11.1636 35.9178C10.7669 36.301 10.5368 36.825 10.5232 37.3764C10.5096 37.9278 10.7135 38.4625 11.0908 38.8648C11.4681 39.2671 11.9886 39.5049 12.5398 39.5266C13.091 39.5484 13.6285 39.3524 14.0364 38.981L16.1 37.049V46.1994C16.1 46.7564 16.3213 47.2905 16.7151 47.6843C17.1089 48.0782 17.6431 48.2994 18.2 48.2994C18.757 48.2994 19.2911 48.0782 19.6849 47.6843C20.0788 47.2905 20.3 46.7564 20.3 46.1994V37.0462L22.3636 38.981C22.7715 39.3524 23.3091 39.5484 23.8602 39.5266C24.4114 39.5049 24.9319 39.2671 25.3092 38.8648C25.6865 38.4625 25.8904 37.9278 25.8768 37.3764C25.8632 36.825 25.6331 36.301 25.2364 35.9178L19.6364 30.6678Z"
          fill="#9CA3AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3048_22633">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UploadFile;
