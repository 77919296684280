import useTokenData from "../../../hooks/useTokenData";

export const EmailOwnerTemplate = ({ email, isOwner, applicationUserId }) => {
  const { userId } = useTokenData();

  const getStatusText = () => {
    if (isOwner) return "owner";
    if (applicationUserId === userId) return "You";
    return null;
  };

  const statusText = getStatusText();

  return (
    <>
      <p className="text-p">{email}</p>
      {statusText && (
        <p className="text-mobileTitle">
          <span className="text-sm font-bold capitalize text-light-purple sm:block sm:text-base">
            {statusText}
          </span>
        </p>
      )}
    </>
  );
};
