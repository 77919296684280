import { useRef, useState } from "react";

import QRCode from "react-qr-code";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

import useAxios from "../../hooks/useAxios";
import useTwilioStatus from "../../hooks/useTwilioStatus";

function CaptureLinkDialog({ captureLink }) {
  const [isCopied, setIsCopied] = useState(false);

  const toast = useRef();
  const { http } = useAxios();
  const url = new URL(captureLink);
  const params = new URLSearchParams(url.search);
  const sessionId = params.get("id");
  const { twilioStatus } = useTwilioStatus();
  const {
    control: controlPhone,
    formState: { errors: errorsPhone, isSubmitting: isSubmittingPhone },
    handleSubmit: handleSubmitPhone,
  } = useForm({
    mode: "onChange",
    defaultValues: { sendTo: 1, sessionId: sessionId, phoneNumber: "" },
  });

  const {
    control: controlEmail,
    formState: { errors: errorsEmail, isSubmitting: isSubmittingEmail },
    handleSubmit: handleSubmitEmail,
  } = useForm({
    mode: "onChange",
    defaultValues: { sendTo: 3, sessionId: sessionId, email: "" },
  });
  const onSubmitPhone = async (formData, e) => {
    try {
      const response = await http.post(
        "/Identifications/SendCaptureIdentityViaMobileLink",
        formData,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
  };

  const onSubmitEmail = async (formData) => {
    try {
      const response = await http.post(
        "/Identifications/SendCaptureIdentityViaMobileLink",
        formData,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
  };
  return (
    <div className="grid w-full grid-cols-11">
      <Toast ref={toast} />

      {captureLink && (
        <>
          <div className="col-span-5 flex flex-col gap-2">
            {/* sms  */}
            {twilioStatus && (
              <div className="rounded-lg border border-slate-50 p-3">
                <form
                  key={1}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitPhone(onSubmitPhone)(e);
                    e.stopPropagation();
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <div>
                      <label className="font-semibold">Phone Number</label>
                      <Controller
                        name="phoneNumber"
                        control={controlPhone}
                        rules={{
                          required: "Phone Number is required",
                          pattern: {
                            value: /^\(\d{3}\) \d{3}-\d{4}$/,
                            message: "Invalid phone format",
                          },
                        }}
                        render={({ field }) => (
                          <InputMask
                            {...field}
                            autoClear={false}
                            className="w-full"
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                          />
                        )}
                      />
                      {errorsPhone.phoneNumber?.message && (
                        <span className="text-sm text-red-500">
                          {errorsPhone.phoneNumber?.message}
                        </span>
                      )}
                    </div>
                    <div className="flex w-full justify-end">
                      <Button
                        loading={isSubmittingPhone}
                        label="Send SMS"
                        icon="pi pi-send"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}

            {/* email  */}
            <div className="rounded-lg border border-slate-50 p-3">
              <form
                key={2}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmitEmail(onSubmitEmail)(e);
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-col gap-2">
                  <div>
                    <label className="font-semibold">Email</label>
                    <Controller
                      name="email"
                      control={controlEmail}
                      render={({ field }) => (
                        <InputText
                          type="email"
                          placeholder="Enter your email"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                          ref={field.ref}
                        />
                      )}
                      rules={{
                        required: "Email address is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                    />
                    {errorsEmail.email?.message && (
                      <span className="text-sm text-red-500">
                        {errorsEmail.email?.message}
                      </span>
                    )}
                  </div>
                  <div className="flex w-full justify-end">
                    <Button
                      loading={isSubmittingEmail}
                      label="Send Email"
                      icon="pi pi-envelope"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="rounded-lg border border-slate-50 p-3">
              <div className="grid grid-cols-12 rounded-lg border">
                <p className="col-span-10 w-full overflow-hidden text-ellipsis border-r p-4 text-gray-600">
                  {captureLink}
                </p>
                <div
                  className="p4 col-span-2 flex cursor-pointer justify-center bg-slate-50 p-4 hover:bg-slate-100"
                  onClick={async () => {
                    if (isCopied) return;
                    // ** This feature is available only in secure contexts (HTTPS)
                    await navigator.clipboard.writeText(captureLink);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 2500);
                  }}
                >
                  <div className="flex items-center gap-1 px-3">
                    <i
                      className={`pi pi-${isCopied ? "check" : "copy"} `}
                      style={{ fontSize: "1.125rem" }}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="text-xs italic text-gray-500">
                This link will expire in 30 minutes
              </div>
            </div>
          </div>
          <Divider className="col-span-1" layout="vertical">
            or
          </Divider>
          <div className="col-span-5 flex w-full items-center justify-center">
            <div className="flex flex-col gap-3">
              <p>
                Open the Camera App on your phone and scan the QR code below to
                continue
              </p>
              <div className="p-14">
                <div className="w-full max-w-xs bg-white"></div>
                <QRCode
                  value={captureLink}
                  level="Q"
                  className="h-auto w-full"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CaptureLinkDialog;
