import { useTokenStore } from "../store/store";

function useTokenData() {
  const { token } = useTokenStore();
  let base64Url;
  let base64;
  let jsonPayload;
  let deCodedJWT;
  if (token) {
    base64Url = token.split(".")[1];
    base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    deCodedJWT = JSON.parse(jsonPayload);
  }
  return deCodedJWT
    ? {
        entityId: deCodedJWT.EntityId,
        userId:
          deCodedJWT[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ],
        userRoles:
          deCodedJWT[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ],
        userName:
          deCodedJWT[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ],

        email:
          deCodedJWT[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
          ],
        SystemAdministrator:
          deCodedJWT[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ],
        individualPracticeOwner: deCodedJWT.IndividualPracticeOwner,
        isOwner: deCodedJWT.IsOwner,
        VerifyProvider: deCodedJWT.VerifyProvider,
        firstName: deCodedJWT.FirstName,
        lastName: deCodedJWT.LastName,
        IdentifierId: deCodedJWT.IdentifireId,
        entityType: deCodedJWT.EntityType,
        IsEntityApproved: deCodedJWT.IsEntityApproved,
        IsHidden: deCodedJWT.IsHidden,
        exp: deCodedJWT.exp,
        IsExternalLogin: deCodedJWT.IsExternalLogin,
        isCompletedRegister: deCodedJWT.IsCompletedRegister,
        UserJourneyStatus: deCodedJWT.UserJurneyStatus,
        HasPin: deCodedJWT.HasPin,
        impersonatedBy: deCodedJWT.ImpersonatedBy,
        IsPhoneVerified: deCodedJWT.IsPhoneVerified,
        phoneNumber: deCodedJWT.PhoneNumber,
      }
    : {
        entityId: null,
        userId: null,
        userRoles: null,
        userName: null,
      };
}

export default useTokenData;
