import { useEffect, useRef, useState } from "react";

import Webcam from "react-webcam";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";

import useAxios from "../../hooks/useAxios";
import { CaptureLinkDialog } from "../dialogs";
import { DocumentUpload } from "../formElements";
import useTokenData from "../../hooks/useTokenData";
import { useCapturedPhoto } from "../../store/store";
import useHandleResize from "../../hooks/useHandleResize";
import CapturedPhotoPreview from "./CapturedPhotoPreview";
import {
  checkCameraAccess,
  compressImage,
  getBase64Src,
} from "../../utils/helpers";

function IdentityVerification({ setValue, verificationData }) {
  const [backImg, setBackImg] = useState();
  const [frontImg, setFrontImg] = useState();
  const [passportImg, setPassportImg] = useState();
  const [sessionId, setSessionId] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [captureLink, setCaptureLink] = useState(null);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [selectedIdType, setSelectedIdType] = useState(null);
  const [showCaptureLinkDialog, setShowCaptureLinkDialog] = useState(false);
  const [capturedViaMobilePhoto, setCapturedViaMobilePhoto] = useState(null);
  const [showCapturedPhotoPreview, setShowCapturedPhotoPreview] =
    useState(false);

  const {
    capturedPhoto: capturedPhotoFromSignalR,
    setCapturedPhoto: setCapturedPhotoFromSignalR,
  } = useCapturedPhoto();

  const toast = useRef(null);
  const { http } = useAxios();
  const webcamRef = useRef(null);
  const { userId } = useTokenData();
  const { isMobile } = useHandleResize();

  const captureButtonLabel = isMobile
    ? "Capture Via Another Device"
    : "Capture Via Mobile";

  const IdTypes = [
    { label: "Passport", value: "passport" },
    { label: "State Issued Driver's License", value: "driverLicense" },
  ];

  useEffect(() => {
    if (capturedViaMobilePhoto?.filePropertyName === 2) {
      // passport
      handlePassportIdChange(capturedViaMobilePhoto.file);
    } else if (capturedViaMobilePhoto?.filePropertyName === 4) {
      // selfie
      setValue(
        "identification.SelfieImageFile.FileDetails",
        capturedViaMobilePhoto.file,
      );
      setCapturedPhoto(capturedViaMobilePhoto.url);
    } else if (
      capturedViaMobilePhoto?.some((item) => item) &&
      capturedViaMobilePhoto?.some((item) => item?.filePropertyName === 1)
    ) {
      capturedViaMobilePhoto[0]?.file &&
        handleFrontImgChange(capturedViaMobilePhoto[0]?.file);

      capturedViaMobilePhoto[1]?.file &&
        handleBackImgChange(capturedViaMobilePhoto[1]?.file);
    }
  }, [capturedViaMobilePhoto]);

  useEffect(() => {
    if (frontImg && backImg) {
      sendImagesSideBySide(frontImg, backImg);
    }
  }, [frontImg, backImg]);

  useEffect(() => {
    if (capturedPhotoFromSignalR) {
      setShowCaptureLinkDialog(false);
      setShowCapturedPhotoPreview(true);
    }
  }, [capturedPhotoFromSignalR]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const sendImagesSideBySide = (frontImg, backImg) => {
    const img1 = new Image();
    const img2 = new Image();

    img1.onload = () => {
      img2.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img1.width + img2.width;
        canvas.height = Math.max(img1.height, img2.height);
        const ctx = canvas.getContext("2d");

        ctx.drawImage(img1, 0, 0);
        ctx.drawImage(img2, img1.width, 0);

        const dataURL = canvas.toDataURL("image/png");
        const blob = dataURItoBlob(dataURL);
        const file = new File([blob], "combined_image.png", {
          type: "image/png",
        });
        setValue("identification.IdentityImageFile.FileDetails", file);
      };

      img2.src = URL.createObjectURL(frontImg);
    };
    img1.src = URL.createObjectURL(backImg);
  };

  const handleIdTypeChange = (e) => {
    setSelectedIdType(e.value);
    setValue("identification.IdentityImageFile.FileDetails", "");
    setBackImg(undefined);
    setFrontImg(undefined);
    setPassportImg(undefined);
  };

  const handleFrontImgChange = (file) => {
    setFrontImg(file);
    setValue("identification.IdentityImageFile.FilePropertyName", 1);
    setValue("identification.IdentityImageFile.FileType", 1);
  };

  const handleBackImgChange = (file) => {
    setBackImg(file);
    setValue("identification.IdentityImageFile.FilePropertyName", 1);
    setValue("identification.IdentityImageFile.FileType", 1);
  };

  const handlePassportIdChange = (file) => {
    setPassportImg(file);
    setValue("identification.IdentityImageFile.FileDetails", file);
    setValue("identification.IdentityImageFile.FilePropertyName", 2);
    setValue("identification.IdentityImageFile.FileType", 1);
  };

  const onPassportClear = () => {
    setValue("identification.IdentityImageFile.FileDetails", "");
  };

  const onFrontIdImageClear = () => {
    setFrontImg(undefined);
    setValue("identification.IdentityImageFile.FileDetails", "");
  };

  const onBackIdImageClear = () => {
    setBackImg(undefined);
    setValue("identification.IdentityImageFile.FileDetails", "");
  };

  const handleCapture = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedPhoto(imageSrc);

      const blob = dataURItoBlob(imageSrc);
      const file = new File([blob], "selfie_image.png", { type: "image/png" });

      let finalFile = file;

      if (file.type.startsWith("image/")) {
        try {
          const compressedBlob = await compressImage(file, 1280, 0.8);
          finalFile = new File([compressedBlob], file.name, {
            type: "image/png",
          });
        } catch (error) {
          console.warn("Compression failed, using original file:", error);
        }
      }
      setValue("identification.SelfieImageFile.FileDetails", finalFile);
      setShowCamera(false);
    } catch (error) {
      console.error("Error capturing or processing image:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to capture or process the image.",
        life: 4000,
      });
    }
  };

  const openCamera = async () => {
    const errorMessage = await checkCameraAccess();
    if (!errorMessage) {
      setShowCamera(true);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Camera Warning",
        detail: errorMessage,
        life: 4000,
      });
    }
  };

  const handleDeleteSelfiePhoto = () => {
    setCapturedPhoto(null);
    setValue("identification.SelfieImageFile.FileDetails", "");
  };

  const generateCaptureLink = async (type) => {
    try {
      const response = await http.post(
        "/Identifications/GenerateCaptureIdentityViaMobileLink",
        {
          identificationId: userId,
          identityTypes: type,
        },
      );
      setCaptureLink(response.data);
      const sessionId = new URL(response.data).searchParams.get("id");
      setSessionId(sessionId);
      setShowCaptureLinkDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Dialog
        visible={showCaptureLinkDialog}
        onHide={() => {
          setShowCaptureLinkDialog(false);
        }}
        className="max-w-4xl"
        header="Complete Via Mobile"
      >
        <CaptureLinkDialog captureLink={captureLink} />
      </Dialog>
      <Dialog
        visible={showCapturedPhotoPreview}
        onHide={() => {
          setShowCapturedPhotoPreview(false);
          setCapturedViaMobilePhoto(null);
          setCapturedPhotoFromSignalR(null);
        }}
        className="max-w-4xl"
        header="Captured Photo Preview"
      >
        <CapturedPhotoPreview
          setCapturedViaMobilePhoto={setCapturedViaMobilePhoto}
          sessionId={sessionId}
          setShowCapturedPhotoPreview={setShowCapturedPhotoPreview}
        />
      </Dialog>

      <div>
        <h5 className="font-Poppins text-base capitalize leading-loose text-gray-500">
          Verify your Identity
        </h5>
        <span className="p-input-icon-left mt-4 w-full">
          <label className="block pb-2 font-Poppins text-base capitalize leading-loose text-gray-500">
            Select ID Type
          </label>
          <Dropdown
            value={selectedIdType}
            onChange={handleIdTypeChange}
            options={IdTypes}
            optionLabel="label"
            placeholder="Choose Type"
            className="w-full"
          />
        </span>
        {selectedIdType === "passport" && (
          <div className="flex flex-col gap-2 py-2">
            <label className="font-Poppins text-gray-500">Upload ID</label>
            <div className="flex items-start gap-2">
              <div className="flex flex-col gap-1 sm:w-1/2 md:w-1/3">
                <div className="flex items-center">
                  <DocumentUpload
                    name="passportImg"
                    fileType="image"
                    maxFileSizeInMB={10}
                    previewImage={passportImg}
                    onUpload={handlePassportIdChange}
                    onClear={onPassportClear}
                  />
                </div>
              </div>
              <div>
                <Button
                  outlined
                  type="button"
                  label={captureButtonLabel}
                  onClick={(e) => {
                    generateCaptureLink(2);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        )}

        {selectedIdType === "driverLicense" && (
          <div className="flex flex-col gap-2 p-2">
            {frontImg !== undefined && backImg === undefined && (
              <p className="mt-1 text-left text-red-500">
                Upload back images of your driver license.
              </p>
            )}
            {backImg !== undefined && frontImg === undefined && (
              <p className="mt-1 text-left text-red-500">
                Upload front images of your driver license.
              </p>
            )}
            <div className="flex flex-col gap-4 sm:w-1/2 md:w-1/3">
              <div className="flex flex-col gap-1">
                <label className="font-Poppins text-gray-500">
                  Front side of ID card
                </label>

                <div className="flex items-center">
                  <DocumentUpload
                    name="fornImg"
                    fileType="image"
                    maxFileSizeInMB={10}
                    previewImage={frontImg}
                    onUpload={handleFrontImgChange}
                    onClear={onFrontIdImageClear}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <label className="font-Poppins text-gray-500">
                  Back side of ID card
                </label>
                <div className="flex w-full items-center">
                  <DocumentUpload
                    name="backImg"
                    fileType="image"
                    maxFileSizeInMB={10}
                    previewImage={backImg}
                    onUpload={handleBackImgChange}
                    onClear={onBackIdImageClear}
                  />
                </div>
              </div>
              <Divider
                layout="horizontal"
                align="center"
                style={{
                  margin: 0,
                }}
              >
                or
              </Divider>
              <div>
                <Button
                  outlined
                  type="button"
                  label={captureButtonLabel}
                  className="w-full"
                  onClick={(e) => {
                    generateCaptureLink(1);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        )}
        {!backImg &&
          !frontImg &&
          !passportImg &&
          verificationData?.identification?.identityImage && (
            <div className="mt-4">
              <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                {verificationData?.identification?.identityImageType ===
                "DriverLicense"
                  ? "Driver License"
                  : verificationData?.identification?.identityImageType}
              </label>
              <img
                src={getBase64Src(
                  verificationData?.identification?.identityImage,
                )}
                alt="Captured"
                className="w-90 h-60"
              />
            </div>
          )}

        <div className="take-selfie mt-9">
          <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
            Selfie Photo
          </label>
          <div>
            {showCamera && (
              <Webcam
                audio={false}
                screenshotFormat="image/png"
                ref={webcamRef}
                mirrored={true}
                disabled={!webcamRef.current}
              />
            )}
            {showCamera && (
              <div className="flex gap-3 py-3">
                <Button
                  onClick={handleCapture}
                  label="Take Selfie"
                  severity="info"
                  icon="pi pi-cloud-upload"
                  type="button"
                />
                <Button
                  label="Cancel"
                  outlined
                  type="button"
                  onClick={() => {
                    setShowCamera(false);
                  }}
                />
              </div>
            )}
            {capturedPhoto && (
              <div>
                <img src={capturedPhoto} alt="Captured" className="w-90 h-60" />
                <Button
                  type="button"
                  onClick={handleDeleteSelfiePhoto}
                  className="mb-5 mt-5"
                  severity="danger"
                >
                  Delete Photo
                </Button>
              </div>
            )}
            {!capturedPhoto &&
              verificationData?.identification?.selfieImage &&
              !showCamera && (
                <div>
                  <img
                    src={getBase64Src(
                      verificationData?.identification?.selfieImage,
                    )}
                    alt="Captured"
                    className="w-90 h-60"
                  />
                </div>
              )}

            {!showCamera && !capturedPhoto && (
              <div className="flex gap-2 p-2">
                <Button
                  onClick={openCamera}
                  label="Open Camera"
                  type="button"
                  severity="info"
                  icon="pi pi-camera"
                  className=""
                />
                <Button
                  outlined
                  type="button"
                  label={captureButtonLabel}
                  onClick={(e) => {
                    generateCaptureLink(4);
                  }}
                ></Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IdentityVerification;
