import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import ConfirmOrderByProviderSkeleton from "../../components/skeletons/ConfirmOrderByProviderSkeleton";

function ManageOrderFromProvider() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const params = new URLSearchParams(window.location.search);

  const uniqId = params.get("Id");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/Orders/GetOrderData/${uniqId}`);
        setData(response.data);
      } catch (error) {
        if (error.response?.data.startsWith("Invalid")) {
          navigate("/invalid-link");
        } else if (error.response?.data === "The link has expired.") {
          navigate("/expired-link");
        } else {
          navigate("/redundent-link");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [uniqId, navigate]);

  const handleOrderAction = async (action) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/Orders/AcceptOrDenyTheOrder?orderId=${data.orderId}&providerAction=${action}&isEmail=False&linkId=${uniqId}`,
      );

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      const url = new URL(response.data);
      navigate(url.pathname, { replace: true });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "An error occurred",
        life: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccept = () => handleOrderAction("Accept");
  const handleDenied = () => handleOrderAction("Deny");

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <ConfirmOrderByProviderSkeleton />
      ) : (
        <div className="flex h-screen items-center justify-center p-4 sm:p-6 md:p-8 lg:p-10">
          <div className="flex w-full max-w-4xl justify-center rounded-3xl bg-light-text py-8 sm:py-12 md:py-16">
            <div className="w-full max-w-3xl rounded-3xl border border-solid border-light-purple p-6 sm:p-8 md:p-10 lg:p-12">
              <div className="mb-5 flex justify-center">
                <span className="flex items-center justify-center rounded-full bg-gray-300 p-2">
                  <MedicalHistoryIcon />
                </span>
              </div>
              <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
                Order Approval Request
              </h4>

              <div className="mt-3">
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/2">
                    <span className="text-normal font-Poppins capitalize leading-loose text-light-purple">
                      Provider Name:
                    </span>
                    <span> {data?.providerName}</span>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <span className="text-normal font-Poppins capitalize leading-loose text-light-purple">
                      Nurse Name:
                    </span>
                    <span>{data?.nurseName}</span>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/2">
                    <span className="text-normal font-Poppins capitalize leading-loose text-light-purple">
                      Order Number:
                    </span>
                    <span>{data?.orderTrackingNumber}</span>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <span className="text-normal font-Poppins capitalize leading-loose text-light-purple">
                      Patient Name:
                    </span>
                    <span>{data?.patientName}</span>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/2">
                    <span className="text-normal font-Poppins capitalize leading-loose text-light-purple">
                      Provider Practice Name:
                    </span>
                    <span>{data?.providerPracticeName}</span>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <span className="text-normal font-Poppins capitalize leading-loose text-light-purple">
                      Service Name:
                    </span>
                    <span>{data?.serviceName}</span>
                  </div>
                </div>
                <h3 className="text-normal mt-4 font-Poppins capitalize leading-loose text-light-purple">
                  Billable Items
                </h3>
                <div className="h-72 overflow-y-scroll">
                  {data?.billableItems?.map((innerArray, index) => (
                    <div key={index}>
                      {innerArray.map((item, subIndex) => (
                        <div
                          key={subIndex}
                          className="billable-item border-b-2 border-solid py-2"
                        >
                          <p>
                            <span className="text-normal font-Poppins capitalize leading-loose text-gray-600">
                              HCPCS:
                            </span>
                            <span> {item.hcpcs} </span>
                          </p>
                          <p>
                            <span className="text-normal font-Poppins capitalize leading-loose text-gray-600">
                              Short Description:
                            </span>
                            <span>{item.shortDescription} </span>
                          </p>
                          <p>
                            <span className="text-normal font-Poppins capitalize leading-loose text-gray-600">
                              Units:
                            </span>
                            <span>{item.billableItemUnits}</span>
                          </p>
                          <p>
                            <span className="text-normal font-Poppins capitalize leading-loose text-gray-600">
                              Total Price:
                            </span>
                            <span> ${item.totalPrice.toFixed(2)}</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              {!isLoading ? (
                <div className="mt-6 flex justify-center gap-5">
                  <Button
                    onClick={handleAccept}
                    type="submit"
                    loading={isLoading}
                    className="mt-3 justify-center rounded-full bg-light-purple px-6 py-2.5 font-inter font-medium capitalize text-light-text"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={handleDenied}
                    type="submit"
                    disabled={isLoading}
                    className="mt-3 justify-center rounded-full bg-gray-200 px-6 py-2.5 font-inter font-medium capitalize text-light-purple"
                  >
                    Decline
                  </Button>
                </div>
              ) : (
                <div className="mt-6 flex justify-center">
                  <ProgressSpinner className="h-8 w-8" strokeWidth={2} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ManageOrderFromProvider;
