import { Skeleton } from "primereact/skeleton";

const FormSkeleton = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <Skeleton height="24px" width="20%" />
        <Skeleton height="36px" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton height="24px" width="40%" />
        <Skeleton height="36px" />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col gap-2">
          <Skeleton height="24px" width="35%" />
          <Skeleton height="36px" />
        </div>
        <div className="flex flex-col gap-2">
          <Skeleton height="24px" width="35%" />
          <Skeleton height="36px" />
        </div>
        <div className="flex flex-col gap-2">
          <Skeleton height="24px" width="35%" />
          <Skeleton height="36px" />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton height="24px" width="25%" />
        <Skeleton height="36px" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton height="24px" width="20%" />
        <Skeleton height="36px" />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <Skeleton height="24px" width="35%" />
          <Skeleton height="36px" />
        </div>
        <div className="flex flex-col gap-2">
          <Skeleton height="24px" width="35%" />
          <Skeleton height="36px" />
        </div>
      </div>
    </div>
  );
};

export default FormSkeleton;
