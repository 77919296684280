import { LegalMetaHeaders } from "../../meta";
import medxLogo from "./../../images/Medx@2x.png";
import privacyIcon from "./../../images/privacy.png";
import tosIcon from "./../../images/termsandconditions.webp";

function Legal() {
  return (
    <>
      <LegalMetaHeaders />
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="flex h-auto w-10/12 max-w-2xl flex-col gap-6 rounded-xl border border-light-purple bg-white p-8 shadow-lg">
          <div className="m-auto">
            <img src={medxLogo} alt="MedX logo" className="w-36" />
          </div>

          <div className="justify-left ml-1 flex flex-col gap-5 text-3xl font-semibold text-gray-800">
            <span>
              <a
                href="/legal/tos"
                className="group relative inline-flex items-center"
              >
                <i className="pi mr-4">
                  <img
                    src={tosIcon}
                    alt="terms-and-conditions-logo "
                    className="w-12"
                  />
                </i>
                <span className="relative hover:before:absolute hover:before:bottom-[-3px] hover:before:h-[3px] hover:before:w-full hover:before:bg-light-purple hover:before:content-['']">
                  Terms of Service
                </span>
              </a>
            </span>

            <span>
              <a
                href="/legal/privacy"
                className="group relative inline-flex items-center"
              >
                <i className="pi mr-4">
                  <img
                    src={privacyIcon}
                    alt="terms-and-conditions-logo "
                    className="w-12"
                  />
                </i>

                <span className="relative hover:before:absolute hover:before:bottom-[-3px] hover:before:h-[3px] hover:before:w-full hover:before:bg-light-purple hover:before:content-['']">
                  Privacy Policy
                </span>
              </a>
            </span>
          </div>

          <div className="mt-14 text-center text-gray-600">
            <p>© 2024 MedX™. All rights reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Legal;
