import { Skeleton } from "primereact/skeleton";

function StepsSkeleton() {
  return (
    <div className="mb-4 flex justify-between px-6">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="flex flex-col items-center gap-2">
          <Skeleton shape="circle" size="3rem" />
          <Skeleton width="5rem" height="1rem" />
        </div>
      ))}
    </div>
  );
}

export default StepsSkeleton;
