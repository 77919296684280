import React from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../hooks/useAxios";
import { useTokenStore } from "../../../store/store";
import useTokenData from "../../../hooks/useTokenData";
import { trimValidation } from "../../../utils/helpers";

function TechnicalSupportDialog({
  TechSupportFormDialog,
  setTechSupportFormDialog,
}) {
  const { http } = useAxios();
  const navigate = useNavigate();
  const { userId } = useTokenData();
  const { setToken } = useTokenStore();

  const defaultValues = {
    technicalSupportName: "",
    technicalSupportPhoneNumber: "",
    technicalSupportEmail: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const handleCreateIndividualPractice = async (formData) => {
    try {
      const response = await http.post(
        `/Identifications/CreateIndividualPractice/${userId}`,
        formData,
      );
      const tokenResponse = await http.post(
        `/Authentication/GetToken/${response.data}/1`,
      );
      setToken(tokenResponse.data.token);
      navigate("/welcome", { replace: true });
      reset();
    } catch (error) {
      console.error("Error creating individual practice:", error);
    }
  };

  const handleCancel = () => {
    setTechSupportFormDialog(false);
    reset();
  };
  return (
    <Dialog
      draggable={false}
      visible={TechSupportFormDialog}
      onHide={() => {
        setTechSupportFormDialog(false);
        reset();
      }}
      header="Technical Support Contact Information"
      className="w-[50vw]"
    >
      <form
        onSubmit={handleSubmit(handleCreateIndividualPractice)}
        className="px-3"
      >
        <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
          individual Support Contact Information{" "}
        </label>
        <span className="w-full">
          <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
            General Practice Support Individual Name or Department Name
          </label>
          <InputText
            {...register("technicalSupportName")}
            placeholder="Name"
            className="w-full pb-2 pt-2"
          />
        </span>
        <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
          General Practice Support Phone Number{" "}
          <span className="text-red-600">*</span>
        </label>
        <span className="p-input-icon-left w-full">
          <i className="pi pi-phone" />
          <Controller
            name="technicalSupportPhoneNumber"
            control={control}
            rules={{
              required: "Phone number is required",
              validate: (value) =>
                trimValidation(value, "Technical Support phone"),
            }}
            render={({ field }) => (
              <InputMask
                {...field}
                mask="999-999-9999"
                placeholder="Phone Number"
                className={`w-full pb-2 pt-2 ${
                  errors?.technicalSupportPhoneNumber ? "p-invalid" : ""
                }`}
                onClick={(e) => {
                  if (e.target.value === "___-___-____") {
                    e.target.setSelectionRange(0, 0);
                  }
                }}
              />
            )}
          />
        </span>

        {errors?.technicalSupportPhoneNumber && (
          <p className="mt-1 text-red-500">
            {errors?.technicalSupportPhoneNumber?.message}
          </p>
        )}
        <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
          General Practice Support Email Address{" "}
          <span className="text-red-600">*</span>
        </label>
        <span className="p-input-icon-left w-full">
          <i className="pi pi-envelope" />
          <Controller
            name="technicalSupportEmail"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid Email Address",
              },
              validate: (value) =>
                trimValidation(value, "Technical support email"),
            }}
            render={({ field }) => (
              <InputText
                {...field}
                placeholder="Email"
                className={`w-full pb-2 pt-2 ${
                  errors?.technicalSupportEmail ? "p-invalid" : ""
                }`}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </span>

        {errors?.technicalSupportEmail && (
          <p className="mt-1 text-red-500">
            {errors?.technicalSupportEmail?.message}
          </p>
        )}

        <div className="mt-7 flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
          <div className="w-full md:w-auto">
            <Button
              onClick={handleCancel}
              disabled={isSubmitting}
              type="button"
              severity="danger"
              label="Cancel"
            ></Button>
          </div>

          <div className="w-full items-end justify-end md:w-auto">
            <Button loading={isSubmitting} label="Create"></Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default TechnicalSupportDialog;
