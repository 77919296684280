import { Skeleton } from "primereact/skeleton";

export const LocationCardSkeleton = () => {
  return (
    <div className="border-rounded mb-2 border bg-white p-5">
      <Skeleton className="mb-4 p-4" width="30%" />
      <div className="flex w-[100%] items-center">
        <Skeleton size="25px" shape="circle" className="mr-4 p-2" />
        <Skeleton className="p-3" width="20%" />
      </div>
    </div>
  );
};
