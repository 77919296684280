import { useEffect, useState } from "react";

const MOBILE_MAX_VIEW_WIDTH = 640;
const TABLET_MAX_VIEW_WIDTH = 1024;

const useHandleResize = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  let isMobile = screenWidth <= MOBILE_MAX_VIEW_WIDTH;
  let isTablet =
    MOBILE_MAX_VIEW_WIDTH < screenWidth && screenWidth <= TABLET_MAX_VIEW_WIDTH;
  return { isTablet, isMobile };
};
export default useHandleResize;
