import { forwardRef, useState } from "react";

import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog } from "primereact/confirmdialog";

import { useLayoutToast } from "../../../../../store/store";

const RenderedQuestion = forwardRef(
  (
    {
      question,
      index,
      onDelete,
      setEditQuestion,
      setQuestionToEdit,
      setQuestion,
      formQuestions,
      setFormQuestions,
      questionToEdit,
      createNewLast,
      isQuestionInUse,
    },
    ref,
  ) => {
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const handleEditClick = () => {
      setEditQuestion(true);
      setQuestion(formQuestions[index]);
      setQuestionToEdit({ index, question: formQuestions[index] });
    };
    const { layoutToast } = useLayoutToast();

    const handleDeleteClick = () => {
      if (isQuestionInUse(index)) {
        layoutToast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Question already used in another place",
          life: 4000,
        });
        return;
      }
      setConfirmationVisible(true);
    };
    const handleDeleteConfirmed = () => {
      onDelete(index);
      setConfirmationVisible(false);
    };
    const handleMoveToTop = () => {
      const updatedQuestions = Array.from(formQuestions);
      const [removed] = updatedQuestions.splice(index, 1);
      updatedQuestions.splice(2, 0, removed);
      setFormQuestions(updatedQuestions);
    };
    const handleMoveToBottom = () => {
      const updatedQuestions = Array.from(formQuestions);
      const [removed] = updatedQuestions.splice(index, 1);
      updatedQuestions.push(removed);
      setFormQuestions(updatedQuestions);
    };

    function convertFeetToFeetAndInches(feetValue) {
      const feet = Math.floor(feetValue);
      const inches = Math.round((feetValue - feet) * 12);
      return `${feet} (ft) ${inches} (in)`;
    }

    function limitToThreeDecimalPlaces(value) {
      if (typeof value !== "number" || isNaN(value)) {
        return value;
      }

      const stringValue = value.toString();

      if (
        stringValue.indexOf(".") !== -1 &&
        stringValue.split(".")[1].length > 3
      ) {
        return parseFloat(value.toFixed(3));
      }

      return value;
    }

    function renderConstraints(question) {
      if (question?.constraints?.type === "height") {
        return (
          <div>
            {question?.constraints?.min && (
              <div>
                - Minimum value:{" "}
                {convertFeetToFeetAndInches(question?.constraints?.min)}
              </div>
            )}
            {question?.constraints?.max && (
              <div>
                - Maximum value:{" "}
                {convertFeetToFeetAndInches(question?.constraints?.max)}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div>
            {question?.constraints?.min && (
              <div>
                - Minimum value:{" "}
                {limitToThreeDecimalPlaces(+question?.constraints?.min)}
              </div>
            )}
            {question?.constraints?.max && (
              <div>
                - Maximum value:{" "}
                {limitToThreeDecimalPlaces(+question?.constraints?.max)}
              </div>
            )}
          </div>
        );
      }
    }

    const renderHeader = () => {
      return (
        <p className="p-2 font-inter font-semibold">Question Description</p>
      );
    };
    const header = renderHeader();
    return (
      <div className="mt-2 rounded-2xl border border-slate-200 bg-white px-10 py-5 shadow-lg">
        <div className="items-center">
          {!questionToEdit?.index && !createNewLast && (
            <div className="flex justify-end">
              <Button
                type="button"
                icon="pi pi-arrow-circle-up text-2xl"
                onClick={handleMoveToTop}
                className=""
                link
                tooltip="Move to first"
                tooltipOptions={{
                  showDelay: 1000,
                  hideDelay: 300,
                  position: "bottom",
                }}
              />
            </div>
          )}
          <div className="mb-2 flex items-center justify-between">
            <div>
              <label className="mr-2 border-b-4 text-xl font-semibold text-[#3D3877]">
                Question:
              </label>
            </div>
          </div>
          <div className="ml-4">{question.label}</div>
        </div>
        {question.type === "multipleChoice" || question.type === "dropDown" ? (
          <div>
            <label className="ml-8">Answer Options:</label>
            {question.options.map((option, i) => (
              <div key={i} className="mb-2 ml-12 font-semibold">
                <RadioButton disabled className="mr-2" />
                {option.label}
              </div>
            ))}
          </div>
        ) : null}
        {question.type === "yesNo" ? (
          <div className="p-2">
            <div className="mt-2">
              <RadioButton className="mr-1" disabled /> Yes
            </div>

            <div className="mt-2">
              <RadioButton className="mr-1" disabled /> No
            </div>
          </div>
        ) : null}
        {["number", "decimal"].includes(question.type) ? (
          <div className="p-2">
            {renderConstraints(question)}
            {question?.constraints?.type && (
              <div>
                - Display type:{" "}
                {question?.constraints?.type === "height"
                  ? "American Height"
                  : "Standard Field"}
              </div>
            )}
          </div>
        ) : null}
        {question.conditions.show && (
          <div className="ml-8 mt-4 grid grid-cols-2 gap-4">
            <div>
              <div>Conditions:</div>
              <div>
                <Checkbox disabled className="mr-2" />
                Only show this question if:
              </div>
            </div>
            <div>
              <div>Question Number: {question.conditions.question}</div>
              <div>Answer: {question.conditions.answer}</div>
            </div>
          </div>
        )}

        {question.description?.htmlValue &&
          question.description.textValue.trim().replace(/\\./g, "") && (
            <div className="card mt-5">
              <Editor
                value={question.description.htmlValue}
                readOnly
                headerTemplate={header}
                style={{ minHeight: "164px" }}
              />
            </div>
          )}
        {!questionToEdit?.index && !createNewLast && (
          <>
            <div className="flex justify-end">
              <Button
                type="button"
                label="Edit"
                icon="pi pi-pencil"
                onClick={handleEditClick}
                className="p-button-secondary mt-2"
              />
              <Button
                type="button"
                label="Delete"
                icon="pi pi-trash"
                onClick={handleDeleteClick}
                className="p-button-danger ml-2 mt-2"
              />
            </div>
            <div className="flex justify-end">
              {" "}
              <Button
                type="button"
                icon="pi pi-arrow-circle-down text-2xl"
                onClick={handleMoveToBottom}
                className=""
                link
                tooltip="Move to last"
                tooltipOptions={{
                  showDelay: 1000,
                  hideDelay: 300,
                  position: "bottom",
                }}
              />
            </div>
          </>
        )}
        <ConfirmDialog
          visible={confirmationVisible}
          onHide={() => setConfirmationVisible(false)}
          message="Are you sure you want to delete this question?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          acceptClassName="p-button-danger"
          rejectClassName="p-button-secondary"
          acceptLabel="Yes"
          rejectLabel="No"
          accept={handleDeleteConfirmed}
          reject={() => setConfirmationVisible(false)}
        />
      </div>
    );
  },
);

export default RenderedQuestion;
