import { useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../hooks/useAxios";
import { validatePassword } from "../../utils/helpers";

export const ResetPassword = ({ isAdmin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const toast = useRef(null);
  const { http } = useAxios();
  const {
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const location = useLocation();
  const queryString = location.search;
  const urlParams = queryString
    .slice(1)
    .split("&")
    .map((param) => param.split("="))
    .reduce((acc, [key, value]) => {
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});

  const userToken = urlParams.token;
  const userEmail = urlParams.email;

  const navigate = useNavigate();
  // handle password eye
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };
  const handleConfirmPasswordClick = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const endpoint = isAdmin
        ? "Authentication/SystemAdminResetPassword"
        : "/Authentication/ResetPassword";
      await http.post(endpoint, {
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        email: userEmail,
        token: userToken,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Password Reset Successfully",
        life: 2000,
      });
      navigate(location.pathname.includes("admin") ? "/admin/login" : "/login");
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 2000,
      });
      setIsDisabled(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex h-[80vh] items-center justify-center">
        <div className="Xl:w-1/3 mt-10 rounded-3xl border border-solid border-light-purple bg-light-text py-5 pl-10 pr-10 sm:w-4/5 md:w-5/12 lg:w-1/3 2xl:w-1/3">
          <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
            MedX
          </h4>
          <div className="text-start">
            <h3 className="mt-8 font-inter font-bold">Reset Password</h3>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pt-4">
                <label>
                  Password <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                    validate: validatePassword,
                  }}
                  render={({ field }) => (
                    <>
                      <span className="p-input-icon-left p-input-icon-right w-full">
                        <i className="pi pi-lock" />
                        <InputText
                          placeholder="Password (8 or more characters)"
                          className="w-full pb-2 pt-2"
                          name="password"
                          type={!passwordEye ? "password" : "text"}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            trigger("confirmPassword");
                          }}
                        />
                        {!passwordEye ? (
                          <i
                            className="pi pi-eye-slash cursor-pointer"
                            onClick={handlePasswordClick}
                          />
                        ) : (
                          <i
                            className="pi pi-eye cursor-pointer"
                            onClick={handlePasswordClick}
                          />
                        )}
                      </span>
                    </>
                  )}
                />
                {errors.password && (
                  <p className="mt-1 text-red-500">{errors.password.message}</p>
                )}
              </div>
              <div className="pt-4">
                <label>
                  Confirm Password <span className="text-red-600">*</span>
                </label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: "Confirm Password is required",
                    validate: (value, formValues) => {
                      return value === formValues.password
                        ? true
                        : "Passwords do not match";
                    },
                  }}
                  render={({ field }) => (
                    <span className="p-input-icon-left p-input-icon-right w-full">
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="Confirm password"
                        className="w-full pb-2 pt-2"
                        name="confirmPassword"
                        type={!confirmPasswordEye ? "password" : "text"}
                        {...field}
                      />
                      {!confirmPasswordEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handleConfirmPasswordClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handleConfirmPasswordClick}
                        />
                      )}
                    </span>
                  )}
                />
                {errors.confirmPassword && (
                  <p className="mt-1 text-red-500">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>

              {isLoading ? (
                <div className="mt-9 text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  disabled={isDisabled}
                  className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                >
                  Reset password
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
