export const ProviderTemplate = ({ role, isProvider }) => {
  return (
    <>
      <div className="flex items-center gap-3">
        <div className="w-1/2">{role}</div>
        {isProvider === true ? (
          <div className="inline rounded-md bg-slate-100 px-4 py-1 text-center text-sm font-bold text-light-purple sm:block sm:p-3 sm:text-base">
            Provider
          </div>
        ) : null}
      </div>
    </>
  );
};
