import { Skeleton } from "primereact/skeleton";

function InitiateOrderSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      {/* Header */}
      <div className="flex flex-col gap-2">
        <Skeleton width="30%" height="2rem" />
        <Skeleton width="90%" height="1rem" />
        <Skeleton width="80%" height="1rem" />
      </div>

      {/* Initiate Order section */}
      <div className="flex flex-col gap-3">
        <Skeleton width="40%" height="2rem" />
        <Skeleton width="100%" height="1rem" />
      </div>

      {/* Order Provider section */}
      <div className="rounded-lg border p-4">
        <div className="flex flex-col gap-3">
          <Skeleton width="30%" height="1.5rem" />
          <Skeleton width="100%" height="1rem" />
          <Skeleton width="95%" height="1rem" />
          <Skeleton width="90%" height="1rem" />
          <Skeleton width="100%" height="3rem" className="mt-2" />
        </div>
      </div>
    </div>
  );
}

export default InitiateOrderSkeleton;
