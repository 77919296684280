export const ProviderStatusTemplate = ({ status }) => {
  const statusData = {
    Null: { className: "bg-sky-100 text-sky-600", content: "Verify Provider" },
    InComplete: {
      className: "bg-orange-200 text-orange-600",
      content: "Incomplete",
    },
    Pending: { className: "bg-slate-200 text-slate-500", content: "Pending" },
    Approved: { className: "bg-green-200 text-green-700", content: "Approved" },
    Denied: { className: "bg-red-200 text-red-700", content: "Denied" },
  };

  const { content, className } = statusData[status];

  return (
    <div
      className={`inline rounded-md p-2 text-center text-sm font-bold capitalize sm:block sm:p-3 sm:text-base ${className}`}
    >
      {content}
    </div>
  );
};
