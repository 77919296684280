const fileTypeMap = {
  "image/png": 1,
  "image/jpg": 2,
  "image/jpeg": 3,
  "application/pdf": 4,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 5,
  "application/dicom": 6,
  "image/tiff": 7,
  "image/gif": 8,
  "text/plain": 9,
  "text/csv": 10,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 11,
  "image/svg+xml": 12,
  "application/msword": 13,
};

export const fileTyper = (filetype) => {
  return fileTypeMap[filetype];
};
