import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import useImpersonating from "../hooks/useImpersonating";
import useTokenData from "../hooks/useTokenData";
import {
  useRefreshTokenStore,
  useStoreEntityIdFromUrl,
  useSwitchOrdering,
  useTokenStore,
} from "../store/store";
import { useEffect, useState } from "react";
import ServicesIcon from "../iconComponents/ServicesIcon";
import axios from "axios";
import { googleLogout } from "@react-oauth/google";
import { Tooltip } from "primereact/tooltip";

function ReusableSidebar({ setShowSidebar }) {
  const { unPersonate } = useAxios();
  const { isSystemAdminImpersonating } = useImpersonating();
  const navigate = useNavigate();
  const location = useLocation();
  const { IsHidden, userRoles } = useTokenData();
  const { token, setToken } = useTokenStore();
  const { setEntityId } = useStoreEntityIdFromUrl();
  const { refreshToken: currentRefreshToken, setRefreshToken } =
    useRefreshTokenStore();
  const { isOrderingMode, setIsOrderingMode } = useSwitchOrdering();

  const [visible, setVisible] = useState(true);
  const [toggledSubMenu, setToggledSubMenu] = useState(false);

  const sidebarIcon = visible ? (
    <i className="pi pi-angle-double-left text-light-purple"></i>
  ) : (
    <i className="pi pi-angle-double-right text-light-purple"></i>
  );
  const isAdmin = location.pathname.includes("admin");

  useEffect(() => {
    setIsOrderingMode(location.pathname.includes("ordering"));
  }, [location]);

  const toggleSidebar = () => {
    setVisible(!visible);
  };

  const adminItems = [
    {
      path: `/admin/ourPractices`,
      name: "Our Practices",
      icon: <i className="pi pi-users pr-3"></i>,
    },
    {
      path: `/admin/transactionTracking`,
      name: "Transaction Tracking",
      icon: <i className="pi pi-dollar pr-3"></i>,
    },
    {
      path: `/admin/worklists`,
      name: "Worklists",
      icon: <i className="pi pi-list pr-2"></i>,
    },
    {
      path: `/admin/manageAdmins`,
      name: "Manage Admins",
      icon: <i className="pi pi-user pr-2"></i>,
    },
    {
      path: `/admin/settings`,
      name: "Settings",
      icon: <i className="pi pi-cog pr-2"></i>,
      children: [
        {
          label: "Global Information",
          path: "/admin/settings/admin-information",
          icon: <i className="pi pi-info-circle pr-2"></i>,
        },
        {
          label: "Password Settings",
          path: `/admin/settings/passwordSetting`,
          icon: <i className="pi pi-lock pr-2"></i>,
        },
        {
          label: "Environment Settings",
          path: `/admin/settings/environment`,
          icon: <i className="pi pi-globe pr-2"></i>,
        },
        {
          label: "Notifications Settings",
          path: `/admin/settings/notificationsSettings`,
          icon: <i className="pi pi-bell pr-2"></i>,
        },
      ],
    },
  ];
  const orderingItems = [
    {
      path: `/ordering/FindServices`,
      name: "Find Services",
      icon: <ServicesIcon />,
      hide: IsHidden === "True",
    },
    {
      path: `/ordering/Orders/`,
      name: "My Orders",
      icon: <i className="pi pi-shopping-cart pr-3"></i>,
    },
    {
      path: `/ordering/staff`,
      name: "Staff",
      icon: <i className="pi pi-users pr-2"></i>,
      hide: userRoles !== "Admin" || IsHidden === "True",
    },
    {
      path: `/ordering/practices`,
      name: "Practices",
      icon: <i className="pi pi-star pr-2"></i>,
      hide: IsHidden === "True",
    },
    {
      path: `/ordering/Settings/`,
      name: "Settings",
      icon: <i className="pi pi-cog pr-2"></i>,
    },
  ];
  const renderingItems = [
    {
      path: `/Dashboard`,
      name: "Dashboard",
      icon: <i className="pi pi-th-large pr-3"></i>,
    },
    {
      path: `/rendering/Orders/`,
      name: "My Orders",
      icon: <i className="pi pi-shopping-cart pr-3"></i>,
    },
    {
      path: "/service",
      name: "Services",
      icon: <ServicesIcon />,
      hide: userRoles !== "Admin" || IsHidden === "True",
      children: [
        {
          label: "My Services",
          path: "/Service/MyServices",
          icon: <i className="pi pi-cog text-xs"></i>,
        },
        {
          label: "Billable Items",
          path: "/Service/billableItems",
          icon: <i className="pi pi-dollar text-xs"></i>,
        },
        {
          label: "Documents Library",
          path: "/Service/documents",
          icon: <i className="pi pi-folder text-xs"></i>,
        },
      ],
    },
    {
      path: `/rendering/staff/`,
      name: "Staff",
      icon: <i className="pi pi-users pr-2"></i>,
      hide: userRoles !== "Admin" || IsHidden === "True",
    },
    {
      path: `/rendering/Transactions`,
      name: "Transactions",
      icon: <i className="pi pi-dollar pr-2"></i>,
      hide: userRoles !== "Admin",
    },
    {
      path: `/rendering/Settings/`,
      name: "Settings",
      icon: <i className="pi pi-cog pr-2"></i>,
    },
    {
      path: `/rendering/mylocations/`,
      name: "Locations",
      icon: <i className="pi pi-map-marker pr-2"></i>,
      hide: IsHidden === "True",
    },
  ];

  const sidebarItems = isAdmin
    ? adminItems
    : isOrderingMode
      ? orderingItems
      : renderingItems;

  const microsoft = () => {
    sessionStorage.clear();
  };

  const logOut = async () => {
    try {
      await axios.post(
        "/Authentication/LogOut",
        { refreshToken: currentRefreshToken },
        {
          headers: { Authorization: `Bearer ${token}` },
          "Content-Type": "application/json",
        },
      );
    } catch (err) {}
    microsoft();
    setToken(null);
    setRefreshToken(null);
    setEntityId(null);
    navigate(
      isAdmin || isSystemAdminImpersonating ? "/admin/login" : "/login",
      {
        replace: true,
      },
    );
    googleLogout();
  };

  return (
    <>
      <Tooltip
        target=".side-bar-item"
        pt={{
          text: {
            style: {
              backgroundColor: "#f8fafc",
              color: "#4b5563",
              fontWeight: "500",
            },
          },
        }}
      />
      <div
        className={`md:reusable-sidebar sticky top-20 h-[calc(100vh-5rem)] overflow-y-auto overflow-x-hidden rounded-tr-lg text-normal-text transition-all duration-500 ease-in-out md:border-r-4 lg:block lg:bg-light-text lg:p-2 lg:shadow-2xl ${visible ? "w-[14.7rem]" : "w-[5rem]"}`}
      >
        <div>
          <div className="hidden pl-11 lg:block">
            <div
              style={{ marginLeft: visible ? "150px" : "0px" }}
              className="ml-12 cursor-pointer pr-2 text-lg"
            >
              <i onClick={toggleSidebar} className="toggle-icon">
                {sidebarIcon}
              </i>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {sidebarItems.map((item, index) => {
              if (item.hide) return null;

              const isActive = location.pathname
                .toLocaleLowerCase()
                .includes(item?.path?.toLocaleLowerCase());

              return (
                <div key={index}>
                  <div
                    onClick={(e) => {
                      !item.children && navigate(item.path);
                      if (item.children) setToggledSubMenu(!toggledSubMenu);
                      if (!setShowSidebar) return;
                      if (!item.children) setShowSidebar(false);
                    }}
                    data-pr-position="right"
                    data-pr-tooltip={visible ? undefined : item.name}
                    className={`side-bar-item flex cursor-pointer items-center justify-between gap-4 rounded-lg px-5 py-3 transition-all duration-500 hover:bg-light-purple hover:text-light-text ${isActive ? "bg-light-purple text-light-text" : ""}`}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-lg">{item.icon}</div>
                        <div
                          className="text-lg"
                          style={{ display: visible ? "block" : "none" }}
                        >
                          {item.name}
                        </div>
                      </div>
                      {item.children && visible && (
                        <i
                          className={`pi ${toggledSubMenu ? "pi-angle-up" : "pi-angle-down"} ease transition-opacity duration-300`}
                        ></i>
                      )}
                    </div>
                  </div>

                  {item?.children && (
                    <div
                      className={`transform ${toggledSubMenu ? "max-h-screen" : "max-h-0"} overflow-hidden transition-all duration-300`}
                    >
                      <div
                        className={`${visible ? "pl-4" : "flex items-center justify-center"} pt-2`}
                      >
                        <div
                          className={`flex flex-col gap-2 border-l border-gray-500 pl-1`}
                        >
                          {item.children.map((child, index) => {
                            const isActiveChild = location.pathname
                              .toLocaleLowerCase()
                              .includes(child.path.toLocaleLowerCase());

                            return (
                              <NavLink
                                data-pr-position="right"
                                data-pr-tooltip={
                                  visible ? undefined : child.label
                                }
                                to={child.path}
                                key={index}
                                onClick={() => {
                                  if (!setShowSidebar) return;
                                  setShowSidebar(false);
                                }}
                                className={`side-bar-item flex items-center gap-2 rounded-lg p-2 text-sm transition-all duration-500 hover:bg-[#3B4FC426] ${isActiveChild ? "bg-[#3B4FC426]" : ""}`}
                              >
                                {child?.icon}
                                {visible ? child.label : ""}
                              </NavLink>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {isSystemAdminImpersonating && (
            <div
              onClick={() => {
                unPersonate();
              }}
              className="my-2 mr-2 cursor-pointer items-center justify-between gap-4 rounded-lg px-5 py-3 transition-all duration-500 hover:bg-light-purple hover:text-light-text"
            >
              <div className="flex">
                <div className="pr-2 text-lg">
                  <i className="pi pi-times" />
                </div>
                <div
                  style={{ display: visible ? "block" : "none" }}
                  className="text-lg capitalize"
                >
                  Cancel impersonation
                </div>
              </div>
            </div>
          )}

          {!isSystemAdminImpersonating && (
            <div
              onClick={() => {
                logOut();
              }}
              data-pr-position="right"
              data-pr-tooltip={visible ? undefined : "Log Out"}
              className="side-bar-item my-2 cursor-pointer items-center justify-between gap-4 rounded-lg px-5 py-3 transition-all duration-500 hover:bg-light-purple hover:text-light-text"
            >
              <div className="flex">
                <div className="pr-2 text-lg">
                  <i className="pi pi-power-off" />
                </div>
                <div
                  style={{ display: visible ? "block" : "none" }}
                  className="text-lg"
                >
                  Log Out
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ReusableSidebar;
