function GroupPracticeIcon() {
  return (
    <svg
      width="95"
      height="90"
      viewBox="0 0 69 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69 47.2535C69 66.2137 53.5542 81.5866 34.4989 81.5866C15.4435 81.5866 0 66.2137 0 47.2535C0 28.2933 2.92014 0 34.5011 0C66.0822 0 69.0023 28.291 69.0023 47.2535H69Z"
        fill="white"
      />

      <path
        d="M21.1192 41.7539L15.1484 43.5545C15.1484 43.5545 12.6016 47.2171 12.9497 51.764C13.3001 56.3086 14.5414 67.6473 14.5414 67.6473C14.5414 67.6473 14.2071 71.041 14.4155 72.7252C14.5666 73.9332 15.579 81.2173 16.1149 85.6024H18.2036C18.3113 83.9455 18.5357 82.4367 18.5357 82.4367C18.5357 82.4367 19.2389 75.6539 19.2389 68.037C19.2389 66.4849 20.3565 53.8676 20.3565 53.8676L21.1215 41.7494L21.1192 41.7539Z"
        fill="#82BCBB"
      />
      <path
        d="M19.5116 41.7539L25.4824 43.5545C25.4824 43.5545 28.0292 47.2171 27.6811 51.764C27.3307 56.3086 26.0893 67.6473 26.0893 67.6473C26.0893 67.6473 26.4237 71.041 26.2153 72.7252C26.0641 73.9332 25.0518 81.2173 24.5159 85.6024H22.4271C22.3195 83.9455 22.095 82.4367 22.095 82.4367C22.095 82.4367 21.3919 75.6539 21.3919 68.037C21.3919 66.4849 20.2742 53.8676 20.2742 53.8676L19.5093 41.7494L19.5116 41.7539Z"
        fill="#82BCBB"
      />
      <path
        d="M13.9256 14.402C13.9256 14.402 15.215 22.2468 10.6733 22.1397L19.1429 24.7379H21.4217L29.8913 22.1397C25.3496 22.2468 26.6391 14.402 26.6391 14.402"
        fill="#5D3F3F"
      />
      <path
        d="M26.7489 12.982C26.7489 16.9067 23.8494 23.4935 20.2719 23.4935C16.6944 23.4935 13.7949 16.909 13.7949 12.982C13.7949 9.05508 16.6944 5.87567 20.2719 5.87567C23.8494 5.87567 26.7489 9.05736 26.7489 12.982Z"
        fill="#5D3F3F"
      />
      <path
        d="M22.7777 21.1961L22.4021 17.1917H18.1421L17.7665 21.1961L14.7456 21.7568L17.2443 28.2911H23.2999L25.7986 21.7568L22.7777 21.1961Z"
        fill="#FFD6BB"
      />
      <path
        d="M18.1422 17.1917L17.856 20.2343L22.59 19.1996L22.4022 17.1917H18.1422Z"
        fill="#FFBFA6"
      />
      <path
        d="M24.628 14.7734C24.628 17.1665 22.6789 19.1084 20.2718 19.1084C17.8647 19.1084 15.918 17.1688 15.918 14.7734C15.918 7.56907 17.867 7.29785 20.2718 7.29785C22.6767 7.29785 24.628 8.34854 24.628 14.7734Z"
        fill="#FFD6BB"
      />
      <path
        d="M23.6411 14.0875C23.6411 14.835 24.0717 15.4413 24.6008 15.4413C25.1298 15.4413 25.5627 14.835 25.5627 14.0875C25.5627 13.3399 25.1321 12.7336 24.6008 12.7336C24.0694 12.7336 23.6411 13.3399 23.6411 14.0875Z"
        fill="#FFD6BB"
      />
      <path
        d="M14.979 14.0875C14.979 14.835 15.4096 15.4413 15.9386 15.4413C16.4677 15.4413 16.9006 14.835 16.9006 14.0875C16.9006 13.3399 16.47 12.7336 15.9386 12.7336C15.4073 12.7336 14.979 13.3399 14.979 14.0875Z"
        fill="#FFD6BB"
      />
      <path
        d="M25.9588 29.3281L14.7455 29.7726L12.6865 47.8963H27.9422L25.9588 29.3281Z"
        fill="#3D3877"
      />
      <path
        d="M21.5315 30.5771C21.5315 33.0158 19.5458 34.9895 17.0975 34.9895C14.6491 34.9895 12.6611 33.0135 12.6611 30.5771C12.6611 28.1407 14.6468 26.1647 17.0975 26.1647C19.5481 26.1647 21.5315 28.1407 21.5315 30.5771Z"
        fill="#3D3877"
      />
      <path
        d="M19.0967 30.5771C19.0967 33.0158 21.0824 34.9895 23.5307 34.9895C25.979 34.9895 27.967 33.0135 27.967 30.5771C27.967 28.1407 25.9813 26.1647 23.5307 26.1647C21.0801 26.1647 19.0967 28.1407 19.0967 30.5771Z"
        fill="#3D3877"
      />
      <path
        d="M13.5388 35.7029C13.5388 35.7029 12.8311 46.2849 12.6868 47.8963H18.5431V27.7851L17.5262 21.1916C15.0252 20.8998 12.6685 22.3949 12.6685 22.3949L14.0816 28.9543L13.5365 35.7051L13.5388 35.7029Z"
        fill="#3D3877"
      />
      <path
        d="M27.9422 47.8963C27.8002 46.2849 27.0903 35.7029 27.0903 35.7029L26.5475 28.952L27.9606 22.3927C27.9606 22.3927 25.9863 21.5152 23.1028 21.1893L22.0859 27.7828V47.894H27.9445L27.9422 47.8963Z"
        fill="#3D3877"
      />
      <path
        d="M23.1026 21.1824C23.1026 21.1824 22.5598 22.7414 20.2833 22.7414C18.0067 22.7414 17.4479 21.1824 17.4479 21.1824L16.0508 23.9356L20.2214 29.0432L24.7585 23.7943L23.1003 21.1824H23.1026Z"
        fill="#594FC4"
      />
      <path
        d="M23.0409 15.7945C22.3882 14.8738 20.2238 14.7097 20.2238 14.7097C20.2238 14.7097 18.0618 14.8738 17.4068 15.7945C17.4068 15.7945 16.793 17.317 17.5098 18.4224C17.5098 18.4224 18.5542 19.015 20.2238 18.999C21.8935 19.0172 22.9378 18.4224 22.9378 18.4224C23.6524 17.317 23.0409 15.7945 23.0409 15.7945Z"
        fill="white"
      />
      <path
        d="M24.8502 15.3706C24.7815 15.4025 24.7105 15.4185 24.6395 15.4253C24.4837 15.9039 24.2684 16.4122 24.0005 16.9136C23.6249 17.611 22.9721 18.0805 22.4133 18.0532C22.1934 18.044 22.0056 17.9574 21.8613 17.8002C21.7583 17.6862 21.7125 17.57 21.7193 17.4423C21.7445 17.0275 22.3194 16.5307 22.9286 16.0042C23.0225 15.9222 23.1187 15.8401 23.2126 15.7581C23.6867 15.341 23.9547 14.525 24.1677 13.8732C24.2822 13.5199 24.4356 12.8271 24.5547 12.7359C24.5547 12.663 24.5547 12.59 24.5479 12.5148C24.2982 12.5581 24.1608 13.1986 23.9615 13.8071C23.7554 14.4362 23.4989 15.2202 23.066 15.6008C22.9721 15.6828 22.8782 15.7649 22.7843 15.8469C22.1133 16.4281 21.5315 16.9295 21.5018 17.4309C21.4903 17.6156 21.5567 17.7865 21.6987 17.9438C21.8819 18.142 22.1247 18.256 22.4018 18.2674C23.05 18.297 23.7692 17.7956 24.1883 17.0161C24.4677 16.4965 24.7105 15.9199 24.88 15.3569C24.8685 15.3615 24.8593 15.3706 24.8502 15.3752V15.3706Z"
        fill="white"
      />
      <path
        d="M15.6754 15.3706C15.7441 15.4025 15.8151 15.4185 15.8861 15.4253C16.0419 15.9039 16.2549 16.4122 16.5251 16.9136C16.9007 17.611 17.5535 18.0805 18.1123 18.0532C18.3322 18.044 18.52 17.9574 18.6643 17.8002C18.7673 17.6862 18.8154 17.57 18.8086 17.4423C18.7834 17.0275 18.2085 16.5307 17.5993 16.0042C17.5054 15.9222 17.4115 15.8401 17.3153 15.7581C16.8389 15.341 16.5732 14.525 16.3602 13.8732C16.2457 13.5199 16.0923 12.8271 15.9732 12.7359C15.9732 12.663 15.9732 12.59 15.98 12.5148C16.2297 12.5581 16.3671 13.1986 16.5664 13.8071C16.7725 14.4362 17.029 15.2202 17.4619 15.6008C17.5558 15.6828 17.6497 15.7649 17.7436 15.8469C18.4169 16.4281 18.9964 16.9295 19.0261 17.4309C19.0376 17.6156 18.9712 17.7865 18.8292 17.9438C18.646 18.142 18.4032 18.256 18.1261 18.2674C17.4779 18.297 16.7587 17.7956 16.3396 17.0161C16.0602 16.4965 15.8174 15.9199 15.6479 15.3569C15.6594 15.3615 15.6686 15.3706 15.6777 15.3752L15.6754 15.3706Z"
        fill="white"
      />
      <path
        d="M18.561 6.91724V9.2488C18.561 9.2488 22.5805 12.255 25.2739 12.255C25.2739 12.255 26.3687 7.05854 18.561 6.91724Z"
        fill="#5D3F3F"
      />
      <path
        d="M17.6015 6.91724L16.6235 9.20094C16.6235 9.20094 19.404 11.7673 21.298 12.018C21.298 12.018 23.0753 7.74685 17.6015 6.91724Z"
        fill="#5D3F3F"
      />
      <path
        d="M20.2835 7.29787L19.8255 9.39924C19.8255 9.39924 17.7963 12.4009 15.5472 12.8339C15.5472 12.8339 13.7882 8.6722 20.2835 7.29559V7.29787Z"
        fill="#5D3F3F"
      />
      <path
        d="M30.1109 25.7772C29.7078 24.3071 27.9626 22.3926 27.9626 22.3926L26.1899 29.809L27.5985 33.6949L31.3752 33.2847C31.1645 29.7907 30.2896 26.4313 30.1109 25.7772Z"
        fill="#7DD3FC"
      />
      <path
        d="M29.6646 43.2787C29.6646 43.2787 28.4233 43.4793 28.4141 44.4365C28.4073 45.0314 28.9203 44.6736 28.9203 44.6736C28.9203 44.6736 28.6935 46.5903 29.5662 46.9276C30.4388 47.2649 32.5916 48.1128 32.0832 44.5528C31.5748 40.9927 29.6646 43.281 29.6646 43.281"
        fill="#FFDECC"
      />
      <path
        d="M29.0392 33.6904C28.7002 33.5308 28.359 33.3713 28.02 33.2095C28.0475 33.3143 28.0612 33.3758 28.0612 33.3758L29.4812 43.6092L31.6318 43.2673C31.6318 43.2673 31.4898 37.9911 31.3455 34.7342C30.3951 34.3513 29.9622 34.1234 29.0392 33.6881V33.6904Z"
        fill="#FFDECC"
      />
      <path
        d="M27.5985 33.6949L29.0597 42.0389L31.9546 42.4537C31.9546 42.4537 31.8791 38.0276 31.3729 33.2847L29.3506 32.0494L27.5962 33.6972L27.5985 33.6949Z"
        fill="#7DD3FC"
      />
      <path
        d="M10.52 25.7772C10.923 24.3071 12.6683 22.3926 12.6683 22.3926L14.441 29.809L13.0301 33.6949L9.25342 33.2847C9.46413 29.7907 10.339 26.4313 10.5177 25.7772H10.52Z"
        fill="#7DD3FC"
      />
      <path
        d="M10.9664 43.2787C10.9664 43.2787 12.2077 43.4793 12.2169 44.4365C12.2238 45.0314 11.7107 44.6736 11.7107 44.6736C11.7107 44.6736 11.9375 46.5903 11.0649 46.9276C10.1923 47.2649 8.04167 48.1128 8.54783 44.5528C9.05627 40.9927 10.9664 43.281 10.9664 43.281"
        fill="#FFDECC"
      />
      <path
        d="M11.5915 33.6904C11.9305 33.5308 12.2717 33.3713 12.6107 33.2095C12.5832 33.3143 12.5672 33.3758 12.5672 33.3758L11.1472 43.6092L8.99658 43.2673C8.99658 43.2673 9.13858 37.9911 9.28287 34.7342C10.2311 34.3513 10.6662 34.1234 11.5892 33.6881L11.5915 33.6904Z"
        fill="#FFDECC"
      />
      <path
        d="M13.0323 33.6949L11.5711 42.0389L8.67383 42.4537C8.67383 42.4537 8.74941 38.0276 9.25557 33.2847L11.2779 32.0494L13.0323 33.6972V33.6949Z"
        fill="#7DD3FC"
      />
      <path
        d="M51.3212 12.4259C51.3212 15.5483 48.779 18.0782 45.6412 18.0782C42.5035 18.0782 40.4033 15.5483 40.4033 12.4259C40.4033 9.30347 42.5035 6.77362 45.6412 6.77362C48.779 6.77362 51.3212 9.30347 51.3212 12.4259Z"
        fill="#5D3F3F"
      />
      <path
        d="M53.5221 12.4259C53.5221 15.5483 51.2112 18.0782 48.0735 18.0782C44.9358 18.0782 42.3936 15.5483 42.3936 12.4259C42.3936 9.30347 44.9358 6.77362 48.0735 6.77362C51.2112 6.77362 53.5221 9.30347 53.5221 12.4259Z"
        fill="#5D3F3F"
      />
      <path
        d="M53.2359 11.528C53.2359 14.6504 50.6936 17.1802 47.5559 17.1802C44.4182 17.1802 41.876 14.6504 41.876 11.528C41.876 8.40552 44.4182 5.87567 47.5559 5.87567C50.6936 5.87567 53.2359 8.40552 53.2359 11.528Z"
        fill="#5D3F3F"
      />
      <path
        d="M43.7061 7.51893C43.7061 7.51893 44.3954 5.83008 45.5337 5.2489L44.9634 7.51893"
        fill="#5D3F3F"
      />
      <path
        d="M45.3479 6.84204C45.3479 6.84204 43.534 6.58905 42.4575 7.27736L44.718 7.92463"
        fill="#5D3F3F"
      />
      <path
        d="M48.6667 41.9249L40.9805 43.7027C40.9805 43.7027 38.4749 47.3516 38.5024 51.9121C38.5528 59.994 38.7749 67.3739 38.7749 67.3739C38.7749 67.3739 38.2756 70.747 38.4039 72.4404C38.4955 73.6552 38.6352 80.953 38.9558 85.3609L42.8608 85.5478C43.0486 83.8977 43.3464 82.4025 43.3464 82.4025C43.3464 82.4025 44.377 75.6631 44.7457 68.0553C44.8213 66.5055 47.3132 53.993 47.3132 53.993L48.6667 41.9272V41.9249Z"
        fill="#59A2D3"
      />
      <path
        d="M44.959 41.9249L52.6452 43.7027C52.6452 43.7027 55.1531 47.3516 55.1234 51.9121C55.073 59.994 54.8508 67.3739 54.8508 67.3739C54.8508 67.3739 55.3501 70.747 55.2218 72.4404C55.1302 73.6552 54.9905 80.953 54.6699 85.3609L50.7649 85.5478C50.5771 83.8977 50.2794 82.4025 50.2794 82.4025C50.2794 82.4025 49.2487 75.6631 48.88 68.0553C48.8044 66.5055 46.3126 53.993 46.3126 53.993L44.9613 41.9272L44.959 41.9249Z"
        fill="#59A2D3"
      />
      <path
        d="M49.322 21.1095L48.9464 17.105H44.6864L44.3108 21.1095L41.2876 21.6702L43.7863 28.2045H49.8442L52.3429 21.6702L49.322 21.1095Z"
        fill="#FFD6BB"
      />
      <path
        d="M44.6862 17.105L44.3999 20.1477L49.134 19.113L48.9462 17.105H44.6862Z"
        fill="#FFBFA6"
      />
      <path
        d="M51.17 14.6846C51.17 17.0777 49.2209 19.0195 46.8138 19.0195C44.4067 19.0195 42.46 17.08 42.46 14.6846C42.46 7.4802 44.409 7.20898 46.8138 7.20898C49.2186 7.20898 51.17 8.25967 51.17 14.6846Z"
        fill="#FFD6BB"
      />
      <path
        d="M50.1855 14.0008C50.1855 14.7484 50.6161 15.3547 51.1452 15.3547C51.6742 15.3547 52.1071 14.7484 52.1071 14.0008C52.1071 13.2533 51.6765 12.647 51.1452 12.647C50.6138 12.647 50.1855 13.2533 50.1855 14.0008Z"
        fill="#FFD6BB"
      />
      <path
        d="M41.5234 14.0008C41.5234 14.7484 41.954 15.3547 42.4831 15.3547C43.0121 15.3547 43.445 14.7484 43.445 14.0008C43.445 13.2533 43.0144 12.647 42.4831 12.647C41.9517 12.647 41.5234 13.2533 41.5234 14.0008Z"
        fill="#FFD6BB"
      />
      <path
        d="M49.5834 15.7079C48.9307 14.7894 46.7663 14.623 46.7663 14.623C46.7663 14.623 44.6043 14.7871 43.9492 15.7079C43.9492 15.7079 43.3354 17.2303 44.0523 18.3357C44.0523 18.3357 45.0967 18.9283 46.7663 18.9123C48.4359 18.9306 49.4803 18.3357 49.4803 18.3357C50.1949 17.2303 49.5834 15.7079 49.5834 15.7079Z"
        fill="white"
      />
      <path
        d="M51.3925 15.284C51.3238 15.3159 51.2528 15.3318 51.1818 15.3387C51.026 15.8173 50.813 16.3255 50.5428 16.8269C50.1672 17.5244 49.5144 17.9939 48.9556 17.9665C48.7334 17.9551 48.5479 17.8708 48.4036 17.7112C48.3006 17.5973 48.2548 17.481 48.2616 17.3534C48.2868 16.9386 48.8617 16.4418 49.4709 15.9153C49.5648 15.8332 49.6587 15.7512 49.7549 15.6691C50.2313 15.252 50.497 14.4384 50.71 13.7843C50.8245 13.431 50.9779 12.7381 51.097 12.647C51.097 12.574 51.097 12.5011 51.0902 12.4259C50.8405 12.4692 50.7031 13.1096 50.5038 13.7182C50.2977 14.3472 50.0412 15.1312 49.6106 15.5119C49.5167 15.5939 49.4228 15.676 49.3289 15.758C48.6579 16.3392 48.0761 16.8406 48.0486 17.342C48.0372 17.5266 48.1036 17.6976 48.2456 17.8525C48.4288 18.0508 48.6716 18.1648 48.9487 18.1762C49.5969 18.2058 50.3137 17.7044 50.7352 16.9249C51.0146 16.4053 51.2573 15.8287 51.4268 15.2657C51.4154 15.2703 51.4085 15.2794 51.3971 15.284H51.3925Z"
        fill="white"
      />
      <path
        d="M42.2199 15.284C42.2886 15.3159 42.3596 15.3318 42.4306 15.3387C42.5863 15.8173 42.7993 16.3255 43.0696 16.8269C43.4452 17.5244 44.0979 17.9939 44.6567 17.9665C44.8766 17.9551 45.0621 17.8708 45.2087 17.7112C45.3118 17.5973 45.3599 17.481 45.353 17.3534C45.3278 16.9386 44.7529 16.4418 44.1437 15.9153C44.0498 15.8332 43.9559 15.7512 43.8597 15.6691C43.3833 15.252 43.1177 14.4384 42.9047 13.7843C42.7902 13.431 42.6367 12.7381 42.5176 12.647C42.5176 12.574 42.5176 12.5011 42.5245 12.4259C42.7741 12.4692 42.9115 13.1096 43.1108 13.7182C43.3169 14.3472 43.5734 15.1312 44.0063 15.5119C44.1002 15.5939 44.1941 15.676 44.288 15.758C44.9591 16.3392 45.5408 16.8406 45.5706 17.342C45.582 17.5266 45.5156 17.6976 45.3736 17.8525C45.1904 18.0508 44.9476 18.1648 44.6705 18.1762C44.0223 18.2058 43.3032 17.7044 42.8841 16.9249C42.6046 16.4053 42.3619 15.8287 42.1924 15.2657C42.2038 15.2703 42.213 15.2794 42.2244 15.284H42.2199Z"
        fill="white"
      />
      <path
        d="M42.0068 11.92L42.3939 10.7348L42.4832 13.1165L44.1093 10.8419C44.1093 10.8419 45.5843 11.7148 47.5333 12.3576L46.5119 10.3929C46.5119 10.3929 49.5099 12.7792 51.9101 12.7838C51.9101 12.7838 51.5093 6.67337 46.4294 6.88533C43.1726 7.02208 42.0572 9.8619 42.0068 11.9222V11.92Z"
        fill="#5D3F3F"
      />
      <path
        d="M43.6647 21.0069C41.095 20.6308 38.6741 21.8205 38.6741 21.8205L40.1239 30.9987L39.565 39.689C39.565 39.689 38.5596 49.854 38.6718 49.6649L44.7068 49.5395V29.4921L43.6624 21.0046L43.6647 21.0069Z"
        fill="#0077AA"
      />
      <path
        d="M54.0631 39.689L53.5043 30.9987L54.9563 21.8205C54.9563 21.8205 52.5355 20.6308 49.9658 21.0069L48.9214 29.4944V49.2501L54.9563 49.6671C55.0686 49.8563 54.0631 39.6913 54.0631 39.6913V39.689Z"
        fill="#0077AA"
      />
      <path
        d="M56.5111 43.1967C56.5111 43.1967 55.2628 43.3494 55.217 44.3066C55.1873 44.9015 55.714 44.5619 55.714 44.5619C55.714 44.5619 55.414 46.4672 56.2729 46.8387C57.1317 47.2102 59.2503 48.1378 58.8792 44.5596C58.5105 40.9836 56.5133 43.1967 56.5133 43.1967"
        fill="#FFDECC"
      />
      <path
        d="M57.8921 29.5195L54.0215 31.156L54.4337 32.6784L56.3164 43.5203L58.4784 43.2605L58.9227 33.214L57.8921 29.5195Z"
        fill="#FFDECC"
      />
      <path
        d="M54.9562 21.8206C54.9562 21.8206 56.2021 23.0308 56.7266 24.2798C57.2488 25.5264 58.923 31.3838 58.923 31.3838L54.3401 32.7741L52.7598 30.1052L54.9562 21.8206Z"
        fill="#0077AA"
      />
      <path
        d="M54.338 32.7741L58.9209 31.3839C58.9209 31.3839 58.6759 30.5246 58.3438 29.3942L53.8662 31.9764L54.338 32.7741Z"
        fill="#59A2D3"
      />
      <path
        d="M37.1194 43.1967C37.1194 43.1967 38.3676 43.3494 38.4134 44.3066C38.4432 44.9015 37.9164 44.5619 37.9164 44.5619C37.9164 44.5619 38.2164 46.4672 37.3575 46.8387C36.4987 47.2102 34.3801 48.1378 34.7512 44.5596C35.1222 40.9836 37.1194 43.1967 37.1194 43.1967Z"
        fill="#FFDECC"
      />
      <path
        d="M35.7383 29.5195L39.6089 31.156L39.1967 32.6784L37.314 43.5203L35.152 43.2605L34.71 33.214L35.7383 29.5195Z"
        fill="#FFDECC"
      />
      <path
        d="M38.6743 21.8206C38.6743 21.8206 37.4284 23.0308 36.9039 24.2798C36.3817 25.5264 34.7075 31.3838 34.7075 31.3838L39.2904 32.7741L40.8707 30.1052L38.6743 21.8206Z"
        fill="#0077AA"
      />
      <path
        d="M34.71 31.3838L39.2929 32.7741L39.7578 31.9901L35.2825 29.4101C34.9527 30.5314 34.71 31.3838 34.71 31.3838Z"
        fill="#59A2D3"
      />
      <path
        d="M49.9655 21.0069L46.8163 23.4319L43.4519 20.9796L38.6743 49.6649H54.9561L52.107 34.3148L49.9655 21.0069Z"
        fill="#0077AA"
      />
      <path
        opacity="0.4"
        d="M52.3428 30.0027L50.3869 30.9895L48.4287 30.0027V26.821H52.3428V30.0027Z"
        fill="white"
      />
      <path
        d="M52.6541 26.625H48.1147V27.6552H52.6541V26.625Z"
        fill="#59A2D3"
      />
      <path
        d="M22.3306 24.7925L27.5685 23.7054L26.5264 31.2721C26.5264 31.2721 22.4084 31.7895 21.9275 30.4106C21.4465 29.0317 22.3306 24.7925 22.3306 24.7925Z"
        fill="#F4C5B0"
      />
      <path
        d="M45.4648 24.7925L40.2246 23.7054L41.2667 31.2721C41.2667 31.2721 45.3847 31.7895 45.8656 30.4106C46.3466 29.0317 45.4648 24.7925 45.4648 24.7925Z"
        fill="#F9D0BB"
      />
      <path
        d="M39.416 7.76963C39.416 10.8351 36.9196 13.3193 33.8391 13.3193C30.7586 13.3193 28.2622 10.8351 28.2622 7.76963C28.2622 4.70418 29.7807 2.15154 33.8391 2.21991C36.9173 2.27233 39.416 4.70418 39.416 7.76963Z"
        fill="#AA724F"
      />
      <path
        d="M35.7405 37.0498L28.0542 38.8275C28.0542 38.8275 25.5486 42.4764 25.5761 47.037C25.6265 55.1189 25.8486 62.4987 25.8486 62.4987C25.8486 62.4987 25.3494 65.8719 25.4776 67.5653C25.5692 68.7801 25.7089 76.0779 26.0296 80.4858L29.9345 80.6726C30.1223 79.0225 30.4201 77.5274 30.4201 77.5274C30.4201 77.5274 31.4507 70.7857 31.8195 63.1802C31.895 61.6304 34.3869 49.1179 34.3869 49.1179L35.7405 37.0521V37.0498Z"
        fill="#D9D9D9"
      />
      <path
        d="M32.0322 37.0498L39.7162 38.8275C39.7162 38.8275 42.2241 42.4764 42.1943 47.037C42.1439 55.1189 41.9218 62.4987 41.9218 62.4987C41.9218 62.4987 42.421 65.8719 42.2928 67.5653C42.2012 68.7801 42.0615 76.0779 41.7408 80.4858L37.8359 80.6726C37.6481 79.0225 37.3503 77.5274 37.3503 77.5274C37.3503 77.5274 36.3197 70.7857 35.9509 63.1802C35.8754 61.6304 33.3835 49.1179 33.3835 49.1179L32.0322 37.0521V37.0498Z"
        fill="#D9D9D9"
      />
      <path
        d="M36.3952 16.2321L36.0173 12.2299H31.7597L31.384 16.2321L28.3608 16.795L30.8596 23.3271H36.9174L39.4161 16.795L36.3952 16.2321Z"
        fill="#FFD6BB"
      />
      <path
        d="M31.7594 12.2299L31.4731 15.2703L36.2072 14.2356L36.0171 12.2299H31.7594Z"
        fill="#FFBFA6"
      />
      <path
        d="M38.2436 9.80946C38.2436 12.2026 36.2945 14.1444 33.8874 14.1444C31.4803 14.1444 29.5312 12.2048 29.5312 9.80946C29.5312 2.60508 31.4803 2.33386 33.8874 2.33386C36.2945 2.33386 38.2436 3.38455 38.2436 9.80946Z"
        fill="#FFD6BB"
      />
      <path
        d="M37.2588 9.12347C37.2588 9.87102 37.6894 10.4773 38.2184 10.4773C38.7475 10.4773 39.1804 9.87102 39.1804 9.12347C39.1804 8.37591 38.7498 7.76965 38.2184 7.76965C37.6871 7.76965 37.2588 8.37591 37.2588 9.12347Z"
        fill="#FFD6BB"
      />
      <path
        d="M28.5967 9.12347C28.5967 9.87102 29.0273 10.4773 29.5563 10.4773C30.0854 10.4773 30.5182 9.87102 30.5182 9.12347C30.5182 8.37591 30.0877 7.76965 29.5563 7.76965C29.025 7.76965 28.5967 8.37591 28.5967 9.12347Z"
        fill="#FFD6BB"
      />
      <path
        d="M36.6561 10.8305C36.0034 9.91198 33.8391 9.74561 33.8391 9.74561C33.8391 9.74561 31.6747 9.9097 31.022 10.8305C31.022 10.8305 30.4082 12.3529 31.1251 13.4583C31.1251 13.4583 32.1694 14.0509 33.8391 14.035C35.5087 14.0532 36.5531 13.4583 36.5531 13.4583C37.2699 12.3529 36.6561 10.8305 36.6561 10.8305Z"
        fill="white"
      />
      <path
        d="M38.4679 10.4088C38.3991 10.4408 38.3259 10.4567 38.2571 10.4635C38.1014 10.9422 37.8861 11.4504 37.6182 11.9518C37.2425 12.6492 36.5898 13.1188 36.031 13.0914C35.8111 13.0823 35.6233 12.9957 35.479 12.8384C35.3759 12.7245 35.3301 12.6082 35.337 12.4806C35.3622 12.0658 35.9371 11.5689 36.5463 11.0425C36.6402 10.9604 36.7341 10.8784 36.8303 10.7963C37.3044 10.3792 37.5723 9.56328 37.7853 8.91145C37.8999 8.55818 38.0533 7.86532 38.1724 7.77416C38.1701 7.70122 38.1724 7.62829 38.1655 7.55536C37.9159 7.59866 37.7785 8.2391 37.5792 8.84763C37.3731 9.47668 37.1166 10.2607 36.6837 10.6413C36.5898 10.7234 36.4959 10.8054 36.402 10.8875C35.7309 11.4687 35.1492 11.9701 35.1194 12.4715C35.108 12.6561 35.1744 12.827 35.3164 12.982C35.4996 13.1803 35.7424 13.2942 36.0195 13.3056C36.67 13.3353 37.3868 12.8316 37.806 12.0544C38.0854 11.5347 38.3281 10.9581 38.4976 10.3952C38.4862 10.3997 38.4793 10.4088 38.4679 10.4134V10.4088Z"
        fill="white"
      />
      <path
        d="M29.293 10.4088C29.3617 10.4408 29.4327 10.4567 29.5037 10.4635C29.6594 10.9422 29.8724 11.4504 30.1427 11.9518C30.5183 12.6492 31.171 13.1188 31.7298 13.0914C31.9497 13.0823 32.1375 12.9957 32.2818 12.8384C32.3849 12.7245 32.433 12.6082 32.4261 12.4806C32.4009 12.0658 31.826 11.5689 31.2168 11.0425C31.1229 10.9604 31.029 10.8784 30.9328 10.7963C30.4564 10.3792 30.1908 9.56328 29.9778 8.91145C29.8632 8.55818 29.7098 7.86532 29.5907 7.77416C29.5907 7.70122 29.5907 7.62829 29.5976 7.55536C29.8472 7.59866 29.9846 8.2391 30.1839 8.84763C30.39 9.47668 30.6465 10.2607 31.0794 10.6413C31.1733 10.7234 31.2672 10.8054 31.3611 10.8875C32.0345 11.4687 32.6139 11.9701 32.6437 12.4715C32.6551 12.6561 32.5887 12.827 32.4444 12.982C32.2612 13.1803 32.0184 13.2942 31.7413 13.3056C31.0931 13.3353 30.374 12.8316 29.9549 12.0544C29.6754 11.5347 29.4327 10.9581 29.2632 10.3952C29.2746 10.3997 29.2838 10.4088 29.2952 10.4134L29.293 10.4088Z"
        fill="white"
      />
      <path
        d="M30.7381 16.1295C28.1684 15.7535 25.7613 16.9432 25.7613 16.9432L27.1996 26.1213L26.6407 34.8117C26.6407 34.8117 25.6353 44.9767 25.7498 44.7898L31.7848 44.6644V24.6171L30.7404 16.1295H30.7381Z"
        fill="white"
      />
      <path
        d="M41.1364 34.8117L40.5775 26.1213L42.0296 16.9432C42.0296 16.9432 39.611 15.7535 37.039 16.1295L35.9946 24.6171V44.3727L42.0296 44.7898C42.1418 44.9767 41.1364 34.8117 41.1364 34.8117Z"
        fill="white"
      />
      <path
        d="M37.0388 16.1295L33.8896 18.5568L30.5251 16.1022L25.7476 44.7898H42.0293L39.1802 29.4397L37.0388 16.1295Z"
        fill="white"
      />
      <path
        d="M28.8877 7.04259L29.2748 5.85743L29.3641 8.23914L30.8551 6.65057C30.8551 6.65057 31.9453 7.55084 34.2058 7.88131L34.1898 6.64602C34.1898 6.64602 36.5465 8.23458 38.9467 8.23914C38.9467 8.23914 38.9467 2.00568 33.3126 2.00568C30.0512 2.00568 28.9404 4.98224 28.89 7.04259H28.8877Z"
        fill="#AA724F"
      />
      <path
        d="M28.2645 8.10694C28.2645 8.10694 30.6533 6.99016 33.775 6.99016C36.8967 6.99016 39.4183 8.10694 39.4183 8.10694C39.4183 8.10694 41.2322 1.66608 34.0155 1.66608C26.7987 1.66608 28.2668 8.10694 28.2668 8.10694H28.2645Z"
        fill="white"
      />
      <path
        d="M39.4162 23.3271H35.7402V26.7344H39.4162V23.3271Z"
        fill="white"
      />
      <path
        d="M38.8411 23.0148H36.3149V23.6393H38.8411V23.0148Z"
        fill="#4B5863"
      />
      <path
        d="M43.7998 19.4024C43.2753 18.1557 42.0294 16.9432 42.0294 16.9432L39.833 25.2279L40.4354 26.7321L45.8015 25.4695C45.8015 25.4695 44.3243 20.6468 43.7998 19.4001V19.4024Z"
        fill="white"
      />
      <path
        d="M24.0024 19.4024C24.5268 18.1557 25.759 16.9432 25.759 16.9432L27.9692 25.2279L27.3691 26.7321L22.0029 25.4695C22.0029 25.4695 23.4825 20.6468 24.0047 19.4001L24.0024 19.4024Z"
        fill="white"
      />
      <path
        d="M37.325 28.5258C37.325 28.5258 39.1321 27.9423 40.4169 27.3087C40.4169 27.3087 40.6299 25.4991 37.9755 25.4854C35.8065 25.474 35.6416 26.3697 35.5775 26.6136C35.5111 26.8574 31.061 27.9537 31.061 27.9537"
        fill="#F4C5B0"
      />
      <path
        d="M26.5262 31.2722L26.9591 27.0102L28.8371 26.9144L36.7776 26.7002L37.325 28.5258L30.816 30.6021C30.816 30.6021 28.8348 30.9759 26.5239 31.2722"
        fill="#F4C5B0"
      />
      <path
        d="M41.2666 31.2722L40.8337 27.0102L38.9556 26.9144L31.0152 26.7002L30.4678 28.5258L36.9768 30.6021C36.9768 30.6021 38.9579 30.9759 41.2689 31.2722"
        fill="#F9D0BB"
      />
      <path
        d="M30.5458 28.5486C30.5458 28.5486 28.922 27.8238 27.5524 26.9783C27.5524 26.9783 27.3394 25.1686 29.9939 25.155C32.1628 25.1436 32.3277 26.0393 32.3941 26.2831C32.4605 26.527 36.9106 27.6233 36.9106 27.6233"
        fill="#F9D0BB"
      />
      <path
        d="M1.05157 42.5608L4.86493 44.0058L4.47787 45.5351L2.78762 56.4089L0.620993 56.1855L0.353027 46.392L1.05157 42.5608Z"
        fill="#FFDECC"
      />
      <path
        d="M6.06478 41.5466C7.33819 39.1968 7.32216 34.3399 5.5655 31.3383C3.43551 27.7031 4.26002 22.4587 7.29697 20.8405C10.5973 19.081 13.7396 21.2189 13.7396 21.2189C13.7396 21.2189 17.175 20.6354 17.782 24.7447C18.3637 28.6854 14.3419 34.8208 16.9483 41.4463"
        fill="#5D3F3F"
      />
      <path
        d="M7.313 40.9631H7.0542C7.0542 36.1951 8.47419 33.777 11.2752 33.777C14.0763 33.777 15.739 36.0766 15.739 40.4275H15.4779C15.4779 36.186 14.0648 34.0368 11.2752 34.0368C8.64825 34.0368 7.31529 36.3661 7.31529 40.9631H7.313Z"
        fill="#C5C5C5"
      />
      <path
        d="M12.267 56.0579L6.29623 57.8584C6.29623 57.8584 3.74712 61.521 4.09753 66.0679C4.44795 70.6125 5.68929 81.9513 5.68929 81.9513C5.68929 81.9513 5.35491 85.3472 5.56333 87.0292C5.71449 88.2371 6.72909 95.5213 7.26273 99.9064H9.35149C9.45914 98.2472 9.68359 96.7406 9.68359 96.7406C9.68359 96.7406 10.3867 89.9579 10.3867 82.341C10.3867 80.7889 11.5044 68.1716 11.5044 68.1716L12.2693 56.0533L12.267 56.0579Z"
        fill="#FFDECC"
      />
      <path
        d="M10.659 56.0579L16.6299 57.8584C16.6299 57.8584 19.1767 61.521 18.8285 66.0679C18.4781 70.6125 17.2368 81.9513 17.2368 81.9513C17.2368 81.9513 17.5712 85.3472 17.3627 87.0292C17.2116 88.2371 16.1993 95.5213 15.6633 99.9064H13.5746C13.4669 98.2472 13.2425 96.7406 13.2425 96.7406C13.2425 96.7406 12.5394 89.9579 12.5394 82.341C12.5394 80.7889 11.4217 68.1716 11.4217 68.1716L10.6567 56.0533L10.659 56.0579Z"
        fill="#FFDECC"
      />
      <path
        d="M13.925 35.5001L13.5494 31.4956H9.28944L8.91383 35.5001L5.89062 36.0607L8.38935 42.595H14.4472L16.9459 36.0607L13.925 35.5001Z"
        fill="#FFD6BB"
      />
      <path
        d="M9.28922 31.4956L9.00293 34.5383L13.737 33.5035L13.5492 31.4956H9.28922Z"
        fill="#FFBFA6"
      />
      <path
        d="M15.7753 29.0751C15.7753 31.4682 13.8263 33.4101 11.4191 33.4101C9.01204 33.4101 7.06299 31.4705 7.06299 29.0751C7.06299 21.8708 9.01204 21.5995 11.4191 21.5995C13.8263 21.5995 15.7753 22.6502 15.7753 29.0751Z"
        fill="#FFD6BB"
      />
      <path
        d="M14.7886 28.3914C14.7886 29.139 15.2192 29.7452 15.7482 29.7452C16.2773 29.7452 16.7101 29.139 16.7101 28.3914C16.7101 27.6439 16.2796 27.0376 15.7482 27.0376C15.2169 27.0376 14.7886 27.6439 14.7886 28.3914Z"
        fill="#FFD6BB"
      />
      <path
        d="M6.12646 28.3914C6.12646 29.139 6.55704 29.7452 7.0861 29.7452C7.61516 29.7452 8.04803 29.139 8.04803 28.3914C8.04803 27.6439 7.61745 27.0376 7.0861 27.0376C6.55475 27.0376 6.12646 27.6439 6.12646 28.3914Z"
        fill="#FFD6BB"
      />
      <path
        d="M12.6789 44.881C12.6789 47.3174 10.6932 49.2934 8.24491 49.2934C5.79658 49.2934 3.80859 47.3174 3.80859 44.881C3.80859 42.4446 5.79429 40.4686 8.24491 40.4686C10.6955 40.4686 12.6789 42.4446 12.6789 44.881Z"
        fill="#F7F7F7"
      />
      <path
        d="M10.2441 44.881C10.2441 47.3174 12.2298 49.2934 14.6805 49.2934C17.1311 49.2934 19.1168 47.3174 19.1168 44.881C19.1168 42.4446 17.1311 40.4686 14.6805 40.4686C12.2298 40.4686 10.2441 42.4446 10.2441 44.881Z"
        fill="#F7F7F7"
      />
      <path
        d="M14.6808 37.0453L11.3713 41.3392L7.74577 36.6373L5.89062 76.6181H17.2001L14.6808 37.0453Z"
        fill="#82BCBB"
      />
      <path
        d="M5.02502 62.9204L2.63623 83.7609H19.4814L17.9355 62.9204H5.02502Z"
        fill="#5D3F3F"
      />
      <path
        d="M8.67344 35.4932C6.17243 35.2015 3.81571 36.6351 3.81571 36.6351L5.22882 43.2537L4.68373 50.0045C4.68373 50.0045 2.59497 75.0067 2.45068 76.6181H8.30699L8.66886 67.5334V35.4932H8.67344Z"
        fill="#F7F7F7"
      />
      <path
        d="M18.2376 50.0045L17.6925 43.2537L19.1056 36.6351C19.1056 36.6351 16.7466 35.1969 14.2456 35.4886L13.2837 58.5376L13.3409 76.6158H19.9027C19.7607 75.0045 18.2353 50.0022 18.2353 50.0022L18.2376 50.0045Z"
        fill="#F7F7F7"
      />
      <path
        d="M14.1859 30.0984C13.5332 29.1776 11.3689 29.0135 11.3689 29.0135C11.3689 29.0135 9.20451 29.1776 8.55178 30.0984C8.55178 30.0984 7.93798 31.6209 8.65484 32.7263C8.65484 32.7263 9.69922 33.3189 11.3689 33.3029C13.0385 33.3211 14.0829 32.7263 14.0829 32.7263C14.7974 31.6209 14.1859 30.0984 14.1859 30.0984Z"
        fill="white"
      />
      <path
        d="M15.9976 29.6745C15.9289 29.7064 15.8579 29.7247 15.7869 29.7292C15.6312 30.2078 15.4159 30.7161 15.1479 31.2175C14.7723 31.9149 14.1196 32.3844 13.5608 32.3571C13.3386 32.3457 13.1531 32.2613 13.0088 32.1018C12.9057 31.9878 12.8599 31.8716 12.8668 31.744C12.892 31.3292 13.4669 30.8323 14.0761 30.3058C14.17 30.2238 14.2639 30.1417 14.3601 30.0597C14.8365 29.6426 15.1021 28.8267 15.3151 28.1748C15.4296 27.8216 15.5831 27.1287 15.7022 27.0375C15.6999 26.9646 15.7022 26.8917 15.6953 26.8165C15.4457 26.8598 15.3083 27.5002 15.109 28.1087C14.9029 28.7378 14.6464 29.5218 14.2135 29.9024C14.1196 29.9845 14.0257 30.0665 13.9318 30.1486C13.2607 30.7298 12.679 31.2312 12.6492 31.7326C12.6378 31.9172 12.7042 32.0881 12.8462 32.2431C13.0294 32.4414 13.2722 32.5554 13.5493 32.5667C14.1975 32.5964 14.9166 32.0927 15.3357 31.3155C15.6152 30.7959 15.8579 30.2192 16.0274 29.6563C16.016 29.6608 16.0068 29.67 15.9976 29.6745Z"
        fill="white"
      />
      <path
        d="M6.82274 29.6745C6.89145 29.7064 6.96245 29.7247 7.03345 29.7292C7.18919 30.2078 7.40219 30.7161 7.67245 31.2175C8.04806 31.9149 8.70079 32.3844 9.25963 32.3571C9.4795 32.3457 9.6673 32.2613 9.81159 32.1018C9.91465 31.9878 9.96275 31.8716 9.95359 31.744C9.9284 31.3292 9.35353 30.8323 8.74431 30.3058C8.65041 30.2238 8.5565 30.1417 8.46031 30.0597C7.98393 29.6426 7.71825 28.8267 7.50525 28.1748C7.39074 27.8216 7.23729 27.1287 7.11819 27.0375C7.11819 26.9646 7.11819 26.8917 7.12506 26.8165C7.37471 26.8598 7.51212 27.5002 7.71138 28.1087C7.91751 28.7378 8.17402 29.5218 8.60689 29.9024C8.70079 29.9845 8.7947 30.0665 8.8886 30.1486C9.55966 30.7298 10.1414 31.2312 10.1712 31.7326C10.1826 31.9172 10.1162 32.0881 9.9742 32.2431C9.79098 32.4414 9.54821 32.5554 9.27108 32.5667C8.62292 32.5964 7.90377 32.0927 7.48464 31.3155C7.20522 30.7959 6.96245 30.2192 6.79297 29.6563C6.80442 29.6608 6.81358 29.67 6.82274 29.6745Z"
        fill="white"
      />
      <path
        d="M12.1433 20.795L15.2489 24.4667C15.2489 24.4667 10.8767 28.4711 6.39233 27.2427C6.39233 27.2427 4.33564 20.9386 12.1433 20.795Z"
        fill="#5D3F3F"
      />
      <path
        d="M11.706 21.048L11.6763 23.7032C11.6763 23.7032 13.7055 26.7048 15.9546 27.1379C15.9546 27.1379 18.2013 22.4223 11.706 21.0457V21.048Z"
        fill="#5D3F3F"
      />
      <path
        d="M4.67015 46.4491L3.36697 54.7953L0.0345719 54.7862C0.0345719 54.7862 -0.0410081 50.5834 0.158248 46.0821L3.70135 45.5853L4.67015 46.4491Z"
        fill="#F7F7F7"
      />
      <path
        d="M1.35374 39.3974C2.0958 38.0641 3.81811 36.6373 3.81811 36.6373L7.03599 41.2822L4.13417 47.6228L0.158203 46.0821C0.407846 43.4337 0.687263 40.5939 1.35374 39.3974Z"
        fill="#F7F7F7"
      />
      <path
        d="M18.0521 46.4057C17.9811 46.4012 17.9262 46.3374 17.933 46.2667C18.1758 43.101 17.1704 40.6258 15.5924 40.505C14.0166 40.3865 12.6447 42.6816 12.402 45.8474C12.3974 45.918 12.3333 45.9727 12.2623 45.9659C12.1913 45.9613 12.1363 45.8998 12.1432 45.8268C12.402 42.4605 13.8609 40.113 15.613 40.2475C17.3651 40.3797 18.4507 42.9209 18.1919 46.2872C18.1873 46.3579 18.1231 46.4126 18.0521 46.4057Z"
        fill="#C5C5C5"
      />
      <path
        d="M12.9198 46.0798C12.8992 46.3533 12.7229 46.563 12.5259 46.5471C12.3289 46.5311 12.1869 46.2986 12.2076 46.0251C12.2282 45.7516 12.4045 45.542 12.6015 45.5579C12.7985 45.5716 12.9405 45.8063 12.9198 46.0798Z"
        fill="white"
      />
      <path
        d="M11.6508 45.918C11.6096 46.4536 12.0127 46.9209 12.5509 46.9619L12.6998 45.0246C12.1616 44.9836 11.6921 45.3847 11.6508 45.918Z"
        fill="#C5C5C5"
      />
      <path
        d="M17.4292 46.424C17.4086 46.6975 17.5506 46.93 17.7476 46.9459C17.9446 46.9596 18.1209 46.7522 18.1415 46.4787C18.1621 46.2052 18.0201 45.9727 17.8232 45.9568C17.6262 45.9408 17.4498 46.1505 17.4292 46.424Z"
        fill="white"
      />
      <path
        d="M18.7072 46.4536C18.6659 46.9892 18.1964 47.3881 17.6582 47.3493L17.8071 45.412C18.3453 45.4531 18.7461 45.9203 18.7072 46.4536Z"
        fill="#C5C5C5"
      />
      <path
        d="M13.3826 41.7889L13.3633 42.0411L17.563 42.3598L17.5823 42.1075L13.3826 41.7889Z"
        fill="#C5C5C5"
      />
      <path
        d="M8.43314 35.1171C7.51702 36.2954 6.92383 41.0042 6.92383 43.7232H7.18492C7.18492 41.1227 7.74147 36.4914 8.58888 35.3542C8.5133 35.2903 8.45604 35.2083 8.43543 35.1194L8.43314 35.1171Z"
        fill="#C5C5C5"
      />
      <path
        d="M14.2412 35.0146C14.1679 35.0738 14.0786 35.1058 13.9824 35.0989C14.9741 36.1291 15.478 37.9045 15.478 40.4276H15.7391C15.7391 37.9296 15.2283 36.1131 14.2435 35.0146H14.2412Z"
        fill="#C5C5C5"
      />
      <path
        d="M8.04376 44.4662C8.04376 45.0087 7.60174 45.4508 7.05435 45.4508C6.50697 45.4508 6.06494 45.0109 6.06494 44.4662C6.06494 43.9215 6.50697 43.4816 7.05435 43.4816C7.60174 43.4816 8.04376 43.9215 8.04376 44.4662Z"
        fill="#C5C5C5"
      />
      <path
        d="M7.71851 44.4662C7.71851 44.8309 7.42077 45.1271 7.05432 45.1271C6.68788 45.1271 6.39014 44.8309 6.39014 44.4662C6.39014 44.1015 6.68788 43.8052 7.05432 43.8052C7.42077 43.8052 7.71851 44.1015 7.71851 44.4662Z"
        fill="white"
      />
      <path
        d="M7.38208 44.4662C7.38208 44.6463 7.2355 44.7922 7.05456 44.7922C6.87363 44.7922 6.72705 44.6463 6.72705 44.4662C6.72705 44.2862 6.87363 44.1403 7.05456 44.1403C7.2355 44.1403 7.38208 44.2862 7.38208 44.4662Z"
        fill="#C5C5C5"
      />
      <path
        d="M2.58575 56.0875C2.58575 56.0875 3.83626 56.2174 3.8981 57.1747C3.93932 57.7673 3.40568 57.4391 3.40568 57.4391C3.40568 57.4391 3.74007 59.3399 2.88807 59.725C2.03608 60.1102 -0.0641284 61.0766 0.242772 57.4938C0.547383 53.9109 2.58575 56.0875 2.58575 56.0875Z"
        fill="#FFDECC"
      />
      <path
        d="M18.7784 50.9731C18.673 50.9731 18.5036 51.0574 18.3959 51.0893C18.1623 51.16 17.9173 51.2033 17.6951 51.3081C17.1729 51.5497 16.3759 52.4568 16.7446 53.079C17.2233 53.8813 17.8783 55.6453 19.4861 53.6602C21.694 50.9275 18.7899 50.9731 18.7784 50.9731Z"
        fill="#FFDECC"
      />
      <path
        d="M24.3138 44.9858C24.2085 44.6576 24.1031 44.3271 23.9955 43.9989C23.9405 44.0832 23.9107 44.1311 23.9107 44.1311L18.4575 51.1281L19.9279 52.8534C19.9279 52.8534 22.9603 49.87 25.0765 47.7207C24.7375 46.8433 24.6001 45.8769 24.3138 44.9835V44.9858Z"
        fill="#FFDECC"
      />
      <path
        d="M22.546 44.9539L18.7578 50.4831L20.5901 52.4591C20.5901 52.4591 23.3751 49.8449 26.286 47.1578L24.8111 43.4952L22.546 44.9539Z"
        fill="#F7F7F7"
      />
      <path
        d="M22.0442 38.8321C21.1166 37.6196 19.108 36.635 19.108 36.635L17.2002 41.9477L21.6434 47.3333L24.8109 43.4952C23.5008 41.0885 22.4542 39.37 22.0419 38.8321H22.0442Z"
        fill="#F7F7F7"
      />
      <path
        d="M65.1727 32.2112C65.1727 34.5063 63.3038 36.366 60.9975 36.366C58.6911 36.366 56.8223 34.5063 56.8223 32.2112C56.8223 29.9161 58.6911 28.0563 60.9975 28.0563C63.3038 28.0563 65.1727 29.9161 65.1727 32.2112Z"
        fill="#AE6C6C"
      />
      <path
        d="M58.5106 32.2112C58.5106 34.5063 56.6417 36.366 54.3354 36.366C52.029 36.366 50.1602 34.5063 50.1602 32.2112C50.1602 29.9161 52.029 28.0563 54.3354 28.0563C56.6417 28.0563 58.5106 29.9161 58.5106 32.2112Z"
        fill="#AE6C6C"
      />
      <path
        d="M58.6729 56.6071L52.7021 58.4077C52.7021 58.4077 50.1553 62.0703 50.5034 66.6171C50.8538 71.1618 52.0952 82.5028 52.0952 82.5028C52.0952 82.5028 51.7608 85.8964 51.9692 87.5807C52.1204 88.7887 53.1327 96.0728 53.6663 100.458H55.7551C55.8627 98.7987 56.0872 97.2922 56.0872 97.2922C56.0872 97.2922 56.7903 90.5094 56.7903 82.8925C56.7903 81.3404 57.908 68.7231 57.908 68.7231L58.6729 56.6049V56.6071Z"
        fill="#82BCBB"
      />
      <path
        d="M57.0653 56.6071L63.0361 58.4077C63.0361 58.4077 65.5829 62.0703 65.2348 66.6171C64.8844 71.1618 63.643 82.5028 63.643 82.5028C63.643 82.5028 63.9774 85.8964 63.769 87.5807C63.6178 88.7887 62.6055 96.0728 62.0696 100.458H59.9808C59.8732 98.7987 59.6487 97.2922 59.6487 97.2922C59.6487 97.2922 58.9456 90.5094 58.9456 82.8925C58.9456 81.3404 57.828 68.7231 57.828 68.7231L57.063 56.6049L57.0653 56.6071Z"
        fill="#82BCBB"
      />
      <path
        d="M64.3026 27.8352C64.3026 31.7599 61.4031 35.0874 57.8256 35.0874C54.2482 35.0874 51.3486 31.7599 51.3486 27.8352C51.3486 23.9105 54.2482 20.7288 57.8256 20.7288C61.4031 20.7288 64.3026 23.9105 64.3026 27.8352Z"
        fill="#AE6C6C"
      />
      <path
        d="M60.3314 36.0493L59.9581 32.0448H55.6958L55.3202 36.0493L52.2993 36.6099L54.798 43.1442H60.8559L63.3546 36.6099L60.3314 36.0493Z"
        fill="#FFD6BB"
      />
      <path
        d="M55.696 32.0448L55.4097 35.0875L60.1437 34.0527L59.9582 32.0448H55.696Z"
        fill="#FFBFA6"
      />
      <path
        d="M62.182 29.6243C62.182 32.0174 60.233 33.9593 57.8259 33.9593C55.4188 33.9593 53.4697 32.0197 53.4697 29.6243C53.4697 22.42 55.4188 22.1487 57.8259 22.1487C60.233 22.1487 62.182 23.1994 62.182 29.6243Z"
        fill="#FFD6BB"
      />
      <path
        d="M61.1973 28.9406C61.1973 29.6882 61.6278 30.2944 62.1592 30.2944C62.6905 30.2944 63.1211 29.6882 63.1211 28.9406C63.1211 28.193 62.6905 27.5868 62.1592 27.5868C61.6278 27.5868 61.1973 28.193 61.1973 28.9406Z"
        fill="#FFD6BB"
      />
      <path
        d="M52.5352 28.9406C52.5352 29.6882 52.9657 30.2944 53.4971 30.2944C54.0284 30.2944 54.459 29.6882 54.459 28.9406C54.459 28.193 54.0284 27.5868 53.4971 27.5868C52.9657 27.5868 52.5352 28.193 52.5352 28.9406Z"
        fill="#FFD6BB"
      />
      <path
        d="M56.1721 73.6346L56.8157 69.833L57.5417 73.6346H65.1776L63.5125 44.1812L52.2992 44.6256L50.4692 73.6346H56.0988H56.1721Z"
        fill="#ECECEC"
      />
      <path
        d="M51.092 50.5537C51.092 50.5537 49.3651 72.021 49.2231 73.6323H55.0817L56.0986 42.6337L55.0817 36.0401C52.5807 35.7484 50.224 36.6737 50.224 36.6737L51.6371 43.8006L51.092 50.5514V50.5537Z"
        fill="#ECECEC"
      />
      <path
        d="M66.2104 73.6346C66.0684 72.0232 64.6438 50.556 64.6438 50.556L64.101 43.8051L65.5141 36.6782C65.5141 36.6782 63.1551 35.7506 60.6541 36.0424L59.6372 42.6382L59.6464 73.6369H66.2081L66.2104 73.6346Z"
        fill="#ECECEC"
      />
      <path
        d="M59.0852 45.4301C59.0852 47.8666 57.0995 49.8426 54.6512 49.8426C52.2028 49.8426 50.2148 47.8666 50.2148 45.4301C50.2148 42.9937 52.2005 41.0154 54.6512 41.0154C57.1018 41.0154 59.0852 42.9915 59.0852 45.4301Z"
        fill="#ECECEC"
      />
      <path
        d="M56.6528 45.4301C56.6528 47.8666 58.6385 49.8426 61.0892 49.8426C63.5398 49.8426 65.5232 47.8666 65.5232 45.4301C65.5232 42.9937 63.5375 41.0154 61.0892 41.0154C58.6408 41.0154 56.6528 42.9915 56.6528 45.4301Z"
        fill="#ECECEC"
      />
      <path
        d="M60.6565 36.0401C60.6565 36.0401 60.116 37.5945 57.8371 37.5945C55.5583 37.5945 55.0819 36.0447 55.0819 36.0447L53.6069 38.791L56.1721 41.2138L57.2829 45.1407L58.2814 46.0478L60.0541 40.2087L62.317 38.6474L60.6588 36.0401H60.6565Z"
        fill="#594FC4"
      />
      <path
        d="M60.5946 30.6477C59.9419 29.7292 57.7775 29.5628 57.7775 29.5628C57.7775 29.5628 55.6155 29.7269 54.9605 30.6477C54.9605 30.6477 54.3467 32.1701 55.0635 33.2778C55.0635 33.2778 56.1079 33.8704 57.7775 33.8544C59.4472 33.8727 60.4916 33.2778 60.4916 33.2778C61.2061 32.1724 60.5946 30.6477 60.5946 30.6477Z"
        fill="white"
      />
      <path
        d="M62.4037 30.2237C62.335 30.2557 62.264 30.2716 62.193 30.2784C62.0373 30.7571 61.822 31.2653 61.5517 31.7667C61.1761 32.4641 60.5234 32.9337 59.9645 32.9063C59.7447 32.8949 59.5569 32.8106 59.4126 32.651C59.3095 32.5371 59.2637 32.4208 59.2706 32.2932C59.2958 31.8784 59.8706 31.3816 60.4798 30.8551C60.5738 30.773 60.6699 30.691 60.7638 30.6089C61.2379 30.1918 61.5059 29.3782 61.7189 28.7241C61.8334 28.3731 61.9869 27.6779 62.106 27.5891C62.106 27.5161 62.106 27.4432 62.0991 27.368C61.8495 27.4113 61.712 28.0517 61.5128 28.6603C61.3066 29.2893 61.0501 30.0733 60.6173 30.4539C60.5234 30.536 60.4295 30.618 60.3356 30.7001C59.6622 31.2813 59.0828 31.7827 59.053 32.2841C59.0415 32.4687 59.1102 32.6396 59.2522 32.7946C59.4355 32.9929 59.6782 33.1069 59.9554 33.1183C60.6035 33.1479 61.3227 32.6465 61.7418 31.867C62.0212 31.3474 62.2617 30.7685 62.4335 30.2078C62.422 30.2124 62.4129 30.2215 62.4014 30.226L62.4037 30.2237Z"
        fill="white"
      />
      <path
        d="M53.2311 30.2237C53.2998 30.2557 53.3731 30.2716 53.4418 30.2784C53.5975 30.7571 53.8128 31.2653 54.0808 31.7667C54.4564 32.4641 55.1091 32.9337 55.668 32.9063C55.8878 32.8949 56.0757 32.8106 56.2199 32.651C56.323 32.5371 56.3711 32.4208 56.3642 32.2932C56.339 31.8784 55.7642 31.3816 55.155 30.8551C55.0611 30.773 54.9671 30.691 54.871 30.6089C54.3969 30.1918 54.1312 29.3782 53.9159 28.7241C53.8014 28.3731 53.6479 27.6779 53.5288 27.5891C53.5311 27.5161 53.5288 27.4432 53.5357 27.368C53.7853 27.4113 53.9228 28.0517 54.122 28.6603C54.3282 29.2893 54.5847 30.0733 55.0175 30.4539C55.1114 30.536 55.2053 30.618 55.2992 30.7001C55.9703 31.2813 56.552 31.7827 56.5818 32.2841C56.5933 32.4687 56.5246 32.6396 56.3848 32.7946C56.2016 32.9929 55.9588 33.1069 55.6817 33.1183C55.0336 33.1479 54.3144 32.6465 53.8953 31.867C53.6159 31.3474 53.3731 30.7685 53.2036 30.2078C53.2151 30.2124 53.2219 30.2215 53.2334 30.226L53.2311 30.2237Z"
        fill="white"
      />
      <path
        d="M56.1146 21.7704L55.4092 24.9179C55.4092 24.9179 59.6394 27.5845 62.8275 27.1081C62.8275 27.1081 63.9222 21.9117 56.1146 21.7704Z"
        fill="#AE6C6C"
      />
      <path
        d="M57.349 21.5972L57.3788 24.2546C57.3788 24.2546 55.3496 27.2563 53.0982 27.6893C53.0982 27.6893 50.8514 22.9738 57.349 21.5972Z"
        fill="#AE6C6C"
      />
      <path
        d="M58.7508 48.3088L55.0818 36.0424L52.8281 39.1899L55.6429 40.9107L54.8894 43.6343L58.7508 48.3088Z"
        fill="white"
      />
      <path
        d="M62.9076 39.1899L60.6563 36.0401L57.8071 45.5578C57.9468 46.0114 58.0293 46.4831 58.098 46.9595L60.8464 43.6342L60.0929 40.9106L62.9076 39.1899Z"
        fill="white"
      />
      <path
        d="M47.7736 39.3973C48.447 38.0298 50.2243 36.676 50.2243 36.676L51.9053 44.6622L49.9219 48.1606L45.8818 46.9025C46.6972 44.2884 47.4713 40.0059 47.7713 39.3973H47.7736Z"
        fill="#ECECEC"
      />
      <path
        d="M57.0905 47.1396C56.7676 47.233 56.582 47.568 56.6782 47.8894L58.7601 55.0026C58.854 55.324 59.1907 55.5063 59.5136 55.4129L64.6164 53.9337L62.1933 45.6604L57.0928 47.1396H57.0905Z"
        fill="white"
      />
      <path
        d="M58.1602 46.8296L57.0929 47.1396C56.77 47.233 56.5845 47.568 56.6807 47.8894L58.7626 55.0026C58.8565 55.324 59.1931 55.5063 59.5161 55.4129L60.5856 55.1029L58.1625 46.8296H58.1602Z"
        fill="#82BCBB"
      />
      <path
        d="M60.471 50.6449C60.471 50.6449 59.6923 49.5623 58.7761 49.952C58.2058 50.1936 58.7899 50.5264 58.7899 50.5264C58.7899 50.5264 56.8569 51.1098 56.9485 52.0693C57.0401 53.0289 57.2531 55.4037 60.4023 53.4459C63.5514 51.4882 60.4687 50.6449 60.4687 50.6449"
        fill="#FFDECC"
      />
      <path
        d="M67.7701 46.6974C67.7701 46.351 67.7701 46.0068 67.7724 45.6604C67.6945 45.7242 67.651 45.7607 67.651 45.7607L60.3037 50.7566L61.1717 52.8488C61.1717 52.8488 64.9782 50.9343 67.6533 49.5349C67.6029 48.5959 67.7678 47.6341 67.7701 46.6974Z"
        fill="#FFDECC"
      />
      <path
        d="M66.1004 46.1277L60.7915 50.2347L61.9275 52.6734C61.9275 52.6734 65.3836 51.0347 68.9816 49.3663L68.7068 45.4302L66.1004 46.1277Z"
        fill="#ECECEC"
      />
      <path
        d="M67.6647 39.6617C67.1562 38.2258 65.5461 36.676 65.5461 36.676L62.9443 44.4137L64.5063 48.1173L68.7045 45.4325C68.2006 42.7408 67.8914 40.3021 67.6647 39.664V39.6617Z"
        fill="#ECECEC"
      />
      <path
        d="M48.8474 57.4208C48.8474 57.4208 50.2949 57.4846 50.4827 58.5421C50.5972 59.2008 49.9491 58.8703 49.9491 58.8703C49.9491 58.8703 50.5583 60.9603 49.6284 61.4458C48.6986 61.9312 46.406 63.1414 46.3327 59.137C46.2571 55.1325 48.8497 57.4208 48.8497 57.4208"
        fill="#FFDECC"
      />
      <path
        d="M48.1376 48.4934C48.4537 48.2882 48.7675 48.0831 49.0812 47.878C49.0698 47.9874 49.0629 48.0489 49.0629 48.0489L48.8453 57.6373L46.4245 58.0886C46.4245 58.0886 46.6352 53.61 46.3306 50.3668C47.2169 49.8586 47.2833 49.0472 48.1376 48.4911V48.4934Z"
        fill="#FFDECC"
      />
      <path
        d="M49.9218 48.1606L49.0331 56.892L46.1359 57.3068C46.1359 57.3068 46.1199 51.732 45.8794 46.9025H48.7377L49.9195 48.1606H49.9218Z"
        fill="#ECECEC"
      />
      <path
        d="M29.8884 47.4382H29.6182C29.6182 42.5038 31.0885 40.0013 33.9858 40.0013C36.883 40.0013 38.6053 42.3808 38.6053 46.8844H38.3351C38.3351 42.4947 36.8716 40.2703 33.9858 40.2703C31.2672 40.2703 29.8884 42.6816 29.8884 47.4382Z"
        fill="#C5C5C5"
      />
      <path
        d="M35.0144 62.7973L28.8352 64.6616C28.8352 64.6616 26.199 68.4519 26.5586 73.1583C26.9205 77.8624 28.2076 89.5978 28.2076 89.5978C28.2076 89.5978 27.8595 93.1099 28.0771 94.8535C28.2328 96.1047 29.2818 103.644 29.8337 108.182H31.9958C32.108 106.466 32.3393 104.904 32.3393 104.904C32.3393 104.904 33.0677 97.8847 33.0677 90.0035C33.0677 88.3967 34.2243 75.3372 34.2243 75.3372L35.0167 62.7973H35.0144Z"
        fill="#594FC4"
      />
      <path
        d="M33.3495 62.7973L39.5287 64.6616C39.5287 64.6616 42.1648 68.4519 41.8053 73.1583C41.4434 77.8624 40.1562 89.5978 40.1562 89.5978C40.1562 89.5978 40.5021 93.1099 40.2868 94.8535C40.131 96.1047 39.0821 103.644 38.5278 108.182H36.3658C36.2536 106.466 36.0222 104.904 36.0222 104.904C36.0222 104.904 35.2962 97.8847 35.2962 90.0035C35.2962 88.3967 34.1396 75.3372 34.1396 75.3372L33.3472 62.7973H33.3495Z"
        fill="#594FC4"
      />
      <path
        d="M40.8412 33.018C40.8412 37.0794 37.3782 38.4857 33.6771 38.4857C29.976 38.4857 27.436 37.0794 27.436 33.018C27.436 28.9566 30.4363 25.6632 34.1398 25.6632C37.8432 25.6632 40.8412 28.9566 40.8412 33.018Z"
        fill="#DD7957"
      />
      <path
        d="M36.73 41.5192L36.3429 37.3757H31.9341L31.5447 41.5192L28.4185 42.1004L31.0042 48.8626H37.2728L39.8585 42.1004L36.73 41.5192Z"
        fill="#FFD6BB"
      />
      <path
        d="M31.9341 37.3757L31.6387 40.5255L36.5376 39.4543L36.343 37.3757H31.9341Z"
        fill="#FFBFA6"
      />
      <path
        d="M38.6445 34.871C38.6445 37.3484 36.6268 39.3563 34.1372 39.3563C31.6476 39.3563 29.6299 37.3484 29.6299 34.871C29.6299 27.4159 31.6476 27.1356 34.1372 27.1356C36.6268 27.1356 38.6445 28.2227 38.6445 34.871Z"
        fill="#FFD6BB"
      />
      <path
        d="M37.6255 34.1622C37.6255 34.9371 38.0698 35.5638 38.6195 35.5638C39.1692 35.5638 39.6135 34.9371 39.6135 34.1622C39.6135 33.3873 39.1669 32.7605 38.6195 32.7605C38.0721 32.7605 37.6255 33.3895 37.6255 34.1622Z"
        fill="#FFD6BB"
      />
      <path
        d="M28.6611 34.1622C28.6611 34.9371 29.1055 35.5638 29.6551 35.5638C30.2048 35.5638 30.6514 34.9371 30.6514 34.1622C30.6514 33.3873 30.2048 32.7605 29.6551 32.7605C29.1055 32.7605 28.6611 33.3895 28.6611 34.1622Z"
        fill="#FFD6BB"
      />
      <path
        d="M35.4403 51.2307C35.4403 53.7537 33.3859 55.7981 30.8505 55.7981C28.3151 55.7981 26.2607 53.7537 26.2607 51.2307C26.2607 48.7077 28.3151 46.6633 30.8505 46.6633C33.3859 46.6633 35.4403 48.7077 35.4403 51.2307Z"
        fill="white"
      />
      <path
        d="M32.9233 51.2307C32.9233 53.7537 34.9777 55.7981 37.5131 55.7981C40.0485 55.7981 42.1029 53.7537 42.1029 51.2307C42.1029 48.7077 40.0462 46.6633 37.5131 46.6633C34.98 46.6633 32.9233 48.7077 32.9233 51.2307Z"
        fill="white"
      />
      <path
        d="M37.5133 43.1192L34.087 47.5635L30.3377 42.6953L28.4185 84.0754H40.1196L37.5133 43.1192Z"
        fill="#594FC4"
      />
      <path
        d="M31.2973 41.5147C28.7069 41.2115 26.2701 42.6975 26.2701 42.6975L27.7313 49.5486L27.1679 56.5342C27.1679 56.5342 25.0058 82.4094 24.8569 84.0777H30.9194L31.295 74.6762V41.5169L31.2973 41.5147Z"
        fill="white"
      />
      <path
        d="M41.196 56.5319L40.6326 49.5464L42.0961 42.6952C42.0961 42.6952 39.6546 41.207 37.0666 41.5101L36.0703 65.3659L36.1299 84.0754H42.9206C42.7717 82.4071 41.1937 56.5319 41.1937 56.5319H41.196Z"
        fill="white"
      />
      <path
        d="M37.0022 35.9308C36.3265 34.9781 34.0866 34.8094 34.0866 34.8094C34.0866 34.8094 31.849 34.9804 31.171 35.9308C31.171 35.9308 30.5366 37.508 31.2787 38.6521C31.2787 38.6521 32.3597 39.2652 34.0866 39.2492C35.8158 39.2675 36.8945 38.6521 36.8945 38.6521C37.6343 37.508 36.9999 35.9308 36.9999 35.9308H37.0022Z"
        fill="white"
      />
      <path
        d="M38.8758 35.4909C38.8048 35.5251 38.7315 35.541 38.6582 35.5479C38.4979 36.0447 38.2757 36.5689 37.9963 37.0863C37.607 37.8088 36.9313 38.292 36.3519 38.2669C36.1228 38.2555 35.9305 38.1689 35.7816 38.0048C35.6739 37.8886 35.6258 37.7655 35.6327 37.6333C35.6579 37.2048 36.2534 36.6897 36.8855 36.145C36.984 36.0607 37.0825 35.9764 37.1787 35.8898C37.6711 35.4567 37.9459 34.6157 38.1681 33.9388C38.2872 33.5741 38.4452 32.8562 38.5689 32.7628C38.5666 32.6876 38.5666 32.6101 38.562 32.5349C38.3055 32.5804 38.1612 33.2437 37.9551 33.8704C37.7421 34.5223 37.4764 35.3336 37.0298 35.7257C36.9336 35.81 36.8351 35.8966 36.7389 35.9786C36.0427 36.5803 35.4426 37.1 35.4128 37.6196C35.4014 37.8111 35.4701 37.9888 35.619 38.1484C35.8068 38.3535 36.0587 38.4697 36.345 38.4834C37.0161 38.5153 37.7604 37.9934 38.1933 37.1889C38.4819 36.651 38.7338 36.0539 38.9101 35.4727C38.8987 35.4795 38.8895 35.4863 38.8781 35.4909H38.8758Z"
        fill="white"
      />
      <path
        d="M29.3825 35.4909C29.4535 35.5251 29.5268 35.541 29.6024 35.5479C29.7627 36.0447 29.9848 36.5689 30.2643 37.0863C30.6536 37.8088 31.3293 38.292 31.9064 38.2669C32.1354 38.2555 32.3278 38.1689 32.4767 38.0048C32.5843 37.8886 32.6324 37.7655 32.6256 37.6333C32.6004 37.2048 32.0049 36.6897 31.3728 36.145C31.2766 36.0607 31.1781 35.9764 31.0796 35.8898C30.5872 35.4567 30.3124 34.6157 30.0902 33.9388C29.9711 33.5741 29.8131 32.8562 29.6894 32.7628C29.6917 32.6876 29.6894 32.6101 29.6963 32.5349C29.9551 32.5804 30.0971 33.2437 30.3032 33.8704C30.5162 34.5223 30.7819 35.3336 31.2285 35.7257C31.3247 35.81 31.4232 35.8966 31.5194 35.9786C32.2156 36.5803 32.8157 37.1 32.8454 37.6196C32.8569 37.8111 32.7882 37.9888 32.6416 38.1484C32.4515 38.3535 32.2019 38.4697 31.9133 38.4834C31.2422 38.5153 30.5002 37.9934 30.065 37.1889C29.7741 36.651 29.5245 36.0539 29.3481 35.4727C29.3596 35.4795 29.3688 35.4863 29.3802 35.4909H29.3825Z"
        fill="white"
      />
      <path
        d="M32.3668 26.7435L29.978 30.103C29.978 30.103 34.5014 34.2488 39.1438 32.9747C39.1438 32.9747 40.447 26.8917 32.3668 26.7435Z"
        fill="#DD7957"
      />
      <path
        d="M33.6449 26.5635L33.6769 29.3121C33.6769 29.3121 31.5767 32.4186 29.2475 32.8676C29.2475 32.8676 26.9228 27.9857 33.6449 26.5635Z"
        fill="#DD7957"
      />
      <path
        d="M41.7525 57.5347C41.6449 57.537 41.4685 57.6213 41.3563 57.6555C41.1135 57.7284 40.8616 57.774 40.6303 57.8811C40.0898 58.1318 39.2653 59.0709 39.6477 59.7136C40.1424 60.5432 40.8204 62.3711 42.4854 60.3153C44.7711 57.4891 41.764 57.5347 41.7525 57.5347Z"
        fill="#FFDECC"
      />
      <path
        d="M47.4828 51.34C47.3729 50.9982 47.263 50.6586 47.153 50.319C47.0958 50.4056 47.066 50.4557 47.066 50.4557L41.4204 57.6966L42.9412 59.4834C42.9412 59.4834 46.0812 56.3952 48.2707 54.1707C47.9203 53.2613 47.7783 52.2631 47.4828 51.34Z"
        fill="#FFDECC"
      />
      <path
        d="M45.6524 51.3059L41.7314 57.0288L43.6278 59.0732C43.6278 59.0732 46.509 56.3678 49.5231 53.5873L47.9954 49.7971L45.6502 51.3059H45.6524Z"
        fill="white"
      />
      <path
        d="M45.1328 44.9698C44.1731 43.7163 42.0958 42.6975 42.0958 42.6975L40.1216 48.1948L44.7205 53.7673L48.0002 49.797C46.6444 47.3059 45.5633 45.5282 45.1351 44.9698H45.1328Z"
        fill="white"
      />
      <path
        d="M26.641 57.5347C26.7486 57.537 26.925 57.6213 27.0372 57.6555C27.2799 57.7284 27.5319 57.774 27.7632 57.8811C28.3037 58.1318 29.1282 59.0709 28.7457 59.7136C28.251 60.5432 27.5708 62.3711 25.9081 60.3153C23.6223 57.4891 26.6295 57.5347 26.641 57.5347Z"
        fill="#FFDECC"
      />
      <path
        d="M20.9104 51.34C21.0204 50.9982 21.128 50.6586 21.2402 50.319C21.2975 50.4056 21.3295 50.4557 21.3295 50.4557L26.9729 57.6966L25.4521 59.4834C25.4521 59.4834 22.3121 56.3952 20.1226 54.1707C20.473 53.2613 20.615 52.2631 20.9104 51.34Z"
        fill="#FFDECC"
      />
      <path
        d="M22.7383 51.3059L26.6593 57.0288L24.7629 59.0732C24.7629 59.0732 21.8817 56.3678 18.8677 53.5873L20.3953 49.7971L22.7406 51.3059H22.7383Z"
        fill="white"
      />
      <path
        d="M23.2605 44.9698C24.2202 43.7163 26.2975 42.6975 26.2975 42.6975L28.2717 48.1948L23.6728 53.7673L20.3931 49.797C21.7489 47.3059 22.8322 45.5282 23.2582 44.9698H23.2605Z"
        fill="white"
      />
      <path
        d="M36.7231 26.1145C36.7231 27.564 35.5436 28.7378 34.0893 28.7378C32.6349 28.7378 31.4531 27.564 31.4531 26.1145C31.4531 24.6649 32.6326 23.4935 34.0893 23.4935C35.5459 23.4935 36.7231 24.6672 36.7231 26.1145Z"
        fill="#DD7957"
      />
      <path
        d="M41.0039 53.0699C40.9306 53.0631 40.8733 52.9993 40.8802 52.9264C41.1321 49.6512 40.09 47.0872 38.4593 46.9641C36.8286 46.841 35.4086 49.2159 35.1567 52.4933C35.1498 52.5663 35.0857 52.6232 35.0124 52.6164C34.9391 52.6118 34.8819 52.548 34.8887 52.4728C35.1567 48.988 36.666 46.5607 38.4799 46.6975C40.2939 46.8342 41.4161 49.4643 41.1481 52.9469C41.1436 53.0198 41.0771 53.0745 41.0039 53.0699Z"
        fill="#C5C5C5"
      />
      <path
        d="M35.6901 52.7326C35.6695 53.0152 35.4863 53.2318 35.2824 53.2158C35.0786 53.1999 34.932 52.9583 34.9526 52.6757C34.9732 52.393 35.1565 52.1765 35.3603 52.1925C35.5641 52.2084 35.7107 52.45 35.6901 52.7326Z"
        fill="white"
      />
      <path
        d="M34.3778 52.5663C34.3366 53.1201 34.7511 53.6033 35.3077 53.6466L35.4611 51.6409C34.9046 51.5976 34.4191 52.0124 34.3778 52.5663Z"
        fill="#C5C5C5"
      />
      <path
        d="M40.3579 53.0882C40.3373 53.3708 40.4839 53.6124 40.6877 53.6284C40.8915 53.6443 41.0748 53.4278 41.0954 53.1452C41.116 52.8626 40.9694 52.621 40.7656 52.605C40.5617 52.5891 40.3785 52.8056 40.3579 53.0882Z"
        fill="white"
      />
      <path
        d="M41.6818 53.1201C41.6383 53.6739 41.1527 54.0887 40.5962 54.0454L40.7496 52.0398C41.3062 52.0831 41.723 52.5663 41.6795 53.1178L41.6818 53.1201Z"
        fill="#C5C5C5"
      />
      <path
        d="M36.1709 48.2928L36.1509 48.5541L40.499 48.884L40.519 48.6227L36.1709 48.2928Z"
        fill="#C5C5C5"
      />
      <path
        d="M31.0478 41.3848C30.0996 42.6041 29.4858 47.4769 29.4858 50.2917H29.7561C29.7561 47.6 30.3333 42.807 31.2081 41.6286C31.1303 41.5648 31.0707 41.4782 31.0478 41.3848Z"
        fill="#C5C5C5"
      />
      <path
        d="M37.0574 41.2799C36.9818 41.3414 36.8902 41.3756 36.7871 41.3665C37.8132 42.4332 38.3354 44.2724 38.3354 46.882H38.6056C38.6056 44.2975 38.0766 42.4172 37.0574 41.2799Z"
        fill="#C5C5C5"
      />
      <path
        d="M30.6423 51.062C30.6423 51.6249 30.1842 52.0808 29.6185 52.0808C29.0528 52.0808 28.5947 51.6249 28.5947 51.062C28.5947 50.499 29.0528 50.0432 29.6185 50.0432C30.1842 50.0432 30.6423 50.499 30.6423 51.062Z"
        fill="#C5C5C5"
      />
      <path
        d="M30.3078 51.062C30.3078 51.4403 30.0009 51.7457 29.6207 51.7457C29.2405 51.7457 28.9336 51.4403 28.9336 51.062C28.9336 50.6836 29.2405 50.3782 29.6207 50.3782C30.0009 50.3782 30.3078 50.6836 30.3078 51.062Z"
        fill="white"
      />
      <path
        d="M29.9572 51.062C29.9572 51.2489 29.8061 51.3993 29.6183 51.3993C29.4305 51.3993 29.2793 51.2489 29.2793 51.062C29.2793 50.8751 29.4305 50.7247 29.6183 50.7247C29.8061 50.7247 29.9572 50.8751 29.9572 51.062Z"
        fill="#C5C5C5"
      />
    </svg>
  );
}

export default GroupPracticeIcon;
