import { useEffect, useRef, useState } from "react";

import Cookies from "js-cookie";
import { addYears } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { useRefreshTokenStore, useTokenStore } from "../../store/store";
import { Calendar } from "primereact/calendar";

function OAuthRegistration() {
  const { http } = useAxios();
  const { email, userId, firstName, lastName } = useTokenData();
  const genderOptions = [
    { name: "Male", value: 1 },
    { name: "Female", value: 2 },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState(null);
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  useEffect(() => {
    reset({ ...defaultValues, email, firstName, lastName });
  }, [email, firstName, lastName]);

  const params = new URLSearchParams(window.location.search);
  const EId = params?.get("EId");
  const IDate = params?.get("IDate");
  const SR = params?.get("SR");
  const IP = params?.get("IP");
  const InvId = params?.get("InvID");

  //default value of registration form
  const defaultValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: null,
    password: "",
    confirmPassword: "",
    checked: false,
    receiveSmsMessages: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const handleGenderChange = (e) => {
    setSelectedGender(e.value);
  };

  const onSubmit = async (formData) => {
    const modifyData = {
      dateOfBirth: formData.dateOfBirth.toLocaleDateString("en-CA"),
      gender: formData.gender === "Male" ? 1 : 2,
    };
    const combinedFormData = { ...formData, ...modifyData };

    try {
      setIsLoading(true);
      const response = await http.put(
        InvId
          ? `/Authentication/CompleteRegistration/${userId}?InvitationId=${InvId}&EntityId=${EId}&InvitationDate=${IDate}&staffRole=${SR}&IsProvider=${IP || ""}`
          : `/Authentication/CompleteRegistration/${userId}`,
        combinedFormData,
      );
      setToken(response.data.token);
      setRefreshToken(response.data.refreshToken);

      if (InvId) {
        if (response.data.isInvitedAsAProvider) {
          navigate("/welcome-provider");
        } else {
          navigate("/Dashboard");
        }
      } else {
        if (response.data.entityExist == true) {
          navigate("/chooseEntity", {
            replace: true,
          });
        } else {
          navigate("/verifySuccess", {
            replace: true,
          });
        }
      }

      const cookieNames = ["InvID", "EId", "IDate", "SR", "IP"];
      cookieNames.forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex items-center justify-center">
        <div className="flex w-3/6 justify-center rounded-3xl bg-light-text py-16">
          <div className="2xl:w-5/5 Xl:w-4/5 md:w-5/5 rounded-3xl border border-solid border-light-purple p-10 py-8 sm:w-4/5 lg:w-4/5">
            <h4 className="mb-2 text-center font-philosopher text-title font-extrabold text-dark-purple">
              Personal Information
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container mb-3">
                <div>
                  <label>
                    First Name <span className="text-red-600">*</span>
                  </label>
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-user" />
                    <InputText
                      name="firstName"
                      placeholder="First Name"
                      className="w-full pb-2 pt-2"
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                      disabled={firstName != undefined && firstName != ""}
                    />
                  </span>
                  {errors.firstName && (
                    <p className="mt-1 text-red-500">
                      {errors.firstName.message}
                    </p>
                  )}
                </div>

                <div className="mt-3 grid gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5">
                  <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label>Middle Name</label>
                    <span>
                      <InputText
                        placeholder="Middle Name"
                        name="middleName"
                        {...register("middleName", {
                          pattern: /^[A-Za-z]+$/,
                        })}
                        className="w-full pb-2 pt-2"
                      />
                      {errors.middleName && (
                        <p className="mt-1 text-red-500">
                          Only letters are allowed, no spaces
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label>
                      Last Name <span className="text-red-600">*</span>
                    </label>
                    <span>
                      <InputText
                        placeholder="Last Name"
                        name="lastName"
                        {...register("lastName", {
                          required: "Last name is required",
                        })}
                        disabled={lastName != undefined && lastName != ""}
                        className="w-full pb-2 pt-2"
                      />
                      {errors.lastName && (
                        <p className="mt-1 text-red-500">
                          {errors.lastName.message}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="lg:col-span-1 xl:col-span-1 2xl:col-span-1">
                    <label>Suffix</label>
                    <span>
                      <InputText
                        placeholder="suffix"
                        name="suffix"
                        {...register("suffix", {
                          pattern: /^[A-Za-z.]+$/,
                        })}
                        className="w-full pb-2 pt-2"
                      />
                      {errors.suffix && (
                        <p className="mt-1 text-red-500">
                          Only letters are allowed, no spaces
                        </p>
                      )}
                    </span>
                  </div>
                </div>
                <div className="pt-1">
                  <label>
                    Date of Birth <span className="text-red-600">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      rules={{
                        required: "Date of Birth is required",
                        validate: {
                          validDate: (value) => {
                            const currentDate = new Date();
                            const minDate = addYears(currentDate, -18);
                            return (
                              new Date(value) <= minDate ||
                              "You must be at least 18 years old."
                            );
                          },
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <Calendar
                            value={new Date(field.value)}
                            showIcon
                            showOnFocus={false}
                            pt={{
                              dropdownButton: {
                                root: {
                                  style: {
                                    backgroundColor: "#4F46E5",
                                  },
                                },
                              },
                            }}
                            onChange={(e) => {
                              e.value && field.onChange(e.value);
                            }}
                            mask="99/99/9999"
                            dateFormat="mm/dd/yy"
                            placeholder="MM/DD/YYYY"
                            className="w-full"
                          />
                        </>
                      )}
                    />
                  </span>

                  {errors.dateOfBirth && (
                    <p className="mt-1 text-red-500">
                      {errors.dateOfBirth.message}
                    </p>
                  )}
                </div>

                <div className="pt-4">
                  <label>
                    Gender <span className="text-red-600">*</span>
                  </label>
                  <Dropdown
                    name="gender"
                    value={selectedGender}
                    options={genderOptions}
                    optionLabel="name"
                    placeholder="Gender"
                    {...register("gender", {
                      required: "Please select your gender",
                    })}
                    onChange={handleGenderChange}
                    className="md:w-14rem w-full"
                  />
                  {errors.gender && (
                    <p className="mt-1 text-red-500">{errors.gender.message}</p>
                  )}
                </div>
                <div className="pt-4">
                  <label>
                    Mobile Phone Number <span className="text-red-600">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="999-999-9999"
                        placeholder="Mobile Phone Number"
                        onClick={(e) => {
                          if (e.target.value === "___-___-____") {
                            e.target.setSelectionRange(0, 0);
                          }
                        }}
                        className="w-full"
                      />
                    )}
                    rules={{
                      required: "Mobile phone number required",
                      pattern: {
                        value: /^\d{3}-\d{3}-\d{4}$/,
                        message:
                          "The phone number that has been entered is incorrect",
                      },
                    }}
                  />

                  {errors.phoneNumber && (
                    <p className="mt-1 text-red-500">
                      {errors.phoneNumber.message}
                    </p>
                  )}
                </div>

                <div className="pt-4">
                  <label>
                    Email <span className="text-red-600">*</span>
                  </label>
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-envelope" />
                    <InputText
                      placeholder="Email Address"
                      name="email"
                      {...register("email", {
                        required: "Please enter your email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                      disabled={email != undefined && email != ""}
                      className="w-full pb-2 pt-2"
                    />
                  </span>
                  {errors.email && (
                    <p className="mt-1 text-red-500">{errors.email.message}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="mt-4 flex">
                  <Controller
                    name="checked"
                    control={control}
                    rules={{
                      required:
                        "You must agree to MedXServices terms to continue",
                    }}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.checked)}
                        />
                      </>
                    )}
                  />
                  <p className="pl-2 text-gray-800">
                    Yes, I understand and agree to the{" "}
                    <span className="text-light-purple">
                      <Link to={"/legal/tos"} target="_blank">
                        MedXServices Terms of Service,{" "}
                      </Link>
                    </span>
                    and{" "}
                    <span className="text-light-purple">
                      {" "}
                      <Link to={"/legal/privacy"} target="_blank">
                        Privacy Policy
                      </Link>
                    </span>
                    .
                  </p>
                </div>
                <div>
                  {errors.checked && (
                    <p className="mt-1 text-red-500">
                      {errors.checked.message}
                    </p>
                  )}
                </div>
                <div className="flex">
                  <Controller
                    name="receiveSmsMessages"
                    control={control}
                    render={({ field }) => (
                      <div className="flex gap-2">
                        <Checkbox
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.checked)}
                        />
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="receiveSmsMessages"
                            className="text-gray-800"
                          >
                            I consent to receive critical SMS push notifications
                            for important updates and alerts.
                          </label>
                          <p className="text-xs text-gray-500">
                            You will receive SMS notifications for crucial
                            updates related to your account and services. You
                            can opt in/out at any time in your account settings.
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                >
                  create my account{" "}
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default OAuthRegistration;
