import { useNavigate } from "react-router-dom";

import { Avatar } from "primereact/avatar";
import { Tooltip } from "primereact/tooltip";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import useHandleResize from "../../hooks/useHandleResize";

function ServicesCard({
  serviceName,
  serviceDescriprtion,
  serviceType,
  internalOnly,
  entityName,
  minCost,
  maxCost,
  savedService,
  serviceEntityId,
  serviceId,
  imageSrc,
  isEntityPage = false,
  showOrderButton = false,
  hideAddToFavorite = false,
  unclickableCard = false,
  orderButtonClick,
  onCardClick,
  disableOrderButton,
  setRefetch,
}) {
  const navigate = useNavigate();
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { isMobile } = useHandleResize();
  const saveService = async () => {
    const res = await http.post("/Services/SaveService", {
      entityId: entityId,
      serviceId: serviceId,
    });
    setRefetch(true);
  };
  const unSaveService = async () => {
    const res = await http.delete(
      `/Services/UnSaveService/${entityId}/${serviceId}`,
    );
    setRefetch(true);
  };
  const serviceTypeTemplate = (serviceType) => {
    return (
      <>
        {serviceType === 1 ? (
          <p className="flex items-center justify-center rounded-lg bg-[#E0E7FF] p-2 text-xs text-[#6366F1]">
            Scheduled Appointment Service
          </p>
        ) : (
          <p
            className="flex items-center justify-center rounded-lg p-2 text-xs"
            style={{ backgroundColor: "#A0D9D9", color: "#2F4F4F" }}
          >
            Walk-In Service
          </p>
        )}
      </>
    );
  };
  const internalOnlyTemplate = internalOnly ? (
    <p
      className="flex items-center justify-center rounded-lg p-2 text-xs font-semibold"
      style={{ backgroundColor: "#DFF2EB", color: "#4A628A" }}
    >
      Internal Only
    </p>
  ) : null;
  return (
    <div
      onClick={unclickableCard ? undefined : onCardClick}
      className={`mb-3 rounded-md bg-white p-5 drop-shadow-sm ${
        unclickableCard ? "" : "cursor-pointer"
      }`}
    >
      <div className="mb-2 flex w-full justify-between font-semibold">
        <div className="flex flex-col gap-2 text-xl sm:flex-row">
          {serviceName} {serviceTypeTemplate(serviceType)}{" "}
          {internalOnlyTemplate}
        </div>
        <Tooltip target={".tooltip-icon"} />
        {hideAddToFavorite ? null : (
          <div>
            {savedService ? (
              <i
                data-pr-tooltip="Remove this service from your saved services"
                data-pr-position="top"
                onClick={(e) => {
                  e.stopPropagation();
                  unSaveService();
                }}
                className="pi pi-bookmark-fill tooltip-icon cursor-pointer rounded-full border-2 p-2 text-purple-700"
              />
            ) : (
              <i
                data-pr-tooltip="Mark this service as saved"
                data-pr-position="top"
                onClick={(e) => {
                  e.stopPropagation();
                  saveService();
                }}
                className="pi pi-bookmark tooltip-icon cursor-pointer rounded-full border-2 p-2 text-purple-700"
                // style={{ border: "10px solid black" }}
              />
            )}
          </div>
        )}
      </div>
      <div className="mb-2">{serviceDescriprtion}</div>
      <div className="flex items-center justify-between">
        <span className="flex flex-col content-center gap-2 sm:flex-row sm:items-center">
          <span
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
              if (!isEntityPage && !unclickableCard) {
                navigate(`/ordering/practices/${serviceEntityId}`);
              }
            }}
          >
            {!isMobile &&
              (imageSrc ? (
                <img
                  className="h-[30px] w-[30px] rounded-full"
                  alt=""
                  src={imageSrc}
                />
              ) : (
                <Avatar
                  label={entityName[0]}
                  shape="circle"
                  className="bg-blue-500 text-white"
                />
              ))}{" "}
            <span>{entityName}</span>
          </span>
          {/* <span className="flex items-center">
            <i className="pi pi-dollar text-green-700" />
            Starts from ~ ${minCost}
          </span> */}
        </span>
        {showOrderButton ? (
          <button
            type="button"
            // disabled={disableOrderButton}
            onClick={orderButtonClick}
            className={`whitespace-nowrap rounded-md px-4 py-2 text-sm font-semibold text-white md:px-4 md:text-lg ${
              disableOrderButton ? "bg-gray-400" : "bg-green-600"
            }`}
          >
            Order Now
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default ServicesCard;
