export const GENERAL_REQUEST_HEADERS = {
  "X-Robots-Tag": "noindex, nofollow, noarchive, nosnippet",
};

export const RULE_ACTION_VALUES = {
  ADD_CPT: {
    label: "Add Billable Items",
    value: "addCPT",
  },
  ADD_UNITS_TO_EXISTING: {
    label: "Add Units To Existing Billable Items",
    value: "AddUnitsToExisting",
  },
  REMOVE_ALL_EXCEPT: {
    label: "Remove All Billable Items Except",
    value: "removeAllExcept",
  },
  REMOVE_MATCHING_PATTERN: {
    label: "Remove Any Billable Items Matching Pattern",
    value: "removeMatchingPattern",
  },
  REMOVE_CPT: {
    label: "Remove Billable Items",
    value: "removeCPT",
  },
  REMOVE_UNITS_FROM_EXISTING: {
    label: "Reduce Units On Existing Billable Items",
    value: "removeUnitsFromExisting",
  },
  SWAP_CPT: {
    label: "Replace Billable Item",
    value: "swapCPT",
  },
  SET_UNITS: {
    label: "Set Units On Existing Billable Items",
    value: "setUnits",
  },
  ADD_DOCS: {
    label: "Add Documents",
    value: "addDocs",
  },
  REMOVE_DOCS: {
    label: "Remove Documents",
    value: "removeDocs",
  },
  ADD_LOCATIONS: {
    label: "Add locations",
    value: "addLocations",
  },
  REMOVE_LOCATIONS: {
    label: "Remove Locations",
    value: "removeLocations",
  },
  ABORT: {
    label: "Abort Order",
    value: "abort",
  },
};

export const RULE_ACTIONS = [
  {
    label: "Billable Items",
    actions: [
      RULE_ACTION_VALUES.ADD_CPT,
      RULE_ACTION_VALUES.ADD_UNITS_TO_EXISTING,
      RULE_ACTION_VALUES.REMOVE_UNITS_FROM_EXISTING,
      RULE_ACTION_VALUES.REMOVE_ALL_EXCEPT,
      RULE_ACTION_VALUES.REMOVE_MATCHING_PATTERN,
      RULE_ACTION_VALUES.REMOVE_CPT,
      RULE_ACTION_VALUES.SWAP_CPT,
      RULE_ACTION_VALUES.SET_UNITS,
    ],
  },
  {
    label: "Documents",
    actions: [RULE_ACTION_VALUES.ADD_DOCS, RULE_ACTION_VALUES.REMOVE_DOCS],
  },
  {
    label: "Locations",
    actions: [
      RULE_ACTION_VALUES.ADD_LOCATIONS,
      RULE_ACTION_VALUES.REMOVE_LOCATIONS,
    ],
  },
  {
    label: "Order Actions",
    actions: [RULE_ACTION_VALUES.ABORT],
  },
];
