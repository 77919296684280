import { Messages } from "primereact/messages";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function PinStatusPanal() {
  const msgs = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (msgs?.current) {
      msgs.current.clear();
    }
    msgs.current.show({
      sticky: true,
      severity: "info",
      detail: (
        <span>
          Ensure only you can approve orders as a provider. Your unique PIN will
          be required to sign off on orders placed by your staff.
          <span
            onClick={() =>
              navigate("/ordering/settings/Security", {
                replace: true,
                state: { activeIndex: 1 },
              })
            }
            label="Click Here"
            className="ml-2 cursor-pointer font-bold underline"
          >
            Click Here
          </span>
        </span>
      ),
      closable: false,
    });
  }, []);

  return <Messages className="mt-[-1%]" ref={msgs} />;
}

export default PinStatusPanal;
