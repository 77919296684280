import { Helmet } from "react-helmet";

const LegalMetaHeaders = ({ children }) => {
  return (
    <Helmet>
      <meta
        name="robots"
        content="noindex, nofollow, noarchive, nosnippet, notranslate, noimageindex"
      />
      <meta
        name="googlebot"
        content="noindex, nofollow, noarchive, nosnippet, notranslate, noimageindex, nositelinkssearchbox"
      />
      {children}
    </Helmet>
  );
};

export default LegalMetaHeaders;
