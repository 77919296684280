import { useEffect, useRef } from "react";

import { Messages } from "primereact/messages";

function CompletePracticeStatusPanel() {
  const msgs = useRef(null);
  useEffect(() => {
    if (msgs?.current) {
      msgs.current.clear();
    }
    msgs.current.show({
      sticky: true,
      severity: "info",
      detail:
        "Looks like you haven't filled in the identity and selfie data for your practice yet. Please fill them to ensure your practice can undergo admin review",
      closable: false,
    });
  }, []);
  return (
    <div className="card mb-4 drop-shadow-lg">
      <Messages
        ref={msgs}
        pt={{
          detail: { className: "hidden sm:block text-lg font-bold" },
        }}
      />
    </div>
  );
}

export default CompletePracticeStatusPanel;
