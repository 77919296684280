import { useEffect, useRef } from "react";

import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";

import useAxios from "../../../../hooks/useAxios";
import { fileTyper } from "../../../../utils/fileTypesUtils";
import useTokenData from "../../../../hooks/useTokenData";
import { trimValidation } from "../../../../utils/helpers";
import useHandleResize from "../../../../hooks/useHandleResize";

export default function DocumentsLibraryDialog({
  toast,
  setRefetch,
  documentData,
  onDocumentCreate,
  setIsDialogAppearing,
}) {
  const { http } = useAxios();
  const fileUploadRef = useRef();
  const { entityId } = useTokenData();

  const defaultValues = {
    Document: {
      FileDetails: null,
      FileType: 1,
      FilePropertyName: 9,
    },
    DocumentTitle: "",
    InternalDescription: "",
    ExternalDescription: "",
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (documentData) {
      setValue("DocumentTitle", documentData.documentTitle);
      setValue("InternalDescription", documentData.internalDescription);
      setValue("ExternalDescription", documentData.externalDescription);
      setValue("Document.FileDetails", documentData.documentPath);
    }
  }, [documentData]);
  const { isMobile } = useHandleResize();
  const formUploadHandler = (options) => {
    const [file] = options.files;
    const allowedExtensions = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];
    if (file) {
      const fileType = fileTyper(file.type);
      const isValidFileType = allowedExtensions.includes(file.type);
      if (isValidFileType) {
        setValue("Document.FileDetails", file, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
        !watch("DocumentTitle").trim() &&
          setValue("DocumentTitle", file.name, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
          });
        setValue("Document.FileType", fileType, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        options.options.clear();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "The file format is not supported. Please upload a file with one of the following extensions: .pdf, .doc, .docx.",
          life: 3000,
        });
      }
    }
  };

  const onSubmit = async (formData) => {
    const newFormData = new FormData();

    newFormData.append(
      "Document.FileDetails",
      formData.Document.FileDetails || "",
    );

    newFormData.append("Document.FileType", formData.Document.FileType);
    newFormData.append(
      "Document.FilePropertyName",
      formData.Document.FilePropertyName,
    );
    newFormData.append("DocumentTitle", formData.DocumentTitle);
    newFormData.append("InternalDescription", formData.InternalDescription);
    newFormData.append("ExternalDescription", formData.ExternalDescription);

    if (documentData) {
      try {
        const response = await http.put(
          `/EntityDocuments/EditEntityDocument/${documentData.id}`,
          newFormData,
        );

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Document Updated Successfully",
          life: 4000,
        });
        reset(defaultValues);
        setRefetch((prevState) => !prevState);
        setIsDialogAppearing(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await http.post(
          `/EntityDocuments/CreateEntityDocumentAsync/${entityId}`,
          newFormData,
        );
        onDocumentCreate && onDocumentCreate(response.data.id);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Document Created Successfully",
          life: 4000,
        });
        reset(defaultValues);
        setRefetch((prevState) => !prevState);
        setIsDialogAppearing(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCancel = () => {
    reset(defaultValues);
    setIsDialogAppearing(false);
  };

  const emptyTemplate = () => {
    return (
      <div className="flex flex-col items-center">
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1 flex h-full flex-col items-start justify-center py-6">
            <Controller
              name={"Document.FileDetails"}
              control={control}
              rules={
                !documentData ? { required: "Document Is Required" } : undefined
              }
              render={({ field }) => (
                <FileUpload
                  ref={fileUploadRef}
                  mode="advanced"
                  name="Document"
                  accept=".pdf,.doc,.docx"
                  customUpload
                  chooseLabel={documentData ? "Update Attachment" : "Choose"}
                  multiple={false}
                  uploadHandler={formUploadHandler}
                  auto
                  onRemove={(e) => {
                    resetField("Document");
                  }}
                  pt={{
                    chooseIcon: {
                      style: {
                        display: `${isMobile ? "none" : ""}`,
                      },
                    },
                    chooseButton: {
                      style: {
                        padding: `${isMobile ? "9px" : ""}`,
                      },
                    },
                    content: {
                      style: {
                        padding: `${isMobile ? ".7rem" : ""}`,
                      },
                    },
                  }}
                  contentClassName="w-full"
                  emptyTemplate={emptyTemplate}
                  className="w-full"
                />
              )}
            />
            {errors?.Document?.FileDetails && (
              <div className="text-red-500">
                {errors.Document.FileDetails.message}
              </div>
            )}
          </div>
          <div className="col-span-1 flex flex-col gap-4">
            <div>
              <div>
                <label className="block font-bold" htmlFor="DocumentTitle">
                  Title<span className="text-red-500">*</span>
                </label>
                <Controller
                  name="DocumentTitle"
                  control={control}
                  rules={{
                    required: "Document Title is required",
                    validate: (value) =>
                      trimValidation(value, "Document Title"),
                  }}
                  render={({ field }) => (
                    <InputText
                      id="DocumentTitle"
                      type="text"
                      placeholder="Enter Document Title"
                      {...field}
                      className="w-full"
                    />
                  )}
                />
              </div>
              {errors.DocumentTitle && (
                <div className="text-red-500">
                  {errors.DocumentTitle.message}
                </div>
              )}
            </div>
            <div>
              <label className="font-bold" htmlFor="InternalDescription">
                Internal Description<span className="text-red-500">*</span>
              </label>
              <Controller
                name="InternalDescription"
                control={control}
                rules={{
                  required: "Internal Description is required",
                  minLength: {
                    value: 5,
                    message: "Description should be more than 5 characters",
                  },
                  validate: (value) =>
                    trimValidation(value, "Description cannot be empty "),
                }}
                render={({ field }) => (
                  <InputTextarea
                    id="InternalDescription"
                    placeholder="Enter Document Description"
                    rows={5}
                    cols={30}
                    className="w-full"
                    {...field}
                  />
                )}
              />
              {errors.InternalDescription && (
                <div className="text-red-500">
                  {errors.InternalDescription.message}
                </div>
              )}
            </div>
            <div>
              <label className="font-bold" htmlFor="ExternalDescription">
                External Description<span className="text-red-500">*</span>
              </label>
              <Controller
                name="ExternalDescription"
                control={control}
                rules={{
                  required: "Document Description is required",
                  minLength: {
                    value: 5,
                    message: "Description should be more than 5 characters",
                  },
                  validate: (value) =>
                    trimValidation(value, "Description cannot be empty "),
                }}
                render={({ field }) => (
                  <InputTextarea
                    id="ExternalDescription"
                    placeholder="Enter Document Description"
                    rows={5}
                    cols={30}
                    className="w-full"
                    {...field}
                  />
                )}
              />
              {errors.ExternalDescription && (
                <div className="text-red-500">
                  {errors.ExternalDescription.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            type="button"
            severity="danger"
            disabled={isSubmitting}
            onClick={handleCancel}
            label="Cancel"
          ></Button>
          <Button label="Submit" loading={isSubmitting}></Button>
        </div>
      </form>
    </div>
  );
}
