import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useExpireSystemAdmin } from "../store/store";

function ExpireSystemAdminGuard() {
  const { isExpireAdmin } = useExpireSystemAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (isExpireAdmin) {
      window.history.pushState(null, "", window.location.href);
      const handlePopState = () => {
        navigate("/admin/expired", { replace: true });
      };
      window.addEventListener("popstate", handlePopState);
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [isExpireAdmin, navigate]);

  return <Outlet />;
}

export default ExpireSystemAdminGuard;
