import { useRef, useState } from "react";
import { Tooltip } from "primereact/tooltip";

export const PatientInfoTemplate = ({ rowData }) => {
  const [isCopied, setIsCopied] = useState(false);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);

  if (!rowData) {
    return null;
  }

  return (
    <>
      <h4 className="font-inter text-sm font-semibold">
        {rowData.patientName}
      </h4>
      {rowData.patientPhoneNumber && (
        <>
          <h4 className="mt-1 basis-1/3 font-inter text-sm font-semibold">
            <i
              className="pi pi-phone pr-2 text-dark-purple"
              ref={phoneRef}
              onClick={async () => {
                if (isCopied) return;
                await navigator.clipboard.writeText(rowData.patientPhoneNumber);
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 1000);
              }}
              style={{ cursor: "pointer" }}
            />
            <span>{rowData.patientPhoneNumber}</span>
            <Tooltip
              target={phoneRef}
              content={isCopied ? "copied" : "click to copy"}
              position="bottom"
              event="click"
            />
          </h4>
          <h4 className="flex basis-1/2 font-inter text-sm font-semibold">
            <i
              className="pi pi-envelope pr-2 text-dark-purple"
              ref={emailRef}
              onClick={async () => {
                if (isCopied) return;
                await navigator.clipboard.writeText(rowData.patientEmail);
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 1000);
              }}
              style={{ cursor: "pointer" }}
            />
            <span>{rowData.patientEmail}</span>
            <Tooltip
              target={emailRef}
              content={isCopied ? "copied" : "click to copy"}
              position="bottom"
              event="click"
            />
          </h4>
        </>
      )}
    </>
  );
};
