import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

import EmailVerifyIcon from "../../iconComponents/EmailVerifyIcon";

function EmailOrderUpdate() {
  const navigate = useNavigate();
  return (
    <>
      <div className="grid justify-items-center">
        <div className="mt-10 rounded-3xl bg-light-text py-11 pl-10 pr-10 text-center shadow shadow-slate-300 md:w-7/12 lg:w-7/12 xl:w-5/12 2xl:w-5/12">
          <span className="m-auto mb-5 flex w-max justify-center rounded-full bg-gray-100">
            <EmailVerifyIcon />
          </span>

          <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
            Your updates are being processed
          </h4>
          <Button
            onClick={() =>
              navigate("/ordering/FindServices", { replace: true })
            }
            className="mt-2 w-full justify-center rounded-full bg-light-purple py-2 font-inter font-normal capitalize text-light-text"
          >
            {" "}
            Navigate To Order View{" "}
          </Button>
        </div>
      </div>
    </>
  );
}

export default EmailOrderUpdate;
